.user-cancelled .compact {
  display: flex;
  justify-content: start;
  padding: 1rem 1.4rem;
  background: var(--gray-600);
  border-radius: 8px 8px 8px 8px;
}
.user-cancelled .compact .text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #fff;
}
.user-cancelled .compact .icon {
  margin-top: 0.1rem;
  margin-right: 0.8rem;
}
.user-cancelled .expanded,
.user-cancelled .onboarding {
  display: flex;
  justify-content: start;
  border: 0.1rem solid var(--gray-600);
  border-radius: 0.8rem;
  background: transparent;
  padding: 1.6rem;
  margin-bottom: 1.2rem;
}
.user-cancelled .expanded .text,
.user-cancelled .onboarding .text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--gray-800);
}
.user-cancelled .expanded .icon,
.user-cancelled .onboarding .icon {
  margin-right: 1rem;
  margin-top: 0.1rem;
  min-width: 2rem;
}
