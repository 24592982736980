.ai-option {
  padding: 0.7rem 1.5rem 0.7rem 1.1rem;
}
.ai-option:deep() span {
  gap: 1rem;
}
.ai-option.accept.active {
  background-color: var(--success-50);
  border-color: var(--success-500);
}
.ai-option.accept:active {
  --el-button-active-bg-color: var(--success-50);
  --el-button-active-border-color: var(--success-500);
}
.ai-option.reject.active {
  background-color: var(--error-50);
  border-color: var(--error-600);
}
.ai-option.reject:active {
  --el-button-active-bg-color: var(--error-50);
  --el-button-active-border-color: var(--error-600);
}
.ai-option.regular {
  padding: 0.7rem 1.5rem;
}
.ai-option.regular.active {
  background-color: var(--blue-50);
  border-color: var(--blue-500);
}
.ai-option.regular:active {
  --el-button-active-bg-color: var(--blue-50);
  --el-button-active-border-color: var(--blue-500);
}
.ai-option.secondary {
  padding: 0;
  --el-button-bg-color: none;
  --el-border: none;
  ----el-button-text-color: var(--gray-300);
}
.ai-option.secondary:hover,
.ai-option.secondary:focus {
  --el-button-hover-text-color: var(--gray-900);
  --el-button-hover-border-color: none;
  --el-button-hover-bg-color: none;
  box-shadow: none;
}
.ai-option.secondary:active {
  --el-button-active-bg-color: none;
}
.ai-option:hover {
  --el-button-hover-border-color: var(--gray-300);
  --el-button-hover-bg-color: var(--gray-50);
  box-shadow: 0px 1px 3px 0px rgba(16,24,40,0.1), 0px 1px 2px 0px rgba(16,24,40,0.06);
}
.ai-multiselect.pressed {
  pointer-events: none;
}
.ai-multiselect .options {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}
.ai-multiselect .options .ai-option {
  width: max-content;
  border-radius: 10rem;
}
.ai-multiselect .options .ai-option .icon {
  stroke: var(--gray-600);
}
.ai-multiselect .options .ai-option.active .icon {
  stroke: var(--gray-900);
}
.ai-multiselect .controls {
  margin-top: 2rem;
  display: flex;
  gap: 1.2rem;
}
.ai-multiselect .controls .confirm {
  padding: 0.6rem 1rem;
}
.ai-multiselect .controls .confirm.inactive {
  background: var(--gray-100);
  color: var(--gray-400);
  border-color: var(--gray-200);
  box-shadow: 0 0.1rem 0.2rem 0 rgba(16,24,40,0.05);
  opacity: 0.8;
}
