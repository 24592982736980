.link-saver .controls {
  margin-top: 1.2rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
}
.link-saver .added-links {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.link-saver .added-links .link :deep() .ui.left.label.url,
.link-saver .added-links .link :deep() input {
  color: var(--gray-500);
}
.link-saver .added-links .link :deep() input {
  pointer-events: none;
  background: var(--gray-50);
}
.link-saver .added-links .link :deep() .custom.right {
  right: 0.1rem;
}
.link-saver .added-links .link .input-controls {
  border-bottom-right-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-left: 0.1rem solid var(--gray-300);
  padding: 0.9rem;
  gap: 0.9rem;
  display: flex;
  background: var(--base-white);
}
