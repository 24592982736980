<template>
  <i v-if="is_fomantic" :class="fomantic_class" :height="height" :width="width"/>
  <div v-else-if="external" :style="{width, height}">
    <img :src="href_full"/>
  </div>
  <div v-else ref="svgdiv" :style="{width, height}" stroke-width="var(--icon-stroke-width)" v-html="svgData"/>
</template>

<style lang="stylus" scoped>
img
svg {
  display: block
  outline: none
  width: 100%
  height: 100%
  pointer-events: none
}
</style>

<script>
export default {
  props: {
    asset: {
      type: String,
      required: false,
      default: undefined
    },
    icon: {
      type: String,
      required: false,
      default: undefined
    },
    external: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: undefined
    },
    height: {
      type: [Number, String],
      default: undefined
    },
  },
  data() {
    return {
      svgData: undefined
    }
  },
  computed: {
    is_fomantic() {
      return this.icon ? this.icon.indexOf('fm ') === 0 : false
    },
    fomantic_class() {
      return this.icon.replace('fm ', '')
    },
    href_full() {
      if (this.asset) return `/assets/${this.asset}.svg`
      if (this.icon) return `/assets/icons/${this.icon}.svg`
      throw new Error('Failed to form icon href')
    },
    cache () {
      return LOCAL_FRONT ? 'disabled' : '21600'
    }
  },
  async mounted() {
    if (this.external) {
      return
    }

    try {
      const resp = await fetch(this.href_full)
      if (!resp.ok) {
        console.log(`Failed to get ${this.href_full}. Status code ${resp.status}`)
        return
      }
      this.svgData = (await resp.text()).trim()
    }
    catch(err) {
      console.log(`Failed to fetch ${this.href_full}. ${err}`)
      return
    }
  },
  updated() {
    if (!this.svgData) {
      return
    }

    const svgdiv = this.$refs.svgdiv
    if (!svgdiv.firstChild) {
      return
    }

    const attrs = [...svgdiv.attributes]
    attrs.forEach((attr) => {
      if (!['width', 'height'].includes(attr.name)) {
        svgdiv.firstChild.setAttribute(attr.name, attr.value)
      }
    })
  }
}
</script>
