.profile :deep() .builder-inputs .field.bio {
  display: flex;
  flex-direction: column;
}
.profile :deep() .builder-inputs .field.bio .textarea {
  flex-grow: 1;
  max-height: 49.738rem;
}
.profile .controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
}
