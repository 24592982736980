.ip-whitelist {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: overlay;
}
.ip-whitelist .icon {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}
.ip-whitelist .info {
  margin-top: 0.5rem;
}
.ip-whitelist .action {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.ip-whitelist .data {
  flex-grow: 1;
  padding: 1rem;
  min-width: 65rem;
  min-height: 30rem;
  max-width: 75rem;
  max-height: 60rem;
  display: flex;
  height: 0px;
  overflow-y: overlay;
}
.ip-whitelist .data .textarea {
  flex-grow: 1;
}
