<template>
  <div class="tags">
    <Badge v-for="(tag, i) in highlight_tags" :key="i" blue>
      <div>
        <Highlight class="highlight" :text="$utils.str.capitalize(tag) " :highlight="highlight"/>
      </div>
    </Badge>
    <Badge v-for="(tag, i) in visible_tags" :key="i" :brand="i === 0" :indigo="i === 2">
      {{ $utils.str.capitalize(tag) }}
    </Badge>
    <Popover v-if="hidden_tags.length" placement="top-end" :teleported="false" class="popover" :show-after="0" trigger="hover" :offset="6">
      <template v-slot:reference>
        <Badge class="pointer" gray>
          +{{ hidden_tags.length }}
        </Badge>
      </template>
      <div class="popover-tags">
        {{ hidden_tags.join(', ') }}
      </div>
    </Popover>
  </div>
</template>
<style lang="stylus" scoped>
.tags {
  display: flex
  gap: 0.4rem
  &:deep().highlight {
    background: var(--gray-700)
  }
  .popover {
    .popover-tags {
      padding: 0.8rem 1.2rem
      font-size: 1.2rem
      line-height: 1.8rem
      font-weight: 500
      color: var(--gray-900)
    }
  }
}
</style>
<script>
const MAX_VISIBLE = 3
export default {
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    highlight: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show_rest: false
    }
  },
  computed: {
    sorted_tags(){
      const tags = this.tags.slice()
      if (this.highlight) return tags.sort((a) => this.checkTag(a) ? -1 : 1)
      return tags
    },
    highlight_tags() {
      if (this.highlight) return this.sorted_tags.filter(this.checkTag)
      return []
    },
    visible_tags() {
      const end = this.highlight_tags.length >= MAX_VISIBLE ? 0 : this.highlight_tags.length + (MAX_VISIBLE - this.highlight_tags.length)
      return this.sorted_tags.slice(this.highlight_tags.length, end)
    },
    hidden_tags(){
      return this.sorted_tags.slice(3)
    }
  },
  methods: {
    checkTag(tag){
      return tag.toLowerCase().includes(this.highlight.toLowerCase())
    }
  }
}
</script>