.integrations {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2.4rem;
}
.integrations .integration-item {
  flex-basis: 38.5rem;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.integrations .integration-item .content {
  padding: 2.4rem;
  flex-grow: 1;
}
.integrations .integration-item .content .head {
  display: flex;
  gap: 1.2rem;
  justify-content: space-between;
  align-items: center;
}
.integrations .integration-item .content .head .logo-img {
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
  background: var(--base-white);
  width: 5.2rem;
  height: 5.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.integrations .integration-item .content .head .name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--gray-900);
  flex-grow: 1;
}
.integrations .integration-item .content .head .toggle {
  align-self: start;
  display: block;
}
.integrations .integration-item .content .head .toggle :deep() label {
  padding-left: 3.5rem;
}
.integrations .integration-item .content .description {
  margin-top: 2.6rem;
}
.integrations .integration-item .controls {
  padding: 0.9rem 2.4rem;
  display: flex;
  justify-content: flex-end;
}
