<template>
  <Carousel ref="carousel"
            class="carousel"
            :class="{'show-navs': showNavs, vertical}" 
            :show-indicators="showNavs"
            :show-navigators="showNavs"
            :unstyled="false"
            :orientation="orientation"
  >
    <template v-slot:item="slotProps">
      <slot name="item" :slot-props="slotProps.data"/>
    </template>
    <template v-slot:previcon>
      <Icon :icon="vertical ? 'arrow-up' : 'chevron-left'" width="1.6rem" height="1.6rem" stroke="var(--gray-600)"/>
    </template>
    <template v-slot:nexticon>
      <Icon :icon="vertical ? 'arrow-down' : 'chevron-right'" width="1.6rem" height="1.6rem" stroke="var(--gray-600)"/>
    </template>
  </Carousel>
</template>
<style lang="stylus" scoped>
.carousel {
  &.show-navs:deep() {
    .p-carousel-content {
      display: grid
      grid-template-columns: 1fr auto 1fr
    }
  }
  &:deep() {
    text-align: unset
    .p-carousel-content .p-carousel-prev-button
    .p-carousel-content .p-carousel-next-button {
      width: 2.1rem
      background: transparent
      border-radius: 50%
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s
      height: 100%
    }
    .p-carousel-prev {
      justify-content: flex-start
    }
    .p-carousel-next {
      justify-content: flex-end
    }

    .p-carousel-indicator-list .p-carousel-indicator {
      padding: 0 0.25rem
    }
    .p-carousel-indicator-list .p-carousel-indicator button {
      background-color: var(--gray-200)
      width: 2rem
      height: 0.5rem
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s
      border-radius: 0
    }
    .p-carousel-indicator-list .p-carousel-indicator button:hover {
      background: var(--gray-300)
    }
    .p-carousel-indicator-list .p-carousel-indicator.p-carousel-indicator-active button {
      background: var(--gray-400)
      color: #2E3440
    }

    .p-carousel-indicator-list .p-reset {
      justify-content: center
    }

    .p-carousel-indicators {
      padding: 1rem 0 0.8rem
    }
  }

  &.vertical {
    position: relative
    &.show-navs:deep() {
      .p-carousel-content {
        display: grid
        grid-template-areas: "carousel prev" "carousel dots" "carousel next"
        grid-template-columns: 1fr 3.4rem
        grid-template-rows: 1fr auto 1fr
        &:hover {
          .p-carousel-prev-button
          .p-carousel-next-button {
            opacity: 1
          }
          .p-disabled {
            opacity: 0.3
          }
        }
      }
      .p-carousel-prev-button {
        grid-area: prev
        display: flex
        justify-content: end
        align-items: flex-end
        transition: opacity 0.3s
        width: 100%
        padding-bottom: 1.3rem
        border-radius: 0
        opacity: 0
      }
      .p-carousel-next-button {
        grid-area: next
        display: flex
        justify-content: end
        align-items: flex-start
        transition: opacity 0.3s
        width: 100%
        padding-top: 1.3rem
        border-radius: 0
        opacity: 0
      }
      .p-carousel-indicator-list {
        grid-area: dots
        align-items: end
        gap: 1.2rem
        flex-direction: column
        padding: 0.5rem 0.4rem 0.5rem 0
        .p-carousel-indicator {
          padding: 0
          .p-carousel-indicator-button {
            width: 0.8rem
            height: 0.8rem
            border-radius: 50%
            position: relative
            &:before {
              content: ''
              display: block
              width: 3rem
              height: 1.9rem
              position: absolute
              right: calc(100% - 0.8rem)
            }
          }
        }
      }
      .p-carousel-viewport {
        grid-area: carousel
      }
    }
  }
}
 
</style>
<script>
import Carousel from 'primevue/carousel'

export default {
  components: {
    Carousel
  },
  props: {
    showNavs: {
      type: Boolean,
      default: true
    },
    orientation: {
      type: String,
      default: 'horizontal'
    }
  },
  computed: {
    vertical() {
      return this.orientation === 'vertical'
    }
  },
  mounted() {
    if (this.showNavs && this.vertical) this.replaceDots()
  },
  methods: {
    replaceDots() {
      const parent = this.$refs.carousel.$el.querySelector('.p-carousel-content')
      const indicators = this.$refs.carousel.$el.querySelector('.p-carousel-indicator-list')
      parent.append(indicators)
    }
  }
} 
</script>