<template>
  <div class="statuses">
    <Collapse v-for="(item, ind) in statuses"
              :key="item"
              class="collapse"
              :model-value="ind > (hidden_statuses - 1)" 
    >
      <div ref="statuses" 
           class="status delay-in-1000" 
           :class="{active: active_status === ind}" 
           @transitionend="onTransitionEnd()"
      >
        <div style="display: contents" @transitionend.stop>
          <div class="dot">
            <Icon class="icon static" icon="content"/>
            <Icon class="icon animated" external icon="animated/dot-loader"/>
          </div>
          <div class="line"/>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="text fira-code-400">
            {{ item.description }}
          </div>
        </div>
      </div>
    </Collapse>
  </div>
</template>

<style lang="stylus">
.statuses {
  display: flex
  flex-direction: column

  .collapse + .collapse.open {
    margin-top: 1.2rem
  }

  .collapse:last-of-type .status {
    .line {
      opacity: 0
    }
  }

  .status {
    display: grid
    grid-template-columns: auto 1fr
    grid-template-areas: "dot title" "line text"
    gap: 1.2rem 1.6rem
    transition: opacity 0.3s

    &.active {
      .dot {
        .icon {
          &.static {
            opacity: 0
          }
          &.animated {
            opacity: 1
          }
        }
      }
      .text {
        width: 0%
        animation-iteration-count: infinite
      }
    }

    .dot {
      grid-area: dot
      position: relative
      width: 1rem
      height: 1rem
      justify-self: center
      align-self: center
      .icon {
        position: absolute
        transition: opacity 1s
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        width: 1rem
        height: 1rem
      }
      .static {
        position: absolute
        transition: opacity 0.5s
        border-radius: 50%
        background: var(--gray-400)
        position: absolute
        opacity: 1
      }
      .animated {
        opacity: 0
      }
    }

    .line {
      grid-area: line
      justify-self: center
      height: 3.4rem
      width: 0.1rem
      border-radius: 2rem
      background: var(--gray-200)
      transition: opacity 0.5s
      opacity: 1
    }

    .title {
      grid-area: title
      color: var(--gray-700)
      font-size: 1.4rem
      font-weight: 600
      line-height: 1
    }

    .text {
      grid-area: text
      color: var(--gray-600)
      text-align: left
      white-space: nowrap
      overflow: hidden
      width: 100%
      max-width: max-content
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
      animation: typing 5s steps(40, end)
      animation-iteration-count: 1
      animation-delay: 1.2s

      @keyframes typing {
        0% {
          width: 0%
        }
        40% {
          width: 100%
        }
        100% {
          width: 100%
        }
      }
    }
  }
}
</style>

<script>
import {Later} from 'utils/timers'

const MAX_ITEMS = 5
export default {
  props: {
    status: {type: Object, required: false, default: undefined},
    finish: {type: Boolean, required: true}
  },
  emits: ['end'],
  data() {
    return {
      later: new Later(300),
      statuses: [],
      hidden_statuses: 0,
      tab_active: true
    }
  },
  computed: {
    active_status(){
      if (this.finish) return undefined
      return this.statuses.length - 1 
    },
    visible_count() {
      return this.statuses.length - this.hidden_statuses
    }
  },
  watch: {
    status: function (val) {
      if (this.finish || val == undefined) {
        return
      }

      if (this.statuses.length == 0) {
        this.statuses = [...this.statuses, val]
        return
      } 
      
      const prev = this.statuses.at(-1)
      if (val.title != prev.title || val.description != prev.description) {
        this.statuses = [...this.statuses, val]
        // do not allow more than 5 items
        if (this.visible_count > MAX_ITEMS) {
          this.hidden_statuses++
        } 
      }
    },
    active_status(val){
      if (val !== undefined) return 
      if (!this.tab_active) return this.finishLoading()
      this.later.once(() => this.hideLast())
    }
  },
  beforeUnmount() {
    this.later.abort()
    window.removeEventListener('focus', this.onWindowFocus)
    window.removeEventListener('blur', this.onWindowBlur)
  },
  async mounted() {
    if (this.finish) {
      return this.finishLoading()
    }
    if (this.status) {
      this.statuses.push(this.status)
    }

    window.addEventListener('focus', this.onWindowFocus)
    window.addEventListener('blur', this.onWindowBlur)
  },
  methods: {
    onTransitionEnd() {
      this.statuses.splice(this.statuses.length - 1, 1)
      if (this.visible_count === 0) return this.finishLoading()
      this.hideLast()
    },
    hideLast(){
      this.$refs.statuses[this.statuses.length - 1].style.opacity = 0
    },
    finishLoading(){
      this.$emit('end')
    },
    onWindowFocus(){
      this.tab_active = true
    },
    onWindowBlur(){
      this.tab_active = false
      if (this.finish) this.finishLoading()
    }
  }
}
</script>