<template>
  <el-switch v-model="value" class="switch" :size="size"/>
</template>
<style lang="stylus" scoped>
.switch {
  -el-switch-on-color: var(--el-color-primary)
  --el-switch-off-color: rgba(0, 0, 0, 0.05)
  --el-switch-on-color: var(--brand-600)
  --el-switch-border-color: transparent

  &.el-switch--small {
    &:deep() {
      .el-switch__core {
        min-width: 3.5rem
        height: 1.5rem
      }
      .el-switch__action {
        width: 1.5rem
        height: 1.5rem
        box-shadow: 0 1px 2px 0 var(--gray-300), 0 0 0 1px var(--gray-300) inset
        background: var(--base-white) linear-gradient(transparent, rgba(0, 0, 0, 0.05))
      }
    }
    &:not(.is-checked):deep() {
      .el-switch__core {
        .el-switch__action {
          left: -0.15rem
        }
      }
    }

    &.is-disabled:deep() .el-switch__core
    &.is-disabled:deep() .el-switch__label {
      cursor: default
    }
  }
}
</style>
<script>
export default {
  mixins: [ModelValue(Boolean)],
  props: {
    size: {
      type: String,
      default: 'small',
      validator(value) {
        return ['large', 'default', 'small'].includes(value)
      }
    }
  }
}
</script>