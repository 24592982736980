<template>
  <el-dropdown class="pointer" style="margin-left: auto;" placement="top-start" trigger="click">
    <Icon icon="menu-04" stroke="var(--gray-700)" stroke-width="1" width="1.4rem" height="1.4rem"/>
    <template v-slot:dropdown>
      <el-dropdown-menu class="chats-dropdown-edit">
        <div class="title">Modify the answer</div>
        <el-dropdown-item v-for="action of ACTIONS" :key="action.name" class="item" @click="$emit('ask-ai', {text: action.prompt})">
          <Icon :icon="action.icon" width="1.6rem" height="1.6rem" stroke-width="1.2"/>
          <span>{{ action.name }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style lang="stylus">
.chats-dropdown-edit {
  --el-dropdown-menuItem-hover-color: var(--gray-700)
  padding: 0
  background: #FFFDFB
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)
  .title {
    text-align: left
    font-size: 1.4rem
    font-weight: 500
    color: var(--gray-700)
    border-bottom: 0.1rem solid var(--gray-200)
    padding: 1.2rem 1.6rem
  }
  .item {
    color: var(--gray-700)
    gap: 1rem
    padding: 1.2rem 1.6rem
  }
}
</style>

<script setup>
defineEmits(['ask-ai'])

const ACTIONS = [
  {
    name: 'Creative',
    icon: 'edit-04',
    prompt: 'Make it more creative and engaging. Use a variety of expressions and introduce some novel ideas. --temp 0.9'
  },
  {
    name: 'Friendly',
    icon: 'message-smile-circle',
    prompt: 'Make it friendlier and more approachable. Use a conversational tone and warm language'
  },
  {
    name: 'Professional',
    icon: 'luggage-02',
    prompt: 'Make it more professional. Ensure the language is formal and the tone is appropriate for a business audience'
  },
  {
    name: 'Concise',
    icon: 'align-vertical-center-01',
    prompt: 'Make it more concise. Focus on brevity and clarity, removing any unnecessary words or phrases'
  },
  {
    name: 'Key points',
    icon: 'list',
    prompt: 'Make it highlight the key points. Ensure the main ideas are clear and easily identifiable'
  }
]
</script>