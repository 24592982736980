.chat {
  display: flex;
  justify-content: start;
  padding: 1rem 1.4rem;
  border-radius: 0px 8px 8px 8px;
  background: var(--base-white);
}
.chat .text {
  color: var(--text-primary);
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
}
.chat .icon {
  margin-right: 0.8rem;
  margin-top: 0.2rem;
  min-width: 2rem;
  stroke: var(--brand-500);
}
.chat.error {
  background: var(--gray-600);
}
.chat.error .icon {
  stroke: var(--base-white);
}
.chat.error .text {
  color: var(--base-white);
}
