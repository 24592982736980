.date-input,
.date-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--gray-600);
  padding: 0.9rem 1rem;
  cursor: default;
}
.date-placeholder {
  color: var(--gray-400);
}
