<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <Loader v-if="!c_users">
    Loading<br>
    Please wait...
  </Loader>
  <DataTable v-else class="data-table" :items="c_users" :lazy="false" :sort-field="sort_field" :sort-order="sort_order" @sort="onSort">
    <template v-slot:header-right>
      <SearchInput @search="onSearch"/>
    </template>
    <Column sortable sort-field="name" style="width: 70%">
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="Name" sortable :sort-order="sortOrder"/>
      </template>
      <template v-slot:body="{data: {name}}">
        <div class="ellipsis-cell">
          <div class="ellipsed">
            <Highlight :text="name" :highlight="highlight"/>
          </div>
        </div>
      </template>
    </Column>
    <Column sortable sort-field="email">
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="E-mail" sortable :sort-order="sortOrder"/>
      </template>
      <template v-slot:body="{data: {email}}">
        <div nowrap>
          <Highlight :text="email" :highlight="highlight"/>
        </div>
      </template>
    </Column>
    <Column sort-field="storage_limit" sortable>
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="Flimit" sortable :sort-order="sortOrder"/>
      </template>
      <template v-slot:body="{data}">
        <div nowrap class="limit">
          {{ $utils.format.bytes(data.storage_limit) }}
          <Icon class="pointer" icon="edit-03" width="2rem" height="2rem" @click="() => openFLimit(data)"/>
        </div>
      </template>
    </Column>
    <Column sortable sort-field="occupied_storage">
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="FUsed" sortable :sort-order="sortOrder"/>
      </template>
      <template v-slot:body="{data: {occupied_storage}}">
        {{ $utils.format.bytes(occupied_storage) }}
      </template>
    </Column>
  </DataTable>
  <FLimitModal ref="flimitModal" @update="onUserUpdate"/>
</template>

<style lang="stylus" scoped>
.data-table {
  .limit {
    display: flex
    align-items: center
    gap: 0.4rem
  }
}
</style>

<script>
import $profile from 'stores/profile'
import FLimitModal from './flimit-modal.vue'
import {DataTable, TableHead, SearchInput} from 'components/data-table'
import Column from 'primevue/column'

export default {
  components: {
    DataTable,
    SearchInput,
    Column,
    TableHead,
    FLimitModal,
  },
  data: function() {
    return {
      users: undefined,
      highlight: '',
      sort_field: 'name',
      sort_order: 1
    }
  },
  computed: {
    c_users() {
      if (this.highlight) return this.users.filter(x => x.name.includes(this.highlight))
      return this.users
    }
  },
  async mounted() {
    this.users = await $profile.fetchUserTable() || []
  },
  methods: {
    openFLimit(user) {
      this.$refs.flimitModal.open(user)
    },
    async onUserUpdate(user_id, data) {
      await $profile.updateUser(user_id, data)
      const ind = this.userTableData.findIndex(el => el.id === user_id)
      Object.assign(this.userTableData[ind], data)
      this.userTableData = Object.assign([], this.userTableData)
    },
    onSearch(name){
      this.highlight = name
    },
    onSort(ev){
      this.sort_field = ev.sortField
      this.sort_order = ev.sortOrder
    },
  },
}
</script>