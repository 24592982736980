.popover-body {
  padding: 1.8rem 1.6rem;
  min-width: 29.2rem;
  display: flex;
  flex-direction: column;
}
.popover-body .head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary);
  font-weight: 500;
}
.popover-body .head .text {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}
.popover-body .input-block {
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  gap: 0.8rem;
}
.popover-body .input-block .label {
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}
.popover-body .overview-controls {
  margin-top: 0.6rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.popover-body .overview-controls .add-btn {
  display: flex;
  justify-content: space-between;
  min-width: 0;
}
