<template>
  <div v-if="feed" 
       class="action feed pointer" 
       @mouseover="hover = true" 
       @mouseleave="hover = false" 
  >
    <!-- TODO: Uncomment when features will be done -->
    <!-- <Collapse v-model="hover" class="collapse" horizontal>
      <div class="strange-button pointer" @click.stop="$emit('click')">
        <Icon class="icon" stroke="var(--brand-600)" icon="event-icon" width="1.6rem" height="1.6rem"/>
      </div>
    </Collapse> -->
    <div class="icons" relative>
      <Icon class="icon active" width="1.6rem" height="1.6rem" icon="animated/stars-animations"/>
      <Icon class="icon" width="1.6rem" height="1.6rem" icon="stars-02-secondary" external/>
    </div>
    <span>{{ text }}</span>
    <!-- <div class="ghost">
      This block is needed to mimic margin-right, but with a smooth width change 
       (this is necessary so that the text does not jump when hovering the cursor).
    </div> -->
  </div>
  <div v-else class="action">
    <div class="icon">
      <Icon width="1.6rem" height="1.6rem" icon="stars-02-secondary" external/>
    </div>
    <span>{{ text }}</span>
  </div>
</template>

<style lang="stylus" scoped>
.action {
  display: inline-flex
  gap: 0.4rem
  flex-direction: row
  font-size: 1.2rem
  color: var(--text-secondary)
  border-radius: 0.6rem
  line-height: 1.8rem
  padding: 0.4rem 0.8rem 0.4rem 0
  transition: background-color 0.3s ease, color 0.3s ease
  &.feed {
    .collapse {
      margin-top: -0.4rem
      .strange-button {
        padding: 0.2rem 0.8rem
        border-radius: 0.4rem
        border: 0.1rem solid
        border-color: transparent
        margin: 0.1rem 0.4rem 0.3rem 0.1rem
        transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease
        &:hover {
          border-color: var(--gray-300)
          background: var(--base-white)
          box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(16, 24, 40, 0.1), 0rem 0.1rem 0.2rem 0rem rgba(16, 24, 40, 0.06)
        }
      }
    }
    .icons {
      display: flex
      align-self: flex-start
      width: 1.6rem
      height: 1.6rem
      min-width: max-content
      // margin-left: -0.4rem
      .icon {
        position: absolute
        opacity: 1
        transition: opacity 0.3s ease
      }
      .active {
        opacity: 0
      }
    }
    // .ghost {
    //   width: 3.9rem
    //   height: 100%
    //   transition: width 0.2s
    // }
  }

  &:hover {
    color: var(--brand-700)
    // .ghost {
    //   width: 0rem
    // }
    .icons {
      .icon {
        opacity: 0
      }
      .active {
        opacity: 1
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    feed: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      hover: false
    }
  },
}
</script>