<template>
  <div class="input right custom-textarea" :class="{disabled: disabled, icon: !!$slots.custom_icon}">
    <textarea ref="textarea" 
              v-model="value"
              :disabled="disabled"
              :placeholder="placeholder"
              :rows="rows"
              :maxlength="maxlength"
              @change="onInput"
              @blur="$emit('blur', this)"
    />

    <div v-if="$slots.custom_icon" class="custom icon">
      <slot name="custom_icon" @append="onAppend"/>
    </div>
    <div v-if="!hideCounter || description" class="footer">
      <Footnote :visible="description">
        {{ description }}
      </Footnote>
      <SymbolCounter v-if="maxlength > 0 && !hideCounter" 
                     :current="modelValue.length" 
                     :maxlength="maxlength"
      />
    </div>
  </div>
</template>

<style scoped lang="stylus">
.custom-textarea {
  display: flex
  flex-direction: column

  textarea {
    resize: none
    width: 100%
    height: 100%
    word-break: break-word
    margin: 0
    -webkit-appearance: none
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
    padding: 0.4375em 0.6875em
    background: var(--base-white)
    border: 0.1rem solid var(--gray-300)
    outline: none
    color: var(--gray-900)
    border-radius: 0.8rem
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
    transition: color 0.1s ease, border-color 0.1s ease
    font-size: 1.6rem
    line-height: 1.2857

    &::placeholder {
      opacity: 0.7
    }
    &[disabled] {
      opacity: 1
    }
    &:focus {
      box-shadow: 0px 0px 0px 4px #D1F1FD, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
      border-color: #75C4F3
    }
    &:hover {
      border-color: #75C4F3
    }
  }
  .custom.icon {
    height: 4rem
    cursor: pointer
    position: absolute
    top: 0
    right: 0
    margin: 0
    width: 4rem
    display: flex
    justify-content: center
    align-items: center
  }
  .footer {
    display: flex
    flex-direction: row
    & > :last-child {
      margin-left: auto
    }
  }
}
</style>

<script setup>
import SymbolCounter from './symbol-counter'
defineEmits(['blur', 'right-icon'])
</script>

<script>
export default {
  mixins: [ModelValue(String)],
  props: {
    placeholder: {type: String, default: '', required: false},
    disabled: {type: Boolean, default: false},
    rows: {type: [String, Number], default: '2', required: false},
    maxlength: {type: Number, default: 0, required: true},
    hideCounter: {type: Boolean, default: false},
    description: {type: String, required: false, default: ''},
  },
  watch: {
    value(next, prev) {
      const dif = next.length - prev.length
      if (dif > 1) {
        this.$refs.textarea.focus()
        this.$nextTick(() => {
          this.$refs.textarea.selectionEnd = prev.length
        })
        return
      }
    }
  }
}
</script>

