.popconfirm {
  --el-text-color-regular: var(--text-secondary);
  font-size: 1.4rem;
  padding: 1.2rem;
  min-width: 15rem;
  box-shadow: 0px 0px 1.2rem rgba(0,0,0,0.12);
}
.popconfirm[data-popper-placement^=bottom] .el-popper__arrow::before {
  border-top-color: var(--el-border-color-light) !important;
}
.popconfirm[data-popper-placement^=top] .el-popper__arrow::before {
  border-bottom-color: var(--el-border-color-light) !important;
  border-right-color: var(--el-border-color-light) !important;
}
.popconfirm .el-button--primary {
  background-color: var(--brand-500) !important;
  border-color: transparent !important;
}
.popconfirm .el-button--primary:hover {
  background-color: var(--brand-600) !important;
  border-color: transparent !important;
}
.popconfirm .el-button--primary:active {
  border-color: transparent !important;
}
