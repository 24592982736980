<template>
  <div class="sources">
    <SourcesGround :loading="loading" :sources="sources" 
                   :show-all="show_all" :cols-count="COLS_COUNT" @show-all="show_all = true"
    />
    <!-- REST ITEMS -->
    <Collapse v-if="rest_items" v-model="show_all" class="rest-items">
      <div class="rest">
        <Source v-for="(source, i) of rest_items" :key="i" class="source" :data="source" :num="COLS_COUNT + i + 1"/>
        <SourceWrap class="less" pointer @click.prevent="show_all = false">
          View less
        </SourceWrap>
      </div>
    </Collapse>
  </div>
</template>

<style lang="stylus" scoped>
.sources {
  .rest-items.open
  .rest-items.open:deep() .collapse-inner {
    padding-bottom: 1rem
    margin-bottom: -1rem
  }
  .rest-items {
    transition: all 200ms
    margin-top: 0rem
    &.open {
      margin-top: 1.2rem
    }
    .rest {
      display: grid
      grid-template-columns: repeat(5, 1fr)
      line-height: 1.7rem
      min-height: 2.8rem
      gap: 1.2rem
      .source {
        min-width: 0
      }
      .less {
        justify-content: center
      }
    }
  }
}
</style>
<script>
import SourcesGround from './sources-ground.vue'
import Source from './source.vue'
import SourceWrap from './source-wrap.vue'
const COLS_COUNT = 5
export default {
  components: {SourcesGround, Source, SourceWrap},
  props: {
    sources: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {COLS_COUNT}
  },
  data() {
    return {
      show_all: false
    }
  },
  computed: {
    rest_items(){
      return this.sources.toSpliced(0, COLS_COUNT)
    },
  },
}
</script>
