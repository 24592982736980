<template>
  <div class="download-progress" :class="{error, inline}">
    <div class="file-info">
      <slot v-if="$slots.img" name="img" :instance="instance" class="file-icon"/>
      <FileIcon v-else :error="error" :ext="ext" class="file-icon"/>
      <div class="text">
        <div class="name">          
          {{ file.name }}
        </div>
        <div v-if="error" class="err-text">
          Upload failed, 
          <b style="font-weight: 700;" class="pointer" @click="instance.start()"> try again</b>
        </div>
        <div v-else class="size">
          {{ size }}
        </div>
      </div>
    </div>
    <div class="delete pointer" @click="$emit('delete', instance.id)">
      <Icon v-show="!error" width="2rem" height="2rem" icon="trash-04" stroke="var(--gray-500)"/>
      <Icon v-show="error" width="2rem" height="2rem" icon="trash-04" stroke="var(--error-600)"/>
    </div>
    <Progressbar class="progress" :value="progress" :error="error" :success="success"/>
  </div>
</template>
<style lang="stylus" scoped>

.error {
  &.download-progress {
    background: var(--error-50)
  }
  .text {
    color: var(--error-600)
    white-space: nowrap
  }
}
.download-progress {
  display: grid
  grid-template-areas: 'Info Progress Delete'
  grid-template-columns: 26rem 1fr auto
  grid-template-rows: 1fr
  background: var(--base-white)
  align-items: center
  .file-info {
    grid-area: Info
    display: flex
    padding: 1.6rem 2rem 1.6rem 2.4rem
    line-height: 2rem
    gap: 1.2rem
    .text {
      min-width: 0
      font-size: 1.4rem
      .size {
        color: var(--gray-600)
        font-weight: 400
      }
      .name {
        font-weight: 500
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
      }
    }
  }
  .delete {
    grid-area: Delete
    padding: 1.6rem 2.4rem 1.6rem 2rem
    display: flex
  }
  .progress {
    grid-area: Progress
    padding: 1.6rem 0
  }
}

.download-progress.inline {
  grid-template-areas: 'Info Info Delete' '. Progress Progress'
  grid-template-columns: 6.7rem 1fr auto
  grid-template-rows: 1fr
  padding: 0
  .progress {
    padding: 0 1.6rem 1.6rem 0
  }
  .file-info {
    padding: 1.6rem 2rem 0 1.6rem
  }
  .delete {
    padding: 0 1.6rem 0 0
  }
}
</style>

<script>
import {FileUpload} from 'stores/files'

export default {
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    instance: {
      type: FileUpload,
      requied: true,
      default: undefined
    }
  },
  emits: ['delete'],
  computed: {
    file () {
      return this.instance.file
    },
    ext () { 
      return this.file.name.split('.').pop()
    },
    size () {
      return $utils.format.bytes(+this.instance.file.size)
    },
    progress () {
      return Math.floor(this.instance.bytes_loaded / this.instance.file.size * 100)
    },
    error() {
      return this.instance.error
    },
    success() {
      return this.instance.result !== undefined
    }
  }
}
</script>