.ingest .credentials {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ingest .credentials .info {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}
.ingest .credentials .info span {
  font-size: 1.5rem;
}
.ingest .credentials .info span:first-child {
  font-weight: 500;
}
.ingest .credentials .info .icon {
  cursor: pointer;
}
.ingest .credentials .issue {
  margin-top: 0.5rem;
}
.ingest .no-credentials div {
  margin-bottom: 1rem;
}
