.el-button {
  --el-button-font-weight: 500;
  outline: none;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding: 1rem 1.4rem;
  height: auto;
}
.el-button :deep() span {
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;
}
.el-button+.el-button {
  margin-left: 0;
}
.el-button.small {
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.el-button.small :deep() span {
  display: flex;
  gap: 0.5rem;
}
.el-button.tiny {
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.el-button.tiny :deep() span {
  display: flex;
  gap: 0.2rem;
}
.el-button.link {
  padding: 0.2rem;
}
.el-button--default {
  --el-button-outline-color: transparent;
  --el-button-text-color: var(--gray-700);
  --el-button-bg-color: var(--base-white);
  --el-button-border-color: var(--gray-300);
  --el-button-hover-text-color: var(--gray-700);
  --el-button-hover-bg-color: var(--gray-50);
  --el-button-hover-border-color: var(--gray-300);
  --el-button-active-text-color: var(--gray-700);
  --el-button-active-bg-color: var(--gray-100);
  --el-button-active-border-color: var(--gray-300);
  --el-button-disabled-text-color: var(--gray-700);
  --el-button-disabled-bg-color: var(--base-white);
  --el-button-disabled-border-color: var(--gray-300);
}
.el-button--default:focus {
  box-shadow: 0px 0px 0px 4px var(--gray-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.el-button--default.text {
  --el-button-border-color: transparent;
  --el-button-hover-border-color: var(--gray-50);
  --el-button-active-border-color: var(--gray-100);
  --el-button-disabled-border-color: transparent;
}
.el-button--default.text:focus {
  box-shadow: none;
}
.el-button--default.link {
  --el-button-border-color: #fff;
  --el-button-bg-color: #fff;
  --el-button-hover-border-color: #fff;
  --el-button-hover-bg-color: #fff;
  --el-button-hover-text-color: var(--gray-900);
  --el-button-active-border-color: #fff;
  --el-button-active-bg-color: #fff;
  --el-button-active-text-color: var(--gray-900);
  --el-button-disabled-border-color: #fff;
  --el-button-disabled-bg-color: #fff;
}
.el-button--default.link:focus {
  box-shadow: none;
}
.el-button--primary {
  --el-button-text-color: #fff;
  --el-button-bg-color: var(--brand-500);
  --el-button-border-color: var(--brand-500);
  --el-button-hover-text-color: #fff;
  --el-button-hover-bg-color: var(--brand-600);
  --el-button-hover-border-color: var(--brand-700);
  --el-button-active-text-color: #fff;
  --el-button-active-bg-color: var(--brand-500);
  --el-button-active-border-color: var(--brand-500);
  --el-button-outline-color: var(--brand-300);
  --el-button-disabled-text-color: #fff;
  --el-button-disabled-bg-color: var(--brand-500);
  --el-button-disabled-border-color: var(--brand-500);
}
.el-button--primary:focus {
  box-shadow: 0px 0px 0px 4px var(--brand-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.el-button--primary.text {
  --el-button-text-color: var(--brand-500);
  --el-button-bg-color: var(--base-white);
  --el-button-border-color: transparent;
  --el-button-hover-text-color: var(--brand-500);
  --el-button-hover-bg-color: var(--brand-50);
  --el-button-hover-border-color: transparent;
  --el-button-active-text-color: var(--brand-500);
  --el-button-active-bg-color: var(--brand-100);
  --el-button-active-border-color: transparent;
  --el-button-outline-color: transparent;
  --el-button-disabled-text-color: var(--brand-500);
  --el-button-disabled-bg-color: var(--base-white);
  --el-button-disabled-border-color: transparent;
}
.el-button--primary.text:focus {
  box-shadow: none;
}
.el-button--primary.link {
  --el-button-text-color: var(--brand-500);
  --el-button-border-color: #fff;
  --el-button-bg-color: #fff;
  --el-button-hover-border-color: #fff;
  --el-button-hover-bg-color: #fff;
  --el-button-hover-text-color: var(--brand-600);
  --el-button-active-border-color: #fff;
  --el-button-active-bg-color: #fff;
  --el-button-active-text-color: var(--brand-600);
  --el-button-disabled-border-color: #fff;
  --el-button-disabled-bg-color: #fff;
  --el-button-disabled-text-color: var(--brand-600);
}
.el-button--primary.link:focus {
  box-shadow: none;
}
