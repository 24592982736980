@keyframes fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes wait {
  0% { opacity: 0 }
  100% { opacity: 0 }
}


@keyframes pulse {
  0% { opacity: 0.1 }
  50% { opacity: 0.6 }
  100% { opacity:0.1 }
}
