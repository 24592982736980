sup {
  display: inline;
  text-align: center;
  color: var(--gray-900);
  transition: background 0.2s, color 0.2s;
  border-radius: 4rem;
  font-size: 1rem !important;
  padding: 0 0.3rem;
  margin-right: -0.2rem;
  margin-left: -0.1rem;
}
sup.focused,
sup:hover {
  color: var(--base-white);
  background: var(--brand-500);
}
