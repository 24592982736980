.chat-input {
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  border-top: none;
  background: transparent;
  padding-top: 0;
  padding-left: 1.4rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}
.chat-input .input {
  flex-grow: 1;
}
