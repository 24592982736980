.p-autocomplete-overlay {
  --p-autocomplete-overlay-background: var(--base-white);
  --p-autocomplete-overlay-color: #4b5563;
  --p-autocomplete-overlay-border-color: var(--primary-300);
  --p-autocomplete-overlay-border-radius: 0 0 0.8rem 0.8rem;
  --p-autocomplete-overlay-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
  --p-autocomplete-option-color: var(--gray-900);
  --p-autocomplete-option-focus-color: var(--gray-900);
  z-index: 3001 !important;
  border-top: none !important;
  -webkit-clip-path: inset(0px -5px -5px -5px);
  clip-path: inset(0px -5px -5px -5px);
  overflow: hidden;
  margin-top: -1px;
}
.p-autocomplete-overlay .p-autocomplete-list-container {
  max-height: 20rem !important;
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option:hover {
  background: #edfaff !important;
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option,
.p-autocomplete-overlay .p-autocomplete-empty-message {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  background: transparent;
  transition: background 0.2s;
  border-radius: 0;
  border-top: 0.1rem solid #fafafa;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
