<template>
  <el-input v-model.trim="search"
            size="large"
            placeholder="Search"
            class="search-input"
  >
    <template v-slot:prefix>
      <Icon icon="search" stroke="var(--gray-500)" width="2rem" height="2rem"/>
    </template>
  </el-input>
  <Divider m="0 -1.6rem 0"/>
  <div class="items">
    <div v-if="c_items.length === 0">
      Nothing found
    </div>
    <template v-else>
      <div v-for="item in c_items" :key="item" class="item">
        <Checkbox :model-value="chosens.has(item)" :checked-value="item" @change="onChecked(item)">
          <Highlight :text="item" :highlight="search"/>
        </Checkbox>
      </div>
    </template>
  </div>
</template>
<style lang="stylus" scoped>
.search-input {
  &:deep() {
    .el-input__wrapper {
      padding: 0
      box-shadow: none
    }
    input:placeholder {
      color: var(--gray-500)
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
    }
  }
}
.items {
  margin: 0 -1.6rem 0
  padding: 1rem 1.6rem
  display: flex
  flex-direction: column
  gap: 1.8rem
  max-height: 31rem
  overflow-y: auto
  .item {
    height: 2rem
    display: flex
    align-items: center
  }

  @media (max-height 555px) {
    max-height: 10rem
  }
}
</style>
<script>
import {Later} from 'utils/timers'
import {ctx_mixin} from './filter-defs'
export default {
  mixins: [ctx_mixin],
  props: {
    items: {
      type: Array,
      default: () => [] 
    },
    title: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      search: '',
      chosens: new Set(),
      later: new Later(200)
    }
  },
  computed: {
    c_items(){
      return this.items.filter(item => item.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  watch: {
    async prev(){
      this.chosens.clear()
      this.prev.forEach(item => this.chosens.add(item))
      this.later.once(this.cleanSearch)
    },
    chosens:{
      handler() {
        this.next = Array.from(this.chosens)},
      deep: true
    }
  },
  beforeUnmount() {
    this.later.abort()
  },
  methods: {
    onChecked(item) {
      if (this.chosens.has(item)) {
        this.chosens.delete(item)
      } else {
        this.chosens.add(item)
      }
    },
    cleanSearch(){
      this.search = ''
    }
  }
}
</script>