<template>
  <GenratePopover ref="popover" v-read:task_error="error" @generated="onGenerated">
    <div class="ui form">
      <div class="field">
        <label v-if="title">{{ title }}</label>
        <TextArea v-model="generated_text"
                  hide-counter
                  :maxlength="maxlength"
                  :rows="rows || 4"
        />
      </div>
    </div>
    <template v-slot:controls>
      <div class="controls">
        <Button small primary @click="$refs.popover.generate()">
          <Icon icon="stars-03" width="2rem" height="2rem" stroke="white"/>
          <span>Regenrate</span>
        </Button>
        <div class="right">
          <Button small :disabled="!can_add || error" @click="onAppend()">
            <Icon icon="plus" width="2rem" height="2rem" stroke="var(--gray-700)"/>
            <span>Append</span>
          </Button>
          <Button small primary :disabled="error" @click="onReplace">
            <Icon icon="refresh-cw-05" width="1.6rem" height="1.6rem" stroke="var(--base-white)"/>
            <span>Replace</span>
          </Button>
        </div>
      </div>
    </template>
  </GenratePopover>
</template>
<style lang="stylus" scoped>
.controls {
  display: flex
  flex-direction: row
  justify-content: space-between
  min-width: 40rem

  .right {
    display: flex
    flex-direction: row
    gap: 0.8rem
  }
}

.field {
  width: 50rem
}
</style>

<script>
import GenratePopover from './genrate-popover.vue'
export default {
  components: {
    GenratePopover
  },
  props: {
    title: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    rows: {type: [String, Number], default: '2', required: false},
    maxlength: {type: Number, default: 0, required: true},
    description: {type: String, required: false, default: ''},
    parent: {type: Node, default: document.body},
    can_add: {type: Boolean, default: true, required: true}
  },
  emits: ['replace', 'append'],
  data() {
    return {
      generated_text: '',
      error: undefined
    }
  },
  methods: {
    onGenerated(value) {
      this.generated_text = value
    },
    onReplace() {
      this.$emit('replace', this.generated_text)
      this.$refs.popover.hide()
    },
    onAppend(){
      this.$emit('append', this.generated_text)
      this.$refs.popover.visible = false
    }
  }
}
</script>