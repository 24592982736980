.sample-item {
  width: 100%;
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  background: var(--base-white);
}
.sample-item .icon {
  height: 4rem;
}
.sample-item .text {
  min-width: 0;
  font-size: 1.4rem;
  flex-grow: 1;
}
.sample-item .text .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}
.sample-item .text .name.failed {
  color: var(--error-600);
}
.sample-item .actions {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
