.settings-icon {
  display: flex;
  justify-content: center;
  background: url("assets/bg-pattern-decorative.png") no-repeat center calc(50%);
}
.settings-icon .icon-bg {
  width: 10.4rem;
  height: 10.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}
