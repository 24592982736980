    table {
      width: 100%
      border-radius: 0.4rem
      border-collapse: separate
      border-spacing: 0
      tr {
        th
        td {
          border-right: 0.1rem solid var(--gray-200)
          border-bottom: 0.1rem solid var(--gray-200)
          padding: 1.2rem 1.4rem
          text-align: left
          font-size: 1.6rem
          line-height: 2.4rem
        }
        th {
          background: #F7F5F3
          border-top: 0.1rem solid var(--gray-200)
          font-weight: 600
          color: var(--gray-900)
        }
        td {
          color: var(--gray-900)
          font-weight: 400
        }
        th:first-child
        td:first-child {
          border-left: 0.1rem solid var(--gray-200)
        }
        &:first-child {
          th:first-child {
            border-top-left-radius: 0.4rem
          }
          th:last-child {
            border-top-right-radius: 0.4rem
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 0.4rem
          }
          td:last-child {
            border-bottom-right-radius: 0.4rem
          }
        }
      }
    }