<template>
  <Popover v-model="show">
    <template v-slot:reference>
      <slot name="reference"></slot>
    </template>
    <div class="popover-body">
      <div class="head">
        <div v-if="title" class="text">
          {{ title }}
        </div>
        <div class="text pointer" @click="show=false">
          <Icon stroke="var(--gray-500)" height="2rem" width="2rem" icon="x-close"/>
        </div>
      </div>
      <div class="input-block">
        <div v-if="label" class="label">{{ label }}</div>
        <Input ref="input" 
               v-model="name" 
               :maxlength="maxlength"
               class="input"
               :placeholder="placeholder"
               @keyup.enter="submit()"
        />
      </div>

      <div class="overview-controls">
        <Button :disabled="!valid" primary tiny class="add-btn" @click="submit()">
          <Icon stroke="#fff" width="1.6em" height="1.6rem" icon="plus"/>
          Add
        </Button>
      </div>
    </div>
  </Popover>
</template>

<style lang="stylus" scoped>
.popover-body {
  padding: 1.8rem 1.6rem
  min-width: 29.2rem
  display: flex
  flex-direction: column
  gap: 1.6rem
  .head {
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    color: var(--text-primary)
    font-weight: 500
    .text {
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 600
      line-height: 2.8rem
    }
  }

  .input-block {
    display: flex
    flex-direction: column
    gap: 0.8rem
    .label {
      color: var(--gray-700)
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
    }
    .input {
      width: 100%
    }
  }
  .overview-controls {
    width: 100%
    display: flex
    justify-content: flex-end
    .add-btn {
      display: flex
      justify-content: space-between
      min-width: 0
    }
  }
}
</style>

<script>
export default {
  props: {
    placeholder: {  
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    validator: {
      type: Function,
      default: undefined
    },
    maxlength: {
      type: Number,
      default: 50
    }
  },
  emits: ['submit'],
  data(){
    return {
      name: '',
      show: false
    }
  },
  computed: {
    valid () {
      if (!this.name) return false
      if (this.validator) return this.validator(this.name)
      return true
    }
  },
  watch: {
    show(val){
      if (val) this.$nextTick(() => this.$refs.input.focus())
    }
  },
  methods: {
    submit(){
      this.show = false
      this.$emit('submit', this.name)
      this.name = ''
    },
    open(){
      this.show = true
    },
  }
}
</script>