.statuses {
  display: flex;
  flex-direction: column;
}
.statuses .collapse + .collapse.open {
  margin-top: 1.2rem;
}
.statuses .collapse:last-of-type .status .line {
  opacity: 0;
}
.statuses .status {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "dot title" "line text";
  gap: 1.2rem 1.6rem;
  transition: opacity 0.3s;
}
.statuses .status.active .dot .icon.static {
  opacity: 0;
}
.statuses .status.active .dot .icon.animated {
  opacity: 1;
}
.statuses .status.active .text {
  width: 0%;
  animation-iteration-count: infinite;
}
.statuses .status .dot {
  grid-area: dot;
  position: relative;
  width: 1rem;
  height: 1rem;
  justify-self: center;
  align-self: center;
}
.statuses .status .dot .icon {
  position: absolute;
  transition: opacity 1s;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1rem;
  height: 1rem;
}
.statuses .status .dot .static {
  position: absolute;
  transition: opacity 0.5s;
  border-radius: 50%;
  background: var(--gray-400);
  position: absolute;
  opacity: 1;
}
.statuses .status .dot .animated {
  opacity: 0;
}
.statuses .status .line {
  grid-area: line;
  justify-self: center;
  height: 3.4rem;
  width: 0.1rem;
  border-radius: 2rem;
  background: var(--gray-200);
  transition: opacity 0.5s;
  opacity: 1;
}
.statuses .status .title {
  grid-area: title;
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
}
.statuses .status .text {
  grid-area: text;
  color: var(--gray-600);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  max-width: max-content;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  animation: typing 5s steps(40, end);
  animation-iteration-count: 1;
  animation-delay: 1.2s;
}
@-moz-keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
