<template>
  <CollapseMenu :loading="loading" 
                class="collapse-menu" 
                :title="title"
  >
    <div class="career">
      <MdContent v-if="summary" class="summary" :markdown="summary"/>
      <div v-if="jobs || education" class="career-grid">
        <div v-if="jobs" class="jobs">
          <div class="title">
            Career Summary
          </div>
          <div class="jobs-items">
            <div v-for="(job, index) in jobs" :key="index" class="job">
              <div v-if="job.logo_url" class="logo">
                <img :src="job.logo_url"/>
              </div>
              <Icon v-else icon="building-08" width="2.4rem" height="2.4rem"/>
              <div class="info">
                <div class="position" v-html="job.position"/>
                <div class="company" v-html="job.company"/>
                <div class="period">{{ makePeriod(job.start, job.end) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="education" class="education">
          <div class="title">
            Educational Background
          </div>
          <div class="education-items">
            <div v-for="(edu, index) in education" :key="index" class="establishment">
              <Icon icon="graduation-hat-02" width="2.4rem" height="2.4rem"/>
              <div class="info">
                <div class="position" v-html="edu.degree"/>
                <div class="institution" v-html="edu.institution"/>
                <div class="year" v-html="edu.year"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CollapseMenu>
</template>
<style lang="stylus" scoped>
.career {
  display: grid
  gap: 1.8rem
  .summary {
    color: var(--gray-900)
    line-height: 2.3rem
    font-size: 1.6rem
    font-weight: 400
    width: 100%
  }
  .career-grid {
    display: grid
    grid-template-columns: 1fr 1fr
    .title {
      display: flex
      align-items: center
      justify-content: space-between
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
    }
    .jobs {
      .jobs-items {
        .job {
          margin-top: 1.8rem
          display: flex
          gap: 1.2rem
          .logo {
            width: 2.4rem
            height: 2.4rem
            img {
              width: 100%
              height: 100%
              object-fit: cover
            }
          }
          .info {
            .position {
              color: var(--gray-700)
              font-size: 1.4rem
              line-height: 2rem
              font-weight: 500
            }
            .company {
              color: var(--gray-600)
              font-size: 1.4rem
              font-weight: 400
              line-height: 2rem
            }
            .period {
              color: var(--gray-600)
              font-size: 1.2rem
              font-weight: 400
              line-height: 1.8rem
            }
          }
        }
      }
    }
    .education {
      .education-items {
        .establishment {
          margin-top: 1.8rem
          display: flex
          gap: 1.2rem
          .info {
            .position {
              color: var(--gray-700)
              font-size: 1.4rem
              line-height: 2rem
              font-weight: 500
            }
            .institution {
              color: var(--gray-600)
              font-size: 1.4rem
              font-weight: 400
              line-height: 2rem
            }
            .year {
              color: var(--gray-600)
              font-size: 1.2rem
              font-weight: 400
              line-height: 1.8rem
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import MdContent from 'components/md-content'
import {makePeriod} from '../cards-defs.js'
export default {
  components: {MdContent},
  props: {
    data: {type: Object, required: true},
  },
  computed: {
    title(){
      return this.data.title
    },
    jobs(){
      return this.data.jobs?.length ? this.data.jobs : undefined
    },
    education(){
      return this.data.education?.length ? this.data.education : undefined
    },
    summary(){
      return this.data.summary
    },
    loading(){
      if (this.jobs || this.education) return false
      return true
    }
  },
  methods: {
    makePeriod(start, end){
      return makePeriod(start, end)
    },
  }
}
</script>