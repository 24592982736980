@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
.file-icon {
  display: inline-block;
  margin-left: 0.6rem;
}
.file-icon.pulse {
  animation: pulse 2s infinite;
}
.file-icon .svg-icon {
  fill: v-bind(file_fill);
  stroke: v-bind(file_stroke);
}
.file-icon .ext {
  position: absolute;
  padding: 0.2rem 0.3rem;
  text-transform: uppercase;
  color: var(--base-white);
  font-size: v-bind(fontSize);
  font-weight: 700;
  line-height: normal;
  border-radius: 0.2rem;
  bottom: 15%;
  left: -18.75%;
  background: v-bind(label_color);
  user-select: none;
}
