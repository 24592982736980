<template>
  <div class="ai-chat" :class="[mode]">
    <ScrollBar ref="scroller" v-resize:100="setClientHeight" class="scroller" @scroll="onScroll">
      <template v-if="$slots.preview">
        <slot name="preview"></slot>
      </template>

      <template v-for="(item, idx) in history" v-else>
        <AiAssistant v-if="item.type === 'assistant'"
                     :key="idx"
                     class="entry delay-in-1000"
                     :style="{minHeight: getMinHeight(idx)}"
                     :mode="mode"
                     :last="idx === history.length - 1"
                     :last-answer="last_answer_idx === idx"
                     :item="item"
                     :generating="generating"
                     :regenerating="regenerating"
                     @text-added="onTextAdded"
                     @ask-ai="$emit('ask-ai', $event)"
                     @like="$emit('like', {idx, feedback: $event})"
                     @dislike="$emit('dislike', {idx, feedback: $event})"
                     @save="$emit('save', {idx, text: $event})"
                     @refrag="$emit('refrag', {idx, range: $event})"
                     @start="$emit('start', $event)"
                     @disable-chat="disabled = $event"
        />

        <UserMessage v-if="item.type === 'user'"
                     :key="idx"
                     ref="user_msg"
                     :text="item.data.title || item.data.text || item.data"
                     class="entry delay-in-1000"
                     :mode="mode"
        />
        
        <AIMessage v-if="item.type === 'assistant-error'"
                   :key="idx"
                   error
                   class="entry delay-in-1000"
                   :text="item.data.text"
                   :last="last_answer_idx === idx"
                   :style="{minHeight: getMinHeight(idx)}"
        />

        <UserCancelled v-if="item.type === 'user-cancelled'"
                       :key="idx"
                       class="entry delay-in-1000"
                       :mode="mode"
                       :text="item.data.text"
                       :style="{minHeight: getMinHeight(idx)}"
        />

        <ChatError v-if="item.type === 'error'"
                   :key="idx" 
                   :chat_id="chat_id"
                   :data="item.data"
                   class="entry delay-in-1000"
                   :mode="mode"
                   :style="{minHeight: getMinHeight(idx)}"
        />
      </template>
    </ScrollBar>
    <ChatInput ref="input" 
               :loading="!!last_answer && generating"
               :description="description"
               :generating="!can_type || !can_chat"
               :disabled="input_disabled"
               :placeholder="placeholder"
               :mode="mode"
               @regenerate="params => $emit('regenerate', params)"
               @stop="stop()"
    />
  </div>
</template>

<style lang="stylus" scoped>
.ai-chat {
  height: 100%
  display: flex
  flex-direction: column
  min-height: 0

  .scroller {
    flex-grow: 1
    min-height: 0
    width: 100%
  }

  &.compact {
    .entry {
      padding: 0 4.8rem
      margin-top: 2.4rem
      &:last-child {
        margin-bottom: 2.4rem
      }
    }
  }

  &.expanded {
    .entry {
      max-width: 90rem
      margin: 0 auto
      padding-left: 1.4rem
      padding-right: 2rem
      &:last-child:not(.chat-error) {
        padding-bottom: 2.4rem
      }
    }
  }

  &.onboarding {
    :deep() {
      .footnote {
        font-size: 1rem !important
        margin: 0.4rem auto 0 auto !important
        color: var(--gray-500)
      }

      .chat-scroller-wrapper {
        overflow: hidden
        height: 100%
        width: 100%
      }

      .content {
        padding-top: 8.8rem
      }
      
      .bary {
        z-index: 100
      }
    }

    .entry {
      max-width: 77.2rem
      margin: 0 auto
      padding-left: 1.4rem
      padding-right: 2rem
      &:last-child {
        padding-bottom: 11.8rem
      }
    }
  }
}

.loading {
  display: flex
  justify-content: center
}
</style>

<script>
import UserMessage from './user-message.vue'
import AIMessage from './ai-message.vue'
import ChatError from './chat-error.vue'
import UserCancelled from './user-cancelled.vue'
import AiAssistant from './ai-assistant'
import ChatInput from './chat-input'

export default {
  components: {
    AiAssistant,
    UserMessage,
    UserCancelled,
    AIMessage,
    ChatError,
    ChatInput
  },
  props: {
    chat_id: {type: String, required: false, default: ''},
    generating: {type: Boolean, default: false},
    regenerating: {type: Boolean, default: false},
    placeholder: {type: String, default: 'What can I help you with?'},
    history: {type: Array, default: () => []},
    can_chat: {type: Boolean, default: true},
    description: {type: String, default: ''},
    mode: {type: String, default: 'compact'},
    watchSubtree: {type: Boolean, default: false},
  },
  emits: ['regenerate', 'stop', 'ask-ai', 'like', 'dislike', 'save', 'refrag', 'start'],
  data() {
    return {
      disabled: false,
      autoscroll: false,
      client_height: undefined,
      typing: false,
      observer: new MutationObserver(() => {
        this.scrollToEnd(true) 
      }),
    }
  },
  computed: {
    last_answer_idx() {
      return this.history.findLastIndex(x => x.type === 'assistant')
    },
    last_prompt_idx() {
      return this.history.findLastIndex(x => x.type === 'user')
    },
    last_answer(){
      return this.history[this.last_answer_idx]
    },
    can_type(){
      if (this.mode === 'compact') return !this.generating
  
      // for expanded and onboarding modes
      if (this.generating) return false
      return !this.typing
    },
    input_disabled() {
      return !this.generating && this.disabled
    }
  },
  watch: {
    can_type(value) {
      this.autoscroll = !value
      if (value) {
        this.$nextTick(() => {
          this.focus()
        })
      }
    },
    'history.length': function () {
      if (this.generating && this.mode !== 'compact') {
        this.$nextTick(() => this.scrollToEnd(true))
      }
    }
  },
  mounted() {
    this.setClientHeight()
    this.$nextTick(() => {
      if (!this.$slots.preview) this.scrollToEnd(false)
      if (this.mode === 'compact'){
        this.observer.observe(this.getScroller(), {
          childList: true, subtree: this.watchSubtree
        })
      }
    })
  },
  beforeUnmount() {
    this.observer.disconnect()
  },
  methods: {
    getMinHeight(ind){
      const conditions = [this.mode === 'expanded', ind === this.history.length - 1, this.history[ind] !== 'user', this.client_height]
      if (conditions.some(x => !x)) return 'unset'
  
      const last_prompt_h = this.$refs.user_msg?.at(-1)?.$el.clientHeight || 0
      return (this.client_height - last_prompt_h) + 'px'
    },
    scrollToEnd(smooth = false) {
      this.$refs.scroller.scrollToEnd(smooth)
    },
    onTextAdded(full) {
      this.typing = !full
      if (this.autoscroll) this.scrollToEnd(this.mode !== 'compact')
    },
    setClientHeight(){
      if (this.$refs.scroller?.$el) {
        this.client_height = this.$refs.scroller.$el.clientHeight
      }
    },
    onScroll() {
      // if we're not at the scroll end it means that user have scrolled manually. 
      // In this case we ignore autoscroll to not to annoy the user
      const scroller = this.$refs.scroller
      const on_bottom = scroller.checkBottom()
      this.autoscroll = !this.can_type && on_bottom
    },
    focus() {
      this.$refs.input.focus()
    },
    getScroller(){
      return this.$refs.scroller.getContent()
    },
    stop(){
      this.typing = false
      this.$emit('stop')
    }
  }
}
</script>

