<template>
  <Popover v-model="show" :outside-exceptions="['.p-autocomplete-overlay']">
    <template v-slot:reference>
      <slot name="reference"></slot>
    </template>
    <div class="popover-body">
      <div class="head">
        <div class="text">Add Company</div>
        <div class="text pointer" @click="show=false">
          <Icon stroke="var(--gray-500)" height="2rem" width="2rem" icon="x-close"/>
        </div>
      </div>
      <div class="input-block">
        <div class="label">Company Name</div>
        <Autocomplete ref="input"
                      v-model="name"
                      placeholder="e.g. Genrate.AI"
                      option-label="display_name"
                      :suggestions="suggestions"
                      :unstyled="false"
                      @complete="onAutoComplete"
                      @item-select="onItemSelect"
        />
      </div>
      <div class="overview-controls">
        <Button :disabled="!can_add" primary tiny class="add-btn" @click="submit()">
          <Icon stroke="var(--base-white)" width="1.6em" height="1.6rem" icon="plus"/>
          Add
        </Button>
      </div>
    </div>
  </Popover>
</template>

<style lang="stylus" scoped>
.popover-body {
  padding: 1.8rem 1.6rem
  min-width: 29.2rem
  display: flex
  flex-direction: column
  .head {
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    color: var(--text-primary)
    font-weight: 500
    .text {
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 600
      line-height: 2.8rem
    }
  }

  .input-block {
    display: flex
    flex-direction: column
    margin-top: 1.6rem
    gap: 0.8rem
    .label {
      color: var(--gray-700)
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
    }
  }
  .overview-controls {
    margin-top: 0.6rem
    width: 100%
    display: flex
    justify-content: flex-end
    .add-btn {
      display: flex
      justify-content: space-between
      min-width: 0
    }
  }
}

</style>

<script>
import $companies from 'stores/companies'

export default {
  emits: ['submit'],
  data() {
    return {
      name: '',
      show: false,
      query: undefined,
      suggestions: [],
    }
  },
  computed:{
    tr_name(){
      return this.name.trim()
    },
    to_add() {
      return this.suggestions.find(x => x.display_name.toLowerCase() == this.tr_name.toLowerCase())
    },
    can_add() {
      return !!this.to_add
    }
  },
  watch: {
    async show(val) {
      if (!val) return 
      await this.$nextTick()
      this.$refs.input.$el.querySelector('input')?.focus()
    }
  },
  methods: {
    submit() {
      this.show = false
      this.$emit('submit', this.to_add)
      this.name = ''
    },
    open() {
      this.show = true
    },
    async onAutoComplete(args) {
      if (!this.tr_name) {
        this.query = undefined
        this.suggestions = []
        return 
      }

      if (this.query == this.tr_name) {
        this.suggestions = [...this.suggestions]
        return
      }

      this.query = this.tr_name
      let suggestions = await $companies.search(this.tr_name)

      if (this.query == this.tr_name) {
        this.suggestions = suggestions
      }
    },
    onItemSelect(event){
      this.name = event.value.display_name
    }
  }
}
</script>
