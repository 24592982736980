<template>
  <div class="ai-select" :class="{pressed}">
    <div class="options" :disabled="disabled">
      <Button v-for="(option, idx) in options" :key="idx" class="ai-option regular" 
              :class="{active: selected === idx}" @click="onSelect(idx)"
      >
        <span v-html="option.title"/>
      </Button>
    </div>

    <div class="controls">
      <Button class="confirm" 
              :primary="!skipped"
              :disabled="confirm_disabled"
              :class="{inactive: skipped}"
              @click="onAccept"
      >
        Confirm
      </Button>
      <Button class="ai-option secondary" :disabled="disabled" @click="onSkip">
        Skip
      </Button>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
@import './ai-option'
.ai-select {
  &.pressed {
    pointer-events: none
  }
  .options {
    display: flex
    flex-wrap: wrap
    gap: 1.2rem
  }
  .controls {
    margin-top: 2rem
    display: flex
    gap: 1.2rem

    .confirm {
      padding: 0.6rem 1rem
      &.inactive {
        background: var(--gray-100)
        color: var(--gray-400)
        border-color: var(--gray-200)
        box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05)
        opacity: 0.8
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    options: {type: Array, required: true},
    skip: {type: Object, required: true},
    disabled: {type: Boolean, required: true}
  },
  emits: ['prompt'],
  data() {
    return {
      selected: undefined,
      pressed: !!this.disabled
    }
  },
  computed: {
    confirm_disabled() {
      if (this.disabled) return true
      if (!this.has_selection) return true
      return false
    },
    has_selection() {
      return this.selected !== undefined
    },
    skipped() {
      return !this.has_selection && this.disabled
    }
  },
  methods: {
    onSelect(idx) {
      this.selected = idx
    },
    onAccept(){
      this.pressed = true
      const selection = this.options[this.selected]
      this.$emit('prompt', {
        title: selection.chat_query.title || selection.chat_query.text || selection.title,
        selected: [selection.chat_query]
      })
    },
    onSkip() {
      this.pressed = true
      this.selected = undefined
      this.$emit('prompt', this.skip.chat_query)
    }
  }
}
</script>