.input > .input-inner {
  --el-loading-spinner-size: v-bind(spinner_size);
  --el-color-primary: var(--primary-600);
  --el-mask-color: transparent;
  position: relative;
  font-weight: normal;
  font-style: normal;
  display: flex;
  color: var(--gray-900);
}
.input > .input-inner.labeled > .label:not(.corner) {
  padding-top: 0.4375em;
  padding-bottom: 0.4375em;
}
.input > .input-inner > input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.5em;
  background: var(--base-white);
  border: 0.1rem solid var(--gray-300);
  color: var(--gray-900);
  border-radius: 0.8rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.input > .input-inner > input:not([type="color"]) {
  padding: 0.4375em 0.6875em;
}
.input > .input-inner > input:focus {
  border-color: #75c4f3;
  background: #fff;
  color: rgba(0,0,0,0.8);
  box-shadow: 0px 0px 0px 4px var(--primary-100);
}
.input > .input-inner > input.valid:hover:not(:focus) {
  border-color: var(--input-hover-border-color);
}
.input > .input-inner > input.valid:focus {
  border-color: var(--input-focus-border-color);
}
.input > .input-inner > input.invalid {
  border-color: var(--input-error-border-color);
  color: var(--input-error-color);
}
.input > .input-inner > input.invalid::selection {
  background: var(--error-100) !important;
  color: var(--negative-text-color) !important;
}
.input > .input-inner > input.invalid::-moz-selection {
  background: var(--error-100) !important;
  color: var(--negative-text-color) !important;
}
.input > .input-inner > input.invalid:focus {
  box-shadow: 0px 0px 0px 4px var(--error-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.input > .input-inner.icon.left:not(.corner) > input {
  padding-right: 0.6875em;
}
.input > .input-inner.icon.left:not([class*="left corner"]) > input {
  padding-left: 4rem;
}
.input > .input-inner.icon > i.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0;
  right: 0;
  left: 1.2rem;
  margin: 0;
  height: 100%;
  width: 2rem;
  opacity: 0.5;
  border-radius: 0 0.8rem 0.8rem 0;
  transition: opacity 0.3s ease;
}
.input > .input-inner > .left.label {
  display: inline-block;
  vertical-align: baseline;
  padding: 0.5833em 1.1rem;
  text-transform: none;
  border: 0.1rem solid var(--gray-300);
  position: absolute;
  top: var(--input-border-width);
  left: calc(var(--input-horizontal-padding) + var(--input-border-width));
  padding-left: 0;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: var(--input-border-width) solid var(--input-border-color);
  border-radius: 0;
  transition: var(--input-transition);
  line-height: var(--input-line-height);
  user-select: none;
  pointer-events: none;
  background: transparent;
  font-weight: 400;
  color: var(--gray-600);
  padding-top: 0.4375em;
  padding-bottom: 0.4375em;
}
.input > .input-inner > .left.label.url.invalid {
  color: var(--input-error-color);
  border-color: var(--input-error-border-color);
}
.input > .input-inner > .right {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0%;
}
.input > .input-inner > .right.custom {
  justify-content: center;
}
.input > .input-inner > .right > .button {
  padding: 0 var(--input-horizontal-padding);
  border-left: var(--input-border-width) solid var(--input-border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input > .input-inner > .right > .tooltip .icon {
  height: 1.6rem;
  padding: 0 var(--input-horizontal-padding);
}
.input > .input-inner > .right > .icon {
  height: 1.6rem;
  padding: 0 var(--input-horizontal-padding);
}
.input .footer {
  display: flex;
  flex-direction: row;
}
.input .footer > :last-child {
  margin-left: auto;
}
