.audit {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: overlay;
}
.audit .icon {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}
.audit .info {
  margin-top: 0.5rem;
}
.audit .action {
  margin-top: 2.5rem;
}
.audit .audit-actions {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.audit .data {
  flex-grow: 1;
  border: 1px solid var(--gray-300);
  background: var(--gray-200);
  padding: 1rem;
  min-width: 65rem;
  min-height: 30rem;
  max-width: 75rem;
  margin: 3.5rem;
  display: flex;
  height: 0px;
  overflow-y: overlay;
}
.audit .data .message {
  text-align: center;
  width: 100%;
  margin-top: 3rem;
}
