<template>
  <div class="collapse-wrapper" :class="{open: value, horizontal, vertical: !horizontal}">
    <div class="collapse-inner">
      <slot/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.collapse-wrapper {
  display: grid
  overflow: hidden

  &.horizontal {
    grid-template-columns: 0fr
    transition: grid-template-columns 200ms
    &.open {
      grid-template-columns: 1fr
    }
    .collapse-inner {
      min-width: 0
      overflow: hidden
    }
  }
  &.vertical {
    grid-template-rows: 0fr
    transition: grid-template-rows 200ms
    &.open {
      grid-template-rows: 1fr
    }
    .collapse-inner {
      min-height: 0
      overflow: hidden
    }
  }
}
</style>
<script>
export default {
  mixins: [ModelValue(Boolean)],
  props: {
    horizontal: {type: Boolean, default: false},
  }
}
</script>