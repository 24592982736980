<template>
  <div class="divider"></div>
</template>
<style lang="stylus" scoped>
.divider {
  background: v-bind(bg)
  height: v-bind(h)
  margin: v-bind(m)
}
</style>
<script>
export default {
  props: {
    bg: {
      type: String, default: 'var(--gray-200)'
    },
    h: {
      type: String, default: '0.1rem'
    },
    m: {
      type: String, default: '0.6rem 0'
    }
  }
}
</script>