<template>
  <div class="ai-editor">
    <div relative>
      <Segment class="segment" :disabled="loading">
        <div class="head">
          <div class="title">
            <Icon class="icon" icon="edit-05" stroke-width="0.8"/>
            <div>Post Editor</div>
          </div>
          <div class="actions">
            <!-- undo -->
            <Icon :disabled="!undos.length" class="pointer" title="Undo" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" stroke-width="1" icon="flip-backward" @click="onUndo()"/>
            <!-- redo -->
            <Icon :disabled="!redos.length" class="pointer" title="Redo" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" stroke-width="1" icon="flip-forward" @click="onRedo()"/>
            <!-- copy -->
            <Copy :disabled="!text" size="1.6rem" @copy="onCopy()"/>
            <!--- post to linkedin -->
            <Icon v-if="posting || !text " width="1.4rem" height="1.4rem" icon="social/linkedin" :disabled="true"/>
            <Icon v-else-if="posted" width="1.4rem" height="1.4rem" stroke="var(--success-600)" stroke-width="1" icon="check"/>
            <Popconfirm v-else width="max-content"
                        confirm-button-text="Yes"
                        cancel-button-text="No"
                        :title="linkedin_enabled ? 'Post to LinkedIn?' : 'Connect to Linkedin and post?'"
                        @confirm="publish"
            >
              <Icon class="pointer" width="1.4rem" height="1.4rem" icon="social/linkedin" title="Post to LinkedIn"/>
            </Popconfirm>
            <!-- share -->
            <Icon v-if="navigator.share" :disabled="!text" class="pointer" title="Share" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" stroke-width="1" icon="share-02" @click="share()"/>
          </div>
        </div>
        <div class="text">
          <MediumEditor ref="editor"
                        v-model="text"
                        @refrag="onRegenerateFragment($event)" 
                        @undo="onUndo()"
                        @redo="onRedo()"
                        @on-link="onLink($event)"
                        @copy="onSelectionCopy($event)"
          /> 
        </div>
      </Segment>
      <Loader v-if="loading" large/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.ai-editor {
  .segment {
    margin-top: 1rem
    padding: 1.6rem 2.4rem
  }
  .head {
    display: flex
    justify-content: space-between
    padding: 0 2.4rem
    margin-left: -2.4rem
    margin-right: -2.4rem
    .title {
      display: flex
      align-items: center
      gap: 0.8rem
      color: #000
      font-size: 1.6rem
      font-style: normal
      font-weight: 500
      line-height: 2.4rem
      user-select: none
      .icon {
        width: 1.6rem
        height: 1.6rem
      }
    }
    .actions {
      // global disabled opacity looks bad here
      [disabled]:not([disabled="false"]) {
        opacity: 0.5
      }
      display: flex
      gap: 1rem
      align-items: center
      justify-content: flex-end
      .return {
        opacity: 0.4
        &.active {
          opacity: 1
          cursor: pointer
        }
      }
    }
  }
}
</style>

<script>
import MediumEditor from 'components/medium-editor'
import $profile from 'stores/profile'
import $integrations from 'stores/integrations'
import Rest from 'utils/rest'
import {API_INTEGRATIONS} from 'consts/api'
import {Later} from 'utils/timers'
import Markdown from 'utils/markdown'
import {downloadFile} from 'stores/files'

const MAX_SIZE = 30
const INTEGRATION_LINKEDIN = 'linkedin'


export default {
  components: {
    MediumEditor,
  },
  props: {
    postText: {type: String, default: ''},
    loading: {type: Boolean, default: false},
  },
  emits: ['save', 'refrag'],
  data() {
    const markdown = new Markdown()
    return {     
      text: markdown.makeHTML(this.postText),
      markdown,
      posting: false,
      posted: false,
      undos: [],
      redos: [],
      later: new Later(500),
      send_mail: $utils.timers.debounce(this.sendMail, 200),
      publish: $utils.timers.debounce(this.publishToLinkedin, 200),
      post_on_connect: false
    }
  },
  computed: {
    linkedin(){
      return $integrations.getIntegration(INTEGRATION_LINKEDIN)
    },
    linkedin_enabled(){
      if (this.linkedin) {
        return this.linkedin.connected && this.linkedin.enabled 
      }
      return false
    },
    navigator(){
      return window.navigator
    }
  },
  watch: {
    text(next, prev){
      const dec_next = $utils.str.decodeHTMLEntities(next)
      const dec_prev = $utils.str.decodeHTMLEntities(prev)
      if (dec_next === dec_prev) return

      // It is important to save encoded text (**next**), because it may contain \n symbols
      // and if we will want to regenerate text, index may be incorrect
      if (dec_next.length && dec_next !== dec_prev) this.$emit('save', this.markdown.makeMD(next))

      if ([this.undos.at(-1), this.redos.at(-1)].includes(dec_prev)) return
      if (this.later.once_run) return
      else {
        this.later.once()
        this.addHistory(dec_prev)
      }
    },
    'undos.length': function(next){
      if (next > MAX_SIZE) {
        this.undos.shift()
      }
    },
    'redos.length': function(next){
      if (next > MAX_SIZE) {
        this.redos.shift()
      }
    },
    loading(){
      if (!this.loading) this.text = this.markdown.makeHTML(this.postText)
    },
    linkedin_enabled(val){
      // Watch linkedin enabled state and post to linkedin
      if (val && this.post_on_connect) {
        this.postLinkedin(true)
        this.post_on_connect = false
      }
    },
  },
  beforeUnmount(){
    this.later.abort()
  },
  methods: {
    async onCopy(){
      if (this.text) {
        const text = this.getText()
        await $utils.copyText(text)
      }
    },
    onUndo(){
      const current = $utils.str.decodeHTMLEntities(this.text)
      const undoable = this.undos.pop()
      if (undoable === undefined) return
      
      this.text = undoable
      this.redos.push(current)
    },
    onRedo(){
      const current = $utils.str.decodeHTMLEntities(this.text)
      const redoable = this.redos.pop()
      if (redoable === undefined) return
      
      this.text = redoable
      this.undos.push(current)
    },
    addHistory(text){
      this.undos.push(text)
      this.redos = []
    },
    getText(text = this.text){
      const regex = /\^([^\\^]+)\^/g
      const md = this.markdown.makeMD(text)
      const html =  this.markdown.makeHTML(md.replaceAll(regex, '')).replaceAll(/(\r\n|\n|\r)/gm, '')
      return $utils.unicode.convertHtmlToUnicode(
        html, 
        node => {
          Array.from(node.querySelectorAll('ul li p, ol li p')).forEach(el => {
            const div = document.createElement('div')
            div.innerHTML = el.innerHTML
            el.replaceWith(div)
          })
          Array.from(node.querySelectorAll('p')).forEach((el, i) =>i > 0 && el.before('\n\n'))
          Array.from(node.querySelectorAll('table')).forEach(el => el && this.parseHtmlTable(el))
        })
    },
    sendMail(){
      const mail = document.createElement('a')
      const body = encodeURIComponent(this.getText())
      const subject = encodeURIComponent(`${$profile.display_name} shared this Genrate.ai post with you`)
      mail.href = `mailto:${$profile.email}?body=${body}&subject=${subject}`
      mail.click()
    },
    async share(){
      const subject = `${$profile.display_name} shared this Genrate.ai post with you`
      const body = this.getText()
      try {
        await navigator.share({title: subject, text: body})
      } catch (error) {
        if (error.name === 'AbortError') return
        throw error
      }
    },
    async postLinkedin(showToast = false){
      try {
        this.posting = true
        const rest = new Rest()
        await rest.post(`${API_INTEGRATIONS}/${this.linkedin.id}/publish`, {text: this.getText()})
        this.posting = false
        this.posted = true
        await $utils.timers.wait(2000)
        this.posted = false
        if (showToast) {
          $integrations.showSuccessToast(this.linkedin.type, 'Your post has been successfully published to <b>LinkedIn</b>')
        }
      }
      catch (error) {
        if (error.http_code === 3000) {
          // code 3000 mean expired credentials we need to sync state with the server
          // store would offer to reccnnect itself when it sees disconnected state
          await this.linkedin.load()
        }
        else if (error.http_code === 3001) {
          $integrations.showErrorToast(this.linkedin.type, 'Failed to post to <b>LinkedIn</b>. May be you are posting too often. Please try again later.')
        }
        else throw error
      }
      finally {
        this.posting = false
      }
    },
    async publishToLinkedin(){
      if (!this.linkedin_enabled) {
        this.post_on_connect = true
        await $integrations.connect(INTEGRATION_LINKEDIN)
      } else {
        await this.postLinkedin()
      }
    },
    onRegenerateFragment(html) {
      let md_fragment = this.markdown.makeMD(html)
      const start_idx = md_fragment.indexOf('<regenrate>')
      md_fragment = md_fragment.replace('<regenrate>', '')
      const end_idx = md_fragment.indexOf('<regenrate>')
      md_fragment = md_fragment.replace('<regenrate>', '')

      // FOR TESTS
      // console.log(this.postText)
      // console.log(md_fragment)
      // console.log('is equal: ', this.postText === md_fragment)
      // console.log(this.postText.slice(start_idx, end_idx))
      // console.log(md_fragment.slice(start_idx, end_idx))

      const event = {start_idx, end_idx}
      this.$emit('refrag', event)
    },
    onLink(link){
      if (link.classList.contains('file')) {
        downloadFile(link.host)
        return
      }
      window.open(link.href, '_blank')
    },
    async onSelectionCopy(selection) {
      const modified = this.getText(selection)
      await $utils.copyText(modified)
    },
  }
}
</script>