.root {
  display: block;
  position: relative;
}
.root :deep() .contentcontainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}
.root :deep() .contentcontainer .content {
  height: 100%;
  width: 100%;
  overflow: scroll;
  box-sizing: border-box;
  border: none;
  scrollbar-width: none;
  position: relative;
}
.root :deep() .contentcontainer .content::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.root :deep() .bary {
  position: absolute;
  left: calc(100% - 0.4rem);
  background-color: #ccc;
  border: 0 none;
  border-radius: 1rem;
  z-index: 2;
  width: 0.4rem;
  outline: none;
  cursor: pointer;
  opacity: 1;
}
.root :deep() .bary[data-p-scrollpanel-hidden="true"] {
  display: none;
}
