.reference {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0.8rem 1.6rem;
  gap: 0.6rem;
}
.reference .indicator {
  position: absolute;
  left: 0;
}
.reference .title {
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}
