<template>
  <div class="collapse-menu">
    <div class="title" :class="{loading, pointer: !loading}" @click="!loading && openMenu()">
      <span v-html="title"/>
      <div v-if="loading" class="loading-icon">
        <!-- Loading... -->
        <Icon icon="animated/logo-line-v2-90f" width="1.6rem" height="1.6rem" external/>
      </div>
      <Icon v-else :class="{open}" class="icon" icon="chevron-down" width="1.6rem" height="1.6rem"/>
    </div>
    <Collapse v-if="!loading" v-model="open" class="collapse">
      <div class="wrapper">
        <slot/>
      </div>
    </Collapse>
  </div>
</template>
<style lang="stylus" scoped>
.collapse-menu {
  .title {
    display: flex
    align-items: center
    justify-content: space-between
    font-size: 1.6rem
    font-weight: 500
    line-height: 2.4rem
    user-select: none
    &.loading {
      color: var(--gray-500)
      font-weight: 400
    }
    .loading-icon {
      display: flex
      align-items: center
      gap: 1.6rem
      color: var(--gray-500)
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
    }
    .icon {
      transition: transform 0.2s
      &.open {
        transform: rotate(90deg)
      }
    }
  }
  .wrapper {
    padding: v-bind(padding)
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    padding: {
      type: String,
      default: '1rem 1.6rem'
    },
    initial: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    }
  },
  mounted() {
    this.open = this.initial
  },
  methods: {
    openMenu() {
      this.open = !this.open
    }
  }
}
</script>