<template>
  <DataTable :items="files" 
             :total="count"
             :loading="loading"
             :sort-field="sort_field" 
             :sort-order="sort_order"
             :skip="skip"
             override-skip
             @page="onPage" 
             @sort="onSort"
  >
    <template v-slot:empty>
      <Empty bg="assets/bg-pattern-square.png">
        <template v-slot:image>
          <Icon width="4rem" height="4rem" icon="search" class="icon-svg"/>
        </template>
        <template v-slot:title>No files were found</template>
        <template v-slot:sub-text>
          <template v-if="highlight">
            Your search “{{ highlight }}” did not match any files. <br>
          </template>
          <a class="pointer" @click="onReset">Clear filters</a>
        </template>
      </Empty>
    </template>

    <template v-slot:header-left>
      <div class="filters">
        <FiltersDropdown title="Type" width="24rem" :got-filter="!!filter_exts.length">
          <template v-slot="{close}">
            <Filters v-model="filter_exts" title="Type" @on-apply="close()">
              <FiltersCheckgroups :items="allExts"/>
            </Filters>
          </template>
        </FiltersDropdown>

        <FiltersDropdown title="Dates" width="28.5rem" :got-filter="!!filter_dates.length">
          <template v-slot="{close}">
            <Filters v-model="filter_dates" title="Dates" @on-apply="close()">
              <FiltersCalendar/>
            </Filters>
          </template>
        </FiltersDropdown>
  
        <!-- TEMPRARLIY disabled, until we support multiple users for one tenant -->
        <!-- Dropdown v-model="dd" :items="[]" hide_footnote placeholder="Uploaded by"/ -->
  
        <div v-if="can_clear" class="clear-filters" width="2rem" height="2rem" stroke="var(--gray-600)" @click="clearFilters()">
          Clear All
        </div>
      </div>
    </template>

    <template v-slot:header-right>
      <div class="right">
        <SearchInput ref="search" @search="onSearch"/>
        <UploadButton ref="upload_btn" type="icon" @upload="$emit('upload', $event)"/>

        <Icon v-show="selected.size > 0" 
              class="pointer" 
              width="2rem" 
              height="2rem" 
              icon="trash-04" 
              stroke="var(--gray-600)" 
              @click="onDeleteSelected"
        />
      </div>
    </template>
  
    <!-- File icon & Name -->
    <Column class="name-col" style="width: 100%" sortable sort-field="name">
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="File name"
                   sortable
                   :sort-order="sortOrder"
                   :selected="selected.size > 0"
                   :indeterminate="selected.size > 0 && !all_selected"
                   :disabled="files.length === 0"
                   checkbox
                   @change="onToggleHead"
        />
      </template>
      <template v-slot:body="{data: {id, name, ext, deleting, processing, failed}}">
        <div class="file-name ellipsis-cell" :disabled="deleting">
          <Checkbox :model-value="selected.has(id)" 
                    :disabled="deleting || processing"
                    size="large"
                    @change="onToggleSelected(id)"
          />
          <FileIcon :ext="ext" :error="failed" :pulse="processing"/>
          <div class="name ellipsed" :class="{failed}">
            <Highlight :text="name" :highlight="highlight"/>
          </div>
        </div>
      </template>
    </Column>

    <!-- File size -->
    <Column field="size" class="content-width-col" sortable sort-field="size">
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="Size" sortable :sort-order="sortOrder"/>
      </template>
      <template v-slot:body="{data: {size, deleting, failed}}">
        <span class="text" style="white-space: nowrap" :disabled="deleting" :class="{failed}">
          {{ $utils.format.bytes(size) }}
        </span>
      </template>
    </Column>

    <!-- File creation date -->
    <Column field="created_at" class="content-width-col" sortable sort-field="created_at">
      <template v-slot:sorticon="{sortOrder}">
        <TableHead name="Date created" sortable :sort-order="sortOrder"/>
      </template>
      <template v-slot:body="{data: {created_at, deleting, failed}}">
        <span class="text" :disabled="deleting" :class="{failed}">
          {{ $utils.format.date(created_at) }}
        </span>
      </template>
    </Column>

    <!-- TODO:TENANT Temporarily disabled since we do not support multiple users -->
    <!--Column field="date" header="Uploaded by">
        <template v-slot:body="{data: {by}}">
          <div class="person">
            <Avatar v-bind="by"/>
            <span>{{ by.name }}</span>
          </div>
        </template>
      </Column-->

    <!-- File tags -->
    <Column field="tags" class="content-width-col">
      <template v-slot:header>
        <TableHead name="Tags"/>
      </template>
      <template v-slot:body="{data: {tags, deleting}}">
        <TagsGroup :highlight="highlight" :disabled="deleting" :tags="tags || []"/>
      </template>
    </Column>

    <!-- File action buttons -->
    <Column class="content-width-col">
      <template v-slot:body="{data: {id, deleting, status}}">
        <div class="controls">
          <div class="icon-wrap pointer" :disabled="deleting">
            <Icon icon="download-02" 
                  width="2rem" 
                  height="2rem"
                  stroke="var(--gray-500)"
                  @click="onDownload(id)"
            />
          </div>
          <Popconfirm width="max-content"
                      confirm-button-text="Yes"
                      cancel-button-text="No"
                      title="Are you sure you want to delete this file?"
                      @confirm="onDelete(id)"
          >
            <div class="icon-wrap pointer" :disabled="deleting || ['processing'].includes(status)">
              <Icon icon="trash-04" 
                    width="2rem" 
                    height="2rem" 
                    stroke="var(--gray-500)"
              />
            </div>
          </Popconfirm>
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<style lang="stylus" scoped>
.file-name {
  display: flex
  align-items: center
  gap: 1.2rem

  .name {
    font-size: 1.4rem
    line-height: 2rem
    color: var(--text-secondary)
    font-weight: 500
  }
}
.controls {
  display: flex
  justify-content: flex-end
  gap: 0.4rem

  .icon-wrap {
    padding: 10px
  }
}

.filters {
  display: flex
  gap: 1.2rem
  align-items: center
  .clear-filters {
    color: var(--gray-600)
    font-weight: 400
    line-height: 2rem
    cursor: pointer
    padding: 0.8rem 1.2rem
  }
}

.right {
  display: flex
  gap: 1.6rem
  align-items: center
}
</style>

<script>
import Column from 'primevue/column'
import {DataTable,TableHead, SearchInput} from 'components/data-table'
import {downloadFile} from 'stores/files'
import FiltersDropdown from 'components/filters/filters-dropdown.vue'
import FiltersCheckgroups from 'components/filters/filters-checkgroups.vue'
import Filters from 'components/filters/filters.vue'
import FiltersCalendar from 'components/filters/filters-calendar.vue'
import TagsGroup from './tags-group.vue'

export default {
  components: {
    DataTable,
    Column,
    SearchInput,
    Filters,
    FiltersDropdown,
    FiltersCheckgroups,
    FiltersCalendar,
    TableHead,
    TagsGroup
  },
  props: {
    count: {type: Number, default: 0},
    loading: {type: Boolean, default: false},
    files: {type: Array, default: () => []},
    allExts: {type: Array, default: () => []},
    skip: {type: Number, default: 0},
    highlight: {type: String, default: ''},
  },
  emits: ['select', 'delete', 'upload', 'update'],
  data: function() {
    return {
      selected: new Set(),
      //
      // Filters
      //
      filter_exts: [],
      filter_dates: [],
      //
      // Sorting
      //
      sort_field: 'created_at',
      sort_order: 1
    }
  },
  computed: {
    all_selected() {
      return this.selected.size === this.files.length
    },
    can_clear(){
      return this.filter_exts.length || this.filter_dates?.length
    },
  },
  watch: {
    filter_exts(){
      if (this.loading) return
      this.onFilters({ext: this.filter_exts.join(',').toLowerCase()})
    },
    filter_dates(){
      if (this.loading) return
      const period = this.filter_dates.map((d, i) => {
        if (i > 0) d = new Date(d.getTime() + 24 * 60 * 60 * 1000 - 1000)
        return new Date(d).toISOString()
      })
        .join(',')

      this.onFilters({period})
    },
    'files': {
      handler(){
        const selected = [...this.selected]
        selected.forEach(id => {
          if (!this.files.find(file => file.id === id)) this.selected.delete(id)
        })
      },
      deep: true
    },
  },
  methods: {
    async onPage(ev){
      this.$emit('update', {skip: ev.first, limit: ev.rows})
    },
    async onSearch(name){
      if (this.loading) return
      this.$emit('update', {name, skip: 0})
    },
    async onFilters(ev){
      this.$emit('update', {...ev, skip: 0})
    },
    onSort(ev){
      this.sort_field = ev.sortField
      this.sort_order = ev.sortOrder
      this.$emit('update', {sort: `${this.sort_field}:${this.sort_order === 1 ? 'asc' : 'desc'}`})
    },
    async onDownload(file_id) {
      await downloadFile(file_id)
    },
    async onDelete(file_id) {
      this.$emit('delete', [file_id])
    },
    async onDeleteSelected() {
      this.$emit('delete', [...this.selected])
    },
    onToggleSelected(id) {
      this.selected.has(id) ? this.selected.delete(id) : this.selected.add(id)
    },
    onToggleHead() {
      if (this.selected.size > 0) this.selected = new Set()
      else this.selected = new Set(this.files.filter(file => !file.processing).map(file => file.id))
    },
    async clearFilters(){
      this.filter_exts = []
      this.filter_dates = []
      this.onFilters({
        ext: null,
        period: null
      })
    },
    onReset(){
      if (this.highlight) this.$refs.search.clearSearch()
      if (this.can_clear) this.clearFilters()
    }
  },
}
</script>