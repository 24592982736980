<template>
  <div class="onboarding">
    <Loader v-if="!chat"/>
    <div v-else class="onboarding-chat">
      <div class="header" relative>
        <div class="segment-title">
          <div class="title">
            <img class="logo" src="/assets/logo9.svg"/>
            Genrate.ai Onboarding Chat
          </div>
          <Icon class="pointer" icon="x-close-thin" height="4.8rem" width="4.8rem" stroke-width="2" @click="onClose()"/>
        </div>
      </div>
      <AIChat ref="chat"
              v-window-resize="handleResize"
              class="chat-container"
              mode="onboarding"
              description="Genrate can make mistakes. Consider checking important information."
              placeholder="Enter your response here..."
              :chat_id="chat?.id"
              :history="history"
              :generating="generating"
              @start="onStart"
              @regenerate="onChat"
              @stop="onStop"
              @ask-ai="onChat"
      />
      <CloseModal ref="close"/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.chat-container {
  font-size: 1.4rem
  background-color: var(--bg-primary)
  background-image: url('assets/onboarding-bg.png')
  background-repeat: no-repeat
  background-size: cover

  .new-chat {
    height: 100%
  }
}

.onboarding {
  height: 100%
  background: url(assets/onboarding-overlay.png)
  background-size: 100% 100%
  background-repeat: no-repeat
  .onboarding-chat {
    height: 100%
    display: flex
    flex-direction: column
    .header {
      display: flex
      flex-direction: column
      gap: 2rem
      padding: 1.6rem 2.4rem 2.4rem
      background: url(assets/onboarding-title.png)
      background-size: 100% 100%
      background-repeat: no-repeat
      position: absolute
      top: 0
      width: 100%
      z-index: 99
      .segment-title {
        display: flex
        align-items: center
        justify-content: center
        gap: 1rem
        .fake-block {
          width: 4.8rem
          height: 4.8rem
        }
        .title {
          flex-grow: 1
          display: flex
          align-items: center
          justify-content: center
          gap: 1.6rem
          color: #000
          font-size: 3rem
          font-style: normal
          font-weight: 400
          line-height: 3.8rem
        }
        .logo {
          width: 3.2rem
          height: 3.2rem
          display: block
        }
      }
    }
  }
}

</style>

<script>
import {onboarding as $onboarding} from 'stores/chats'
import $store from 'stores/global'
import $profile from 'stores/profile'
import {get_store} from 'stores/files'
import {SAMPLE_CONTENT} from 'consts/ftypes'
import $companies from 'stores/companies'
import AIChat from 'components/ai-chat'
import CloseModal from './close-modal.vue'

export default {
  components: {AIChat, CloseModal},
  data(){
    return {
      chat: undefined,
    }
  },
  computed: {
    history(){
      return this.chat.history.toSpliced(0, 1)
    },
    generating() {
      return this.chat.generating
    },
  },
  watch: {
    chat() {
      this.$nextTick(() => this.$refs.chat.focus())
    }
  },
  async mounted() {
    await $onboarding.load()
    let onboarding = $store.onboarding
    
    if (onboarding) {
      try {
        const chat = $onboarding.getChat(onboarding.chat_id)
        await chat.load()
        this.chat = chat
        return
      }
      catch(err) {
        // onboarding might be already removed on server/from db
        onboarding = undefined
      }
    }
    
    const chat = await $onboarding.newChat({text: 'Genrate.ai Onboarding Chat'}, 'onboarding')
    await chat.load()
    this.chat = chat

    await $store.setOnboarding({chat_id: this.chat.id, completed: false})
  },
  unmounted() {
    if (this.chat) {
      this.chat.abortGenerating()
    }
  },
  methods: {
    async onChat(params) {
      await this.chat.doChat(params)
    },
    onStop() {
      this.chat.stopChat()
    },
    onClose(){
      this.$refs.close.open()
    },
    async onStart(info){
      if (info['first_name'] || info['last_name']) {
        $profile.first_name = info['first_name']
        $profile.last_name = info['last_name']
      }

      if (info['title']) {
        $profile.title = info['title']
      }

      if (info['company']) {
        $profile.company_name = info['company']
      }

      if (info['bio']) {
        $profile.bio = info['bio']
      }

      if (info['sample_posts']?.length) {
        const posts = info['sample_posts']
        const fstore = get_store(SAMPLE_CONTENT.type, $profile.id)
        await fstore.load()
    
        const files_ids = fstore.files.map(x => x.id)
        for (const id of files_ids) {
          await fstore.delete(id)
        }

        for (const post of posts) {
          await fstore.addSamplePost(post)
        }
      }

      if (info['companies_to_follow']?.length) {
        const promises = info['companies_to_follow']
          .map(({company_name, linkedin_id}) => $companies.newCompany(company_name, linkedin_id))
        await Promise.all(promises)
      }

      await $profile.save()
      await $store.setOnboarding({chat_id: this.chat.id, completed: true})
      $store.goToChats()
    },
    handleResize(){
      if (this.completed) {
        this.$refs.chat.scrollToEnd()
      }
    }
  }
}
</script>