  <!-- 
    el-tabs should be root element, tabs event are listened by users of the Tabs control 
    if you make el-tabs non-root emit 'tab-change' event yourself
  -->
<template>
  <el-tabs v-model="value" class="tabs" :tab-position="tabPosition">
    <!-- need to update keys when content is changed(for count) -->
    <el-tab-pane v-for="(tab) in tabs"
                 :key="JSON.stringify(tab)"
                 class="tab" 
                 :label="tab.title" 
                 :name="tab.value"
    >
      <template v-slot:label>
        <div class="tab-label">
          <span>{{ tab.title }}</span>
          <Badge v-if="typeof tab.count === 'number'"
                 class="badge"
                 :gray="value !== tab.value" 
                 :brand="value === tab.value" 
          >
            {{ tab.count }}
          </Badge>
        </div>
      </template>
    </el-tab-pane>
    <slot/>
  </el-tabs>
</template>

<style lang="stylus" scoped>
  .tabs {
    --el-border-color-light: v-bind(border_color)
    --el-tabs-header-height: 3rem

    :deep() {
      .el-tabs__item {
        transition: color 0.2s
        padding-right: 1.6rem
      }
      .el-tabs__nav-next
      .el-tabs__nav-prev {
        line-height: 3.3rem
      }

      .el-tabs__nav-wrap::after {
        height: 0.1rem
      }

      .el-tabs__header.is-right {
        margin-left: 0.1rem
      }

      .el-tabs__item:hover .badge {
        border: 0.1rem solid var(--brand-200)
        background: var(--brand-50)
        color: var(--brand-700)
      }
    }
    .tab-label {
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      gap: 0.8rem
    }

    .badge {
      transition: all 0.3s
    }
  }
</style>

<script>
export default {
  mixins: [ModelValue(String)],
  props: {
    bordered: {
      type: Boolean, default: false
    },
    tabs: {
      type: Array, default: () => ([])
    },
    tabPosition: {
      type: String, default: 'top'
    }
  },
  computed: {
    border_color() {
      return this.bordered ? 'var(--gray-200)' : 'transparent'
    }
  },
}
</script>