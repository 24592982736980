.view-more {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
}
.view-more .rest-icon {
  width: 1.6rem;
  display: flex;
  border-radius: 50%;
}
.view-more .text {
  margin-left: auto;
  white-space: nowrap;
}
