.chats {
  background: var(--bg-primary);
  display: flex;
  height: 100%;
}
.chats .chat-container {
  flex-grow: 1;
  padding: 0 0 0 0;
}
.chats .chat-container .chat {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chats .feed {
  flex-basis: 41rem;
}
