<template>
  <div class="urls-list">
    <Input v-for="(_, idx) in value" :key="idx"
           v-model="value[idx]" 
           v-read:valid="inputs_validity[idx]"
           class="input" 
           :label="items_label"
           right-icon="trash-04"
           type="url"
           @right-icon="() => removeItem(idx)"
           @blur="$emit('blur')"
    />
    <div class="add-block">
      <IconButton small icon="plus" :disabled="!can_add" @click="addItem"/>
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.urls-list {
  display: flex
  flex-direction: column
  gap: 1.6rem
  
  .add-block {
    display: flex
    align-items: center
    gap: 0.8rem

    span {
      font-size: 1.4rem
    }
  }
}
</style>

<script>
export default {
  mixins: [ModelValue(Array)],
  props: {
    title: {
      type: String, default: ''
    },
    max: {
      type: Number, default: 1
    },
    tooltip: {
      type: String, default: ''
    }
  },
  emits:['blur'],
  data() {
    return {
      inputs_validity: Array(this.modelValue.length).fill(true)
    }
  },
  computed:{
    can_add() {
      return this.value.length < this.max && !this.value.includes('')
    },
    valid () {
      return this.inputs_validity.indexOf(false) == -1
    }
  },
  methods: { 
    addItem() {
      this.value.push('')
      this.inputs_validity.push('true')
    },
    removeItem(idx) {
      this.value.splice(idx, 1)
      this.inputs_validity.splice(idx, 1)
      this.$emit('blur')
    }
  }
}
</script>