.table-head {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.table-head .name {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--text-secondary);
  font-weight: 500;
}
.table-head .sortable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
}
