<template>
  <div class="suggestions">
    <div class="segment-title">
      <span>More</span>
    </div>
    <div v-for="suggestion of suggestions" 
         :key="suggestion" 
         class="suggestion pointer" 
         @click="$emit('ask-ai', {text: suggestion})"
         v-html="suggestion"
    />
  </div>
</template>

<style lang="stylus" scoped>
.suggestions {
  padding: 0.8rem 0 0
  .suggestion {
    color: var(--gray-700)
    font-size: 1.6rem
    font-style: normal
    font-weight: 400
    line-height: 3rem
    transition: color 0.2s
    &:hover {
      color: var(--brand-600)
    }
  }
}
</style>

<script>
export default {
  props: {
    suggestions: {type: Array, default: () => []}
  },
  emits: ['ask-ai'],
}
</script>