.files {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--bg-primary);
  padding: 1.6rem;
}
.files .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1.6rem 2rem 0rem;
}
.files .header .left {
  display: flex;
  gap: 1.6rem;
  flex-direction: row;
  align-items: center;
}
.files .header .title {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--gray-900);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
}
.files .container {
  flex-grow: 1;
}
.files .container .relative {
  position: relative;
  height: 100%;
}
.files .files-upload {
  position: absolute;
  right: 14.2rem;
  bottom: 0;
  width: 100%;
  max-width: 53.7rem;
}
