<template>
  <div class="footnote" :class="{[type]: true}" nowrap>
    <slot/>
  </div>
</template>

<style lang="stylus" scoped>
.footnote {
  font-size: 1.2rem
  line-height: 1.4rem
  margin-top: 0.6rem

  &.default {
    color: var(--gray-600)
  }
  &.error {
    color: var(--error-500)
  }
}
</style>

<script>
export default {
  props:{
    type: {
      validator(value) {
        return ['default', 'error'].includes(value)
      },
      default: 'default'
    }
  }
}
</script>