<template>
  <div class="chats-item pointer" :class="{active}">
    <div class="title" v-html="chat.title"/>
    <Popover v-model="show_popover" placement="right" style="margin-left: auto;" width="28rem" :outside-exceptions="['.el-popper.is-light.el-popover']" @hide="onBlur">
      <template v-slot:reference>
        <div class="more" :class="{visible: show_popover}" @click.prevent="onShow">
          <Icon stroke="var(--gray-500)"
                icon="dots-vertical"
                width="1.6rem" 
                height="1.6rem"
          />
        </div>
      </template>
      <div class="popover">
        <div class="icon">
          <Icon icon="message-text-circle-02" 
                width="1.6rem" 
                height="1.6rem" 
                stroke="var(--gray-500)"
          />
        </div>
        <div class="popover-container">
          <div ref="input"
               class="chat-title"
               :class="{editable}"
               :contenteditable="editable"
               @keyup.stop
               @keydown.stop="onKeydown"
               @input="onInput"
               v-html="chat.title"
          />
          <div class="footer">
            <div class="date">
              {{ $utils.format.date(chat.created_at) }}
            </div>
            <div v-show="editable" class="actions">
              <Icon class="pointer" icon="check" width="1.6rem" height="1.6rem" stroke="var(--success-600)" :disabled="!can_accept" @click="onAccept"/>
              <Icon class="pointer" icon="x" width="1.6rem" height="1.6rem" stroke="var(--error-600)" @click="onCancel"/>
            </div>
            <div v-show="!editable" class="actions">
              <Icon class="pointer" icon="edit-03" width="1.6rem" height="1.6rem" stroke="var(--gray-500)" @click="onEdit"/>
              <Popconfirm :teleported="false" width="max-content" confirm-button-text="Yes" cancel-button-text="No" title="Delete chat?" @confirm="onRemove">
                <Icon class="pointer" icon="trash-04" width="1.6rem" height="1.6rem" stroke="var(--gray-500)"/>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  </div>
</template>
<style lang="stylus" scoped>
.popover {
  display: flex
  padding: 0.9rem 1.6rem 1.2rem 1.6rem
  gap: 0.8rem

  .icon {
    padding: 0.6rem 0 0
  }

  .popover-container {
    width: 100%

    .chat-title {
      font-size: 1.4rem
      font-weight: 400
      border: 0.1rem solid transparent
      padding: 0.4rem 0.6rem
      outline: none
      border-radius: 0.4rem
      word-break: normal
      text-align: left
      max-width: 22rem
      &.editable {
        border: 0.1rem solid #36BFFA
      }
    }

    .footer {
      display: flex
      align-items: center
      gap: 0.8rem
      margin-top: 0.8rem
      .date {
        font-size: 1.2rem
        font-weight: 400
        line-height: 1.6rem
        padding-left: 0.8rem
        color: var(--gray-700)
      }
      .actions {
        margin-left: auto
        display: flex
        align-items: center
        gap: 0.8rem
      }
    }
  }
}

.chats-item {
  transition: background 0.2s
  display: flex
  align-items: center
  padding: 0.2rem 0.4rem 0.3rem 0.8rem
  border-radius: 0.4rem

  .title {
    animation: fade-in 0.9s
    color: var(--gray-700)
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    font-size: 1.3rem
    font-weight: 400
    line-height: 2.4rem
  }

  &.active.active {
    background: var(--gray-100)
  }
  
  &:hover {
    background: var(--gray-50)
    .more {
      opacity: 1
      width: 1.6rem
      &:hover {
        background: var(--gray-50)
      }
    }
  }

  &:has(.more:hover) {
    background: transparent
  }

  .more {
    transition: all 0.3s
    border-radius: 0.4rem
    width: 0
    opacity: 0
    height: 1.6rem
    overflow: hidden
    &.visible {
      width: 1.6rem
      background: var(--gray-50)
      opacity: 1
    }
  }
}
</style>

<script>
import $chats, {Chat} from 'stores/chats'
import $global from 'stores/global'

export default {
  props: {
    active: {
      type: Boolean, default: false
    },
    chat: {
      type: Chat,
      required: true
    },
  },
  emits: ['show'],
  data() {
    return {
      show_popover: false,
      editable: false,
      can_accept: false
    }
  },
  beforeUnmount() {
    this.hide()
  },
  methods:{
    hide(){
      this.$emit('show', {id: this.chat.id, show: false})
    },
    onEdit() {
      this.editable = true
      this.can_accept = false
      this.$nextTick(() => {
        const input = this.$refs.input
        input.focus()
        const r = document.createRange()
        const s = window.getSelection()
        r.setStart(input, 1) 
        r.setEnd(input, 1)    
        s.removeAllRanges()
        s.addRange(r)
      })
    },
    restoreTitle() {
      this.$refs.input.innerText = $utils.str.decodeHTMLEntities(this.chat.title)
    },
    onShow(){
      this.$emit('show', {id: this.chat.id, show: true})
      this.show_popover = true
    },
    onBlur(ev) {
      this.editable = false
      this.hide()
      this.restoreTitle()
    },
    onCancel(){
      this.restoreTitle()
      this.editable = false
    },
    onAccept() {
      this.setNewTitle()
    },
    async setNewTitle(){
      this.editable = false
      this.show_popover = false
      this.chat.setTitle(this.$refs.input.innerText)
      this.$nextTick(async () => await this.chat.save())
    },
    onKeydown(e){
      const prevent = [13, 27].includes(e.keyCode)
      if (prevent) {
        e.preventDefault()
        if (e.keyCode === 13) {
          if (this.can_accept) this.setNewTitle()
          this.show_popover = false
          return
        }
        if (e.keyCode === 27) {
          this.restoreTitle()
          this.show_popover = false
          return
        }
      }
      // title length limit
      if (this.$refs.input.innerText.length === 512 && e.keyCode !== 8) {
        e.preventDefault()
        return
      }
    },
    onInput() {
      const text = this.$refs.input.innerText
      this.can_accept = text.length > 0 && this.chat.title !== text
    },
    async onRemove() {
      this.show_popover = false
      await $chats.removeChat(this.chat.id)
      $global.goToChats()
    }
  }
}
</script>