.navbar-list {
  color: var(--gray-100);
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.navbar-list * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.navbar-list.chats {
  border-left: 0.1rem solid var(--gray-300);
  padding-left: 0.4rem;
}
.navbar-list .item .offset {
  padding-left: 4.8rem;
}
.navbar-list .item .chat-group-title {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: var(--gray-500);
  padding: 0.2rem 0.4rem 0.3rem 0.8rem;
}
.navbar-list .item .children {
  margin-top: 0.4rem;
  margin-left: 1.6rem;
  display: flex;
}
