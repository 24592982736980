<template>
  <div class="sources-ground" :class="{loading}" @scrollend="onScrollEnd()">
    <template v-if="show_skeleton">
      <Skeleton v-for="ind in 5" :key="ind" style="height: 2.8rem"/>
    </template>
    <template v-else>
      <LifecycleEmitter v-for="(source, ind) in items" :key="JSON.stringify(source)" @mounted="loading && onMounted()">
        <div class="ground-item">
          <Source ref="source" class="src" :data="source" :num="ind+1" :disabled="loading"
                  :class="{hidden: hide_last && (ind+1 === items.length)}" @transitionend.stop="loading && onAppend($event)"
          />
        </div>
      </LifecycleEmitter>
    </template>
    <!-- VIEW-MORE -->
    <ViewMore v-if="all_done" class="ground-item" 
              :sources="sources" 
              :cols-count="colsCount" 
              :show-all="showAll" 
              @click.prevent="$emit('show-all')"
    />
  </div>
</template>
<style lang="stylus" scoped>
.sources-ground {
  width: 100%
  display: grid
  grid-template-columns: v-bind(cols)
  grid-template-rows: 2.8rem
  gap: 1.2rem
  min-height: 2.8rem
  line-height: 1.8rem
  overflow-x: scroll
  margin-bottom: -1rem
  padding-bottom: 1rem
  &::-webkit-scrollbar {
    display: none
  }
  .ground-item {
    .src {
      width: 16.359rem
      min-width: 0
    }
  }

  &.loading {
    .ground-item {
      .src {
        width: 16.359rem
        min-width: 0
      }
      &:last-child {
        position: relative
        overflow-x: hidden
        .src {
          position: absolute
          left: 0
          transition: left 1s ease
          &.hidden {
            left: 100%
          }
        }
      }
    }
  }
}
</style>
<script>
import Source from './source.vue'
import ViewMore from './view-more.vue'

export default {
  components: {
    Source, 
    ViewMore
  },
  mixins: [ModelValue(Array)],
  props: {
    sources: {
      type: Array,
      default: () => []
    },
    colsCount: {
      type: Number,
      default: 5
    },
    loading: {
      type: Boolean,
      required: true
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['show-all'],
  data(){
    return {
      items: [],
      new_items: [],
      appending: false,
      hide_last: false,
      all_done: false,
    }
  },
  computed: {
    show_skeleton(){
      return this.loading && !this.sources.length
    },
    cols(){
      const elems = this.items.length
      if (elems === 0 || this.all_done) return `repeat(${this.colsCount}, 16.359rem)`
      else if (elems === 1) return '1fr'
      else if (elems < this.colsCount) return `repeat(${elems - 1}, 16.359rem) 1fr`
      else return `repeat(${elems}, 16.359rem)`
    },
  },
  watch: {
    sources(val, prev) {
      if (this.loading) {
        const diff = val.length - prev.length
        const diff_sources =  val.slice(val.length - diff)
        this.new_items.push(...diff_sources)
        if (!this.appending) this.appendNew()
      }
    },
    async loading(val){
      if (!val) {
        await this.$nextTick()
        if (this.$el.scrollWidth > this.$el.clientWidth) {
          this.scrollToLeft()
        }
        else this.end()
      }
    },
    showAll(val){
      if (val) this.items.push(this.sources[this.colsCount - 1])
      else this.items.pop()
    }
  },
  mounted() {
    if (this.loading) this.start()
    else this.end()
  },
  methods: {
    start(){
      this.new_items.push(...this.sources)
      this.appendNew()
    },
    end(){
      const has_extra = this.sources.length > this.colsCount
      this.items = this.sources.slice(0, has_extra ? this.colsCount - 1 : this.colsCount)
      this.all_done = true
    },
    appendNew(){
      if (this.new_items.length) {
        this.appending = true
        this.hide_last = true
        this.items.push(this.new_items.shift())
      }
      else this.appending = false
    },
    scrollToRight(){
      this.$el.scrollTo({
        left: this.$el.scrollWidth, 
        behavior: 'smooth',
      })
    },
    scrollToLeft() {
      this.$el.scrollTo({
        left: 0, 
        behavior: 'smooth',
      })
    },
    onScrollEnd(){
      if (this.loading) this.hide_last = false
      else this.end()
    },
    onMounted(){
      if (this.items.length > this.colsCount) this.scrollToRight()
      else this.onScrollEnd()
    },
    onAppend(ev){
      if (ev.propertyName === 'left') {
        this.appendNew()
      }
    },

  }
}
</script>