:deep() .header {
  cursor: pointer;
  border: 0.1rem solid #dee2e6;
  padding: 1.2rem 1.8rem 1.2rem 2.4rem;
  background: var(--base-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep() .title {
  color: var(--text-secondary);
  font-size: 1.6srem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
:deep() .toggler {
  cursor: pointer;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
:deep() .toggleablecontent {
  box-sizing: border-box;
}
:deep() .toggleablecontent.p-toggleable-content-enter-active {
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}
:deep() .toggleablecontent.p-toggleable-content-leave-active {
  overflow: hidden;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}
:deep() .toggleablecontent.p-toggleable-content-enter-from,
:deep() .toggleablecontent.p-toggleable-content-leave-to {
  max-height: 0;
}
:deep() .toggleablecontent.p-toggleable-content-enter-to,
:deep() .toggleablecontent.p-toggleable-content-leave-from {
  max-height: 100rem;
}
:deep() .content {
  border: 0.1rem solid #dee2e6;
  background: var(--gray-200);
  color: #495057;
  border-top: 0 none;
}
