.file-name {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.file-name .name {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--text-secondary);
  font-weight: 500;
}
.controls {
  display: flex;
  justify-content: flex-end;
  gap: 0.4rem;
}
.controls .icon-wrap {
  padding: 10px;
}
.filters {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}
.filters .clear-filters {
  color: var(--gray-600);
  font-weight: 400;
  line-height: 2rem;
  cursor: pointer;
  padding: 0.8rem 1.2rem;
}
.right {
  display: flex;
  gap: 1.6rem;
  align-items: center;
}
