<template>
  <div class="person" relative>
    <div class="head">
      <!-- IMAGE -->
      <el-image class="avatar" fit="contain" :src="image_url">
        <template v-slot:error>
          <Skeleton v-if="loading" shape="circle" width="4.8rem" height="4.8rem"/>
          <div v-else-if="initials" class="initials">{{ initials }}</div>
        </template>
        <template v-slot:placeholder>
          <Skeleton v-if="loading" shape="circle" width="4.8rem" height="4.8rem"/>
        </template>
      </el-image>
  
      <!-- INFO -->
      <div class="info">
        <!-- NAME -->
        <div v-if="name" class="name ellipsis" v-html="name"/>
        <Skeleton v-else width="100%" height="3rem"/>
        <!-- POSITION -->
        <div v-if="position" class="position ellipsis" v-html="position"/>
        <Skeleton v-else-if="loading" width="100%" height="2rem"/>
        <div v-else class="position"/>
        <!-- COMPANY -->
        <div v-if="company" class="company ellipsis" v-html="company"/>
        <Skeleton v-else-if="loading" width="100%" height="2rem"/>
        <div v-else class="company"/>
      </div>

      <!-- ACTIONS -->
      <div class="actions">
        <div v-if="email" class="action-icon pointer" :disabled="loading" @click.stop="sendMail()">
          <Icon icon="mail-02" stroke-width="1" stroke="var(--gray-900)" width="2rem" height="2rem"/>
        </div>
        <!-- <div class="action-icon pointer" :disabled="loading || !phone" @click="callPhone()">
          <Icon icon="phone" stroke-width="1" stroke="var(--gray-900)" width="2rem" height="2rem"/>
        </div> -->
        <div class="action-icon pointer" :disabled="loading || !linkedin_url" @click.stop="openLinkedin()">
          <Icon icon="social/linkedin-circle" width="2.2rem" height="2.2rem"/>
        </div>
        <div class="action-icon pointer" :disabled="loading" @click.stop="$emit('save-doc')">
          <FileIcon font-size="0.6rem" width="1.9" height="2.2rem" ext="pdf"/>
        </div>
        <div class="action-icon pointer" :disabled="loading" @click.stop="$emit('save-rtf')">
          <FileIcon font-size="0.6rem" width="1.9" height="2.2rem" ext="rtf"/>
        </div>
        <!-- <Button plain :disabled="true || loading || !shared_contact_id" @click="$emit('follow', shared_contact_id)">
          Follow
        </Button> -->
      </div>
    </div>
    <Divider m="0.8rem 0 0"/>
    <!-- BIO -->
    <MdContent v-if="bio" :markdown="bio" class="bio"/>
    <Skeleton v-else class="bio" width="100%" height="2.4rem"/>
  </div>
</template>
<style lang="stylus" scoped>
.person {
  .show-more {
    position: absolute
    right: 1rem
    bottom: 1rem
    cursor: pointer
    color: var(--gray-600)
    font-size: 1.2rem
    line-height: 1.8rem
    font-weight: 600
    display: flex
    align-items: center
    gap: 0.6rem
  }
  .head {
    display: flex
    gap: 1.1rem
    .avatar {
      width: 4.8rem
      height: 4.8rem
      border-radius: 50%
      overflow: hidden
      flex-shrink: 0
      img {
        width: 100%
        height: 100%
        object-fit: cover
      }
      .initials {
        display: inline-flex
        justify-content: center
        align-items: center
        border: 0.05rem solid var(--gray-300)
        border-radius: 50%
        color: var(--gray-700)
        opacity: 0.8
        background: var(--gray-100)
        font-size: 1.7rem
        font-style: normal
        font-weight: 500
        line-height: 1.8rem
        width: 4.8rem
        height: 4.8rem
        overflow: hidden
        text-transform: uppercase
      }
    }
    .info {
      color: var(--gray-900)
      font-weight: 500
      display: flex
      flex-direction: column
      gap: 0.4rem
      min-width: 18rem
      .name {
        font-size: 2rem
        line-height: 3rem
      }
      .position {
        font-size: 1.4rem
        height: 2rem
      }
      .company {
        font-size: 1.4rem
        height: 2rem
      }
    }
    .actions {
      display: flex
      gap: 0.5rem
      align-items: center
      margin-left: auto
      flex-shrink: 0
      .action-icon {
        display: flex
        justify-content: center
        align-items: center
        padding: 1rem
        transition: background 0.3s ease
        border-radius: 0.8rem
        &:hover {
          background: var(--gray-100)
        }
      }
    }
  }

  .bio {
    margin-top: 1.2rem
    color: var(--gray-900)
    font-size: 1.6rem
    font-weight: 400
    line-height: 2.4rem
    width: 100%
  }
}
</style>

<script>
import MdContent from 'components/md-content'

export default {
  components: {MdContent},  
  props: {
    info: {type: Object, required: false, default: undefined},
    loading: {type: Boolean, default: false}
  },
  emits: ['follow', 'save-doc', 'save-rtf'],
  computed: {
    name() {
      return this.info?.name
    },
    image_url() {
      return this.info?.image_url
    },
    position() {
      return this.info?.position
    },
    company() {
      return this.info?.company
    },
    bio() {
      return this.info?.bio
    },
    email() {
      return this.info?.email
    },
    phone() {
      return this.info?.phone
    },
    linkedin_url() {
      return this.info?.linkedin_url
    },
    shared_contact_id() {
      return this.info?.shared_contact_id
    },
    initials(){
      if(this.name){
        const name = this.name.split(' ')
        return name.length > 1 ? name[0][0] + name[1][0] :  name[0][0]
      }
      return undefined
    }
  },
  methods: {
    callPhone(){
      const phone = document.createElement('a')
      phone.href = `tel:${this.phone}`
      phone.click()
    },
    sendMail(){
      const mail = document.createElement('a')
      const body = encodeURIComponent(`Hello ${this.name}!`)
      mail.href = `mailto:${this.email}?body=${body}`
      mail.click()
    },
    openLinkedin() {
      window.open(this.linkedin_url, '_blank')
    }
  }
}
</script>