<template>
  <div class="calendar">
    <DatePicker v-model="value" :inline="inline" :show-icon="false" :unstyled="false" :placeholder="placeholder" :selection-mode="selectionMode" label-class="p-inputtext">
    <!-- <template v-slot:dropdownicon>
      <Icon icon="fm dropdown" heigth="2rem" width="2rem"/>
    </template> -->
    </DatePicker>
  </div>
</template>
//DATEPICKER
<style lang="stylus">
.p-inputtext {
  color: #495057
  background: var(--base-white)
  padding: 1rem 1.1rem
  border: 1px solid #CED4DA
  transition: background-color 0.2s, color 0.2s, border-color 0.2s
  appearance: none
  border-radius: 6px
  box-shadow: var(--input-box-shadow)
}
.p-inputtext:enabled:hover {
  border-color: var(--primary-300)
}

.p-inputtext[aria-expanded="true"] {
  outline: none
  outline-offset: 0
  box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
  border-color: var(--primary-300)
}
.p-inputtext.p-invalid.p-component {
  border-color: #E24C4C
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem
  padding: 0.65625rem 0.65625rem
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem
  padding: 0.9375rem 0.9375rem
}

.p-calendar {
  align-self: stretch
  width: 100%
}

.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #E24C4C
}

.p-datepicker {
  background: var(--base-white)
  color: #495057
  border-radius: 6px
  width: 100%
  .p-datepicker-panel {
    .p-datepicker-header {
      padding: 0.5rem
      color: #DEE2E6
      background: var(--base-white)
      font-weight: 600
      margin: 0
      border-top-right-radius: 6px
      border-top-left-radius: 6px
      .p-datepicker-prev-button
      .p-datepicker-next-button {
        width: 2rem
        height: 2rem
        color: #6C757D
        border: 0 none
        background: transparent
        border-radius: 50%
        transition: background-color 0.2s, color 0.2s
        &:hover {
          color: #343A40
          border-color: transparent
          background: var(--gray-200)
        }
        &:focus {
          outline: 0 none
          outline-offset: 0
        }
      }
      .p-datepicker-title {
        line-height: 2rem
        .p-datepicker-select-year
        .p-datepicker-select-month
        .p-datepicker-decade {
          color: #495057
          transition: background-color 0.2s, color 0.2s
          font-weight: 600
          padding: 0.5rem
          &:enabled:hover {
            color: var(--primary-500)
          }
          .p-datepicker-select-month {
            margin-right: 0.5rem
          }
        }
      }
    }
    .p-datepicker-inline {
      background: var(--base-white)
      border: 0 none
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }
  }
  table {
    font-size: 1rem
    margin: 0.5rem 0
    th {
      padding: 0.5rem
      text-align: center
      &> span {
        width: 2.5rem
        height: 2.5rem
      }
    }
    td {
      padding: 0.5rem
      &> span {
        width: 2.5rem
        height: 2.5rem
        border-radius: 50%
        border: 0.1rem solid transparent
        user-select: none
        transition: background-color 0.2s, color 0.2s
        &.p-datepicker-day-selected
        &.p-datepicker-day-selected-range {
          color: var(--base-white)
          background: var(--primary-600)
          &:hover {
            background: var(--primary-700)
          }
        }
        &:focus {
          outline: 0 none
          outline-offset: 0
        }
      }
      &.p-datepicker-today {
        span {
          background: var(--gray-100)
          color: var(--gray-700)
          border-color: transparent

          &.p-datepicker-day-selected {
            color: var(--base-white)
            background: var(--primary-600)
          }
        }

        &:hover {
          span {
            background: var(--gray-200) !important
          }
        }
      }
      
      
      &> span:not(.p-datepicker-day-selected):not(.p-disabled) {
        &:hover {
          background: var(--gray-200)
        }
        &:focus {
          outline: 0 none
          outline-offset: 0
        }
      }
      &.p-datepicker-other-month {
        &> span {
          color: var(--gray-300)
        }
      }
    }
  }
  .p-datepicker-buttonbar {
    padding: 1rem 0
    border-top: 1px solid #DEE2E6
    .p-button {
      width: auto
    }
  }
  .p-timepicker {
    border-top: 1px solid #DEE2E6
    padding: 0.5rem
    button {
      width: 2rem
      height: 2rem
      color: #6C757D
      border: 0 none
      background: transparent
      border-radius: 50%
      transition: background-color 0.2s, color 0.2s
      &:enabled:hover {
        color: #343A40
        border-color: transparent
        background: var(--gray-200)
      }
      &:focus {
        outline: 0 none
        outline-offset: 0
      }
      &:last-child {
        margin-top: 0.2rem
      }
      span {
        font-size: 1.25rem
      }
      div {
        padding: 0 0.5rem
      }
    }
  }
  &.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none
    }
  }

  .p-datepicker-month-view {
    margin: 0.5rem 0
    .p-datepicker-month {
      padding: 0.5rem
      border-radius: 6px
      &:not(.p-disabled):not(.p-datepicker-month-selected):hover {
        background: var(--gray-200)
      }
      &.p-datepicker-month-selected {
        color: var(--primary-700)
        background: var(--primary-50)
      }
    }
  }
  .p-datepicker-year-view {
    margin: 0.5rem 0
    .p-datepicker-year {
      padding: 0.5rem
      border-radius: 6px
      &.p-datepicker-year-selected {
        color: var(--primary-700)
        background: var(--primary-50)
      }
    }
  }
  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-left: 1px solid #DEE2E6
      padding-right: 0.5rem
      padding-left: 0.5rem
      padding-top: 0
      padding-bottom: 0
      &:first-child {
        padding-left: 0
        border-left: 0 none
      }
      &:last-child {
        padding-right: 0
      }
    }
  }

  &:not(.p-disabled) {
    .p-datepicker-month-view .p-datepicker-month:not(.p-disabled):focus {
      outline: 0 none
      outline-offset: 0
    }

    .p-datepicker-year-view .p-datepicker-year:not(.p-disabled) {
      &:not(.p-datepicker-year-selected):hover {
        background: var(--gray-200)
      }
      &:focus {
        outline: 0 none
        outline-offset: 0
      }
    }
  }
}


@media screen and (max-width 769px) {
  .p-datepicker table th
  .p-datepicker table td {
    padding: 0
  }
}
</style>

//INPUT
<style lang="stylus" scoped>
.calendar:deep() {
  .p-inputtext {
    outline: none
    color: #495057
    background: var(--base-white)
    padding: 1rem 1.1rem
    border: 1px solid #CED4DA
    transition: background-color 0.2s, color 0.2s, border-color 0.2s
    appearance: none
    border-radius: 6px
    box-shadow: var(--input-box-shadow)
  }
  .p-inputtext:enabled:hover {
    border-color: var(--primary-300)
  }

  .p-inputtext[aria-expanded="true"] {
    outline-offset: 0
    box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
    border-color: var(--primary-300)
  }
  .p-inputtext.p-invalid.p-component {
    border-color: #E24C4C
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem
    padding: 0.65625rem 0.65625rem
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem
    padding: 0.9375rem 0.9375rem
  }

  .p-calendar {
    align-self: stretch
  }

  .p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #E24C4C
  }
}
</style>

<script setup>
import DatePicker from 'primevue/datepicker'
</script>

<script>
export default {
  mixins: [ModelValue(Array)],
  props: {
    placeholder: {  
      type: String,
      default: 'Select dates'
    },
    selectionMode: {
      type: String,
      default: 'range'
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>
