<template>
  <span class="symbol-counter" :style="my_style" nowrap>
    {{ current }}/{{ maxlength }} characters used
  </span>
</template>

<style scoped lang="stylus">
& .symbol-counter {
  line-height: 1.6rem
  margin-top: 0.6rem
  color: var(--gray-600)
  user-select: none
}
</style>

<script>
export default {
  props: {
    current: {
      type: Number, 
      required: true
    },
    maxlength: {
      type: Number,  
      default: 0
    },
    text_align: {
      type: String,
      default: 'right'
    },
    size: {
      type: String,
      default: '1.1rem'
    }
  },
  computed: {
    my_style () {
      return {
        'text-align': this.text_align,
        'font-size': this.size,
      }
    }
  }
}
</script>
