.chat-error .compact {
  display: flex;
  justify-content: start;
  padding: 1rem 1.4rem;
  background: var(--error-600);
  border-radius: 8px 8px 8px 8px;
}
.chat-error .compact .text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #fff;
}
.chat-error .compact .icon {
  margin-right: 0.8rem;
  margin-top: 0.1rem;
}
.chat-error .expanded,
.chat-error .onboarding {
  display: flex;
  justify-content: start;
  border: 0.1rem solid var(--error-600);
  border-radius: 0.8rem;
  background: transparent;
  padding: 1.6rem;
  margin-bottom: 1.2rem;
}
.chat-error .expanded .text,
.chat-error .onboarding .text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--error-600);
}
.chat-error .expanded .icon,
.chat-error .onboarding .icon {
  margin-right: 1rem;
}
.chat-error .icon {
  margin-top: 0.2rem;
  min-width: 2rem;
}
.chat-error .icon.copy {
  margin-right: 0;
  margin-left: auto;
}
