.popover_container.el-popover {
  display: flex;
  flex-direction: column;
  --el-popover-bg-color: var(--base-white);
  --el-popover-font-size: var(--el-font-size-base);
  --el-popover-border-color: var(--gray-200);
  --el-popover-padding: 1.6rem;
  --el-popover-padding-large: 1.6rem;
  --el-popover-title-font-size: 16px;
  --el-popover-border-radius: 0.8rem;
  --el-box-shadow-light: 0 0.2rem 0.8rem 0 rgba(16,24,40,0.1), 0 1.6rem 1.6rem 0 rgba(16,24,40,0.07);
}
.popover_container.el-popover .el-popper__arrow,
.popover_container.el-popover .el-popper__arrow:before {
  width: 1.5rem;
  height: 1.5rem;
}
.popover_container.el-popover[data-popper-placement^=top]>.el-popper__arrow {
  bottom: -0.9rem;
}
.popover_container.el-popover[data-popper-placement^=bottom]>.el-popper__arrow {
  top: -0.9rem;
}
