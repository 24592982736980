.datatable-relative {
  height: 100%;
}
.datatable-relative .datatable {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.datatable-relative .datatable .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datatable-relative .datatable .header .left,
.datatable-relative .datatable .header .right {
  flex-grow: 1;
  flex-shrink: 1;
}
.datatable-relative .datatable .header .left {
  justify-content: flex-start;
}
.datatable-relative .datatable .header .right {
  justify-content: flex-end;
}
.datatable-relative .datatable .empty {
  height: 100%;
}
.datatable-relative .datatable .root {
  border: 0.1rem solid var(--gray-200);
  border-radius: 0.4rem;
  box-sizing: border-box;
  overflow: auto;
}
.datatable-relative .datatable:deep() .table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.datatable-relative .datatable:deep() .table .bodyCell {
  padding: 1.6rem 2.4rem;
}
.datatable-relative .datatable:deep() .table .thead {
  border-bottom: 0.1rem solid var(--gray-200);
}
.datatable-relative .datatable:deep() .table .thead .headerCell {
  padding: 0;
}
.datatable-relative .datatable:deep() .table .thead .headerRow {
  background: #f7f5f3;
  text-align: left;
}
.datatable-relative .datatable:deep() .table .thead .columnHeaderContent {
  padding: 1.3rem 2.4rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
}
.datatable-relative .datatable:deep() .table .thead .columnHeaderContent .sort {
  display: inline-flex;
  gap: 0.4rem;
  align-items: center;
}
.datatable-relative .datatable:deep() .table .bodyRow {
  transition: background 0.2s;
}
.datatable-relative .datatable:deep() .table .bodyRow:not(:last-child) {
  border-bottom: 0.1rem solid var(--gray-200);
}
.datatable-relative .datatable:deep() .table .bodyRow:hover {
  background: var(--gray-50);
  transition: background 0.2s;
}
.datatable-relative .datatable:deep() .paginator-root .content {
  border-top: 0.1rem solid var(--gray-200);
  padding: 1.2rem 2.4rem;
  display: grid;
  grid-template-columns: 1fr auto auto auto auto auto 1fr;
  gap: 1.2rem;
  justify-content: center;
}
.datatable-relative .datatable:deep() .paginator-root .content .next,
.datatable-relative .datatable:deep() .paginator-root .content .prev,
.datatable-relative .datatable:deep() .paginator-root .content .first,
.datatable-relative .datatable:deep() .paginator-root .content .last {
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 0.8rem;
  color: var(--gray-800);
  background: transparent;
  border: none;
  padding: 1rem 1.65rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datatable-relative .datatable:deep() .paginator-root .content .next:disabled,
.datatable-relative .datatable:deep() .paginator-root .content .prev:disabled,
.datatable-relative .datatable:deep() .paginator-root .content .first:disabled,
.datatable-relative .datatable:deep() .paginator-root .content .last:disabled {
  opacity: 0.5;
}
.datatable-relative .datatable:deep() .paginator-root .content .next:hover:not(:disabled),
.datatable-relative .datatable:deep() .paginator-root .content .prev:hover:not(:disabled),
.datatable-relative .datatable:deep() .paginator-root .content .first:hover:not(:disabled),
.datatable-relative .datatable:deep() .paginator-root .content .last:hover:not(:disabled) {
  background: var(--gray-50);
}
.datatable-relative .datatable:deep() .paginator-root .content .current {
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: var(--gray-700);
  font-size: 1.4rem;
  gap: 0.8rem;
}
.datatable-relative .datatable:deep() .paginator-root .content .pages {
  display: flex;
  gap: 0.4rem;
}
.datatable-relative .datatable:deep() .paginator-root .content .pages .page {
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 0.8rem;
  color: var(--gray-800);
  background: transparent;
  border: none;
  padding: 1rem 1.65rem;
  line-height: 1.6rem;
}
.datatable-relative .datatable:deep() .paginator-root .content .pages .page:hover,
.datatable-relative .datatable:deep() .paginator-root .content .pages .page[data-p-highlight='true'] {
  background: var(--gray-50);
}
