<template>
  <div class="builder-inputs" :class="{col}">
    <div v-for="(item, idx) in opts" :key="item.value" class="field" 
         :class="[...(item.className || [])]" 
         :style="{'margin-bottom': !col && fieldGap(item, idx), ...item.style || {}}"
    >
      <BuilderInputs v-if="item.type === 'container'" 
                     v-read:valid="inputs_validity[idx]" 
                     :opts="item.opts" :col="item.dir !== 'row'" @update="onUpdate"
      />
      <template v-if="item.type === 'tabs'">
        <Tabs v-model="store[item.field]" 
              :tabs="item.opts" 
              @tab-change="onTabChange(item.field, idx)"
        />
        <template v-for="(tab, tab_idx) in item.opts" :key="tab_idx">
          <BuilderInputs v-if="tab.value === store[item.field]"
                         :ref="'tab_inputs_' + idx"
                         v-read:valid="inputs_validity[idx]"
                         :opts="tab.opts"
                         @update="onUpdate"
          />
        </template>
      </template>
      <label v-if="item.title">{{ item.title }}</label>
      <div v-if="item.description" class="description">{{ item.description }}</div>
      <Input v-if="['text', 'url', 'email'].includes(item.type)"
             :key="item.field"
             v-model="store[item.field]"
             v-read:valid="inputs_validity[idx]"
             :type="item.type"
             :placeholder="item.placeholder"
             :description="item.description"
             :spellcheck="item.spellcheck ?? true"
             :button="item.can_generate ? 'stars-02' : ''"
             :button-color="'var(--brand-600)'"
             :minlength="item.minlength"    
             :maxlength="item.maxlength"
             :required="item.required"
             @blur="onUpdate(item.field)"
      > 
        <template v-if="item.genrate_url" v-slot:custom_icon>
          <GenrateTextarea :title="item.title"  
                           :maxlength="item.maxlength"
                           :rows="3"
                           :api-url="item.genrate_url"
                           :api-args="() => item.genrate_args(store)"
                           :can_genrate="true"
                           :can_add="(store[item.field] || '' ).length < item.maxlength"
                           @append="(value) => onTextAppend(item, value)"
                           @replace="(value) => onTextReplace(item, value)"
          />
        </template>
      </Input>
      <Dropdown v-else-if="item.type === 'dropdown'"
                v-model="store[item.field]"
                v-read:valid="inputs_validity[idx]"
                :items="typeof item.items === 'string' ? store[item.items] : item.items"
                :disabled="item.disabled"
                :description="item.description"
                :placeholder="item.placeholder"
                :required="item.required"
                @change="onUpdate(item.field)"
      />
      <TextArea v-else-if="item.type === 'textarea'"
                v-model="store[item.field]"
                v-read:valid="inputs_validity[idx]"
                :hide-counter="item.hide_counter"
                :placeholder="item.placeholder"
                :description="item.description"
                :maxlength="item.maxlength"
                :min_width="item.min_width"
                :rows="item.rows || 5"
                class="textarea"
                @blur="onUpdate(item.field)"
      >
        <template v-if="item.genrate_url" v-slot:custom_icon>
          <GenrateTextarea :title="item.title"
                           :maxlength="item.maxlength"
                           :rows="(item.rows || 5) + 2"
                           :api-url="item.genrate_url"
                           :api-args="() => item.genrate_args(store)"
                           :can_genrate="true"
                           :can_add="(store[item.field] || '').length < item.maxlength"
                           @append="(value) => onTextAppend(item, value)"
                           @replace="(value) => onTextReplace(item, value)"
          />
        </template>
    </TextArea>
      <Checkbox v-else-if="item.type === 'checkbox'"
                v-model="store[item.field]"
                @change="onUpdate(item.field)"
      >
        {{ item.label }}
      </Checkbox>
      <Checkgroup v-else-if="item.type === 'checkgroup'"
                  v-model="store[item.field]"
                  :items="item.items"
                  rows="4"
                  @change="onUpdate(item.field)"
      />
      <UrlsList v-else-if="item.type === 'urls-list'" 
                v-model="store[item.field]" 
                v-read:valid="inputs_validity[idx]"
                :title="item.btn_label"
                :type="item.type"
                :items_label="item.title"
                :max="item.max"
                @blur="onUpdate(item.field)"
      />
      <Switch v-else-if="item.type === 'switch'" 
              v-model="store[item.field]"
              class="switch-item"
              :label="item.label"
              :description="item.description"
              @change="onUpdate(item.field)"
      />
      <template v-else-if="item.type === 'uploader'">
        <!-- Avatar uploader -->
        <Uploader v-if="item.mode === 'avatar'" 
                  class="uploader"
                  :mode="item.mode"
                  :label="item.title"
                  :description="item.description"
                  :max-uploads="1"
                  :max-size="AVATAR.max_size"
                  :accept="AVATAR.accept"
                  :store-type="AVATAR.type"
                  :store-tag="store.id"
                  @uploaded="file => onAvatarUploaded(item, file)"
                  @removed="() => onAvatarRemoved(item)"
        />
        <!-- all other files upload -->
        <Uploader v-else
                  class="uploader"
                  :mode="item.mode"
                  :label="item.title"
                  :description="item.description"
                  :accept="item.accept"
                  :max-uploads="item.max_uploads"
                  :max-size="item.max_size"
                  :store-type="item.file_type"
                  :store-tag="store.id"
                  :txt-rows="item.rows"
                  :file-rows="item.file_rows"
        />
      </template>
      <LinkSaver v-else-if="item.type === 'link-saver'" v-model="store[item.field]" :max-length="item.max_length" @change="onUpdate(item.field)"/>
    </div>
  </div> 
</template>

<style lang="stylus" scoped>
.builder-inputs {
  .field {
    width: 100%
    .description {
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
      margin-bottom: 0.8rem
      color: var(--gray-600)
    }
  }
  &:not(.col) {
    .field:last-child {
      margin-bottom: 0
    }
  }
}
.builder-inputs.col {
  display: flex
  flex-direction: row
  gap: 3.2rem
  justify-content: space-between
  > .grow {
    flex-grow: 1
  }
  > .center {
    display: flex
    justify-content: center
    align-items: center
  }
}
</style>

<script>
// do not use <script setup> here
// we call methods on this component
import UrlsList from './urls-list.vue'
import {GenrateTextarea} from 'components/genrate'
import {AVATAR} from 'consts/ftypes.js'

export default {
  components: {UrlsList, GenrateTextarea},
  inject: ['store'],
  props: {
    opts: {type: Array, default: () => [], required: true},
    col: {type: Boolean, default: false, required: false},
    gap: {type: String, default: '2rem', required: false}
  },
  emits: ['update'],
  data() {
    return {
      inputs_validity: Array(this.opts.length).fill(true),
      AVATAR
    }
  },
  computed: {
    valid () {
      return this.inputs_validity.indexOf(false) === -1
    }
  },
  methods: {
    onTabChange(prop, input_idx) {
      this.$nextTick(() => {
        // because we use v-if tab_inputs always have 1 element
        this.inputs_validity[input_idx] = this.$refs[`tab_inputs_${input_idx}`][0].valid
        this.onUpdate(prop)
      })
    },
    onUpdate(prop) {
      this.$emit('update', prop)
    },
    onAvatarUploaded(item, file) {
      this.store[item.field] = file.id
      this.$emit('update', item.field)
    },
    onAvatarRemoved(item) {
      this.store[item.field] = null
      this.$emit('update', item.field)
    },
    onMultiselectAdd(item, value) {
      value = value.toLowerCase()
      const curr_val = this.store[item.field]
      if (item.max_selections > curr_val.length) {
        this.store[item.field] = [...curr_val, value]
        this.$emit('update', item.field)
      }
    },
    onTextAppend(item, value) {
      this.store[item.field] = $utils.str.trim_to(this.store[item.field] + ' ' + value.replaceAll('\n', ' '), item.maxlength)
      this.$emit('update', item.field)
    },
    onTextReplace(item, value) {
      this.store[item.field] = $utils.str.trim_to(value.replaceAll('\n', ' '), item.maxlength)
      this.$emit('update', item.field)
    },
    fieldGap(field, idx) {
      let gap = 0

      // return gap that is included in control
      // for example when chars coutner is present 
      switch(field.type) {
        case 'text': gap = '1.6rem'; break
        case 'textarea': gap = '1.6rem'; break
        case 'dropdown': gap = '1.6rem'; break
        case 'multiselect': gap = '1.6rem'; break      
      }

      return `calc(${this.gap} - ${gap})`
    },
  }
}
</script>
