.search-input {
  min-width: 2rem;
  min-height: 3.2rem;
}
.search-input .open-icon {
  position: absolute;
  right: 0;
}
.search-input .search-wrapper {
  width: v-bind(c_width);
  overflow: hidden;
  transition: width 200ms;
  position: absolute;
  right: 0;
}
.search-input .search-wrapper .search :deep() input {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.search-input .search-wrapper.show {
  margin: -0.4rem;
  padding: 0.4rem;
}
