<template>
  <SettingsFrame title="Profile">
    <template v-slot:description>
      Update your photo and personal details here. (you can reset and restart the onboarding chat 
      <span class="pointer" style="text-decoration: underline;" @click="onReset()">here</span>)
    </template>
    <template v-slot:right>
      <AutoSave :valid="valid" :saving="saving" :saved_at="saved_at"/>
    </template>
    <div class="profile">
      <BuilderInputs v-read:valid="valid" col gap="2rem" :opts="schema" @update="onUpdate"/>
    </div>
  </SettingsFrame>
  <ResetModal ref="reset"/>
</template>

<style lang="stylus" scoped>
.profile {
  :deep() {
    .builder-inputs {
      .field.bio {
        display: flex
        flex-direction: column
        .textarea {
          flex-grow: 1
          max-height: 49.738rem
        }
      }
    }
  }
  .controls {
    display: flex
    justify-content: flex-end
    margin-top: 2.4rem
  }
}
</style>

<script setup>

</script>

<script>
import AutoSave from 'components/builder/autosave.vue'
import {makeProfileSchema} from '../settings-defs.js'
import SettingsFrame from '../settings-frame.vue'
import BuilderInputs from 'components/builder/builder-inputs.vue'
import ResetModal from './reset-modal.vue'
import $profile from 'stores/profile.js'

export default {
  components  : {
    SettingsFrame,
    BuilderInputs,
    AutoSave,
    ResetModal
  },
  provide() {
    return {
      store: $profile,
    }
  },
  data() {
    return {
      schema: makeProfileSchema(),
      valid: true
    }
  },
  computed: {
    saving() {
      return $profile.saving
    },
    saved_at() {
      return $profile.saved_at
    }
  },
  methods: {
    onUpdate() {
      this.$nextTick(async () => {
        if (this.valid) {
          await $profile.save()
        }
      })
    },
    onReset(){
      this.$refs.reset.open()
    }
  }
}
</script>
