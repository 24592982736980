.company-card {
  padding: 1.4rem 1.8rem 2.4rem;
}
.company-card .actions {
  display: flex;
  gap: 1rem;
}
.company-card .links {
  margin-top: 2.4rem;
}
.company-card .collapse-menu {
  margin-top: 1.6rem;
}
.company-card .collapse-menu+.collapse-menu {
  margin-top: 2.7rem;
}
