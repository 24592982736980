.checkbox {
  --el-checkbox-height: max-content;
  --el-checkbox-border-radius: 0.4rem;
  --el-checkbox-checked-bg-color: var(--brand-50);
  --el-checkbox-checked-icon-color: var(--brand-700);
  --el-checkbox-checked-input-border-color: var(--brand-200);
  --el-checkbox-input-border-color-hover: var(--brand-200);
  --el-checkbox-input-width: 1.8rem;
  --el-checkbox-input-height: 1.8rem;
  --el-checkbox-font-weight: 400;
  --el-checkbox-text-color: var(--gray-700);
  --el-checkbox-checked-text-color: var(--gray-900);
}
.checkbox:deep() .el-checkbox__inner::after {
  height: 0.7rem;
  left: 0;
  top: -0.3rem;
  right: 0;
  bottom: 0;
  width: 0.3rem;
  margin: auto;
  border-width: 0.2rem;
}
.checkbox:deep() .is-indeterminate .el-checkbox__inner::before {
  left: 0;
  right: 0;
  top: -1px;
  bottom: 0;
  margin: auto;
  height: 0.4rem;
}
.checkbox:deep() .el-checkbox__label {
  padding-left: 1.2rem;
  font-size: v-bind(fontSize);
}
.checkbox.el-checkbox--large {
  --el-checkbox-input-width: 2rem;
  --el-checkbox-input-height: 2rem;
  height: var(--el-checkbox-height);
}
.checkbox.el-checkbox--large:deep() .el-checkbox__inner {
  width: var(--el-checkbox-input-width);
  height: var(--el-checkbox-input-height);
}
.checkbox.el-checkbox--large:deep() .el-checkbox__inner::after {
  height: 0.7rem;
  top: -0.2rem;
  border-width: 0.2rem;
}
