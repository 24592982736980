.carousel.show-navs:deep() .p-carousel-content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.carousel:deep() {
  text-align: unset;
}
.carousel:deep() .p-carousel-content .p-carousel-prev-button,
.carousel:deep() .p-carousel-content .p-carousel-next-button {
  width: 2.1rem;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  height: 100%;
}
.carousel:deep() .p-carousel-prev {
  justify-content: flex-start;
}
.carousel:deep() .p-carousel-next {
  justify-content: flex-end;
}
.carousel:deep() .p-carousel-indicator-list .p-carousel-indicator {
  padding: 0 0.25rem;
}
.carousel:deep() .p-carousel-indicator-list .p-carousel-indicator button {
  background-color: var(--gray-200);
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.carousel:deep() .p-carousel-indicator-list .p-carousel-indicator button:hover {
  background: var(--gray-300);
}
.carousel:deep() .p-carousel-indicator-list .p-carousel-indicator.p-carousel-indicator-active button {
  background: var(--gray-400);
  color: #2e3440;
}
.carousel:deep() .p-carousel-indicator-list .p-reset {
  justify-content: center;
}
.carousel:deep() .p-carousel-indicators {
  padding: 1rem 0 0.8rem;
}
.carousel.vertical {
  position: relative;
}
.carousel.vertical.show-navs:deep() .p-carousel-content {
  display: grid;
  grid-template-areas: "carousel prev" "carousel dots" "carousel next";
  grid-template-columns: 1fr 3.4rem;
  grid-template-rows: 1fr auto 1fr;
}
.carousel.vertical.show-navs:deep() .p-carousel-content:hover .p-carousel-prev-button,
.carousel.vertical.show-navs:deep() .p-carousel-content:hover .p-carousel-next-button {
  opacity: 1;
}
.carousel.vertical.show-navs:deep() .p-carousel-content:hover .p-disabled {
  opacity: 0.3;
}
.carousel.vertical.show-navs:deep() .p-carousel-prev-button {
  grid-area: prev;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  transition: opacity 0.3s;
  width: 100%;
  padding-bottom: 1.3rem;
  border-radius: 0;
  opacity: 0;
}
.carousel.vertical.show-navs:deep() .p-carousel-next-button {
  grid-area: next;
  display: flex;
  justify-content: end;
  align-items: flex-start;
  transition: opacity 0.3s;
  width: 100%;
  padding-top: 1.3rem;
  border-radius: 0;
  opacity: 0;
}
.carousel.vertical.show-navs:deep() .p-carousel-indicator-list {
  grid-area: dots;
  align-items: end;
  gap: 1.2rem;
  flex-direction: column;
  padding: 0.5rem 0.4rem 0.5rem 0;
}
.carousel.vertical.show-navs:deep() .p-carousel-indicator-list .p-carousel-indicator {
  padding: 0;
}
.carousel.vertical.show-navs:deep() .p-carousel-indicator-list .p-carousel-indicator .p-carousel-indicator-button {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  position: relative;
}
.carousel.vertical.show-navs:deep() .p-carousel-indicator-list .p-carousel-indicator .p-carousel-indicator-button:before {
  content: '';
  display: block;
  width: 3rem;
  height: 1.9rem;
  position: absolute;
  right: calc(100% - 0.8rem);
}
.carousel.vertical.show-navs:deep() .p-carousel-viewport {
  grid-area: carousel;
}
