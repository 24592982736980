<template>
  <Segment class="meeting-prep" title="Meeting Prep">
    <template v-slot:header-left>
      <div class="title">
        Meeting Prep
      </div>
    </template>
    <!-- <template v-slot:header-right>
      <div class="actions" :disabled="loading">
        <Copy size="1.6rem"/>
        <Icon class="pointer" title="Send email" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" stroke-width="1" icon="send-01"/>
      </div>
    </template> -->
    <div v-if="meeting.info?.about" class="about">
      {{ meeting.info.about }}
    </div>
    <el-skeleton v-else class="about" animated style="height: 2rem">
      <template v-slot:template>
        <el-skeleton-item style="width:100%;height:100%;border-radius: 0.4rem"/>
      </template>
    </el-skeleton>
    <template v-if="!loading">
      <CollapseMenu v-for="segment in meeting.segments" :key="segment.title" class="collapse-menu" :title="segment.title">
        <MdContent v-if="segment.type === 'markdown'" :markdown="segment.content"/>
      </CollapseMenu>
    </template>
  </Segment>  
</template>

<style lang="stylus" scoped>
.meeting-prep {
  .actions {
    display: flex
    gap: 1rem
  }
  .title {
    color: #000
    font-size: 1.6rem
    font-weight: 600
    line-height: 2.4rem
  }
  .about {
    margin-top: 1.2rem
    font-size: 1.4rem
    color: var(--gray-900)
    font-weight: 400
    line-height: 2rem
  }
  .collapse-menu {
    margin-top: 1.6rem
  }
}
</style>
<script setup>
import MdContent from 'components/md-content'
defineEmits(['follow-person'])
</script>

<script>

export default {
  props: {
    meeting: {type: Object, required: true},
    loading: {type: Boolean, default: false}
  },
}
</script>