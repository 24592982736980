<template>
  <div class="ai-agents">
    <Agent v-if="persona" type="content-writer"/>
    <Agent v-if="company" type="market-intelligence"/>
    <Agent v-if="editable" type="social-writer"/>
  </div>
</template>
<style lang="stylus" scoped>
.ai-agents {
  display: flex
  gap: 1rem
}
</style>
<script>
export default {
  props: {
    data: {type: Object, required: true},
  },
  computed: {
    persona() {
      return this.data.cards?.some(x => x.type === 'persona')
    },
    company() {
      return this.data.cards?.some(x => x.type === 'company')
    },
    editable() {
      return this.data.editable
    },
  }
}
</script>