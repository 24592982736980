.file {
  border-radius: 0.4rem;
  border: 0.1rem solid var(--gray-200);
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  height: 100%;
  max-width: 20rem;
  min-width: 0;
}
.file .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  flex-shrink: 0;
}
.file .failed {
  background: var(--error-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file .error-text {
  color: var(--error-600);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.file .info {
  min-width: 0;
}
.file .info .name {
  overflow: hidden;
  color: var(--gray-700);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
}
.file .info .size {
  overflow: hidden;
  color: var(--gray-600);
  text-overflow: ellipsis;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}
.file .close {
  border-radius: 50%;
  background: var(--gray-600);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
  cursor: pointer;
  position: absolute;
  right: -0.8rem;
  top: -0.8rem;
}
