<template>
  <template v-if="index > -1">
    <span>{{ text.slice(0, index) }}</span>
    <span class="highlight">
      {{ text.slice(index, index + highlight.length) }}
    </span>
    <span>{{ text.slice(index + highlight.length) }}</span>
  </template>
  <span v-else>{{ text }}</span>
</template>

<style lang="stylus" scoped>
.highlight {
  color: var(--base-white)
  background: var(--primary-400)
}
</style>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  text: {
    type: String, required: true, default: ''
  },
  highlight: {
    type: String, required: true, default: ''
  }
})

const text = computed(() => props.text || '')
const highlight = computed(() => props.highlight || '')


const index = computed(
  () => text.value.toLowerCase().search($utils.regex.escapeRegex(highlight.value.toLowerCase()))
)
</script>