<template>
  <div class="audit">
    <Loader v-if="loading">
      Loading<br>Please wait...
    </Loader>
    <template v-else-if="client_id">
      <SettingsIcon class="icon"/>
      <div class="info"><b>Audit API client id:</b> {{ client_id }}</div>
      <div class="info">
        <b>Audit API client secrect:</b> <span>***********************</span> <Copy style="display: inline-block;" size="1.6rem" @copy="$utils.copyText(client_secret)"/>
      </div>
      <div class="info"><b>OAuth endpoint:</b> {{ AUDIT_TOKEN }}</div>
      <Popconfirm width="max-content"
                  confirm-button-text="Yes"
                  cancel-button-text="No"
                  title="Delete API Client?"
                  :disabled="data_loading"
                  @confirm="onDeleteClient"
      >
        <div>
          <Button class="action" :disabled="data_loading">Delete API Client</Button>
        </div>
      </Popconfirm>
      <div class="audit-actions">
        <Button :disabled="data_loading" @click="onFetchGroups">Fetch 10 Groups</Button>
        <Button :disabled="data_loading" @click="onFetchUsers">Fetch 10 Users</Button>
        <Button :disabled="data_loading" @click="onFetchLogs">Fetch 10 Auit Logs</Button>
      </div>
      <div class="data">
        <Loader v-if="data_loading"></Loader>
        <pre v-else-if="data.length">{{ JSON.stringify(data, null, 4) }}</pre>
        <div v-else class="message">No audit data loaded</div>
      </div>
    </template>
    <template v-else>
      <SettingsIcon class="icon"/>
      <div class="info"><b>No audit API client present</b></div>
      <Button primary class="action" @click="onCreateClient">Create API Client</Button>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.audit {
  display: flex
  flex-direction: column
  align-items: center
  height: 100%
  overflow: overlay

  .icon {
    margin-top: 3rem
    margin-bottom: 2.5rem
  }

  .info {
    margin-top: 0.5rem
  }

  .action {
    margin-top: 2.5rem
  }

  .audit-actions {
    margin-top: 2.5rem
    display: flex
    flex-direction: row
    gap: 2rem
  }

  .data {
    flex-grow: 1
    border: 1px solid var(--gray-300)
    background: var(--gray-200)
    padding: 1rem
    min-width: 65rem
    min-height: 30rem
    max-width: 75rem
    margin: 3.5rem
    display: flex
    height: 0px
    overflow-y: overlay

    .message {
      text-align: center
      width: 100%
      margin-top: 3rem
    }
  }
}
</style>
<script setup>
import SettingsIcon from './settings-icon.vue'
import $settings from 'stores/settings'
import {AUDIT_TOKEN, API_AUDIT_LOGS, API_AUDIT_GROUPS, API_AUDIT_USERS} from 'consts/api'
</script>

<script>
import {Rest} from 'utils'

export default {
  data() {
    return {
      rest: new Rest({auth: false}),
      data_loading: false,
      data: []
    }
  },
  computed: {
    loading() {
      return !$settings.loaded
    },
    client_id() {
      return $settings.client_id
    },
    client_secret() {
      return $settings.client_secret
    }
  },
  async mounted() {
    await $settings.load()
  },
  methods: {
    async onClientAction(event) {
      this.$refs.confirm.open(event.target.name)
    },
    async onDeleteClient() {
      await $settings.deleteClient()
    },
    async onCreateClient() {
      await $settings.createClient()
    },
    async authHeaders() {
      const token_resp = await this.rest.post(
        AUDIT_TOKEN, new URLSearchParams({grant_type: 'client_credentials'}), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${this.client_id}:${this.client_secret}`)}`
          }
        }
      )
      return {
        'Authorization': `Bearer ${token_resp.access_token}`
      }
    },
    async onFetchGroups() {
      this.data_loading = true
      const headers = await this.authHeaders()
      const resp = await this.rest.get(API_AUDIT_GROUPS, {limit: 10}, {headers})
      this.data = resp.groups
      this.data_loading = false
    },
    async onFetchUsers() {
      this.data_loading = true
      const headers = await this.authHeaders()
      const resp = await this.rest.get(API_AUDIT_USERS, {limit: 10}, {headers})
      this.data = resp.users
      this.data_loading = false
    }, 
    async onFetchLogs() {
      this.data_loading = true
      const headers = await this.authHeaders()

      const resp1 = await this.rest.get(API_AUDIT_LOGS, {source: 'cognito', after: (new Date(1, 1, 1990)).getTime(), limit: 5}, {headers})
      const resp2 = await this.rest.get(API_AUDIT_LOGS, {limit: 5, next_token: resp1.next_token}, {headers})

      this.data = resp1.events.concat(resp2.events)
      this.data_loading = false
    }
  }
}
</script>