<template>
  <div class="root" relative>
    <Navbar/>
    <div :key="key" class="content">
      <router-view/>
    </div>
    <template v-if="show_feed">
      <Collapse :model-value="open" horizontal> 
        <Feed collapsible class="feed" :store="feed" @collapse="open = false"/>
      </Collapse> 
      <div v-if="!open" class="feed-notifier row-hvcenter pointer" :class="{open}" @click="open = true">
        <Icon v-if="unseen > 0" 
              stroke="var(--gray-900)" 
              width="1.6rem" 
              height="1.6rem" 
              icon="bell-03-notification"
        />
        <Icon v-else
              stroke="var(--gray-900)" 
              width="1.6rem" 
              height="1.6rem" 
              icon="bell-03"
        />
      </div>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.root {
  display: grid
  grid-template-columns: auto 1fr auto
  height: 100%
  width: 100%
  .content {
    position: relative
    min-height: 0
    overflow: hidden
    text-overflow: ellipsis
  }
  .feed {
    width: 54.6rem
    border-left: 0.1rem solid var(--gray-200)
    background: var(--base-white)
  }
  .feed-notifier {
    position: absolute
    right: 0
    padding: 1.7rem 2rem 1.7rem 0rem
    display: block
    z-index: 1
    -webkit-user-drag: none
    user-select: none
    -moz-user-select: none
    -webkit-user-select: none
    -ms-user-select: none
    &.open {
      height: unset
    }
  }
}
</style>
<script>
import Navbar from 'components/navbar'
import Feed from 'components/feed'

import $feed from 'stores/feed'
export default {
  components: {Navbar, Feed},
  setup() {
    return {feed: $feed}
  },
  data(){
    return {
      key: 0,
      open: false,
    }
  },
  computed: {
    show_feed(){
      return this.$route.name !== 'new-chat'
    },
    unseen(){
      return $feed.unseen
    }
  },
  watch: {
    '$route.fullPath'() {
      if (this.$route.name === 'settings') return
      this.key++
    }
  }
}
</script>