<template>
  <div class="empty-wrapper" :class="{bordered}">
    <div class="circles">
      <div class="empty-icon">
        <slot v-if="$slots.image" name="image"/>
        <img v-else :draggable="false" class="img" :src="icon" alt="file-type-icon"/>
      </div>
    </div>
    <div class="text">
      <slot name="title"/>
    </div>
    <div class="sub-text">
      <slot name="sub-text"/>
    </div>
    <div class="link pointer">
      <slot name="link"/>
    </div>
    <div class="actions">
      <slot name="actions"/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.empty-wrapper {
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%
  overflow: hidden

  &.bordered {
    border-radius: 1.2rem
    border: 0.1rem solid var(--blue-400)
    box-shadow: var(--box-shadow-indigo)
  }
    
  .circles {
    margin: -240px -240px -240px -240px
    padding: 240px 240px 240px 240px
    background: v-bind(c_bg)
    background-size: v-bind(bgSize)
  }
    
  .empty-icon {
    width: v-bind(circleSize)
    height: v-bind(circleSize)
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%)
  }
    
  .text {
    font-size: 1.6rem
    font-weight: 500
    line-height: 2.4rem
    color: var(--text-primary)
    margin-top: 1.6rem
    white-space: nowrap
  }
    
  .sub-text {
    margin-top: 0.4rem
    text-align: center
    font-size: 1.4rem
    font-weight: 400
    line-height: 2rem
    color: var(--text-secondary)
  }
    
  .link {
    margin-top: 0.4rem
    font-size: 1.4rem
  }
  
  .actions {
    margin-top: 2.4rem
    display: flex
    gap: 1.2rem
  }
}
</style>

<script setup>
import {computed} from 'vue'
const props = defineProps({
  bg: {
    type: String,
    default: 'assets/bg-pattern-decorative.png'
  },
  bgSize: {
    type: String,
    default: 'cover'
  },
  circleSize: {
    type: String,
    default: '12rem'
  },
  icon: {
    type: String,
    default: 'assets/icons/csv-icon.svg'
  },
  bordered: {
    type: Boolean,
    default: true
  }
})
const c_bg = computed(() => `url(${props.bg}) no-repeat center calc(50%)`)
</script>
