/*******************************
    User Variable Overrides
*******************************/
@checkboxSize: 1.8rem;
@checkboxCheckFontSize: 1.2rem;
@checkboxBorderRadius: @4px;

@checkboxColor: @brand-700;
@checkboxActiveFocusCheckColor: @brand-700;

@labelHoverColor: @brand-700;
@checkboxHoverBorderColor: @brand-200;
@checkboxFocusBorderColor: @brand-200;

@checkboxPressedBackground: @brand-50;
@checkboxPressedBorderColor: @brand-200;
@checkboxPressedColor: @brand-700;

@checkboxActiveBackground: @brand-50;
@checkboxActiveBorderColor: @brand-200;
@checkboxActiveCheckColor: @brand-700;

@checkboxActiveFocusBackground: @brand-50;
@checkboxActiveFocusBorderColor: @brand-200
@checkboxActiveFocusCheckColor: @brand-700;