<template>
  <Popover v-model="show" :show-arrow="false" placement="bottom-start" :teleported="false">
    <template v-slot:reference>
      <div class="reference" relative @click="show = !show">
        <Icon v-if="gotFilter" icon="indicator" class="indicator" width="0.7rem" height="0.7rem" fill="#0BA5EC" stroke="var(--base-white)"/>
        <div class="title">
          {{ title }}
        </div>
        <Icon icon="chevron-down" width="1.6rem" height="1.6rem"/>
      </div>
    </template>
    <slot :close="() => show = false"/>
  </Popover>
</template>
<style lang="stylus" scoped>
.reference {
  display: flex
  align-items: center
  cursor: pointer
  font-size: 1.4rem
  padding: 0.8rem 1.6rem
  gap: 0.6rem
  .indicator {
    position: absolute
    left: 0
  }
  .title {
    color: var(--gray-600)
    font-size: 1.4rem
    font-weight: 600
    line-height: 2rem
  }
}
</style>
<script>
export default {
  props: {
    title: {type: String, required: true},
    gotFilter: {type: Boolean, default: false},
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>