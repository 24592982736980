.feedback {
  border-radius: 0.8rem;
  background: var(--base-white);
}
.feedback .header {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  padding: 1.2rem 1.6rem;
}
.feedback .textarea {
  padding: 0 1.6rem;
}
.feedback .action {
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem;
}
