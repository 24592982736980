.switch {
  -el-switch-on-color: var(--el-color-primary);
  --el-switch-off-color: rgba(0,0,0,0.05);
  --el-switch-on-color: var(--brand-600);
  --el-switch-border-color: transparent;
}
.switch.el-switch--small:deep() .el-switch__core {
  min-width: 3.5rem;
  height: 1.5rem;
}
.switch.el-switch--small:deep() .el-switch__action {
  width: 1.5rem;
  height: 1.5rem;
  box-shadow: 0 1px 2px 0 var(--gray-300), 0 0 0 1px var(--gray-300) inset;
  background: var(--base-white) linear-gradient(transparent, rgba(0,0,0,0.05));
}
.switch.el-switch--small:not(.is-checked):deep() .el-switch__core .el-switch__action {
  left: -0.15rem;
}
.switch.el-switch--small.is-disabled:deep() .el-switch__core,
.switch.el-switch--small.is-disabled:deep() .el-switch__label {
  cursor: default;
}
