<template>
  <CollapseMenu :loading="loading" 
                class="collapse-menu" 
                :title="title"
  >
    <div class="links">
      <MdContent v-if="summary" class="summary" :markdown="summary"/>
      <div class="news">
        <Carousel class="carousel" 
                  :value="content" 
                  :num-visible="2" 
                  :num-scroll="2"
                  :show-navs="show_navs"
                  orientation="vertical"
                  :vertical-view-port-height="v_heihgt"
        >
          <template v-slot:item="{slotProps}">
            <LinksItem class="link-item" :data="slotProps"/>
          </template>
        </Carousel>
      </div>
    </div>
  </CollapseMenu>
</template>

<style lang="stylus" scoped>  

.links {
  .summary {
    margin-top: 1.2rem
    margin-bottom: 1.4rem
  }
  .news {
    margin-top: 0.4rem
    .link-item {
      margin: 0.4rem 0
    }
  }
}
</style>

<script>
import MdContent from 'components/md-content'
import LinksItem from './links-item.vue'

export default {
  components: {
    LinksItem,
    MdContent
  },
  props: {
    links: {type: Object, required: true}
  },
  computed:{
    show_navs(){
      return this.links.content.length > 2
    },
    v_heihgt(){
      return this.links.content.length >= 2 ? '21.6rem' : '10.8rem'
    },
    summary(){
      return this.links.summary
    },
    title(){
      return this.links.title
    },
    content(){
      return this.links.content
    },
    loading(){
      return !this.content
    }
  }
}
</script>