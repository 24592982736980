<template>
  <SourceWrap v-if="show_more" ref="view" class="view-more" pointer>
    <template v-for="source in extra_items" :key="source">
      <FileIcon v-if="source.type === 'file'" font-size="0.5rem" width="1.28rem" height="1.6rem" :ext="source.type"/>
      <img v-else class="rest-icon" :src="source.logo || '/assets/icons/cube-02.svg'"/>
    </template>
    <span class="text">
      View {{ rest_count }} more
    </span>
  </SourceWrap>
</template>
<style lang="stylus" scoped>
.view-more {
  display: flex
  gap: 0.8rem
  align-items: center
  min-width: 0
  flex-shrink: 0
  .rest-icon {
    width: 1.6rem
    display: flex
    border-radius: 50%
  }
  .text {
    margin-left: auto
    white-space: nowrap
  }
}
</style>
<script>
import SourceWrap from './source-wrap.vue'

const EXTRA_COUNT = 2

export default {
  components: {SourceWrap},
  props: {
    sources: {
      type: Array,
      default: () => []
    },
    colsCount: {
      type: Number,
      default: 5
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    extra_items(){
      if (this.sources.length <= this.colsCount) return []
      return this.sources.toSpliced(0, this.colsCount - 1).toSpliced(EXTRA_COUNT)
    },
    rest_count(){
      const abs = this.colsCount - this.sources.length
      return abs < 0 ? Math.abs(abs) : 0
    },
    show_more(){
      return !!this.extra_items.length && !this.showAll
    },
  }
}
</script>