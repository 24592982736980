<template>
  <Modal ref="modal" max_width="54.4rem" min_width="30rem" :centered="false">
    <template v-slot:content>
      <div class="onboarding-modal">
        <div class="icon">
          <div class="circle-bg">
            <div class="circle">
              <Icon icon="message-chat-circle" stroke="var(--brand-500)" height="2.4rem" width="2.4rem"/>
            </div>
          </div>
        </div>
        <div class="text">
          <div class="header">
            <Icon class="pointer close" icon="x-close" stroke="var(--gray-400)" height="2rem" width="2rem" @click="close"/>
            Are you sure you want to reset your profile and restart the onboarding process?
          </div>
          <div class="info">
            Restarting the onboarding will delete your current profile and guide you through the steps to create a new one.
            <br>
            <br>
            Do you want to reset your profile and start over?
          </div>
        </div>
      </div>
    </template>
    <template v-slot:controls>
      <Button small @click="close">
        No, keep my profile
      </Button>
      <Button small primary @click="submit">
        Yes, start over
      </Button>
    </template>
  </Modal>
</template>

<style lang="stylus" scoped>
.onboarding-modal {
  display: flex
  gap: 1.6rem
  .icon {
    .circle-bg {
      margin: -2rem -20rem -20rem -1.6rem
      padding: 2rem 20rem 20rem 1.6rem
      background: url(assets/bg-pattern-decorative.png)
      background-size: cover
      background-repeat: no-repeat
      background-position: -18.4rem -18rem
      background-size: 170%
      .circle {
        display: flex
        justify-content: center
        align-items: center
        width: 4.8rem
        height: 4.8rem
        background: var(--brand-50)
        border-radius: 50%
      }
    }
  }

  .text {
    .header {
      display: flex
      flex-direction: column
      justify-content: space-between
      font-size: 1.6rem
      font-style: normal
      font-weight: 600
      line-height: 2.2rem
      margin-bottom: 1.2rem
      .close {
        margin-left: auto
      }
    }
    .info {
      margin-top: 0.4rem
      font-size: 1.4rem
      font-style: normal
      font-weight: 400
      line-height: 2.2rem
    }
  }
}

</style>

<script>
import $store from 'stores/global'
export default {
  methods: {
    open() {
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    },
    async submit() {
      this.close()
      $store.deleteOnboarding()
    }
  }
}
</script>