<template>
  <div class="feed-item" 
       @mouseleave="abortSeen" @mouseenter="onMouseEnter"
  >
    <div class="event-header">
      <div class="left" relative>
        <Agent :type="agent"/>
        <span class="time">{{ event_time }}</span>
      </div>
      <div class="right" :class="{show: show_actions}">
        <!-- DISLIKE -->
        <Feedback :show-popover="!disliked" @send="$emit('dislike', $event)" @show="handleFeedback">
          <Icon class="pointer" :fill="disliked ? 'var(--gray-300)' : 'var(--base-white)'" width="1.6rem" height="1.6rem" stroke-width="1" icon="thumbs-down"/>
        </Feedback>
        <!-- LIKE -->
        <Feedback :show-popover="!liked" @send="$emit('like', $event)" @show="handleFeedback">
          <Icon class="pointer" :fill="liked ? 'var(--gray-300)' : 'var(--base-white)'" width="1.6rem" height="1.6rem" stroke-width="1" icon="thumbs-up"/>
        </Feedback>
        <!-- FAVORITE -->
        <Icon class="pointer" 
              :stroke="favorite ? 'var(--brand-500)' : 'black'" 
              :fill="favorite ? 'var(--brand-500)' : 'var(--base-white)'" 
              width="1.6rem" height="1.6rem" stroke-width="1" icon="star-01" 
              @click="$emit('favorite')"
        />
      </div>
    </div>
    <div class="content">
      <div class="title" relative>
        <div v-if="show_indicator" class="indicator"/>
        {{ item.title }}
      </div>
      <div class="text" :class="{'pointer': !!source_url}" @click="onSource()">
        {{ item.summary }}
      </div>
      <Card v-if="card" class="feed-card" :card="card"/>
    </div>
    <div class="bottom">
      <EventButton class="event-button" feed :text="item.action.title" @click="onAction()"/>
      <div class="source" :class="{'pointer': !!source_url}" @click="onSource()">
        <span v-if="source_name" class="name">{{ source_name }}</span>
        <Favicon class="favicon" :src="source_icon" size="1.6rem"/>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.feed-item {
  padding: 2rem 2.6rem 0.8rem
  line-height: 1.8rem
  .event-header {
    display: flex
    align-items: center
    justify-content: space-between
  
    .left {
      display: flex
      align-items: center
      flex-direction: row
      gap: 1rem
      .time {
        font-size: 1.2rem
        display: flex
        color: var(--gray-900)
      }
    }
    .right {
      display: flex
      align-items: center
      gap: 1.2rem
      opacity: 0
      transition: opacity 0.3s
      &.show {
        opacity: 1
      }
    }
  }

  &:hover {
    .event-header .right {
      opacity: 1
    }
  }

  .feed-card {
    margin-top: 0.8rem
  }

  .content {
    margin-top: 0.8rem

    .title {
      .indicator {
        position: absolute
        background: #0BA5EC
        width: 0.7rem
        height: 0.7rem
        border-radius: 50%
        top: 0.5rem
        left: -1.3rem
      }
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 500
      line-height: 1.8rem
    }

    .text {
      color: var(--gray-800)
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
      margin-top: 0.7rem
    }
  }


  .bottom {
    display: flex
    justify-content: space-between
    margin-top: 0.8rem
    .event-button {
      flex-basis: auto
    }
    .source {
      display: flex
      flex-direction: row
      align-items: center
      gap: 0.6rem
      margin-top: 0.4rem
      .name {
        color: var(--gray-700)
        font-size: 1.2rem
        font-weight: 400
        line-height: 1.8rem
        white-space: nowrap
      }
      .favicon {
        flex-shrink: 0
      }
    }
  }
}
</style>

<script>
import {Later} from 'utils/timers'
import Card from './card.vue'
export default {
  components: {Card},
  props: {
    item: {type: Object, required: true},
    showBadge: {type: Boolean, default: true},
    trackSeen: {type: Boolean, default: true},
  },
  emits: ['read', 'action', 'dislike', 'like', 'favorite', 'open'],
  data() {
    return {
      event_time: undefined,
      opened: new Set(),
      seen: new Later(500),
      ago_timer: new Later(1000)
    }
  },
  computed: {
    source_icon(){
      if (this.type === 'calendar') {
        if (this.item.source.type === 'outlook-calendar') {
          return 'assets/outlook.svg'
        }
        if (this.item.source.type === 'google-calendar') {
          return 'assets/google-calendar.svg'
        }
        return undefined
      }
      return this.item.source?.logo_url
    },
    source_name() {
      if (this.type === 'calendar') {
        if (this.item.source.type === 'outlook-calendar') {
          return 'Outlook Calendar'
        }
        if (this.item.source.type === 'google-calendar') {
          return 'Google Calendar'
        }
        return undefined
      }
      return this.item.source?.name
    } ,
    source_url(){
      return this.item.source?.url
    },
    show_indicator() {
      return this.trackSeen && !this.item.seen
    },
    type(){
      return this.item.type
    },
    agent(){
      if (this.type === 'calendar') {
        return 'meeting-prep'
      }
      if (this.type === 'news') {
        return 'market-intelligence'
      }
      return 'genrate'
    },
    card(){
      return this.item.card
    },
    liked(){
      return this.item.liked > 0
    },
    disliked(){
      return this.item.liked < 0
    },
    favorite(){
      return this.item.favorite
    },
    show_actions(){
      return !!this.opened.size || this.liked || this.disliked || this.favorite
    }
  },
  mounted(){
    this.formatAgo()
    if (this.type === 'news') {
      this.ago_timer.repeat(this.formatAgo)
    }
  },
  unmounted() { 
    this.abortSeen()
    this.ago_timer.abort()
  },
  methods: {
    formatAgo() {
      if (this.type === 'calendar') {
        if (this.item.source.start) {
          this.event_time = this.formatDate(this.item.source.start)
        }
      }
      if (this.type === 'news') {
        this.event_time = $utils.format.daysAgo(this.item.created_at)
      }
    },

    formatDate(time) {
      const date = new Date(time)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const year = String(date.getFullYear()).slice(-2) // Get the last two digits of the year

      // Time formatting
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${day}.${month}.${year} ⋅ ${hours}:${minutes}`
    },
    onSource(){
      if (this.source_url) window.open(this.source_url, '_blank')
    },
    onAction(){
      this.$emit('action', {...this.item.action.chat_query, 'feed_id': this.item.id})
    },
    onMouseEnter() {
      if (!this.trackSeen || this.item.seen || this.seen.once_set) return
      this.seen.once(() => this.$emit('read', this.item.id))
    },
    abortSeen(){
      this.seen.abort()
    },
    handleFeedback(ev){
      ev.show ? this.opened.add(ev.id) : this.opened.delete(ev.id) 
    }
  }
}
</script>
