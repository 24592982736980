<template>
  <el-popconfirm hide-icon popper-class="popconfirm">
    <template v-slot:reference>
      <slot/>
    </template>
  </el-popconfirm>
</template>

<style lang="stylus">
.popconfirm {
  --el-text-color-regular: var(--text-secondary)
  font-size: 1.4rem
  padding: 1.2rem
  min-width: 15rem
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.12)

  &[data-popper-placement^=bottom] .el-popper__arrow::before {
    border-top-color: var(--el-border-color-light) !important
  }
  &[data-popper-placement^=top] .el-popper__arrow::before {
    border-bottom-color: var(--el-border-color-light) !important
    border-right-color: var(--el-border-color-light) !important
  }

  // Don't delete this
  // Pop confirm doesn't use our <Button> but default <el-button>
  // So we need to style it
  .el-button--primary {
    background-color: var(--brand-500) !important
    border-color: transparent !important
    &:hover {
      background-color: var(--brand-600) !important
      border-color: transparent !important
    }

    &:active {
      border-color: transparent !important
    }
  }
}
</style>
