<template>
  <div class="autocomplete-wrapper">
    <AutoComplete ref="autocomplete"
                  v-model="value"
                  class="autocomplete"
                  :class="{invalid, empty: !value}"
                  :input-props="{maxLength, spellcheck: false}"
                  :min-length="minLength"
                  :unstyled="false"
                  :option-label="optionLabel"
                  :suggestions="suggestions"
                  :delay="delay"
                  :placeholder="placeholder"
                  :complete-on-focus="complete_on_focus"
                  :panel-style="{display: !value || invalid ? 'none' : 'block', width}"
                  @complete="$emit('complete')"
                  @item-select="$emit('item-select', $event)"
    >
      <template v-slot:loadingicon>
        <Loader class="loader" mini/> 
      </template>
    </AutoComplete>
    <Footnote type="error" 
              :style="{visibility: invalid ? 'visible' : 'hidden', marginTop: '0.6rem'}"
    >
      Should be at least {{ minLength }} characters long 
    </Footnote>
  </div>
</template>
<style lang="stylus">
.p-autocomplete-overlay {
  --p-autocomplete-overlay-background: var(--base-white)
  --p-autocomplete-overlay-color: #4B5563
  --p-autocomplete-overlay-border-color: var(--primary-300)
  --p-autocomplete-overlay-border-radius: 0 0 0.8rem 0.8rem
  --p-autocomplete-overlay-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16,24,40,0.05)
  --p-autocomplete-option-color: var(--gray-900)
  --p-autocomplete-option-focus-color: var(--gray-900)
  z-index: 3001 !important
  border-top: none !important
  -webkit-clip-path: inset(0px -5px -5px -5px)
  clip-path: inset(0px -5px -5px -5px)
  overflow: hidden
  margin-top: -1px
  .p-autocomplete-list-container {
    max-height: 20rem !important
  }
  .p-autocomplete-list {
    .p-autocomplete-option {
      &:hover {
        background: #EDFAFF !important
      }
    }
  }
  .p-autocomplete-list .p-autocomplete-option
  .p-autocomplete-empty-message {
    margin: 0
    padding: 0.75rem 1.25rem
    border: 0 none
    background: transparent
    transition: background 0.2s
    border-radius: 0
    border-top: 0.1rem solid #FAFAFA
    font-size: 1.4rem
    font-style: normal
    font-weight: 400
    line-height: 2.4rem
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  }
}
</style>

<style lang="stylus" scoped>
.autocomplete-wrapper {
  width: 100%
  .autocomplete {
    display: flex
    .loader {
      right: 0.6rem
      left: unset
    }
    &.invalid:deep() {
      .p-autocomplete-input {
        border-color: var(--input-error-border-color)
        color: var(--input-error-color)
        &:hover {
          border-color: var(--input-error-border-color)
          color: var(--input-error-color)
        }
        &:focus {
          border-color: var(--input-error-border-color)
          color: var(--input-error-color)
          box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        }
      }
    }
    &.invalid:deep()
    &.empty:deep() {
      .loader {
        display: none
      }
    }
    &:deep() {
      .p-autocomplete-input {
        width: v-bind(width)
        padding: 0.4375rem 3.5rem 0.4375rem 1.25rem
        margin: 0
        font-size: 100%
        max-width: 100%
        flex: 1 0 auto
        outline: none
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
        text-align: left
        line-height: 1.5em
        background: var(--base-white)
        border: 0.1rem solid var(--gray-300)
        color: var(--gray-900)
        border-radius: 0.8rem
        transition: box-shadow 0.1s ease, border-color 0.1s ease
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        &:hover {
          border-color: var(--primary-300)
        }
        &:focus {
          border-color: var(--primary-300)
          background: var(--base-white)
          color: rgba(0, 0, 0, 0.8)
          box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        }
      }
    }
    &.p-autocomplete-open:not(.invalid):not(.empty) {
      &:deep() {
        .p-autocomplete-input {
          border-color: var(--primary-300)
          background: var(--base-white)
          border-bottom-color: transparent !important
          border-bottom-left-radius: 0
          border-bottom-right-radius: 0
          color: rgba(0, 0, 0, 0.8)
          box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        }
      }
    }
  }
}

</style>

<script>
import AutoComplete from 'primevue/autocomplete'

export default {
  components: {
    AutoComplete,
  },
  mixins: [ModelValue(String)],
  props: {
    placeholder: {  
      type: String,
      default: ''
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    delay: {
      type: Number,
      default: 300
    },
    maxLength: {
      type: Number,
      default: 50
    },
    minLength: {
      type: Number,
      default: 3
    },
    width: {
      type: String,
      default: '2.6rem'
    },
    optionLabel: {
      type: String,
      default: 'name'
    }
  },
  emits: ['complete', 'item-select'],
  computed: {
    complete_on_focus(){
      return this.value.trim().length >= this.minLength
    },
    invalid(){
      return this.value.length > 0 && this.value.length < this.minLength
    },
  },
}
</script>