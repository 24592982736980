.popover {
  display: flex;
  padding: 0.9rem 1.6rem 1.2rem 1.6rem;
  gap: 0.8rem;
}
.popover .icon {
  padding: 0.6rem 0 0;
}
.popover .popover-container {
  width: 100%;
}
.popover .popover-container .chat-title {
  font-size: 1.4rem;
  font-weight: 400;
  border: 0.1rem solid transparent;
  padding: 0.4rem 0.6rem;
  outline: none;
  border-radius: 0.4rem;
  word-break: normal;
  text-align: left;
  max-width: 22rem;
}
.popover .popover-container .chat-title.editable {
  border: 0.1rem solid #36bffa;
}
.popover .popover-container .footer {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.8rem;
}
.popover .popover-container .footer .date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  padding-left: 0.8rem;
  color: var(--gray-700);
}
.popover .popover-container .footer .actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.chats-item {
  transition: background 0.2s;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.4rem 0.3rem 0.8rem;
  border-radius: 0.4rem;
}
.chats-item .title {
  animation: fade-in 0.9s;
  color: var(--gray-700);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.4rem;
}
.chats-item.active.active {
  background: var(--gray-100);
}
.chats-item:hover {
  background: var(--gray-50);
}
.chats-item:hover .more {
  opacity: 1;
  width: 1.6rem;
}
.chats-item:hover .more:hover {
  background: var(--gray-50);
}
.chats-item:has(.more:hover) {
  background: transparent;
}
.chats-item .more {
  transition: all 0.3s;
  border-radius: 0.4rem;
  width: 0;
  opacity: 0;
  height: 1.6rem;
  overflow: hidden;
}
.chats-item .more.visible {
  width: 1.6rem;
  background: var(--gray-50);
  opacity: 1;
}
