<template>
  <Segment class="uploader-segment">
    <div class="inline-text">
      <TextArea v-model="inline_text"
                :maxlength="maxSize"
                :rows="txtRows"
                :placeholder="placeholder"
                :disabled="!can_type"
      />
      <div class="flex-row actions">
        <Button v-if="edit_id || inline_text" small class="button" @click="onDiscard()">
          Discard
        </Button>
        <Button v-if="edit_id" :disabled="!can_upload || !diff" small primary class="button" @click="onAddInlineText()">
          Save
        </Button>
        <Button v-else :disabled="!inline_text || !can_upload" small primary class="button" @click="onAddInlineText()">
          Add
        </Button>
      </div>
    </div>
  </Segment>

  <!-- Uploads list -->
  <Segment v-if="uploads?.length" class="list">
    <ScrollBar ref="uploads_scroll" :style="{'height': uploads_height}"> 
      <div v-resize="onUploadsResize" style="display: grid; gap: 0.1rem;" @click.stop>
        <UploadItem v-for="upload in uploads"
                    :id="upload.id"
                    :key="upload.id"
                    ref="uploads"
                    :mode="mode"
                    :instance="upload"
                    class="item"
                    :inline="true"
                    @delete="store.deleteUpload(upload.id)"
        />
      </div>
    </ScrollBar>
  </Segment>

  <!-- Files list -->
  <Segment v-if="files?.length" class="list">
    <ScrollBar ref="files_scroll" :style="{'height': files_height}">
      <div v-resize="onFilesResize" class="flex-col" style="gap: 0.1rem;" @click.stop>
        <SampleItem v-for="file in files"
                    :key="file.id"
                    ref="files"
                    :file="file"
                    class="item"
                    :mode="mode"
                    @delete="onRemove(file.id)"
                    @download="onDownload(file.id)"
                    @edit="onEdit(file.id, $event)"
        />
      </div>
    </ScrollBar>
  </Segment>
</template>

<style lang="stylus" scoped>
@import './uploader'
.uploader-segment.segment {
  border-color: transparent
  box-shadow: none
  padding: 0

  .inline-text {
    width: 100%
  }

  .button {
    display: flex
    margin-left: auto
    margin-top: 1.2rem
  }
  .actions {
    gap: 0.8rem
    align-items: flex-end
    width: max-content
    margin-left: auto
  }
}
</style>

<script>
import SampleItem from './sample-item.vue'
import {Uploader} from 'mixins'

export default {
  components: {SampleItem},
  mixins: [Uploader],
  emits: ['uploaded', 'removed'],
  data(){
    return {
      prev_text: '',
      inline_text: '',
      edit_id: undefined
    }
  },
  computed:{
    placeholder(){
      return this.files.length < this.fileRows ? undefined : `Cannot have more than ${this.maxUploads} example posts`
    },
    limit(){
      return this.maxUploads ? this.files.length < this.maxUploads : true
    },
    diff(){
      return this.prev_text !== this.inline_text
    },
    can_type() {
      if (this.uploading) return false
      if (this.store.loading) return false
      if (this.edit_id) return true
      return this.limit
    },
    can_upload(){
      if (this.store.loading) return false
      if (this.inline_text) return true
      return this.limit
    },
  },
  methods: {
    async onAddInlineText() {  
      const text = this.inline_text
      const id = this.edit_id
      this.onDiscard()

      if (id) await this.store.delete(id)
      const result = await this.store.addSamplePost(text)
      this.$emit('uploaded', result)
    },
    onEdit(id, text){
      this.edit_id = id
      this.prev_text = text
      this.inline_text = text
    },
    onDiscard(){
      this.edit_id = undefined
      this.prev_text = ''
      this.inline_text = ''
    },
    async onRemove(file_id) {
      this.onDiscard()
      await this.store.delete(file_id)
      this.$emit('removed', file_id)
    },
  }
}
</script>
