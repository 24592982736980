<template>
  <div class="ai-cards">
    <template v-for="(card, ind) in cards" :key="ind">
      <Persona v-if="card.type === 'persona'" class="item" :persona="card" :loading="loading"/>
      <Company v-else-if="card.type === 'company'" class="item" :company="card" :loading="loading"/>
      <MeetingPrep v-else-if="card.type === 'meeting'" class="item" :meeting="card" :loading="loading"/>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.ai-cards {
  display: flex
  flex-direction: column
  gap: 1.6rem
  .item {
    padding: 1.8rem 1.8rem 2.4rem
  }
  :deep() {
    .md-content {
      color: var(--gray-900)
  
      line-height: 2.3rem
      font-size: 1.6rem
      font-weight: 400

      &>* {
        line-height: inherit
        margin-top: 0
        display: block
      
        &:not(:last-child) {
          margin-bottom: 0.4rem
        }

        &:last-child {
          margin-bottom: 0
        }
      }

      &>h5 {
        font-size: 1.6rem
        font-weight: 500
        text-decoration: underline
        color: var(--gray-700)

        &:not(:first-child) {
          margin-top: 1.2rem
        }
      }

      ul
      ol {
        margin-left: 0
        padding-left: 2rem

        li {
          &:not(:last-child) {
            margin-bottom: 0.4rem
          }
          &:first-child {
            margin-top: 0.4rem
          }
        }
        p {
          margin: 0
        }
      }
      b {
        font-weight: 500
      }

      @import 'styles/md.styl'
      table {
        padding: 0.6rem 0
        tr {
          th
          td {
            padding: 1rem 1.4rem
            font-size: 1.4rem
            line-height: 1
          }
        }
      }
    }
  }
}
</style>

<script setup>
import Company from './company'
import MeetingPrep from './meeting-prep'
import Persona from './persona'
</script>

<script>
export default {
  props: {
    cards: {type: Array, required: true},
    loading: {type: Boolean, default: false}
  }
}
</script>