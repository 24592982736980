<template>
  <Popover v-model="visible" :placement="placement" :width="width" @hide="onHide">
    <template v-slot:reference>
      <div @click="onShow">
        <slot/>
      </div>
    </template>
    <div class="feedback" :style="{height}">
      <div class="header">
        <Icon icon="message-text-square-02" width="2.4rem" height="2.4rem" stroke="var(--gray-500)"/>
        <div class="title">Feedback</div>
        <Icon class="pointer" style="margin-left: auto" icon="x-close" width="2rem" height="2rem" stroke="var(--gray-500)" @click="onClose"/>
      </div>
      <Divider v-if="divider" m="0 0 1.2rem"/>
      <div class="textarea">
        <TextArea v-model="message" :maxlength="2048" :rows="rows" placeholder="Add your feedback here"/>
      </div>
      <Divider v-if="divider" m="1.2rem 0 0"/>
      <div class="action">
        <Button :disabled="!can_send" primary tiny @click="onSend">Send</Button>
      </div>
    </div>
  </Popover>
</template>
<style lang="stylus" scoped>
.feedback {
  border-radius: 0.8rem
  background: var(--base-white)
  .header {
    display: flex
    align-items: center
    gap: 1rem
    color: var(--gray-700)
    font-size: 1.4rem
    font-weight: 500
    line-height: 2rem
    padding: 1.2rem 1.6rem
  }
  .textarea {
    padding: 0 1.6rem
  }
  .action {
    display: flex
    justify-content: flex-end
    padding: 1.6rem
  }
}
</style>

<script>
export default {
  props: {
    showPopover: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '44rem'
    },
    height: {
      type: String,
      default: 'max-content'
    },
    rows: {
      type: [String, Number],
      default: '4'
    },
    minLength: {
      type: Number,
      default: undefined
    },
    divider: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'top'
    },
    fbId: {
      type: String,
      default: undefined
    }
  },
  emits: ['send','show'],
  data() {
    return {
      fb_id: this.fbId || crypto.randomUUID(),
      visible: false,
      message: '',
    }
  },
  computed: {
    can_send() {
      if (this.minLength === undefined) return true
      return this.message.length > this.minLength
    }
  },
  watch: {
    visible(){
      if (!this.visible) this.message = ''
    }
  },
  methods: {
    async onSend(){
      const message = this.message.trim() || undefined
      this.visible = false
      this.$emit('send', message)
    },
    onShow(){
      if (this.showPopover) {
        this.$emit('show', {id: this.fb_id, show: true})
        this.visible = true
      }
      else this.$emit('send')
    },
    onHide(){
      this.$emit('show', {id: this.fb_id, show: false})
    },
    onClose() {
      this.visible = false
    }
  }
}
</script>