<template>
  <div class="ai-multiselect" :class="{pressed}">
    <div class="options" :disabled="disabled">
      <Button v-for="(option, ind) in options" :key="ind" class="ai-option accept" 
              :class="{active: selected.has(ind)}" @click="onSelect(ind)"
      >
        <Icon class="icon" icon="check" width="1.2rem" height="1.2rem"/>
        <span v-html="option.title"/>
      </Button>
    </div>
    <div class="controls">
      <Button class="confirm" 
              :primary="!skipped"
              :disabled="confirm_disabled"
              :class="{inactive: skipped}"
              @click="onAccept"
      >
        Confirm
      </Button>
      <Button class="ai-option secondary" :disabled="disabled" @click="onSkip">
        Skip
      </Button>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
@import './ai-option'
.ai-multiselect {
  &.pressed {
    pointer-events: none
  }
  .options {
    display: flex
    flex-wrap: wrap
    gap: 1.2rem
    .ai-option {
      width: max-content
      border-radius: 10rem
      .icon {
        stroke: var(--gray-600)
      }
      &.active {
        .icon {
          stroke: var(--gray-900)
        }
      }
    }
  }
  .controls {
    margin-top: 2rem
    display: flex
    gap: 1.2rem
    .confirm {
      padding: 0.6rem 1rem
      &.inactive {
        background: var(--gray-100)
        color: var(--gray-400)
        border-color: var(--gray-200)
        box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05)
        opacity: 0.8
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    options: {type: Array, required: true},
    skip: {type: Object, required: true},
    disabled: {type: Boolean, default: false}
  },
  emits: ['prompt'],
  data() {
    return {
      selected: new Set(),
      pressed: !!this.disabled
    }
  },
  computed: {
    confirm_disabled() {
      if (this.disabled) return true
      if (!this.has_selection) return true
      return false
    },
    has_selection() {
      return this.selected.size > 0
    },
    skipped() {
      return !this.has_selection && this.disabled
    }
  },
  methods: {
    onSelect(ind) {
      this.selected.has(ind) 
        ? this.selected.delete(ind) 
        : this.selected.add(ind)
    },
    onAccept(){
      this.pressed = true
      
      const selection = Array.from(this.selected).sort()
      const title = selection.map((ind) => this.options[ind].title).join(', ')
      const objects = selection.map((ind) => this.options[ind].chat_query)
  
      this.$emit('prompt', {
        title: title,
        selected: objects
      })
    },
    onSkip() {
      this.pressed = true
      this.selected = new Set()
      this.$emit('prompt', this.skip.chat_query)
    }
  }
}
</script>