<template>
  <div class="settings-icon">
    <div class="icon-bg">
      <Icon width="5rem" height="5rem" icon="settings-01" stroke="var(--brand-500)"/>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.settings-icon {
  display: flex
  justify-content: center
  background: url('assets/bg-pattern-decorative.png') no-repeat center calc(50%)

  .icon-bg {
    width: 10.4rem
    height: 10.4rem
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%)
  }
}
</style>
