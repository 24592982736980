<template>
  <div class="navbar-list" :class="[type]">
    <div v-for="item, ind in items" :key="item.route" class="item">
      <template v-if="type=='chats'">
        <div class="chat-group-title">
          {{ item.title }}
        </div>
        <ChatsItem v-for="chat in item.children" 
                   :key="chat.store.id"
                   :chat="chat.store"
                   :active="isCurrentRoute(chat)"
                   @click.prevent="toLink(chat.route)"
                   @show="$emit('show', $event)"
        />
      </template>
      <NavbarItem v-else :children="item.children?.length || 0"
                  :level="level"
                  :title="item.title"
                  :active="isCurrentRoute(item)"
                  :icon="item.icon"
                  :opened="opened[ind]"
                  :store="item.store"
                  :remove="item.remove"
                  @arrow="opened[ind] = !opened[ind]"
                  @click="item.is_link ? onLinkClick(ind, item) : onNotLinkClick(ind)"
      />
      <!-- self recursion -->
      <Collapse v-if="item.children?.length" :class="{open: opened[ind] && navbarOpen}">
        <NavbarList :type="item.type"
                    class="children"
                    :items="item.children"
                    :level="level + 1"
                    :navbar-open="navbarOpen"
                    @show="$emit('show', $event)"
        />
      </Collapse>
    </div>
  </div>
</template>

<style lang="stylus" scoped> 
.navbar-list {
  color: var(--gray-100)
  display: flex
  flex-direction: column
  gap: 0.4rem
  * {
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
  }
  &.chats {
    border-left: 0.1rem solid var(--gray-300)
    padding-left: 0.4rem
  }
  .item {
    .offset {
      padding-left: 4.8rem
    }
    .chat-group-title {
      font-size: 1rem
      font-weight: 400
      line-height: 1.4rem
      color: var(--gray-500)
      padding: 0.2rem 0.4rem 0.3rem 0.8rem
    }
    .children {
      margin-top: 0.4rem
      margin-left: 1.6rem
      display: flex
    }
  }
}
</style>

<script>
import NavbarItem from './navbar-item'
import ChatsItem from './chats-item.vue'

export default {
  components: {
    NavbarItem,
    ChatsItem
  },
  props: {
    type: {type: String, default: undefined},
    items: {type: Array, default: () => []},
    level: {type: Number, default: 0},
    navbarOpen: {type: Boolean, default: false},
  },
  emits: ['show'],
  data() {
    return {
      opened: this.items.map(x => x.opened || false)
    }
  },
  watch: {
    '$route.fullPath'(){
      if(this.level > 0) return
      this.openByRoute()
    }

  },
  mounted(){
    this.openByRoute()
  },
  methods: {
    openByRoute(){
      const item_ind = this.items.findIndex(this.isCurrentRoute)
      if (item_ind !== -1) {
        this.opened[item_ind] = true
      }
    },
    isCurrentRoute(item){
      if (item.matched_routes?.length) {
        const valids = []
        const query_keys = typeof item.query === 'object' && Object.entries(item.query)
        valids.push(item.matched_routes.some(el => this.$route.path.startsWith(el)))
        if (query_keys.length) {
          valids.push(query_keys.every(([key, value]) => value === this.$route.query[key]))
        }
        return valids.every(x => !!x)
      }
      return this.$route.path.startsWith(item.route)
    },
    toLink(route){
      // This setTimeout is required to ensure that all 
      // asynchronous actions are completed before component will unmount
      setTimeout(() => this.$router.push(route))
    },
    onLinkClick(ind, item){
      if (this.isCurrentRoute(item) && item.children?.length) 
        this.onNotLinkClick(ind)
      else
        this.toLink(item.route)
  
    },
    onNotLinkClick(ind){
      if (this.navbarOpen) {
        this.opened[ind] = !this.opened[ind]
      }
      else {
        this.opened[ind] = true
      }
    }
  }
}
</script>