.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 40rem;
}
.controls .right {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}
.field {
  width: 50rem;
}
