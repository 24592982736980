.career {
  display: grid;
  gap: 1.8rem;
}
.career .summary {
  color: var(--gray-900);
  line-height: 2.3rem;
  font-size: 1.6rem;
  font-weight: 400;
  width: 100%;
}
.career .career-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.career .career-grid .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}
.career .career-grid .jobs .jobs-items .job {
  margin-top: 1.8rem;
  display: flex;
  gap: 1.2rem;
}
.career .career-grid .jobs .jobs-items .job .logo {
  width: 2.4rem;
  height: 2.4rem;
}
.career .career-grid .jobs .jobs-items .job .logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.career .career-grid .jobs .jobs-items .job .info .position {
  color: var(--gray-700);
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
}
.career .career-grid .jobs .jobs-items .job .info .company {
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.career .career-grid .jobs .jobs-items .job .info .period {
  color: var(--gray-600);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}
.career .career-grid .education .education-items .establishment {
  margin-top: 1.8rem;
  display: flex;
  gap: 1.2rem;
}
.career .career-grid .education .education-items .establishment .info .position {
  color: var(--gray-700);
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
}
.career .career-grid .education .education-items .establishment .info .institution {
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.career .career-grid .education .education-items .establishment .info .year {
  color: var(--gray-600);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}
