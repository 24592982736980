.urls-list {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.urls-list .add-block {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.urls-list .add-block span {
  font-size: 1.4rem;
}
