.finances {
  margin: 0 -1.6rem;
}
.finances .stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.2rem;
}
.finances .stats .stat-segment {
  padding: 1.6rem 1.2rem;
}
.finances .stats .stat-segment .stat {
  display: grid;
  grid-template-areas: "title title" "value note";
  grid-template-columns: auto 1fr;
  gap: 1.2rem 0.4rem;
}
.finances .stats .stat-segment .stat .title {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  grid-area: title;
}
.finances .stats .stat-segment .stat .value {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.1rem;
  grid-area: value;
}
.finances .stats .stat-segment .stat .note {
  align-self: flex-end;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  grid-area: note;
}
.finances .info {
  margin-top: 2.2rem;
}
