.news-item {
  display: flex;
  gap: 1.2rem;
  padding: 1.6rem;
  line-height: 100%;
  transition: border-color 0.3s, background 0.3s, box-shadow 0.3s;
}
.news-item:hover {
  border-color: var(--gray-300);
  background: linear-gradient(0deg, #fffdfb 0%, #fffdfb 100%), var(--base-white);
  box-shadow: 0px 1px 3px 0px rgba(16,24,40,0.1), 0px 1px 2px 0px rgba(16,24,40,0.06);
}
.news-item .image {
  height: 100%;
}
.news-item .image .el-image {
  width: 12rem;
  height: 6.6rem;
  border-radius: 0.8rem;
  display: block;
}
.news-item .image .el-image .placeholder {
  height: 100%;
  width: 100%;
}
.news-item .content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.news-item .content .title {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-item .content .meta {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-start;
  align-items: center;
}
.news-item .content .meta .date {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  white-space: nowrap;
}
.news-item .content .meta .source {
  display: flex;
  min-width: 0;
  align-items: center;
  gap: 0.6rem;
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
