<template>
  <div class="input">
    <div v-loading="loading"
         class="input-inner" 
         :class="{
           left: !!c_icon || type ==='url', 
           icon: !!c_icon || !!button, 
           corner: type === 'url',
           labeled: type === 'url'
         }"
    >
      <!-- LEFT ABEL -->
      <div v-if="type==='url'" ref="label" :disabled="disabled" :class="{invalid: !valid}" class="left label url">https://</div>
      
      <!-- LEFT ICON -->
      <i v-if="!!c_icon" class="icon" :disabled="disabled">
        <Icon :icon="c_icon" :stroke="icon_color" :error="!valid"/>
      </i>
      
      <input ref="input"
             v-model="c_value"
             :disabled="disabled"
             :class="{invalid: !valid, valid: valid}"
             type="text"
             :placeholder="placeholder"
             :pattern="pattern" 
             :spellcheck="c_spellcheck" 
             :maxlength="c_maxlength"
             :minlength="minlength"
             :required="required"
             @blur="$emit('blur', this)"
      />

      <!-- RIGHT CONTROLS -->
      <div v-if="tooltip || button || rightIcon" ref="right" :disabled="disabled" class="right">
        <!-- TOOLTIP -->
        <div v-if="tooltip" class="tooltip">
          <div class="icon" :data-tooltip="tooltip" data-inverted="">
            <Icon icon="help-circle" 
                  stroke="var(--gray-400)"      
                  :width="iconWidth" 
                  :height="iconHeight"
            />
          </div>
        </div>
        <!-- ICON -->
        <div v-else-if="rightIcon" class="icon pointer" @click="$emit('right-icon')">
          <Icon :icon="rightIcon" 
                :stroke="rightIconColor"
                :width="iconWidth" 
                :height="iconHeight"
          />
        </div>
        <!-- BUTTON  -->
        <div v-else-if="button" class="button pointer" :disabled="buttonDisabled" @click="$emit('button')">
          <Icon :icon="button" 
                :stroke="buttonColor"
                :width="iconWidth" 
                :height="iconHeight"
          />
        </div>
      </div>
      <div v-else-if="$slots.custom_icon" ref="right" class="custom right">
        <slot :disabled="disabled" name="custom_icon"/>
      </div>
    </div>
    <div v-if="footer" class="footer" :disabled="disabled">
      <Footnote v-if="validable || description.length > 0" :type="valid ? 'default' : 'error'">
        {{ valid ? description : error_text }}
      </Footnote>
      <SymbolCounter v-if="maxlength > 0" 
                     :current="value?.length || 0" 
                     :maxlength="maxlength"
      />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.input {
  & > .input-inner {
    --el-loading-spinner-size: v-bind(spinner_size)
    --el-color-primary: var(--primary-600)
    --el-mask-color: transparent

    position: relative
    font-weight: normal
    font-style: normal
    display: flex
    color: var(--gray-900)
    &.labeled > .label:not(.corner) {
      padding-top: 0.4375em
      padding-bottom: 0.4375em
    }
    & > input {
      margin: 0
      max-width: 100%
      flex: 1 0 auto
      outline: none
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
      text-align: left
      line-height: 1.5em
      background: var(--base-white)
      border: 0.1rem solid var(--gray-300)
      color: var(--gray-900)
      border-radius: 0.8rem
      transition: box-shadow 0.1s ease, border-color 0.1s ease
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)

      &:not([type="color"]) {
        padding: 0.4375em 0.6875em
      }
      &:focus {
        border-color: #75C4F3
        background: #FFF
        color: rgba(0, 0, 0, 0.8)
        box-shadow: 0px 0px 0px 4px var(--primary-100)
      }

      &.valid {
        &:hover:not(:focus) {
          border-color: var(--input-hover-border-color)
        }
        &:focus {
          border-color: var(--input-focus-border-color)
        }
      }
      &.invalid {
        border-color: var(--input-error-border-color)
        color: var(--input-error-color)
        &::selection {
          background: var(--error-100) !important
          color: var(--negative-text-color) !important
        }
        &::-moz-selection {
          background: var(--error-100) !important
          color: var(--negative-text-color) !important
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px var(--error-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        }
      }
    }


    &.icon {
      &.left:not(.corner) > input {
        padding-right: 0.6875em
      }
      &.left:not([class*="left corner"]) > input {
        padding-left: 4rem
      }

      & > i.icon {
        display: flex
        flex-direction: column
        justify-content: center
        cursor: default
        position: absolute
        line-height: 1
        text-align: center
        top: 0
        right: 0
        left: 1.2rem
        margin: 0
        height: 100%
        width: 2rem
        opacity: 0.5
        border-radius: 0 0.8rem 0.8rem 0
        transition: opacity 0.3s ease
      }
    }
    & > .left.label {
      display: inline-block
      vertical-align: baseline
      padding: 0.5833em 1.1rem
      text-transform: none
      border: 0.1rem solid var(--gray-300)
      position: absolute
      top: var(--input-border-width)
      left: calc(var(--input-horizontal-padding) + var(--input-border-width))
      padding-left: 0
      border-left: none
      border-top: none
      border-bottom: none
      border-right: var(--input-border-width) solid var(--input-border-color)
      border-radius: 0
      transition: var(--input-transition)
      line-height: var(--input-line-height)
      user-select: none
      pointer-events: none
      background: transparent
      font-weight: 400
      color: var(--gray-600)
      padding-top: 0.4375em
      padding-bottom: 0.4375em


      &.url.invalid {
        color: var(--input-error-color)
        border-color: var(--input-error-border-color)
      }
    }

    & > .right {
      border: none
      display: flex
      flex-direction: row
      align-items: center
      height: 100%
      position: absolute
      right: 0%

      &.custom {
        justify-content: center
      }
      
      & > .button {
        padding: 0 var(--input-horizontal-padding)
        border-left: var(--input-border-width) solid var(--input-border-color)
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
      }

      & > .tooltip {
        .icon {
          height: 1.6rem
          padding: 0 var(--input-horizontal-padding)
        }
      }

      & > .icon {
        height: 1.6rem
        padding: 0 var(--input-horizontal-padding)
      }
    }
  }

  .footer {
    display: flex
    flex-direction: row
    & > :last-child {
      margin-left: auto
    }
  }
}
</style>

<script>
export default {
  mixins: [ModelValue(String)], 
  props: {
    label: {type: String, default: ''},
    type: {type: String, default: 'text'},
    icon: {type: String, default: ''},
    icon_color: {type: String, default: 'var(--gray-500)'},
    iconWidth: {type: String, default: '1.6rem'},
    iconHeight: {type: String, default: '1.6rem'},
    rightIcon: {type: String, default: ''},
    rightIconColor: {type: String, default: 'var(--gray-400)'},
    error_message: {type: String, default: ''},
    tooltip: {type: String, required: false, default: ''},
    button: {type: String, requied: false, default: ''},
    buttonColor: {type: String, default: 'var(--gray-600)'},
    buttonDisabled: {type: Boolean, default: false},
    spellcheck: {type: Boolean, requied: false, default: true},
    required: {type: Boolean, requied: false, default: false},
    minlength: {type: Number, requied: false, default: undefined},
    maxlength: {type: Number, requied: false, default: undefined},
    description: {type: String, requied: false, default: ''},
    placeholder: {type: String, requied: false, default: ''},
    focused: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    footer: {type: Boolean, default: true},
  },
  emits: [
    'button', 'blur', 'right-icon'
  ],
  data() {
    return {
      valid: true,
      validable: this.requied || this.minlength > 0,
      spinner_size: 0
    }
  },
  computed: {
    pattern(){
      switch (this.type) {
        case 'url':
          return '^(www\\.)?[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]{2,}(\\/\\S*)?$'
        case 'email':
          return '[^@]+@[^@]+\\.[a-zA-Z]{2,6}' 
        default:
          return null
      }
    },
    c_value: {
      get() {
        if (this.type === 'url') {
          return this.value?.replace('https://', '').replace('http://', '')
        }
        return this.value
      },
      set(val) {
        if(val == '') {
          this.value = null
        }
        else if (this.type === 'url' && !(val.startsWith('https://') || val.startsWith('http://'))) {
          this.value = `https://${val}`
        } else {
          this.value = val
        }
      }
    },
    error_text () {
      if (this.valid) return undefined
      if (this.error_message) return this.error_message
      
      const validity = this.$refs.input.validity
      switch (this.type) {
        case 'url': 
          return 'Provide a valid URL'
        case 'email': 
          return 'Provide a valid email'
        case 'text':
          if (validity.tooShort) return `Should be at least ${this.minlength} characters long`
          if (validity.tooLong) return `Should be not more than ${this.maxlength} characters long`
          if (validity.valueMissing) return '\n'
          break
      }
      return 'Invalid value provided'
    },
    c_icon() {
      if (this.icon) return this.icon
      if (this.type === 'email') return 'email'
      return ''
    },
    c_spellcheck() {
      if (this.type === 'email' || this.type === 'url') return true
      return this.spellcheck
    },
    c_maxlength() {
      if (this.maxlength) return this.maxlength
      if (this.type === 'url') return 2000
      return undefined
    }
  },
  watch: {
    'c_value': function () {
      this.checkValidity()
    }
  },
  mounted() {
    this.setOffsets()
    this.checkValidity()
    this.spinner_size = this.$refs.input.getBoundingClientRect().height + 'px'

    if (this.focused) {
      this.focus()
    }
  },
  methods: {
    isFocused(){
      return document.activeElement === this.$refs.input
    },
    focus(){
      this.$refs.input.focus()
    },
    setOffsets(){
      const left_width = this.$refs.label?.getBoundingClientRect().width
      if (left_width) {
        this.$refs.input.style.paddingLeft = `calc(${left_width}px + var(--input-horizontal-padding) * 2)`
      }
      const right_width = this.$refs.right?.getBoundingClientRect().width
      if (right_width) {
        this.$refs.input.style.paddingRight = `calc(${right_width}px)`
      }
    },
    checkValidity() {
      this.valid = !this.valid // this is to force dependent error_text recalc
      if (!this.value) this.valid = !this.required
      else this.valid = this.$refs.input?.checkValidity()
    }
  }
}
</script>
