.builder-inputs .field {
  width: 100%;
}
.builder-inputs .field .description {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 0.8rem;
  color: var(--gray-600);
}
.builder-inputs:not(.col) .field:last-child {
  margin-bottom: 0;
}
.builder-inputs.col {
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
  justify-content: space-between;
}
.builder-inputs.col > .grow {
  flex-grow: 1;
}
.builder-inputs.col > .center {
  display: flex;
  justify-content: center;
  align-items: center;
}
