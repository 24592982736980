.sources-ground {
  width: 100%;
  display: grid;
  grid-template-columns: v-bind(cols);
  grid-template-rows: 2.8rem;
  gap: 1.2rem;
  min-height: 2.8rem;
  line-height: 1.8rem;
  overflow-x: scroll;
  margin-bottom: -1rem;
  padding-bottom: 1rem;
}
.sources-ground::-webkit-scrollbar {
  display: none;
}
.sources-ground .ground-item .src {
  width: 16.359rem;
  min-width: 0;
}
.sources-ground.loading .ground-item .src {
  width: 16.359rem;
  min-width: 0;
}
.sources-ground.loading .ground-item:last-child {
  position: relative;
  overflow-x: hidden;
}
.sources-ground.loading .ground-item:last-child .src {
  position: absolute;
  left: 0;
  transition: left 1s ease;
}
.sources-ground.loading .ground-item:last-child .src.hidden {
  left: 100%;
}
