<template>
  <div class="file-icon" relative :class="{pulse}">
    <Icon :width="width"
          :height="height"
          class="svg-icon"
          icon="file"
    />
    <div class="ext">
      {{ ext }}
    </div>
  </div>
</template>

<style lang="stylus" scoped>
@import 'styles/animations'
.file-icon {
  display: inline-block
  &.pulse {
    animation: pulse 2s infinite
  }

  margin-left: 0.6rem
  .svg-icon {
    fill: v-bind(file_fill)
    stroke: v-bind(file_stroke)
  }
  .ext {
    position: absolute
    padding: 0.2rem 0.3rem
    text-transform: uppercase
    color: var(--base-white)
    font-size: v-bind(fontSize)
    font-weight: 700
    line-height: normal
    border-radius: 0.2rem
    bottom: 15%
    left: -18.75%
    background: v-bind(label_color)
    user-select: none
  }
}
</style>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  width: {
    type: String, default: '3.2rem'
  },
  height: {
    type: String, default: '4rem'
  },
  ext: {
    type: String, requied: true, default: 'txt'
  },
  fontSize: {
    type: String, default: '1rem'
  },
  error: {
    type: Boolean, default: false
  },
  pulse: {
    type: Boolean, default: false
  }
})

const ext_colors = {
  pdf: 'error-600',
  doc: 'blue-dark-600',
  docx: 'blue-dark-600',
  xml: 'indigo-600',
  xls: 'green-600',
  pptx: 'orange-dark-600',
  csv: 'green-600',
  txt: 'gray-700'
}

const label_color = computed(() => props.error ? 'var(--orange-dark-600)' : `var(--${ext_colors[props.ext] || 'gray-700'})`)
const file_stroke = computed(() => props.error ? 'var(--error-300)' : 'var(--gray-300)')
const file_fill = computed(() => props.error ? 'var(--error-100)' : 'var(--base-white)')
</script>
