.tabs {
  --el-border-color-light: v-bind(border_color);
  --el-tabs-header-height: 3rem;
}
.tabs :deep() .el-tabs__item {
  transition: color 0.2s;
  padding-right: 1.6rem;
}
.tabs :deep() .el-tabs__nav-next,
.tabs :deep() .el-tabs__nav-prev {
  line-height: 3.3rem;
}
.tabs :deep() .el-tabs__nav-wrap::after {
  height: 0.1rem;
}
.tabs :deep() .el-tabs__header.is-right {
  margin-left: 0.1rem;
}
.tabs :deep() .el-tabs__item:hover .badge {
  border: 0.1rem solid var(--brand-200);
  background: var(--brand-50);
  color: var(--brand-700);
}
.tabs .tab-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
}
.tabs .badge {
  transition: all 0.3s;
}
