.filter {
  padding: 1.2rem 1.6rem 0.7rem;
}
.filter .title {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: var(--gray-700);
}
.filter .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter .actions .reset,
.filter .actions .apply {
  padding: 0.8rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
}
.filter .actions .reset {
  color: var(--gray-600);
}
.filter .actions .apply {
  color: var(--brand-700);
}
