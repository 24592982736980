<template>
  <div class="sample-item">
    <div>
      <Icon width="4rem" height="4rem" icon="file-blue"/>
    </div>
    <div class="text" :disabled="file.removing">
      <div v-if="text" class="name" :class="{'failed': file.failed}">{{ text }}</div>
      <el-skeleton v-else animated>
        <template v-slot:template>
          <el-skeleton-item/>
        </template>
      </el-skeleton>
    </div>
    <div class="actions">
      <Icon icon="edit-03"
            class="pointer" 
            width="2rem" 
            height="2rem"
            stroke="var(--gray-600)"
            stroke-width="1"
            :disabled="file.removing || file.failed"
            @click.stop="$emit('edit', text)"
      />
      <Icon icon="trash-04" 
            class="pointer" 
            width="2rem" 
            height="2rem" 
            stroke="var(--gray-600)"
            stroke-width="1"
            :disabled="file.removing"
            @click.stop="$emit('delete', file.id)"
      />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.sample-item {
  width: 100%
  padding: 1.6rem
  display: flex
  flex-direction: row
  align-items: center
  gap: 1.2rem
  background: var(--base-white)

  .icon {
    height: 4rem
  }

  .text {
    min-width: 0
    font-size: 1.4rem
    flex-grow: 1
    .name {
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      min-width: 0rem
      font-size: 1.6rem
      font-weight: 400
      line-height: 2.4rem
      &.failed {
        color: var(--error-600)
      }
    }
  }
  .actions {
    display: flex
    align-items: center
    gap: 1.2rem
  }
}
</style>

<script>
import {get_file} from 'stores/files'
import Rest from 'utils/rest'


export default {
  props: {
    file: {
      type: Object,
      requied: true,
      default: undefined
    },
  },
  emits: ['delete', 'download', 'edit'],
  data() {
    return {
      text: undefined
    }
  },
  async mounted() {
    const rest = new Rest({auth: false, json: false})
    const file = await get_file(this.file.id)
    this.text = await rest.get(file.url)
  }
}
</script>