.settings {
  height: 100%;
  width: 100%;
  background: var(--bg-primary);
  padding: 1.6rem 3.6rem;
  display: flex;
  flex-direction: column;
}
.settings .header {
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 3.8rem;
}
.settings .tabs {
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}
.settings .content {
  flex-grow: 1;
  min-height: 0;
}
.settings .content>* {
  height: 100%;
}
.settings :deep() .builder-inputs .field {
  margin: 0 0 1em;
}
.settings :deep() .builder-inputs .field>label:not(.checkbox) {
  display: block;
  color: var(--gray-700);
  margin: 0 0 0.6rem 0;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: none;
}
