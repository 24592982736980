<template>
  <Popover width="32.1rem" 
           :show-arrow="false" 
           placement="bottom-start" 
           :disabled="disabled" 
           :url="url"
           trigger="hover"
           :show-after="200"
           @hide="$emit('hide')"
           @show="$emit('show')"
  >
    <div class="tooltip" :class="{pointer: can_click}" @click="onSrcClick">
      <div class="head">
        <FileIcon v-if="type==='file'" font-size="0.5rem" width="1.9rem" height="2.2rem" :ext="name.split('.').pop()"/>
        <Favicon v-else :src="img" size="2.2rem"/>
        <span class="name">
          {{ name }}
        </span>
        <Icon class="icon" stroke="var(--gray-600)" :icon="icon" :stroke-width="stroke_width" :width="icon_size" :height="icon_size"/>
      </div>
      <div class="summary">
        {{ summary }}
      </div>
    </div>
    <template v-slot:reference>
      <slot/>
    </template>
  </Popover>
</template>

<style lang="stylus" scoped>
.tooltip {
  padding: 0.9rem 1.2rem
  .head {
    display: flex
    align-items: center
    gap: 0.6rem
    font-size: 1.4rem
    font-weight: 400
    line-height: 2rem
    color: var(--gray-900)
    .icon {
      margin-left: auto
    }
  }
  .summary {
    margin-top: 0.6rem
    font-size: 1.2rem
    font-weight: 400
    line-height: 1.8rem
    color: #000
  }
}
.source-item {
  .name {
    flex: 1
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  }
  .num {
    color: var(--gray-700)
    font-size: 1rem
    font-weight: 400
    align-self: start
    line-height: 1
  }
}

</style>

<script>
import {downloadFile} from 'stores/files'
import {iconSize, iconStrokeWidth} from './source-defs'

export default {
  mixins: [ModelValue(Boolean)],
  props: {
    disabled: {type: Boolean, default: false},
    summary: {type: String, default: ''},
    name: {type: String, default: ''},
    img: {type: String, default: ''},
    type: {type: String, default: ''},
    url: {type: String, default: ''},
  },
  emits: ['src-click', 'show', 'hide'],
  computed: {
    icon() {
      const map = {
        url: 'link-external-01',
        api: 'container',
        genrate: 'stars-02'
      }
      return map[this.type] || 'file-04'
    },
    img_src(){
      if (this.url?.includes('apollo.io')) {
        return 'assets/apollo.svg'
      }
      if (this.url?.includes('bing.com')) {
        return 'assets/bing.png'
      }
      return this.logo || '/assets/icons/cube-02.svg'
    },
    stroke_width(){
      return iconStrokeWidth(this.icon)
    },
    icon_size(){
      return iconSize(this.icon)
    },
    can_click() {
      return ['url', 'file'].includes(this.type)
    }
  },
  methods: {
    async onSrcClick(){
      if (this.type === 'url') {
        window.open(this.url)
      }
      else if (this.type === 'file') {
        await downloadFile(this.file_id)
      }
    },
  }
}
</script> 
