<template>
  <div ref="dropdown" class="dropdown">
    <Select v-model="value"
            :style="{minWidth}"
            :unstyled="false" 
            :options="items" 
            :option-label="optionLabel"
            :option-value="optionValue"
            :data-key="optionValue"
            :placeholder="placeholder" 
            :panel-style="{width: head_width}"
    >
      <template v-slot:value>
        <div v-if="!!current" class="item">
          <Icon v-if="current.icon" :icon="current.icon" height="1.4rem"/>
          <Avatar v-else-if="'avatar_id' in current" 
                  :image_id="current.avatar_id" 
                  :name="current.title" 
                  height="1.6rem" 
                  width="1.6rem" 
                  font-size="0.7rem" 
          />
          <span class="title">{{ current.title }}</span>
        </div>
        <div v-else class="placeholder">
          {{ placeholder }}
        </div>
      </template>
      <template v-slot:option="{option}">
        <div class="item">
          <Icon v-if="option.icon" :icon="option.icon" height="1.4rem"/>
          <Avatar v-else-if="'avatar_id' in option" 
                  :image_id="option.avatar_id" 
                  :name="option.title" 
                  height="1.6rem" 
                  width="1.6rem" 
                  font-size="0.7rem" 
          />
          <span class="title">{{ option.title }}</span>
        </div>
      </template>
    </Select>
  </div>
  <Footnote v-if="!hide_footnote" :type="valid ? 'default' : 'error'">
    {{ valid ? description : error_text }}
  </Footnote>
</template>
<style lang="stylus">
.p-select-overlay {
  --p-select-overlay-background: var(--base-white)
  --p-select-overlay-color: #4B5563
  --p-select-overlay-border-color: var(--primary-300)
  --p-select-overlay-border-radius: 0 0 0.8rem 0.8rem
  --p-select-overlay-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16,24,40,0.05)
  --p-select-option-color: var(--gray-900)
  --p-select-option-focus-color: var(--gray-900)
  border-top: none !important
  -webkit-clip-path: inset(0rem -0.5rem -0.5rem -0.5rem)
  clip-path: inset(0rem -0.5rem -0.5rem -0.5rem)
  overflow: hidden
  margin-top: -0.1rem
  .item {
    display: flex
    gap: 0.8rem
  }

  .p-select-list-container {
    max-height: 20rem !important
    .p-select-list {
      margin: 0
      padding: 0
      list-style-type: none
      .p-select-option {
        margin: 0
        padding: 0.75rem 1.25rem
        border: 0 none
        color: #101828
        background: transparent
        transition: box-shadow 0.2s
        border-radius: 0
        border-top: 0.1rem solid #FAFAFA
        cursor: pointer
        font-weight: normal
        position: relative
        overflow: hidden
        white-space: normal !important
        &.p-select-option-selected {
          background: var(--primary-100)
          color: rgba(0, 0, 0, 0.95)
          font-weight: bold
        }
        &.p-select-option-selected.p-focus
        &:not(.p-highlight):not(.p-disabled).p-focus {
          background: var(--primary-100)
          color: rgba(0, 0, 0, 0.95)
        }
        &:not(.p-select-option-selected):not(.p-disabled):hover {
          color: #101828
          background: #EDFAFF
        }
      }
    }
    .p-select-empty-message {
      padding: 0.75rem 1.25rem
      color: #101828
      background: transparent
    }
  }
}
</style>
<style lang='stylus' scoped>
.p-select {
  --p-select-padding-y: 1rem
  --p-select-padding-x: 1.1rem
  background: var(--base-white)
  border: 0.1rem solid #D1D5DB
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s
  border-radius: 0.8rem
  display: inline-flex
  cursor: pointer
  position: relative
  user-select: none
  box-sizing: border-box
  box-shadow: 0px 0.1rem 0.2rem 0px rgba(16, 24, 40, 0.05)
  &:deep() {
    .p-select-dropdown {
      background: transparent
      color: #6B7280
      width: 3rem
      border-top-right-radius: 6px
      border-bottom-right-radius: 6px
      display: flex
      align-items: center
      justify-content: center
      flex-shrink: 0
    }

    .item {
      display: flex
      gap: 0.8rem
      min-width: 0
      .title {
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
      }
    }
    .placeholder {
      color: #667085
    }
  }
  &.p-select-open {
    border-bottom-color: transparent !important
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
  }
  &:not(.p-disabled):hover {
    border-color: #75C4F3
  }
  &:not(.p-disabled).p-focus
  &.p-select-open {
    outline: 0 none
    outline-offset: 0
    border-color: #75C4F3
    box-shadow: 0px 0px 0px 4px #D1F1FD, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
  }
  &.p-invalid.p-component {
    border-color: #E24C4C
  }
  .p-select-label {
    background: transparent
    border: 0 none
    display: block
    white-space: nowrap
    overflow: hidden
    flex: 1 1 auto
    width: 1%
    text-overflow: ellipsis
    cursor: pointer
    &:focus
    &:enabled:focus {
      outline: 0 none
      box-shadow: none
    }
    &.p-placeholder {
      color: #6B7280
    }
  }

  .p-select-label-empty {
    overflow: hidden
    opacity: 0
  }

  input.p-select-label {
    cursor: default
  }

  .p-fluid .p-select {
    display: flex
    .p-select-label {
      width: 1%
    }
  }
}
</style>
<script>
import Select from 'primevue/select'
export default {
  components: {
    Select
  },
  mixins: [ModelValue(String)],
  props: {
    items: {
      type: Array, required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String, 
      required: false, 
      default: ''
    },
    hide_footnote: {
      type: Boolean, 
      default: false
    },
    required: {
      type: Boolean, 
      default: false,
      requied: false 
    },
    errText: {
      type: String,
      default: '\n'
    },
    optionLabel: {
      type: String, default: 'title'
    },
    optionValue: {
      type: String, default: 'value'
    },
    minWidth: {
      type: String, default: '100%'
    }
  },
  data(){
    return {
      head_width: undefined
    }
  },
  computed: {
    current() {
      return this.items.find(el => el[this.optionValue] === this.value)
    }
  },
  watch: {
    value(){
      this.$nextTick(() => this.setHeadWidth())
    }
  },
  mounted(){
    this.setHeadWidth()
  },
  methods: {
    setHeadWidth(){
      this.head_width = `${this.$refs.dropdown?.offsetWidth}px`
    }

  }
}
</script>

