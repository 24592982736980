<template>
  <div class="upload-button" :disabled="loading">
    <input :id="input_id" ref="input" type="file" :accept="accept" style="display: none"
           @click="ev => ev.target.value = null" @change="ev => $emit('upload', ev.target.files)"
    />
    <Icon v-if="type == 'icon'" class="upload-icon pointer" stroke="var(--gray-700)" :icon="icon" width="2rem" height="2rem"
          @click="$refs.input.click()"
    />
    <Button v-else ref="button" :primary="primary" style="width: 100%;" @click="$refs.input.click()">
      <Loader v-if="loading" :color="loader_color" mini inline style="width: 1.8rem"/>
      <Icon v-else class="upload-icon" :stroke="stroke" :icon="icon" width="1.8rem" height="1.8rem"/>
      <slot>
        Upload
      </slot>
    </Button>
  </div>
</template>

<style lang="stylus" scoped>
.upload-button[disabled='true'] {
  opacity: 0.8
}
</style>

<script>
import {FTYPES} from 'consts/ftypes.js'

export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    primary: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'upload-02'
    },
    stroke: {
      type: String,
      default: 'var(--gray-700)'
    },
    accept: {
      type: String,
      default: FTYPES['document'].exts.join(',')
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['upload'],
  data() {
    return {
      input_id: crypto.randomUUID()
    }
  },
  computed: {
    loader_color(){
      if (this.primary)
        return 'var(--base-white)'
      return undefined
    }
  },
  watch: {
    loading(val) {
      if (val) 
        this.$refs.button.$el.blur()
    },
  }
}
</script>