@import 'styles/mixins'
@import 'styles/animations'
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap')

:root {
  --icon-stroke-width: 1.4
  
  // from figma
  --gray-25: #FCFCFD
  --gray-50: #F9FAFB
  --gray-100: #F2F4F7
  --gray-200: #EAECF0
  --gray-300: #D0D5DD
  --gray-400: #98A2B3
  --gray-500: #667085
  --gray-600: #475467
  --gray-700: #344054
  --gray-800: #1D2939
  --gray-900: #101828
  --gray-950: #0C111D

  --brand-25: #FFF5F6
  --brand-50: #FFF1F3
  --brand-100: #FFE4E8
  --brand-200: #FECDD6
  --brand-300: #FEA3B4
  --brand-400: #FD6F8E
  --brand-500: #F63D68
  --brand-600: #E31B54
  --brand-700: #C01048
  --brand-800: #A11043
  --brand-900: #89123E
  --brand-950: #510B24

  --indigo-25: #F5F8FF
  --indigo-50: #EEF4FF
  --indigo-100: #E0EAFF
  --indigo-200: #C7D7FE
  --indigo-300: #A4BCFD
  --indigo-400: #8098F9
  --indigo-500: #6172F3
  --indigo-600: #444CE7
  --indigo-700: #3538CD
  --indigo-800: #2D31A6
  --indigo-900: #2D3282
  --indigo-950: #1F235B

  --error-25: #FFFBFA
  --error-50: #FEF3F2
  --error-100: #FEE4E2
  --error-200: #FECDCA
  --error-300: #FDA29B
  --error-400: #F97066
  --error-500: #F04438
  --error-600: #D92D20
  --error-700: #B42318
  --error-800: #912018
  --error-900: #7A271A
  --error-950: #55160C

  --success-25: #F6FEF9
  --success-50: #ECFDF3
  --success-100: #DCFAE6
  --success-200: #A9EFC5
  --success-300: #75E0A7
  --success-400: #47CD89
  --success-500: #17B26A
  --success-600: #079455
  --success-700: #067647
  --success-800: #085D3A
  --success-900: #074D31
  --success-950: #053321


  --blue-25: #F5FAFF
  --blue-50: #EFF8FF
  --blue-100: #D1E9FF
  --blue-200: #B2DDFF
  --blue-300: #84CAFF
  --blue-400: #53B1FD
  --blue-500: #2E90FA
  --blue-600: #1570EF
  --blue-700: #175CD3
  --blue-800: #1849A9
  --blue-900: #194185
  --blue-950: #102A56
  --success-700: #067647

  --orange-700: #B93815

  --text-primary: var(--gray-900)
  --text-secondary: var(--gray-700)
  --text-tertiary: var(--gray-600)
  --shadow: var(--brand-100)
  --box-shadow: 0px 0px 0px 4px #FFE4E8, 0px 1px 2px 0px rgba(255, 228, 232, 0.05)
  --box-shadow-indigo: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--indigo-100);
  --bg-primary: #fffefb
  --border-secondary: #eaecf0


  // element-ui overrides
  --el-color-primary: var(--brand-500) !important
  --el-border-radius-base: 0.8rem !important
}

html {
  font-size: 62.5% !important
}

html 
body {
  width 100%
  height 100%
  margin 0
  padding 0
  // overflow auto
}

h1 {
    min-height: 1.4rem; /* Increased by 40% */
    font-size: 2.8rem; /* Increased by 40% */
}
h2 {
    font-size: 2.4rem; /* Increased by 40% */
}
h3 {
    font-size: 1.8rem; /* Increased by 40% */
}
h4 {
    font-size: 1.5rem; /* Increased by 40% */
}
h5 {
    font-size: 1.4rem; /* Increased by 40% */
}

//
// convert control to 'error/red' state
// only as property, do not define a class
//
[error]:not([error="false"]) {
  filter: grayscale(100%) brightness(75%) sepia(100%) hue-rotate(-35deg) saturate(600%)
}

//
// disabled controls, icons & stuff
//
[disabled]:not([disabled="false"])
.disabled {
  opacity 0.3
  pointer-events none
  cursor default
}

//
// No user-select
//
.no-select {
  user-select: none
  -moz-user-select: none
  -khtml-user-select: none
  -webkit-user-select: none
  -o-user-select: none
  -webkit-app-region: no-drag;
}

//
// Do not wrap text
//
[nowrap]:not([nowrap="false"])
.nowrap {
  white-space: nowrap  
}

//
// selected elements
//
::selection{
  background: var(--primary-100) !important
}

::-moz-selection{
  background: var(--primary-100) !important
}

*:invalid {
  &::selection {
    background: var(--error-100) !important
    color: var(--negative-text-color) !important
  }
  &::-moz-selection{
    background: var(--error-100) !important
    color: var(--negative-text-color) !important
  }
}


[relative] {
  position: relative
}

[hidden] {
  visibility: hidden
}

//
// Flex column
//
.flex-col {
  display: flex
  flex-direction: column
}

.col-vcenter {
  @extend .flex-col
  align-items center
}

.col-hvcenter {
  @extend .col-vcenter
  justify-content center
}

//
// Flex row
//

.flex-row {
  display flex
  flex-direction row
}
 
//
// Flex row that centers content vertically
//

.row-vcenter {
  @extend .flex-row
  align-items center
}

.row-hvcenter {
  @extend .row-vcenter
  justify-content center
}

.row-vbetween {
  @extend .row-vcenter
  justify-content space-between
}

.fira-code-400 {
  font-family: "Fira Code", monospace;
  font-weight: 400;
  font-style: normal;
}

.ellipsis {
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
}

//
// Animations
//
.delay-in-1000 {
  animation:  wait 1s, fade-in 1s 0.5s
}

//
// Table
//
.ellipsis-cell {
  display: table
  table-layout: fixed
  width: 100%
  .ellipsed {
    @extend .ellipsis
    min-width: 0px  
  }
}

//
// Toasts
//
.ui.toast-container {
  font-size: 1.4rem
  line-height: 2.1rem
  .toast-box {
    box-shadow: none !important
    border: none !important
    .ui.toast {
      box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)
      border: 1px solid rgba(34, 36, 38, 0.12)
      padding: 1rem 2.4rem 0.7rem 1.1rem
      &.integrations {
        &.error {
          background-color: var(--error-50)
          color: var(--error-600)
        }
        .actions {
          margin: 0.5rem -2.4rem 0 -1.1rem !important
          padding-right: 0.6rem
          padding-bottom: 0
          display: flex
          justify-content: flex-end
          gap: 0.6rem
          button {
            margin: 0
            padding: 0.8rem 1.2rem
            &.primary {
              background: var(--brand-500)
              &:hover {
                border-color: var(--brand-700)
                background-color: var(--brand-600)
              }
              &:focus {
                box-shadow: 0rem 0rem 0rem 0.4rem var(--brand-100), 0rem 0.1rem 0.2rem 0rem rgba(16,24,40,0.05)
              }
            } 
          }
        }
      }
      &.success {
          background: var(--base-white)
          padding: 1rem 2.4rem 1rem 1.1rem
          .content {
            display: flex
            align-items: center  
            color: var(--gray-700)
          }
          .close.icon  {
            color: var(--gray-500)
          }
      }
    }
  }
  i.close.icon.right {
    top: 0.6rem !important
    right: 0.6rem !important
  }
}