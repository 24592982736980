<template>
  <Teleport to="#modals">
    <div v-if="show" class="modal-dialog">
      <div :class="'modal-content ui segment ' + (segment || '')" 
           :style="content_style"
      >
        <div class="content">
          <slot name="content"/>
        </div>
        <div v-if="$slots.controls" class="controls">
          <div v-if="centered"></div>
          <slot name="controls"/>
          <div v-if="centered"></div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="stylus" scoped>
.modal-dialog {
  width: 100%
  height: 100%
  overflow: hidden
  position: fixed
  top: 0px
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  z-index: 1000
  background-color: transparent

  .content {
    padding-top: 4px
    padding-bottom: 24px
    display: flex
    flex-direction: column
    align-items: center
    gap: 16px
  }

  .controls {
    display: flex
    justify-content: v-bind(ctrls_justify)
    gap: 0.5em

    & > * {
      flex-basis: 0
      flex-grow: 1
      white-space: nowrap
    }

    :slotted(button) {
      min-width: 8em !important
    }
  }
}

</style>

<script>

export default {
  props: {
    max_width: {
      type: String,
      default: '50%'
    },
    min_width: {
      type: String,
      default: '30em'
    },
    segment: {
      type: String,
      default: undefined
    },
    centered: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    content_style() {
      let style = {
        'background-color': '#fff',
      }
      if (this.max_width) {
        style['max-width'] = this.max_width
      }
      if (this.min_width) {
        style['min-width'] = this.min_width
      }
      return style
    },
    ctrls_justify() {
      return this.centered ? 'center' : 'right'
    },
    id() {
      return Math.random()
    }
  },

  methods: {
    close () {
      this.show = false
      document.getElementById('application').style.opacity = 1
    },

    open () {
      this.show = true
      document.getElementById('application').style.opacity = 0.3
    },
  }
}
</script>