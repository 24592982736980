<template>
  <div class="superuser">
    <Tabs v-model="active"
          class="tabs"
          :tabs="tabs" 
          bordered
          tab-position="right"
    />
    <AuditApi v-if="active === 'audit-api'"/>
    <IpWhitelist v-if="active === 'ip-whitelist'"/>
    <System v-if="active === 'system'"/>
    <Ingest v-if="active === 'ingest'"/>
    <AllAgents v-if="active === 'all-agents'"/>
  </div>
</template>
<style lang="stylus" scoped>
.superuser {
  display: grid
  grid-template-columns: max-content auto
  gap: 3.2rem
  .tabs {
    height: max-content
    padding-right: 1.6rem
    border-right: 0.1rem solid var(--gray-200)
  }
}
</style>

<script setup>
import AuditApi from './audit-api.vue'
import System from './system.vue'
import IpWhitelist from './ip-whitelist.vue'
import AllAgents from './all-agents.vue'
import Ingest from './ingest.vue'
</script>

<script>
export default {
  data() {
    return {
      active: 'audit-api',
      tabs: [
        {value: 'audit-api', title: 'Audit Api'},
        {value: 'ip-whitelist', title: 'IP Whitelist'},
        {value: 'system', title: 'System'},
        {value: 'ingest', title: 'Ingest'},
        // {value: 'all-agents', title: 'All Agents'}
      ]
    }
  }
}
</script>