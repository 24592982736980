<template>
  <MdContent class="md-content" :markdown="visible_text"/>
</template>

<style lang="stylus" scoped>
.md-content {
  color: var(--gray-900)
  font-size: 1.6rem
  font-weight: 400
  line-height: 2.4rem

  :deep() {
    @import "styles/md.styl"
    table {
      margin: 1.4rem 0
    }
    p {
      line-height: 2.4rem
    }
    a {
      sup {
        display: inline
        text-align: center
        color: var(--gray-900)
        transition: background 0.2s, color 0.2s
        border-radius: 4rem
        font-size: 1rem !important
        padding: 0 0.3rem
        margin-right: -0.2rem
        margin-left: -0.1rem
      }
      &:hover sup {
        color: var(--base-white)
        background: var(--brand-500)
      }
    }

    li {
      &>p {
        margin-bottom: 0
      }
      &>ul {
        margin-bottom: 1em
      }
    }
  }
}
</style>

<script>
import Markdown from 'utils/markdown'
import MdContent from 'components/md-content'
import {Later} from 'utils/timers'

export default {
  components: {MdContent},
  props: {
    text: {type: String, default: ''},
    error: {type: Boolean, default: false},
    intermediate: {type: Boolean, default: false}
  },
  emits: ['text-added'],
  data() {
    return {
      markdown: new Markdown(),
      timer: new Later(100),
      visible_text: ''
    }
  },
  watch: {
    text(curr) {
      if (curr) this.textUpdated(curr)
    }
  },
  mounted() {
    if (this.text) this.textUpdated(this.text)
  },
  beforeUnmount() {
    this.timer.abort()
  },
  methods: {
    textUpdated(ntext) {
      // If this is an already generated response
      // just display everythin and exit
      if (!this.intermediate && !this.timer.repeat_set) {
        this.visible_text = ntext
        this.$emit('text-added', true)
        return
      }

      // This is an intermediate text, if timer is already running just exit
      // running timer would take care about the rest
      if (this.timer.repeat_set) return

      // Intermediate text, not running timer. Launch it
      this.timer.repeat(this.addText)
    },
    addText(){
      // text doesn't change backwards while message is generated (intermediate === true)
      // final text can be different (intermediate == false)
      // final text can be larger than intermediate
      let visible_text = this.text.substring(0, this.visible_text.length)
      const invisible_text = this.text.substring(this.visible_text.length)
        
      const to_add_chars = Math.max(Math.floor(invisible_text.length / 10) || invisible_text.length, 5)
      const to_add = invisible_text.substring(0, to_add_chars)
      this.visible_text = visible_text + to_add
      
      if (!this.intermediate && this.visible_text.length === this.text.length) {
        this.visible_text = this.text
        this.timer.abort()
        this.$emit('text-added', true)
      }
      else {
        this.$emit('text-added', false)
      }
    },
    async copyText() {
      const regex = /\^([^\\^]+)\^/g
      const html =  this.markdown.makeHTML(this.visible_text.replaceAll(regex, ''))
        .replaceAll(/(\r\n|\n|\r)/gm, '')

      const modified = $utils.unicode.convertHtmlToUnicode(
        html, 
        node => {
          Array.from(node.querySelectorAll('ul li p, ol li p')).forEach(el => {
            const div = document.createElement('div')
            div.innerHTML = el.innerHTML
            el.replaceWith(div)
          })
          Array.from(node.querySelectorAll('p')).forEach((el, i) =>i > 0 && el.before('\n\n'))
          Array.from(node.querySelectorAll('table')).forEach(el => el && this.parseHtmlTable(el))
        })
      await $utils.copyText(modified)
    },

    parseHtmlTable(table) {
      let parsed_table = ''
      const rows = table.querySelectorAll('tr')

      rows.forEach((row, row_ind) => {
        const cells = row.querySelectorAll('th, td')
        let parsed_row = ''
        cells.forEach((cell, index) => {
          parsed_row += cell.textContent.trim()
          if (index < cells.length - 1) {
            parsed_row += '\t'
          }
        })
        parsed_table += parsed_row
        if (row_ind < rows.length - 1) parsed_table += '\n'
      })
      const div = document.createElement('div')
      div.innerHTML = `\n\n${parsed_table}`

      table.replaceWith(div)
    },
  }
}
</script>