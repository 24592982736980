<template>
  <div class="row-vcenter">
    <div class="initials no-select" :class="{pulse: loading, error: !!image.error}">
      <!-- 
          IMG tag ignores CORS rules, so Origin header is not sent to the server and s3
          doesn't return CORS headers. Browser caches the response. When making pdf 
          images are requested using XMLHTTPRequest that requires CORS but cached 
          response doesn't include CORS headers and request fails. crossoringin='anonymous'
          forces browser to send Origin header for this image. This option is not necessary
          if image would not be requested using XMLHTTPRequest later.
    -->
      <img v-if="image.url"  
           :src="image.url"
           :alt="name"
           :title="name"
           crossorigin="anonymous"
      >
      <template v-else>
        {{ initials }}
      </template>
    </div>

    <div v-if="showInfo" class="info">
      <div v-if="loading" class="skeleton">
        <div style="width: 8.8rem" nowrap></div>
        <div style="width: 4.9rem" nowrap></div>
      </div>
      <div v-else class="text">
        <div v-if="!!image.error" class="error">
          Failed to load avatar
        </div>
        <template v-else>
          <div class="name">{{ image.name }}</div>
          <div class="size">{{ $utils.format.bytes(image.size) }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
@import 'styles/animations'

.initials {
  display: inline-flex
  justify-content: center
  align-items: center
  border: 0.05rem solid var(--gray-300)
  border-radius: 50%
  color: var(--gray-700)
  opacity: 0.8
  background: var(--gray-100)
  font-size: v-bind(fontSize)
  font-style: normal
  font-weight: 500
  line-height: 1.8rem
  width: v-bind(width)
  height: v-bind(height)
  overflow: hidden
  text-transform: uppercase
  img {
    width: 100%
    height: 100%
    object-fit: cover
  }
  &.pulse {
    animation: pulse 2s infinite
  }
  &.error {
    background: var(--error-100)
    border-color: var(--error-300)
    color: var(--error-700)
  }
}

.info {
  margin-left: 1.4rem
  min-width: 0
  font-size: 1.4rem
  .skeleton {
    display: flex
    flex-direction: column
    gap: 0.2rem
    div {
      background: var(--gray-100)
      animation: pulse 2s infinite
      height: 1.9rem
    }
  }
  .text {
    display: flex
    flex-direction: column
    gap: 0.4rem
    .error {
      color: var(--error-600)
    }
    .size {
      color: var(--gray-600)
      font-weight: 400
      line-height: 20px
    }
    .name {
      font-weight: 500
      line-height: 20px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    }
  }
}
</style>

<script>
import $images from 'stores/images'

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    image_id: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: '4rem'
    },
    height: {
      type: String,
      default: '4rem'
    },
    fontSize: {
      type: String,
      default: '1.3rem'
    },
    showInfo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image(){
      return this.image_id ? $images.get(this.image_id) : {}
    },
    loading(){
      return this.image.loading
    },
    initials () {
      if (!this.name) return
      const letters = this.name.split(' ').map(el => el[0])
      return letters.slice(0, 2).join('')
    }
  }
}
</script>