<template>
  <div class="datatable-relative" relative>
    <Loader v-if="loading"/>
    <div v-window-resize:200="configureTable" class="datatable">
      <div class="header">
        <div class="left row-vcenter">
          <slot name="header-left"/>
        </div>
        <div class="right row-vcenter">
          <slot name="header-right"/>
        </div>
      </div>
      <div v-if="empty" class="empty" :disabled="loading">
        <slot name="empty"></slot>
      </div>
      <DataTable v-else :value="items"
                 :disabled="loading"
                 :rows="rows"
                 :always-show-paginator="false"
                 :lazy="lazy"
                 :total-records="total" 
                 :loading="loading"
                 :sort-order="sortOrder"
                 :sort-field="sortField"
                 :first="first"
                 relative
                 paginator
                 paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                 current-page-report-template="Page {currentPage} of {totalPages}"

                 :pt="{
                   root: 'root',
                   table: 'table',
                   thead: 'thead',
                   tbody: 'tbody',
                   headerRow: 'headerRow',
                   headerCell: 'headerCell',
                   bodyRow: 'bodyRow',
                   sort: 'sort',
                   pcpaginator: {
                     root: 'paginator-root',
                     content: 'content',
                     pages: 'pages',
                     page: 'page',
                     current: 'current',
                     prev: ['prev', 'pointer'],
                     next: ['next', 'pointer'],
                     first: ['first', 'pointer'],
                     last: ['last', 'pointer']
                   },
                   column: {
                     bodyCell: 'bodyCell',
                     columnHeaderContent: 'columnHeaderContent',
                     sort: 'sort',
                   },
                 }"

                 @page="$emit('page', $event)"
                 @sort="$emit('sort', $event)"
      >
        <slot/>
        
        <template v-slot:loading>
          <!-- NEED TO PREVENT DEFAULT LOADING -->
        </template>
      </DataTable>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.datatable-relative {
  height: 100%
  .datatable {
    height: 100%
    display: flex
    flex-direction: column
    gap: 1.6rem
  
    .header {
      display: flex
      justify-content: space-between
      align-items: center
      .left
      .right {
        flex-grow: 1
        flex-shrink: 1
      }
      .left {
        justify-content: flex-start
      }
      .right {
        justify-content: flex-end
      }
    }

    .empty {
      height: 100%
    }

    .root {
      border: 0.1rem solid var(--gray-200)
      border-radius: 0.4rem
      box-sizing: border-box
      overflow: auto
    }
    &:deep() {
      .table {
        width: 100%
        border-spacing: 0
        border-collapse: collapse
        .bodyCell {
          padding: 1.6rem 2.4rem
        }
        .thead {
          border-bottom: 0.1rem solid var(--gray-200)
          .headerCell {
            padding: 0
          }
          .headerRow {
            background: #F7F5F3
            text-align: left
          }

          .columnHeaderContent {
            padding: 1.3rem 2.4rem
            font-size: 1.2rem
            font-weight: 500
            line-height: 1
            white-space: nowrap
            .sort {
              display: inline-flex
              gap: 0.4rem
              align-items: center
            }
          }
        }

        .bodyRow {
          transition: background 0.2s
          &:not(:last-child) {
            border-bottom: 0.1rem solid var(--gray-200)
          }
          &:hover {
            background: var(--gray-50)
            transition: background 0.2s
          }
        }
      }
      .paginator-root .content {
        border-top: 0.1rem solid var(--gray-200)
        padding: 1.2rem 2.4rem
        display: grid
        grid-template-columns: 1fr auto auto auto auto auto 1fr
        gap: 1.2rem
        justify-content: center
        .next
        .prev
        .first
        .last {
          font-size: 1.6rem
          cursor: pointer
          border-radius: 0.8rem
          color: var(--gray-800)
          background: transparent
          border: none
          padding: 1rem 1.65rem
          line-height: 1.6rem
          display: flex
          align-items: center
          justify-content: center
          &:disabled {
            opacity: 0.5
          }
          &:hover:not(:disabled) {
            background: var(--gray-50)
          }
        }
        .current {
          white-space: nowrap
          display: flex
          align-items: center
          color: var(--gray-700)
          font-size: 1.4rem
          gap: 0.8rem
        }
        .pages {
          display: flex
          gap: 0.4rem
          .page {
            font-size: 1.6rem
            cursor: pointer
            border-radius: 0.8rem
            color: var(--gray-800)
            background: transparent
            border: none
            padding: 1rem 1.65rem
            line-height: 1.6rem
            &:hover
            &[data-p-highlight='true'] {
              background: var(--gray-50)
            }
          }
        }
      }
    }
  }
}

</style>
<script>
import DataTable from 'primevue/datatable'

export default {
  components: {
    DataTable    
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    sortOrder: {
      type: Number,
      default: 0
    },
    sortField: {
      type: String,
      default: null
    },
    skip: {
      type: Number,
      default: 0
    },
    overrideSkip: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  emits: ['page', 'sort'],
  data: function() {
    return {
      first: this.skip,
      rows: 20,
    }
  },

  computed: {
    empty() {
      return !this.items.length
    },
    max_items() {
      return this.items.length === this.total 
    }
  },

  watch: {
    '$route.fullPath'() {
      this.configureTable()
    },
    // when items change (while sorting) paginator switches to first and ignores skip value
    // its a library bug, need to prevent it
    'items': {
      handler: function () {
        this.first = this.overrideSkip ? new Number(this.skip) : undefined
      },
      deep: true
    },
    async empty() {
      if (!this.empty) {
        await this.$nextTick()
        this.configureTable()
      }
    },
    rows(next, prev){
      if (!this.max_items && next > prev) {
        this.$emit('page', {first: 0, rows: this.rows})
      }
    }
  },
  mounted() {
    this.configureTable()
  },
  methods: {
    configureTable() {
      const row = this.$el.querySelector('.bodyRow')
      if (this.empty || !row) return

      const paginator = this.$el.querySelector('.paginator-root')
      const row_rect = row.getBoundingClientRect()
      let h_in = window.innerHeight - row_rect.top - row_rect.height      
      if (paginator) {
        h_in = h_in - paginator.offsetHeight + row_rect.height 
      }
      const rows = Math.floor(h_in / row_rect.height) 
      this.rows = Math.max(rows, 1)
    }
  },
}
</script>