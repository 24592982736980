.chat-expanded {
  position: relative;
  height: 8rem;
}
.chat-expanded .chat-absolute {
  background: transparent;
  padding-top: 3rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.chat-expanded .chat-absolute.fade {
  background: linear-gradient(transparent, var(--bg-primary) 3rem);
}
.chat-expanded .chat-absolute .container-block {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  cursor: text;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
  border-radius: 0.8rem;
  background: #fffdfb;
  border: 0.1rem solid var(--gray-300);
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
  width: 100%;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
}
.chat-expanded .chat-absolute .container-block:hover:not(.focused):not(.generating) {
  border-color: var(--input-hover-border-color);
}
.chat-expanded .chat-absolute .container-block.focused:not(.generating) {
  border-color: var(--input-hover-border-color);
  box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.chat-expanded .chat-absolute .container-block.focused:not(.generating) .input-block .input::placeholder {
  opacity: 0;
}
.chat-expanded .chat-absolute .container-block.generating {
  cursor: default;
}
.chat-expanded .chat-absolute .container-block.generating> *:not(.input-block),
.chat-expanded .chat-absolute .container-block.generating .input-block > *:not(.stop-button) {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
.chat-expanded .chat-absolute .container-block .files-block {
  display: grid;
  gap: 0.8rem;
  height: 5rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
}
.chat-expanded .chat-absolute .container-block .input-block {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}
.chat-expanded .chat-absolute .container-block .input-block .input-button,
.chat-expanded .chat-absolute .container-block .input-block .stop-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  align-self: end;
  padding: 0.9rem;
}
.chat-expanded .chat-absolute .container-block .input-block .stop-button {
  transition: background 0.3s ease, border-color 0.3s ease;
}
.chat-expanded .chat-absolute .container-block .input-block .stop-button svg {
  transition: fill 0.3s ease;
  fill: var(--base-white);
}
.chat-expanded .chat-absolute .container-block .input-block .stop-button:active {
  background: var(--base-white);
  border-color: var(--gray-300);
}
.chat-expanded .chat-absolute .container-block .input-block .stop-button:active svg {
  fill: var(--brand-500);
}
.chat-expanded .chat-absolute .container-block .input-block .stop-button:focus {
  box-shadow: none;
}
.chat-expanded .chat-absolute .container-block .input-block .input {
  flex-grow: 1;
  border: none;
  outline: none;
  cursor: text;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
  resize: none;
  background: transparent;
}
.chat-expanded .chat-absolute .container-block .input-block .input::placeholder {
  transition: opacity 0.3s;
  opacity: 1;
}
.chat-expanded .chat-absolute .footer {
  display: flex;
  justify-content: center;
  color: var(--gray-600);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}
