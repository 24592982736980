.search-input:deep() .el-input__wrapper {
  padding: 0;
  box-shadow: none;
}
.search-input:deep() input:placeholder {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.items {
  margin: 0 -1.6rem 0;
  padding: 1rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  max-height: 31rem;
  overflow-y: auto;
}
.items .item {
  height: 2rem;
  display: flex;
  align-items: center;
}
@media (max-height: 555px) {
  .items {
    max-height: 10rem;
  }
}
