<template>
  <Segment class="company-card">
    <CompanyInfo ref="companyInfo" :info="info" :loading="loading" @follow="$emit('follow-company', $event)" @save-pdf="savePdf()" @save-rtf="saveRtf()"/>
    <!-- <template v-slot:header-right>
      <div class="actions" :disabled="loading">
        <Copy size="1.6rem"/>
        <Icon class="pointer" title="Send email" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" stroke-width="1" icon="send-01"/>
      </div>
    </template> -->
    <template v-if="!!sections?.length" v-slot:extra>
      <template v-for="section in sections" :key="section.title">
        <Links v-if="section.type === 'links'" class="links" :links="section"/>
        <Finances v-if="section.type === 'finances'" class="collapse-menu" :data="section"/>
        <CollapseMenu v-else-if="section.type === 'markdown'" 
                      :loading="!section.content" 
                      class="collapse-menu" :title="section.title"
        >
          <MdContent :markdown="section.content"/>
        </CollapseMenu>
      </template>
    </template>
  </Segment> 
</template>

<style lang="stylus" scoped>
.company-card {
  padding: 1.4rem 1.8rem 2.4rem
  .actions {
    display: flex
    gap: 1rem
  }
  .links {
    margin-top: 2.4rem
  }
  .collapse-menu {
    margin-top: 1.6rem
    &+.collapse-menu {
      margin-top: 2.7rem
    }
  }
}
</style>

<script setup>
import Links from '../links'
import CompanyInfo from './company-info.vue'
import MdContent from 'components/md-content'
import Finances from './finances.vue'
import {getCompanyTemplate} from '../cards-defs.js'
import {stringToFile} from 'utils/utils'

defineEmits(['follow-company'])
</script>

<script>
export default {
  props: {
    company: {type: Object, required: true},
    loading: {type: Boolean, default: false}
  },
  computed: {
    info() {
      return this.company.info
    },
    sections() {
      return this.company.sections
    }
  },
  methods: {
    savePdf(){
      const html = getCompanyTemplate(this.company)//  
      const options = {
        defaultStyles: {
          h6: {marginBottom: 3, marginTop: 10},
          u: {marginBottom: 2, marginTop: 5},
          table: {marginBottom: 10, marginTop: 7},
          ul: {marginBottom:3, marginLeft:5},
        }
      }

      $utils.pdf.saveDOM(html, {
        name: $utils.str.decodeHTMLEntities(this.info.name), 
        options, 
        delete_empty: true
      })
    },
    saveRtf(){
      const html = getCompanyTemplate(this.company)
      const rtf = $utils.rtf.toRTF($utils.str.decodeHTMLEntities(html))
      stringToFile(rtf, `${$utils.str.decodeHTMLEntities(this.info.name)}.rtf`, 'application/rtf')
    }
  }
}
</script>