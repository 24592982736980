<template>
  <div class="user-message" :class="{[mode]: true}">
    <template v-if="mode === 'compact'">
      <div class="inner">
        <div class="text">{{ text }}</div>
        <Icon class="icon" height="2rem" width="2rem" icon="user-03" stroke="white"/>
      </div>
    </template>
    <template v-else-if="mode === 'expanded'">
      <div>
        <Icon class="icon" width="1.6rem" height="1.6rem" icon="open-quote"/>
      </div>
      <span class="text" v-html="text"/>
    </template>
    <template v-else-if="mode === 'onboarding'">
      <div>
        <div class="icon row-hvcenter">
          <Icon width="1.3rem" height="1.3rem" icon="open-quote"/>
        </div>
      </div>
      <span class="text" v-html="text"/>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.user-message {
  &.compact .inner {
    display: flex
    justify-content: space-between
    padding: 1rem 1.4rem
    background: var(--brand-500)
    border-radius: 8px 0px 8px 8px
   
    .text {
      font-size: 1.6rem
      font-weight: 400
      line-height: 2.4rem
      color: var(--base-white)
    }
    .icon {
      margin-top: 0.2rem
      min-width: 2rem
    }
  }

  &.expanded {
    padding: 1.6rem 0 1.4rem 0
    display: flex
    gap: 1rem

    .text {
      color: var(--gray-900)
      font-size: 2.4rem
      line-height: 3.2rem
      font-style: normal
      font-weight: 400
      word-break: break-word
    }

    .icon {
      margin-top: 0.5rem
    }
  }
  &.onboarding {
    padding: 1.6rem 0 1.4rem 0
    display: flex
    gap: 1rem

    .text {
      font-size: 1.6rem
      font-weight: 500
      line-height: 3rem
      word-break: break-word
    }

    .icon {
      margin-top: -0.1rem
      width: 3.2rem
      height: 3.2rem
      border-radius: 50%
      border: 0.1rem solid var(--gray-300)
      background: var(--base-white)
    }
  }
}

</style>

<script>
export default {
  props: {
    text: {type: String, default: ''},
    mode: {type: String, default: 'compact'}
  },
}
</script>