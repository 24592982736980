<template>
  <div class="table-head" :class="{pointer: sortable}">
    <Checkbox v-if="checkbox" 
              :model-value="selected" 
              :indeterminate="indeterminate"
              :disabled="disabled"
              size="large"
              style="display: flex;"
              @click.stop
              @change="$emit('change', $event)"
    />
    <span class="name">{{ name }}</span>
    <div v-if="sortable" class="sortable">
      <Icon v-if="sortOrder === 1" class="pointer" width="1.6rem" height="1.6rem" icon="arrow-down" stroke="var(--gray-600)"/>
      <Icon v-if="sortOrder === -1" class="pointer" width="1.6rem" height="1.6rem" icon="arrow-up" stroke="var(--gray-600)"/>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.table-head {
  display: flex
  align-items: center
  gap: 1.2rem
  .name {
    font-size: 1.4rem
    line-height: 2rem
    color: var(--text-secondary)
    font-weight: 500
  }
  .sortable {
    display: flex
    align-items: center
    justify-content: center
    width: 1.6rem
    height: 1.6rem
  }
}
</style>
<script>
export default {
  props: {
    name: {type: String, default: ''},
    sortOrder: {type: Number, default: 0},
    sortable: {type: Boolean, default: false},
    checkbox: {type: Boolean, default: false},
    indeterminate: {type: Boolean, default: false},
    selected: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
  },
  emits: ['change']
}
</script>