<template>
  <div class="header">
    <span>{{ expanded ? 'Collapse to view less' : 'Scroll or expand to view more' }}</span>
    <!-- <Icon :icon="expanded ? 'chevron-up-double' : 'chevron-down-double'" 
          width="2rem" height="2rem" 
          class="icon pointer" 
          @click="expanded=!expanded"
    /> -->
    <Icon v-show="expanded" icon="chevron-up-double"
          width="2rem" height="2rem" 
          class="icon pointer" 
          @click="expanded=!expanded"
    />
    <Icon v-show="!expanded" icon="chevron-down-double"
          width="2rem" height="2rem" 
          class="icon pointer" 
          @click="expanded=!expanded"
    />
  </div>
  <ScrollBar v-resize="onResize" :style="{height: c_max_height}" class="segments">
    <Segment v-for="item in items" :key="item.value" ref="segments" :class="{active: value.includes(item.value)}">      
      <Checkbox :model-value="value.includes(item.value)" font-size="1.4rem" :checked-value="item.value" @change="onChange">{{ item.label }}</Checkbox>
    </Segment>
  </ScrollBar>
</template>

<style scoped lang="stylus">
.header {
  user-select: none
  span {
    color: var(--gray-600)
    font-size: 1.4rem
    line-height: 2rem
  }
  .icon:deep() * {
    stroke: var(--gray-400) !important
  }
}

.segments {
  overflow-y: overlay
  margin-top: 0.8rem

  margin: 1rem 0
  border: 1px solid #D0D5DD
  box-shadow: none
  border-radius: 0.8rem

  :deep() {
    .segment {
      top: 0
      bottom: 0
      border-radius: 0
      margin: 0
      width: auto
      box-shadow: none
      border: none
      border-top: 0.1rem solid var(--gray-300)
      background: transparent
      &:first-child {
        border-top: none
      }

      &.active {
        background-color: var(--primary-25)
      }
    }
  }
}
</style>

<script>
export default {
  mixins: [ModelValue(Array)],
  props: {
    items: {type: Array, default: () => [], required: true},
    rows: {type: Number, default: undefined, required: false}
  },
  data() {
    return {
      max_height: 'unset',
      expanded: false
    }
  },
  computed: {
    c_max_height () {
      if (this.expanded) return 'unset'
      return this.max_height
    }
  },
  methods: {
    onResize () {
      if (!this.rows || this.items.length <= this.rows) return
      
      let height = 0
      for (let idx = 0; idx < this.rows; ++idx) {
        const segment = this.$refs.segments[idx].$el
        height += segment.getBoundingClientRect().height
      }

      this.max_height = `${height}px`
    },
    onChange(data){
      if (!data.value && !this.value.includes(data.checkedValue)) {
        this.value.push(data.checkedValue)
      } else if (data.value && this.value.includes(data.checkedValue)) {
        this.value.splice(this.value.indexOf(data.checkedValue), 1)
      }
    }
  }
}
</script>