<template>
  <div class="toggle-wrapper">
    <Toggle v-model="value"></Toggle>
    <div class="text">
      <div class="label">{{ label }}</div>
      <div class="description">{{ description }}</div>
    </div>
  </div>
</template>
<style lang='stylus' scoped>
.toggle-wrapper {
  color: var(--gray-700)
  display: flex
  align-items: center
  gap: 1.6rem
  .label {
    font-weight: 500
    font-size: 1.4rem
  }
  .description {
    font-weight: 400
    font-size: 1.2rem
  }
}
</style>
<script>
export default { 
  mixins: [ModelValue(Boolean)],
  props: {
    label: {
      type: String, default: ''
    },
    description: {
      type: String, default: ''
    }
  }
}
</script>