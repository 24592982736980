<template>
  <div class="files">
    <header class="header">
      <div class="left">
        <div class="title">
          Files
          <Badge v-if="$files.total" gray style="width: max-content;">
            {{ $files.total }}
          </Badge>
        </div>
        <Loader v-if="show_refreshing" inline small/>
      </div>
    </header>
    
    <div class="container" 
         @dragover.prevent="dragover" 
         @drop.prevent="drop"
         @dragleave.prevent="dragleave"
    >
      <div class="relative">
        <Loader v-if="show_loading"/>
        <FilesEmpty v-else-if="show_empty" @upload="onUpload"/>
        <FilesTable v-else 
                    ref="table"
                    :files="$files.files"
                    :loading="loading"
                    :count="$files.count"
                    :all-exts="EXTS"
                    :skip="load_params.skip"
                    :highlight="highlight"
                    @upload="onUpload" 
                    @update="loadItems"
                    @delete="onDelete"
        />
        <FilesDrop v-show="active && !show_loading" :error="error" :exts="EXTS"/>
      </div>
    </div>
    <FilesUpload v-show="$files.uploads.length > 0" class="files-upload"/>
  </div>
</template>

<style lang="stylus" scoped>
.files {
  display: flex
  flex-direction: column
  height: 100vh
  background: var(--bg-primary)
  padding: 1.6rem

  .header {
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0.4rem 1.6rem 2rem 0rem

    .left {
      display: flex
      gap: 1.6rem
      flex-direction: row
      align-items: center
    }

    .title {
      display: flex
      align-items: center
      gap: 0.8rem
      color: var(--gray-900)
      font-size: 1.8rem
      font-weight: 500
      line-height: 2.8rem
    }
  }

  .container {
    flex-grow: 1
    .relative {
      position: relative
      height: 100%
    }
  }
  .files-upload {
    position: absolute
    right: 14.2rem
    bottom: 0
    width: 100%
    max-width: 53.7rem
  }
}
</style>

<script setup>
import FilesEmpty from './files-empty.vue'
import FilesTable from './files-table.vue'
import FilesDrop from './files-drop.vue'
import FilesUpload from './files-upload.vue'
import $files from 'stores/files'
const EXTS = [
  'DOC', 'TXT', 'PDF' //, 'PPT', 'CSV', 'XLS'
]
</script> 

<script>
import $global from 'stores/global'

const DEF_LIMIT = 20

export default {
  data() {
    return {
      initial_load: true,
      load_params: {skip: 0, limit: DEF_LIMIT, filter: ''},
      active: false,
      error: false,
      highlight: '',
    }
  },
  computed: {
    loading () {
      return $files.loading
    },
    store_loaded () {
      return $files.loaded
    },
    show_loading () {
      // store is loading for the first time
      return !$files.loaded && this.initial_load
    },
    show_refreshing () {
      // was loaded already and loading again
      return !$files.loaded && !this.initial_load
    },
    show_empty () {
      // loaded but still no files, checking total (not $files.length) because files can be filtered
      return $files.loaded && $files.total === 0
    },
  },
  async mounted() {
    if (!$global.fileUploadEnabled) {
      await $global.goToChats()
    }
    this.initial_load = true

    await this.loadItems({total: true})
    this.initial_load = false
  },
  methods: {
    async loadItems({total = false, ...ev} = {}){
      this.load_params = {...this.load_params, ...ev}
      await $files.load({...this.load_params, total})
      this.highlight = ev.name || this.load_params.name || ''
    },
    async onUpload(files) {
      await $files.upload(files[0], {
        to_files: false,
        resolve: result => {
          if ($files.files.find(file => file.id === result.id)) return
          this.loadItems({total: true})
        }
      })
    },
    dragover(event) {
      this.active = true
    },
    dragleave(event) {
      if (event.currentTarget.contains(event.relatedTarget)) return
      this.active = false
    },
    drop(event) {
      const files = Array.from(event.dataTransfer.items)
        .filter(item => item.kind === 'file')
        .map(item => item.getAsFile())
      if (files.length) this.onUpload(files)
      this.active = false
    },
    async onDelete(ids) {
      const tasks = ids.map(id => $files.delete(id))
      await Promise.all(tasks)
      const first_page = this.load_params.skip === 0
      const last_page = (this.load_params.skip + this.load_params.limit) >= $files.count
      if ($files.files.length === 0 && !first_page && last_page) {
        this.load_params.skip = this.load_params.skip - this.load_params.limit
      }
      await this.loadItems({total: true})
      if (!$files.count) this.$refs.table?.clearFilters()
    }
  }
}
</script>
