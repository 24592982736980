<template>
  <Segment class="person-card">
    <PersonaInfo :info="info" :loading="loading" @follow="$emit('follow-person', $event)" @save-doc="savePdf()" @save-rtf="saveRtf()"/>
    <!-- <template v-slot:header-right>
      <div class="actions" :disabled="loading">
        <Copy size="1.6rem"/>
        <Icon class="pointer" title="Send email" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" stroke-width="1" icon="send-01"/>
      </div>
    </template> -->
    <template v-if="!!sections" v-slot:extra> 
      <template v-for="section in sections" :key="section.title">
        <Links v-if="section.type === 'links'" class="links" :links="section"/>
        <Career v-if="section.type === 'career'" class="collapse-menu" :data="section"/>
        <CollapseMenu v-else-if="section.type === 'markdown'" 
                      :loading="!section.content" 
                      class="collapse-menu" 
                      :title="section.title"
        >
          <MdContent :markdown="section.content"/>
        </CollapseMenu> 
      </template>
    </template>
  </Segment>  
</template>

<style lang="stylus" scoped>
.person-card {
  .actions {
    display: flex
    gap: 1rem
  }
  .links {
    margin-top: 2.4rem
  }
  .collapse-menu {
    margin-top: 1.6rem
    &+.collapse-menu {
      margin-top: 2.7rem
    }
  }
}
</style>

<script setup>
import PersonaInfo from './persona-info.vue'
import Career from './career.vue'
import MdContent from 'components/md-content'
import Links from '../links'
import {getPersonaTemplate} from '../cards-defs.js'
import {stringToFile} from 'utils/utils'

defineEmits(['follow-person'])
</script>

<script>
export default {
  props: {
    persona: {type: Object, required: true},
    loading: {type: Boolean, default: false},
  },
  computed: {
    info() {
      return this.persona.info
    },
    sections() {
      return this.persona.sections
    }
  },
  methods: {
    savePdf(){
      const html = getPersonaTemplate(this.persona)

      const options = {
        defaultStyles: {
          h6: {marginBottom: 3, marginTop: 10},
          u: {marginBottom: 2, marginTop: 5},
          table: {marginBottom: 10, marginTop: 7},
          ul: {marginBottom:3, marginLeft:5},
        },
      }

      $utils.pdf.saveDOM(html, {
        name: $utils.str.decodeHTMLEntities(this.info.name), 
        options, 
        delete_empty: true
      })
    },
    saveRtf(){
      const html = getPersonaTemplate(this.persona)
      const rtf = $utils.rtf.toRTF($utils.str.decodeHTMLEntities(html))
      stringToFile(rtf, `${$utils.str.decodeHTMLEntities(this.info.name)}.rtf`, 'application/rtf')
    }
  }
}
</script>