<template>
  <SourcePopover ref="popover"
                 width="32.1rem" 
                 :disabled="disabled" 
                 :name="name" 
                 :img="img_src" 
                 :type="type" 
                 :summary="summary"
                 :url="url"
                 @hide="show_tip = false"
                 @show="show_tip = true"
  >
    <SourceWrap :pointer="pointer" :focused="show_tip" class="source-item" @click="pointer && onRefClick()">
      <div v-if="type !== 'genrate'" class="num">{{ num }}</div>
        
      <FileIcon v-if="type==='file'" font-size="0.5rem" width="1.7rem" height="1.8rem" :ext="name.split('.').pop()"/>
      <Favicon v-else :src="img_src" size="1.6rem"/>

      <div class="name">
        {{ name }}
      </div>
        
      <Icon stroke="var(--gray-600)" :width="icon_size" :height="icon_size" :stroke-width="stroke_width" :icon="icon"/>
    </SourceWrap>
  </SourcePopover>
</template>

<style lang="stylus" scoped>
.tooltip {
  padding: 0.9rem 1.2rem
  .head {
    display: flex
    align-items: center
    gap: 0.6rem
    font-size: 1.4rem
    font-weight: 400
    line-height: 2rem
    color: var(--gray-900)
    .icon {
      margin-left: auto
    }
  }
  .summary {
    margin-top: 0.6rem
    font-size: 1.2rem
    font-weight: 400
    line-height: 1.8rem
    color: #000
  }
}
.source-item {
  .name {
    flex: 1
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  }
  .num {
    color: var(--gray-700)
    font-size: 1rem
    font-weight: 400
    align-self: start
    line-height: 1
  }
}

</style>

<script>
import SourceWrap from './source-wrap.vue'
import SourcePopover from './source-popover.vue'
import {iconSize, iconStrokeWidth} from './source-defs'

export default {
  components: {SourceWrap, SourcePopover},
  props: {
    data: {type: Object, default: undefined},
    disabled: {type: Boolean, default: false},
    num: {type: Number, default: undefined},
  },
  data() {
    return {
      show_tip: false,
    }
  },
  computed: {
    type(){
      return this.data?.type
    },
    name(){
      return this.data?.name
    },
    logo(){
      return this.data?.logo
    },
    url(){
      return this.data?.url
    },
    file_id(){
      return this.data?.file_id
    },
    summary(){
      if (['api', 'file', 'genrate'].includes(this.type)) {
        return this.data?.summary
      }
      return this.data?.summary || this.url
    },
    pointer(){
      return !this.show_tip && !this.disabled
    },
    icon() {
      const map = {
        url: 'link-external-01',
        api: 'container',
        genrate: 'stars-02'
      }
      return map[this.type] || 'file-04'
    },
    img_src(){
      if (this.url?.includes('apollo.io')) {
        return 'assets/apollo.svg'
      }
      if (this.url?.includes('bing.com')) {
        return 'assets/bing.png'
      }
      return this.logo || '/assets/icons/cube-02.svg'
    },
    stroke_width(){
      return iconStrokeWidth(this.icon)
    },
    icon_size(){
      return iconSize(this.icon)
    }
  },
  methods: {
    onSrcClick(){
      this.$refs.popover.onSrcClick()
    },
    onRefClick() {
      if (this.summary) this.show_tip = true
      else this.onSrcClick()
    }
  },
}
</script> 
<!-- 
, -->