.tooltip {
  padding: 0.9rem 1.2rem;
}
.tooltip .head {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--gray-900);
}
.tooltip .head .icon {
  margin-left: auto;
}
.tooltip .summary {
  margin-top: 0.6rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: #000;
}
.source-item .name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.source-item .num {
  color: var(--gray-700);
  font-size: 1rem;
  font-weight: 400;
  align-self: start;
  line-height: 1;
}
