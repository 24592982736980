<template>
  <SampleItem v-if="mode === 'inline-text'" :file="file"/>

  <div v-else class="file-item">
    <FileIcon :error="file.failed" :ext="file.ext" class="icon" :pulse="file.processing" :disabled="file.removing"/>
    <div class="text" :disabled="file.removing">
      <div class="name" :class="{'failed': file.failed}">{{ file.name }}</div>
      <div class="size" :class="{'failed': file.failed}">{{ $utils.format.bytes(file.size) }}</div>
    </div>
    <Icon icon="download-02"
          class="pointer" 
          width="2rem" 
          height="2rem"
          stroke="var(--gray-600)"
          @click.stop="$emit('download', file.id)"
    />
    <Icon class="pointer" 
          width="2rem" 
          height="2rem" 
          icon="trash-04" 
          stroke="var(--gray-500)" 
          :disabled="file.removing"
          @click.stop="$emit('delete', file.id)"
    />
  </div>
</template>

<style lang="stylus" scoped>
.file-item {
  width: 100%
  padding: 1.6rem
  display: flex
  flex-direction: row
  align-items: center
  gap: 1.2rem
  background: var(--base-white)

  .icon {
    height: 4rem
  }

  .text {
    min-width: 0
    font-size: 1.4rem
    flex-grow: 1
    .size {
      color: var(--gray-600)
      font-weight: 400
      &.failed {
        color: var(--error-500)
      }
    }
    .name {
      font-weight: 500
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      &.failed {
        color: var(--error-600)
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
      default: undefined
    },
    file: {
      type: Object,
      requied: true,
      default: undefined
    },
  },
  emits: ['delete', 'download']
}
</script>