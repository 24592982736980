.suggestions {
  padding: 0.8rem 0 0;
}
.suggestions .suggestion {
  color: var(--gray-700);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  transition: color 0.2s;
}
.suggestions .suggestion:hover {
  color: var(--brand-600);
}
