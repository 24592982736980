.new-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--bg-primary);
}
.new-chat .title {
  text-align: center;
  margin: 2.4rem;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.2rem;
}
.new-chat .chat {
  flex-grow: 1;
}
.new-chat .chat .feed {
  width: 100%;
}
.new-chat .chat :deep() .head,
.new-chat .chat :deep() .feed-items,
.new-chat .chat :deep() .feed-items+.divider {
  width: 100%;
  max-width: 86.6rem;
  margin: 0 auto;
}
.new-chat .chat :deep() .feed-content {
  padding-bottom: 2rem;
}
.new-chat .chat :deep() .chat-input {
  border-top: none;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.new-chat .chat :deep() .footnote {
  font-size: 1rem;
  margin: 0.4rem auto 0 auto;
  color: var(--gray-500);
}
.new-chat.empty .title {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 7.4rem;
}
.new-chat.empty .title .logo {
  display: block;
  width: 4rem;
  margin: 0 auto;
}
.new-chat.empty .title p {
  color: var(--gray-900);
  font-size: 2.4rem !important;
  font-weight: 400;
  line-height: 3.2rem;
}
