<template>
  <PrimePanel :header="header"
              :pt="{...pt_default, ...pt}" 
              :collapsed="collapsed"
              @click="collapsed = !collapsed"
  >
    <template v-slot:icons>
      <span>
        <Icon v-show="collapsed" stroke="var(--gray-400)" width="2rem" heigth="2rem" icon="chevron-down"/>
        <Icon v-show="!collapsed" stroke="var(--gray-400)" width="2rem" heigth="2rem" icon="chevron-up"/>
      </span>
    </template>
    <slot/>
  </PrimePanel>
</template>
<style lang="stylus" scoped>
:deep() {
  .header {
    cursor: pointer
    border: 0.1rem solid #DEE2E6
    padding: 1.2rem 1.8rem 1.2rem 2.4rem
    background: var(--base-white)
    display: flex
    justify-content: space-between
    align-items: center
  }
  .title {
    color: var(--text-secondary)
    font-size: 1.6srem
    font-style: normal
    font-weight: 500
    line-height: 28px
  }

  .toggler {
    cursor: pointer
    border: 0 none
    background: transparent
    border-radius: 50%
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s
  }
  .toggleablecontent {
    box-sizing: border-box
    &.p-toggleable-content-enter-active {
      overflow: hidden
      transition: max-height 1s ease-in-out
    }
    &.p-toggleable-content-leave-active {
      overflow: hidden
      transition: max-height 0.45s cubic-bezier(0, 1, 0, 1)
    }
    &.p-toggleable-content-enter-from
    &.p-toggleable-content-leave-to {
      max-height: 0
    }
    &.p-toggleable-content-enter-to
    &.p-toggleable-content-leave-from {
      max-height: 100rem
    }
  }
  .content {
    border: 0.1rem solid #DEE2E6
    background: var(--gray-200)
    color: #495057
    border-top: 0 none
  }
}
</style>

<script setup>
import PrimePanel from 'primevue/panel'

const pt_default = {
  root: 'root',
  header: ['header', 'no-select'],
  title: 'title',
  toggleablecontent: 'toggleablecontent',
  toggler: 'toggler',
  content: 'content'
}
</script>

<script>
export default {
  props: {
    header: {type: String, default: ''},
    pt: {type: Object, default: () => ({})}
  },
  data() {
    return {
      collapsed: false
    }
  }
}
</script>