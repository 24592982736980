<template>
  <Loader v-if="first_load">
    Loading<br>
    Please wait...
  </Loader>

  <Empty v-else-if="!total">
    <template v-slot:title>You don’t have any contacts uploaded yet...</template>
    <template v-slot:sub-text>
      Upload a CSV of your LinkedIn connections to build a comprehensive contact list in the app,<br>
      helping you stay connected and leverage your network more effectively.
    </template>
    <template v-slot:link>
      <a href="https://www.linkedin.com/help/linkedin/answer/a566336/export-connections-from-linkedin" target="_blank">
        Learn how to export your LinkedIn connections here.
      </a>
    </template>
    <template v-slot:actions>
      <UploadButton :loading="processing" primary stroke="white" icon="file-plus-02" 
                    :accept="LINKEDIN_CONTACTS.exts.join(',')" 
                    @upload="files => $emit('upload', files)"
      >
        Import CSV
      </UploadButton>
      <AddPopover ref="add" title="Add Contact" label="LinkedIn Profile URL" 
                  placement="top-end" placeholder="https://linkedin.com/in/username/" 
                  :maxlength="100" 
                  :validator="validateProfileURL"
                  @submit="onAddContact"
      >
        <template v-slot:reference>
          <Button class="add-contact" primary disabled @click="() => $refs.add.open()">
            <Icon icon="plus" width="2rem" height="2rem" stroke="var(--base-white)"/>
            Add Contact
          </Button>
        </template>
      </AddPopover>
    </template>
  </Empty>

  <DataTable v-else 
             :items="items" 
             :total="count" 
             :loading="loading || processing" 
             class="people-table"
             :skip="load_params.skip"
             override-skip 
             @page="onPage"
  >
    <!-- <template v-slot:header-left>
      <Button :hidden="selected.size === 0" primary link small>Follow Selected People</Button>
      <Button :hidden="selected.size === 0" small link>Unfollow Selected People</Button>
    </template> -->
    <template v-slot:empty>
      <Empty bg="assets/bg-pattern-square.png">
        <template v-slot:image>
          <Icon width="4rem" height="4rem" icon="search" class="icon-svg"/>
        </template>
        <template v-slot:title>No contacts were found</template>
        <template v-slot:sub-text>
          Your search “{{ highlight }}” did not match any contact. Please try again.
        </template>
      </Empty>
    </template>

    <template v-slot:header-right>
      <SearchInput ref="search"
                   style="margin-right: 1.6rem;"
                   :disabled="loading || processing || true"
                   @search="onSearch"
      />
      <AddPopover ref="add"
                  placement="bottom"
                  title="Add Contact" 
                  label="Linkedin URL" 
                  placeholder="e.g. www.linkedin.com/in/username/" 
                  :validator="validateProfileURL" 
                  @submit="onAddContact"
      >
        <template v-slot:reference>
          <Icon class="pointer" icon="plus" width="2rem" height="2rem" stroke="var(--gray-700)" :disabled="processing || loading || true" @click="onNewContact"/>
        </template>
      </AddPopover>
    </template>
  
    <!-- Contact -->
    <Column field="name" style="width: 40%">
      <template v-slot:header>
        <div class="contact">
          <div>Name</div>
        </div>
      </template>
      <template v-slot:body="{data: { avatar_url, first_name, middle_name, last_name, position, deleting }}">
        <div class="ellipsis-cell" :disabled="deleting">
          <div class="contact">
            <el-image class="contact-avatar" fit="contain" :src="avatar_url">
              <template v-slot:error>
                <div class="initials">
                  {{ first_name[0] }}{{ last_name[0] }}
                </div>
              </template>
              <template v-slot:placeholder>
                <div class="initials">
                  {{ first_name[0] }}{{ last_name[0] }}
                </div>
              </template>
            </el-image>
            <div class="contact-data" style="min-width: 0">
              <div class="contact-name ellipsed">
                <Highlight :text="[first_name,middle_name,last_name].filter(Boolean).join(' ')" :highlight="highlight"/>
              </div>
              <div class="contact-position ellipsed">
                <Highlight :text="position" :highlight="highlight"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Column>

    <!-- Company -->
    <Column field="company" header="Company" style="width: 30%">
      <template v-slot:body="{data: {logo_url, company_name, deleting}}">
        <div v-if="company_name" class="ellipsis-cell" :disabled="deleting">
          <div class="company">
            <el-image class="company-img" fit="contain" :src="logo_url">
              <template v-slot:error>
                <el-image class="company-img" fit="contain" src="assets/company-placeholder.png"/>
              </template>
              <template v-slot:placeholder>
                <el-image class="company-img" fit="contain" src="assets/company-placeholder.png"/>
              </template>
            </el-image>
            <div class="ellipsed">
              <Highlight :text="company_name" :highlight="highlight"/>
            </div>
          </div>
        </div>
      </template>
    </Column>

    <Column field="date" header="Date Added">
      <template v-slot:body="{data: {created_at, deleting}}">
        <span class="date" :disabled="deleting">{{ $utils.format.date(created_at) }}</span>
      </template>
    </Column>

    <Column>
      <template v-slot:body="{data: {following, id, deleting}}">
        <div class="action" :disabled="processing || deleting || true">
          <Button v-if="following" primary @click="follow(id, false)">Following</Button>
          <Button v-else @click="follow(id, true)">Follow</Button>
          <Popconfirm width="max-content"
                      confirm-button-text="Yes"
                      cancel-button-text="No"
                      title="Are you sure you want to delete this contact?"
                      @confirm="onRemove(id)"
          >
            <div class="row-hvcenter pointer">
              <Icon icon="trash-04" 
                    width="2rem" 
                    height="2rem" 
                    stroke="var(--gray-500)"
              />
            </div>
          </Popconfirm>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<style lang="stylus" scoped>
.add-contact[disabled] {
  opacity: 0.8
}
.people-table {
  height: 100%

  .contact {
    display: flex
    align-items: center
    gap: 1rem
    .contact-avatar {
      width: 4rem
      height: 4rem
      border-radius: 50%
      flex-shrink: 0
      img {
        width: 100%
        height: 100%
        object-fit: cover
      }
      .initials {
        display: inline-flex
        justify-content: center
        align-items: center
        border: 0.05rem solid var(--gray-300)
        border-radius: 50%
        color: var(--gray-700)
        opacity: 0.8
        background: var(--gray-100)
        font-size: 1.3rem
        font-style: normal
        font-weight: 500
        line-height: 1.8rem
        width: 4rem
        height: 4rem
        overflow: hidden
        text-transform: uppercase
      }
    }
    .contact-name {
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
    }
    .contact-position {
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
      color: var(--gray-600)
    }
  }

  .company {
    display: flex
    align-items: center
    gap: 1rem

    .company-img {
      width: 2.6rem
      height: 2.6rem
      border-radius: 50%
      flex-shrink: 0
      img {
        width: 100%
        height: 100%
        object-fit: cover
      }
    }
  }

  .action {
    display: flex
    justify-content: flex-end
    gap: 1.4rem
  }
}
</style>

<script>
import Column from 'primevue/column'
import {DataTable, SearchInput} from 'components/data-table'
import $contacts from 'stores/contacts'
import {LINKEDIN_CONTACTS} from 'consts/ftypes.js'
const DEF_LIMIT = 20

export default {
  components: {
    Column,
    DataTable,
    SearchInput
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['upload', 'add'],
  setup(){
    return {
      LINKEDIN_CONTACTS
    }
  },
  data: function() {
    return {
      highlight: '',
      first_load: true,
      load_params: {skip: 0, limit: DEF_LIMIT, filter: ''}
    }
  },
  computed: {
    items(){
      return $contacts.contacts
    },
    total(){
      return $contacts.total
    },
    count(){
      return $contacts.count
    },
    loading(){
      return $contacts.loading
    },
  },
  watch: {
    async processing(val) {
      if (!val) {
        await $contacts.load(this.load_params)
      }
    }
  },
  async mounted() {
    await $contacts.load(this.load_params)
    this.first_load = false
  },
  methods: {
    async onSearch(filter){
      if (this.load_params.filter === filter) return
      this.load_params = {skip: 0, limit: DEF_LIMIT, filter}    
      await $contacts.load(this.load_params)
      
      this.highlight = filter
      this.$refs.search.focus()
    },
    async onPage(ev){
      this.load_params = {...this.load_params, skip: ev.first, limit: ev.rows}
      await $contacts.load(this.load_params)
    },
    // TODO: to linkedin_id
    async onAddContact(li_url){
      await $contacts.newContact(li_url)
      await $contacts.load(this.load_params)
    },
    async follow(id, follow) {
      await $contacts.follow(id, follow)
    },
    onNewContact(){
      this.$refs.add.open()
    },
    validateProfileURL(url) {
      const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w-]+\/?$/
      return regex.test(url)
    },
    async onDelete(id){
      await $contacts.delete(id)
      await $contacts.load(this.load_params)
    } 
  }
}
</script>
