<template>
  <div class="chat-buttons" :class="{pressed}">
    <AiButton v-for="(item, idx) in options" 
              :key="idx" 
              :type="item.type" 
              :title="item.title"
              :selected="selected == idx"
              @click="onSelected(idx, item)"
    />
  </div>
</template>
<style lang="stylus" scoped>
.chat-buttons {
  display: flex
  gap: 1.2rem
  &.pressed {
    pointer-events: none
  }
}
</style>
<script>
import AiButton from './ai-button.vue'

export default {
  components: {AiButton},
  props: {
    options: {type: Array, required: true}
  },
  emits: ['prompt'],
  data(){
    return {
      pressed: false,
      selected: -1
    }
  },
  methods: {
    onSelected(idx, item) {
      this.pressed = true
      this.selected = idx
      this.$emit('prompt', item.chat_query)
    }
  }
}
</script>