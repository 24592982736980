.application {
  min-width: 1200px;
  width: 100%;
  height: 100%;
  background: #fff;
}
.application .router {
  width: 100%;
  height: 100%;
}
.app {
  padding: 8.2rem;
  width: 100%;
}
