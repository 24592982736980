<template>
  <div class="ai-message">
    <div v-if="!researching">
      <div class="segment-title">
        <span>Sources</span>
      </div>
      <Sources class="sources" :sources="sources" :loading="loading"/>
    </div>
    <div class="header">
      <div class="segment-title" style="gap: 0">
        <Collapse v-model="loading" horizontal>
          <AiLogo animate style="margin-right: 1.6rem"/>
        </Collapse>
        <span v-if="researching">Working</span>
        <span v-else-if="agents">Agents</span>
        <span v-else>Answer</span>
      </div>
      <ChatLoader v-if="researching" 
                  :finish="!!item.data?.text || !!item.data?.cards?.length" 
                  class="loader" 
                  :status="item.data?.status"
                  mode="expanded"
                  @end="researching = false"
      />
      <template v-else>
        <AiAgents v-if="agents" class="agents" :data="item.data"/>
        <Cards v-if="item.data?.cards?.length" class="cards" :cards="item.data.cards" :loading="loading" :sources="sources"/>
        <Answer v-if="loading" ref="answer" class="answer" :text="item.data.text" :intermediate="item.intermediate" @text-added="onTextAdded"/>
        <template v-else>
          <AiEditor v-if="last && item.data.editable" 
                    class="editor"
                    :post-text="item.data.text"
                    :loading="regenerating"
                    @save="$emit('save', $event)" 
                    @refrag="$emit('refrag', $event)"
          />
          <Answer v-else ref="answer" class="answer" :text="item.data.text"/>
        </template>
        <AiInput v-if="input" class="ai-buttons" :disabled="!last" :input="input" @prompt="$emit('ask-ai', $event)"/>
      </template>
    </div>

    <template v-if="!researching && !loading">
      <div class="text-controls">
        <Actions v-if="actions" @ask-ai="$emit('ask-ai', $event)"/>
        <Copy v-if="!item.data?.editable" @copy="copyAnswer()"/>
        <Feedback :show-popover="!liked" @send="$emit('like', $event)">
          <Icon class="pointer" stroke-width="1" width="1.6rem" height="1.4rem" 
                :fill="liked ? 'var(--gray-300)' : 'var(--base-white)'" 
                stroke="var(--gray-700)" 
                icon="thumbs-up"
          />
        </Feedback>
        <Feedback :show-popover="!disliked" @send="$emit('dislike', $event)">
          <Icon class="pointer" stroke-width="1" width="1.6rem" height="1.4rem" 
                :fill="disliked ? 'var(--gray-300)' : 'var(--base-white)'"
                stroke="var(--gray-700)"
                icon="thumbs-down"
          />
        </Feedback>
      </div>
      <div v-if="!last || show_suggestions" class="divider"/>
      <Suggestions v-if="show_suggestions"
                   class="suggestions delay-in-1000"
                   :suggestions="item.data.suggestions" 
                   @ask-ai="$emit('ask-ai', $event)"
      />
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.ai-message {
  padding: 0.8rem 0 1rem
  :deep().segment-title
  .segment-title {
    display: flex
    align-items: center
    gap: 0.6rem
    font-weight: 400
    font-size: 1.6rem
    line-height: 2.6rem
    .answer-badge {
      border: 0.1rem solid var(--gray-200)
      margin-left: -0.6rem
    }
  }

  .sources {
    margin-top: 0.8rem
    padding-bottom: 3.2rem
  }

  .loader {
    padding: 0 0.8rem
    margin-top: 1.8rem
  }

  .agents
  .cards
  .answer
  .editor
  .ai-buttons {
    margin-top: 1.6rem
  }

  .text-controls {
    display: flex
    justify-content: flex-end
    align-items: center
    padding: 0.8rem 0.4rem 1.2rem 0.4rem
    gap: 1rem

    .clipboard-icon {
      width: 1.4rem
      height: 1.4rem
      .icon {
        position: absolute
        transition: opacity 0.2s
      }
    }
  }

  .divider {
    background: var(--gray-200)
    height: 0.1rem
    margin: 0.6rem 0
  }
}
</style>


<script>
import Sources from 'components/sources'
import Actions from '../actions.vue'
import Answer from './answer.vue'
import Suggestions from '../ai-suggestions.vue'
import AiLogo from '../ai-logo.vue'
import ChatLoader from '../chat-loader'
import AiEditor from '../ai-editor.vue'
import AiAgents from '../ai-agents.vue'
import Cards from 'components/cards'
import {computed} from 'vue'
import AiInput from 'components/ai-chat/ai-input'

export default {
  components: { 
    Answer,
    AiEditor,
    AiAgents,
    ChatLoader,
    Cards, 
    AiLogo, 
    Suggestions, 
    Sources, 
    Actions,
    AiInput
  },
  provide() {
    return {
      di_sources: computed(() => this.sources)
    }
  },
  props: {
    last: {type: Boolean, default: false},
    item: {type: Object, required: true},
    actions: {type: Boolean, default: false},
    regenerating: {type: Boolean, default: false},
  },
  emits: ['ask-ai', 'text-added', 'like', 'dislike', 'refrag', 'save', 'follow-person', 'follow-company'],
  data() {
    return {
      typing: false,
      researching: true,
      //FOR TESTS
      // source_loading: false
    }
  },
  computed: {
    answer_type(){
      return this.item.data?.type
    },
    loading(){
      return this.item.intermediate || this.typing
    },
    show_suggestions() {
      return this.actions && this.item.data.suggestions?.length > 0
    },
    sources() {
      const items = this.item.data?.sources || []
      if (!this.loading && items.length === 0) 
        items.push({
          type: 'genrate',
          logo: 'assets/g-logo-fat.svg',
          name: 'Genrate AI'
        })
      return items
    },
    liked(){
      return this.item.liked > 0
    },
    disliked(){
      return this.item.liked < 0
    },
    agents(){
      return this.item.data?.cards?.length || (this.item.data?.editable && this.last)
    },
    input() {
      return this.item.data?.input
    },
  },
  mounted(){
    // FOR TESTS
    // if (this.last) {
    //   this.source_loading = true
    //   new $utils.timers.Later(30000).once(() => this.source_loading = false)
    // }
  },
  methods: {
    async copyAnswer(){
      await this.$refs.answer.copyText()
    },
    onTextAdded(full) {
      this.typing = !full
      this.$nextTick(() => this.$emit('text-added', full))
    },
  }
}
</script>
