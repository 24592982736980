.custom-textarea {
  display: flex;
  flex-direction: column;
}
.custom-textarea textarea {
  resize: none;
  width: 100%;
  height: 100%;
  word-break: break-word;
  margin: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  padding: 0.4375em 0.6875em;
  background: var(--base-white);
  border: 0.1rem solid var(--gray-300);
  outline: none;
  color: var(--gray-900);
  border-radius: 0.8rem;
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1.6rem;
  line-height: 1.2857;
}
.custom-textarea textarea::placeholder {
  opacity: 0.7;
}
.custom-textarea textarea[disabled] {
  opacity: 1;
}
.custom-textarea textarea:focus {
  box-shadow: 0px 0px 0px 4px #d1f1fd, 0px 1px 2px 0px rgba(16,24,40,0.05);
  border-color: #75c4f3;
}
.custom-textarea textarea:hover {
  border-color: #75c4f3;
}
.custom-textarea .custom.icon {
  height: 4rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-textarea .footer {
  display: flex;
  flex-direction: row;
}
.custom-textarea .footer > :last-child {
  margin-left: auto;
}
