.superuser {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 3.2rem;
}
.superuser .tabs {
  height: max-content;
  padding-right: 1.6rem;
  border-right: 0.1rem solid var(--gray-200);
}
