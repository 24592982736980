<template>
  <nav class="navbar" :class="{collapsed}" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="logo pointer" :class="{center: collapsed}" @click="genrate">
      <img v-show="collapsed" class="pointer center" src="/assets/logo9.svg"/>

      <!-- v-show is important for proper collapse-->
      <img v-show="!collapsed" src="/assets/logo.svg">
      <div v-show="!collapsed" ref="collapse" class="pointer collapse">
        <Icon v-if="pinned" icon="pin-01" stroke="var(--gray-500)" width="2rem" height="2rem" @click.stop="$store.nav_pinned = false"/>
        <Icon v-else icon="pin-02" stroke="var(--gray-500)" width="2rem" height="2rem" @click.stop="$store.nav_pinned = true"/>
      </div>
    </div>
    
    <Button primary small class="new-genrate" @click="genrate">
      <Icon class="plus-icon" width="2rem" height="2rem" icon="plus"/>
      <div v-if="!collapsed">New Chat</div>
    </Button>

    <NavbarList :items="items" 
                style="flex-grow: 1" 
                class="navbar-list" 
                :navbar-open="open" 
                @show="allowCollapse"
    />
    
    <div class="bottom-bar">
      <div class="control-block">
        <Feedback show-popover divider popper-class="navbar-feedback" 
                  placement="top-start" :min-length="10" width="28rem" 
                  :rows="6" :fb-id="fb_id" @show="allowCollapse" @send="onSend"
        >
          <NavbarItem icon="message-text-square-02" title="Feedback" :active="fb_opened"/>
        </Feedback>
        <router-link class="link" :to="{name: 'settings', query: {tab: $route.query.tab}}">
          <NavbarItem icon="settings-01" title="Settings" :active="is_settings"/>
        </router-link>
      </div>
      <Divider m="1rem 0"/>
      <NavbarProfile class="profile"/>
    </div>
  </nav>
</template>
<style lang="stylus">
.navbar-feedback {
  .el-popper__arrow {
    left: 1.6rem !important
  }
  textarea {
    padding-right: 1rem !important
  }
}
</style>
<style lang="stylus" scoped>
@import 'styles/animations'
.collapsed.navbar {
  .logo {
    i
    .text {
      display: none
    }
  }
  width: 8.1rem
  .new-genrate {
    display: flex
    flex-direction: row
    gap: 0.6rem
    background: transparent
    border-color: transparent
    .plus-icon {
      stroke: var(--brand-600)
      margin-right: 0
    }
    span {
      width: 0%
      opacity: 0
    }
  }
}

.navbar {
  width: 28rem
  transition: width 0.2s
  padding: 3.2rem 1rem 3.2rem
  border-right: 1px solid var(--gray-200)
  display: flex
  flex-direction: column
  gap: 2.4rem
  background: white
  overflow-x: hidden
  height: 100%
  scrollbar-gutter: stable both-edges

  @supports (-moz-appearance:none) {
    padding-left: 1.4rem
    padding-right: 1.4rem
  }

  .logo {
    margin: -3.2rem -1rem -2.4rem
    padding: 3.2rem 1rem 2.4rem
    display: flex
    flex-direction: row

    .collapse {
      margin-left: auto
      font-size: 2rem
      align-self: center
    }

    &.center {
      justify-content: center
    }
  }

  .new-genrate {
    .plus-icon {
      stroke: var(--base-white)
      margin-right: 0.4rem
    }
    div {
      overflow: hidden
      animation: fade-in 0.3s
      opacity: 1
      transition: width 0.3s, opacity 0.3s
      white-space: nowrap
      font-size: 1.4rem
      line-height: 2rem
    }
    &:focus {
      box-shadow: none !important
    }
  }
  .navbar-list {
    overflow-y: overlay
    overflow-x: hidden
    scrollbar-gutter: stable both-edges

    @supports (-moz-appearance:none) {
      padding-left: 0.4rem
      padding-right: 0.4rem
    }
  }

  .bottom-bar .control-block {
    padding: 0 0.4rem
    margin-bottom: 1.6rem
  }

  .bottom-bar .control-block {
    padding: 0 0.4rem
    flex-direction: column
    gap: 0.4rem
    display: flex
  }
  
  .ui.divider {
    border-bottom-width: 0px
    border-color: var(--gray-200)
  }

  .profile {
    padding: 1.4rem 0.6rem 0
  }
}
</style>

<script>
import NavbarList from './navbar-list.vue'
import NavbarProfile from './navbar-profile.vue'
import NavbarItem from './navbar-item.vue'
import $store from 'stores/global'
import {Later} from 'utils/timers'
import {ITEMS} from './navbar-items.js'
import $profile from 'stores/profile'
import {API_FEEDBACK} from 'consts/api'
import Rest from 'utils/rest'

export default {
  components: {
    NavbarList,
    NavbarProfile,
    NavbarItem,
  },
  setup(){
    return {
      n_items: ITEMS,
      fb_id: crypto.randomUUID(),
    }
  },
  data(){
    return {
      active: undefined,
      open: $store.nav_pinned,
      expand: new Later(200),
      allows: new Set(),
    }
  },
  computed: {
    pinned() {
      return $store.nav_pinned
    },
    collapsed() {
      return !this.open
    },
    can_collapse() {
      return !this.allows.size
    },
    items () {
      if ($profile.is_admin) return this.n_items
      return this.n_items.filter(item => !item.superuser)
    },  
    is_superuser () {
      return $profile.is_superuser
    },
    is_settings(){
      return this.$route.path === '/settings'
    },
    fb_opened(){
      return this.allows.has(this.fb_id)
    }
  },
  watch: {
    can_collapse(val) {
      if (val) {
        const hovered = Array.from(document.querySelectorAll(':hover'))
        if (!hovered.includes(this.$el)) {
          this.onMouseLeave()
        }
      }
    }
  },
  methods: {
    onMouseEnter() {
      this.expand.abort()
      if (this.pinned) return

      this.expand.once(() => {
        this.open = true
      })
    },
    onMouseLeave() {
      this.expand.abort()
      if (!this.can_collapse || this.pinned) return

      this.expand.once(() => {
        this.open = false
      })
    },
    genrate(){
      $store.goToChats()
    },
    allowCollapse(ev){
      ev.show ? this.allows.add(ev.id) : this.allows.delete(ev.id)
    },
    async onSend(message){
      const rest = new Rest()
      await rest.post(API_FEEDBACK, {message})
    },
  }
}
</script>
