<template>
  <SettingsFrame v-resize="handleResize" 
                 title="Tone of Voice"
                 description="To train our AI models to compose content in your unique tone of voice, please share examples of your written content, such as LinkedIn posts, emails, and other communications."
                 class="tone-of-voice"
  >
    <div class="sample-input">
      <BuilderInputs :key="key" v-read:valid="valid" col gap="2rem" :opts="schema" @update="onUpdate"/>
    </div>
  </SettingsFrame>
</template>

<style lang="stylus" scoped>
.tone-of-voice:deep() {
  &>.header
  &>.content {
    max-width: 50%
    .custom-textarea {
      flex-grow: 1
      &.disabled {
        opacity: 0.8
      }
      textarea {
        min-height: 0rem
        height: 100%
      }
    }
    .uploader-segment {
      flex-grow: 1
      max-height: 36.5rem
      .inline-text {
        display: flex
        flex-direction: column
        height: 100%
      }
    }
    .builder-inputs.col {
      .field {
        display: flex
        flex-direction: column
        margin-bottom: 0
      }
    }
  }
}
.sample-input {
  display: flex
  flex-direction: column
  height: 100%
  :deep()&>.builder-inputs.col {
    flex-grow: 1
    .segment {
      background: transparent
    }
  }
}
</style>
<script>
import BuilderInputs from 'components/builder/builder-inputs.vue'
import SettingsFrame from './settings-frame.vue'
import $profile from 'stores/profile.js'
import {makeSampleContentSchema} from './settings-defs'

export default {
  components: {
    SettingsFrame,
    BuilderInputs
  },
  provide() {
    return {
      store: $profile,
      schema: this.schema,
    }
  },
  data() {
    return {
      schema: makeSampleContentSchema()
    } 
  },
  methods: {
    handleResize() {
      const [field] = this.schema
      field.file_rows = 5
      this.$nextTick(this.updateRows)
    },
    updateRows() {
      const [field] = this.schema
      if ( this.$el.clientHeight < this.$el.scrollHeight && field.file_rows > 2) {
        field.file_rows--
        this.$nextTick(this.updateRows)
      }
    },
  }
}
</script>