.bytes {
  padding-left: 4px;
  padding-top: 6px;
}
.input-row {
  display: flex;
  flex-direction: row;
}
.input-row .input {
  max-width: 46px;
  min-width: 25rem;
  margin-right: 1rem;
  margin-left: -26px;
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ui.basic.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.ui.basic.label .divider {
  height: 1px;
  width: 100%;
  background: rgba(34,36,38,0.15);
}
.ui.basic.label i {
  margin: 0;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
  color: rgba(34,36,38,0.6);
}
