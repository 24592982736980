.footnote {
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: 0.6rem;
}
.footnote.default {
  color: var(--gray-600);
}
.footnote.error {
  color: var(--error-500);
}
