<template>
  <ScrollPanel ref="panel"
               pt:root:class="root"
               pt:contentcontainer:class="contentcontainer"
               pt:content:class="content"
               pt:bary:class="bary"
  >
    <slot/>
  </ScrollPanel>
</template>

<style lang="stylus" scoped>
.root {
  display: block
  position: relative
  :deep() {
    .contentcontainer {
      overflow: hidden
      width: 100%
      height: 100%
      position: relative
      z-index: 1
      float: left
      .content {
        height: 100%
        width: 100%
        overflow: scroll
        box-sizing: border-box
        border: none
        scrollbar-width: none
        position: relative
        &::-webkit-scrollbar {
          width: 0 !important
          height: 0 !important
        }
      }
    }
    .bary {
      position: absolute
      left: calc(100% - 0.4rem)
      background-color: #CCC
      border: 0 none
      border-radius: 1rem
      z-index: 2
      width: 0.4rem
      outline: none
      cursor: pointer
      opacity: 1
      &[data-p-scrollpanel-hidden="true"] {
        display: none
      }
    }
  }
}
</style>

<script>
import ScrollPanel from 'primevue/scrollpanel'

const DURATION = 400

export default {
  components: {ScrollPanel},
  props: {
    smoothScroll: {type: Boolean, default: true}
  },
  emits: ['scroll'],
  mounted() {
    const content = this.getContent()
    content.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount() {
    const content = this.getContent()
    content.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    getContent() {
      return this.$refs.panel.$el.querySelector('[data-pc-section="content"]')
    },
    maxTop() {
      const el = this.getContent()
      return el.scrollHeight - el.clientHeight
    },
    currTop() {
      const el = this.getContent()
      return el.scrollTop
    },

    checkBottom() {
      // Checking if we're already reach the bottom
      // Some of these properties are rounded, which means that the equality can fail 
      // in cases where scrollTop would have a decimal component or when the rounded values align poorly.
      const el = this.getContent()
      return Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) <= 1
    },
    scrollTop(top, smooth) {
      const el = this.getContent()
      const maxTop = this.maxTop()
      const targetTop = top === -1 ? maxTop : (top > maxTop ? maxTop : top)

      if (this.checkBottom()) {
        return
      }
      
      if (!smooth) {
        this.$refs.panel.scrollTop(targetTop)
        return 
      }

      // Custom smooth scrolling below is written and tested to scroll to the end
      if (maxTop !== targetTop) {
        throw new Error('Not implemented')
      }

      // do not allow to enter into scroll while we're scrolling
      if (this.scrolling) {
        return
      }

      
      this.scrollTo = targetTop
      const startTime = new Date().getTime()

      
      const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from
      }

      // Quadratic in-ease-out
      // const easeInOutQuad = (time, from, distance, duration) => {
      //   time /= duration / 2
      //   if (time < 1) return distance / 2 * time * time + from
      //   time--
      //   return -distance / 2 * (time * (time - 2) - 1) + from
      // }
      const startTop = el.scrollTop
      this.scrolling = true 
      
      const animator = () => {
        const time = new Date().getTime() - startTime
        const newTop = easeInOutQuart(time, startTop, targetTop - startTop, DURATION)
        el.scrollTo({top: newTop, behavior: 'instant'})
        
        if (time >= DURATION) {
          // since easing formula is based on floats we might be not at the end by 1 pixel
          // ensure that we're really at the target
          el.scrollTo({top: targetTop, behavior: 'instant'})
          this.scrolling = false
          
          // continue to the end if any content was added while were scrolling 
          if (top === -1) {
            this.scrollToEnd(smooth)
          }
        } else {
          requestAnimationFrame(animator)
        }
      }

      requestAnimationFrame(animator)
    },
    scrollToEnd(smooth) {
      this.scrollTop(-1, smooth)
    },
    onScroll() {
      this.$emit('scroll')
    }
  }
}
</script>
