<template>
  <Modal ref="modal" max_width="35em" min_width="20em" segment="red">
    <template v-slot:content>
      <div class="title">{{ title }}</div>
      <span class="error-text">
        {{ errtext }}
      </span>
    </template>
    <template v-slot:controls>
      <div></div>
      <Button plain @click="onCopyError">
        Copy error
      </Button>
      <Button plain @click="onRestart">
        Restart
      </Button>
      <div></div>
    </template>
  </Modal>
</template>

<style scoped lang="stylus">
.title {
  font-size: 17px
  font-weight: 800
  text-align: center
  color: #9F3A38
}

.error-text {
  color: rgba(0, 0, 0, 0.85)
  overflow: hidden
  text-align: center
}
</style>

<script>
import Modal from './modal'

export default {
  components: {
    Modal,
  },
  props: {
    error: {
      default: undefined,
      type: [String, Object]
    },
    title: {
      type: String,
      default: 'Error occurred'
    },
  },
  emits: ['copy'],
  computed: {
    errtext() {
      return 'Something went wrong. Please copy error message and contact support.'
    },
    errfull() {
      return typeof this.error === 'string' ? this.error : this.error.stack
    }
  },

  mounted () {
    console.log('error')
    this.$refs.modal.open()
  },

  methods: {
    async onCopyError() {
      await $utils.copyText(this.errfull)
    },
    onRestart() {
      this.close()
    },
    close () {
      this.$refs.modal.close()
      this.$store.clearError()
    }
  }
}
</script>
