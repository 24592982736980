<template>
  <Loader v-if="loading">
    Loading<br>
    Please wait...
  </Loader>
  <div v-else class="scrape">
    <div v-if="task_setting" class="task-id">
      Task: {{ task_setting.type }}/{{ task_setting.id }}
    </div>

    <div v-if="date" class="status">
      Created at: {{ date }}
    </div>
    
    <div class="status">
      <span>
        Status: 
        <span :class="{err: task_status === 'failed'}">
          {{ task_status || 'never launched' }}
        </span>
      </span>

      <el-tooltip v-if="!!task_data?.error" 
                  content="Error copied to clipboard"
                  placement="top"
                  effect="light"
                  :visible="show_copy_ttip"
      >
        <Icon stroke="var(--gray-700)" 
              icon="clipboard"
              stroke-width="1"
              width="1.6rem"
              height="1.6rem"
              class="pointer"
              @click="copyError()"
        />
      </el-tooltip>
    </div>

    <div class="actions">
      <Button primary :disabled="!can_run" green @click="scrape">Scrape</Button>
      <Button :disabled="!can_run" green @click="cleanup">Cleanup</Button>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.scrape {
  display: flex
  flex-direction: column
  gap: 1rem
  font-size: 1.5rem
  font-weight: 500
  
  .status {
    display: flex
    gap: 1rem
    align-items: center
    .err {
      color: #FF7F7F
    }
  }

  .actions {
    display: flex
    flex-direction: row
    gap: 1rem
    margin-top: 1rem
  }
}
</style>
<script>
import {TASK_AMDOCS_SCRAPE, TASK_AMDOCS_SCRAPE_DELETE} from 'consts/api'
import {SETTING_AMDOCS_SCAPE_TASK} from 'consts/settings'
import {TASK_STATUS} from 'utils/tasks'
import {settings, Tasks} from 'utils'

export default {
  data() {
    return {
      tasks: new Tasks(),
      loading: true,
      task_setting: undefined,
      task_data: undefined,
      show_copy_tip: false
    }
  },
  computed: {
    task_status() {
      if (this.task_data) return this.task_data.status
      if (this.task_setting) return 'No task data found. Probably task was running long ago and is already deleted.'
      return 'Never launched'
    },
    ended() {
      return [TASK_STATUS.COMPLETED, TASK_STATUS.FAILED].includes(this.task_status)
    },
    can_run() {
      return !this.loading && (!this.task_data || this.ended)
    },
    date() {
      return this.task_data ? $utils.format.datetime(this.task_data.created_at) : undefined
    }
  },
  async mounted() {
    this.loading = true
    this.task_setting = await settings.get(SETTING_AMDOCS_SCAPE_TASK)
    await this.waitTask()
    this.loading = false
  },
  beforeUnmount() {
    this.tasks.cancelAll()
  },
  methods: {
    async waitTask() {
      this.loading = true
      if (this.task_setting) {
        try {
          this.task_data = await this.tasks.get(this.task_setting.id)
          this.task_data = await this.tasks.waitTask(this.task_setting.id)
        }
        catch(err) {
          if (err.http_code === 404) {
            // task might be missing if it was running long ago
            // that's ok, tasks are kept in db only for 1 month
            return
          }
          throw err
        }
      }
      this.loading = false
    },
    async scrape() {
      this.loading = true
      this.task_setting = undefined
      this.task_data = undefined
      this.task_setting = {
        id: await this.tasks.start(TASK_AMDOCS_SCRAPE), 
        type: 'scrape'
      }
      await settings.put(SETTING_AMDOCS_SCAPE_TASK, this.task_setting)
      await this.waitTask()
      this.loading = false
    },
    async cleanup() {
      this.loading = true
      this.task_setting = undefined
      this.task_data = undefined
      this.task_setting = {
        id: await this.tasks.start(TASK_AMDOCS_SCRAPE_DELETE), 
        type: 'cleanup'
      }
      await settings.put(SETTING_AMDOCS_SCAPE_TASK, this.task_setting)
      await this.waitTask()
      this.loading = false
    },
    async copyError() {
      await $utils.copyText($utils.format.json(this.task_data.error))
      this.show_copy_tip = true
      await $utils.timers.wait(2000)
      this.show_copy_tip = false
    }
  }
}
</script>