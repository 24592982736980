.loader-text {
  color: var(--gray-600);
  text-align: left;
  font-size: 1.6rem;
  line-height: 2rem;
  height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  width: 0%;
  max-width: max-content;
}
.loader-text.typing {
  animation: typing 2s steps(40, end) infinite;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}
@-moz-keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@keyframes typing {
  0% {
    width: 0%;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
