.ai-message {
  padding: 0.8rem 0 1rem;
}
.ai-message :deep().segment-title,
.ai-message .segment-title {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
.ai-message :deep().segment-title .answer-badge,
.ai-message .segment-title .answer-badge {
  border: 0.1rem solid var(--gray-200);
  margin-left: -0.6rem;
}
.ai-message .sources {
  margin-top: 0.8rem;
  padding-bottom: 3.2rem;
}
.ai-message .loader {
  padding: 0 0.8rem;
  margin-top: 1.8rem;
}
.ai-message .agents,
.ai-message .cards,
.ai-message .answer,
.ai-message .editor,
.ai-message .ai-buttons {
  margin-top: 1.6rem;
}
.ai-message .text-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.8rem 0.4rem 1.2rem 0.4rem;
  gap: 1rem;
}
.ai-message .text-controls .clipboard-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.ai-message .text-controls .clipboard-icon .icon {
  position: absolute;
  transition: opacity 0.2s;
}
.ai-message .divider {
  background: var(--gray-200);
  height: 0.1rem;
  margin: 0.6rem 0;
}
