<template>
  <div class="source-wrap" :class="{pointer, focused}">
    <slot/>
  </div>
</template>
<style lang="stylus" scoped>
.source-wrap {
  display: flex
  gap: 0.6rem
  align-items: center
  font-size: 1.4rem
  border-radius: 0.4rem
  padding: 0.4rem 0.8rem
  border: 0.1rem solid #EAECF0
  background: rgba(255, 255, 255, 0.5)
  height: 100%
  transition: all 0.3s
  height: 2.8rem
  cursor: default
  &.pointer {
    cursor: pointer
  }
  &.pointer:hover
  &.focused {
    background: #FFFBF7
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)
  }
}
</style>
<script>
export default {
  props: {
    pointer: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    }
  }
}
</script>