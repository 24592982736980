.runner .caption {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.runner .info {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
.runner .info > div {
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.runner .info > div .err {
  color: var(--error-600);
}
.runner .info > div .copy {
  margin-left: 0.2rem;
}
