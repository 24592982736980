<template>
  <div class="company-info" relative>
    <div class="head">
      <!-- IMAGE -->
      <el-image class="image" fit="contain" :src="logo_url">
        <template v-slot:error>
          <Skeleton v-if="loading" shape="circle" width="4.8rem" height="4.8rem" border-radius="0"/>
          <el-image v-else class="placeholder" fit="contain" src="assets/company-placeholder.png"/>
        </template>
        <template v-slot:placeholder>
          <Skeleton v-if="loading" shape="circle" width="4.8rem" height="4.8rem" border-radius="0"/>
        </template>
      </el-image>

      <!-- INFO -->
      <div class="info" :class="{'no-hq': !loading && !hq_address }">
        <!-- NAME -->
        <div v-if="name" class="name ellipsis" v-html="name"/>
        <Skeleton v-else width="100%" height="3rem"/>
        <!-- ADDRESS -->
        <div v-if="hq_address" class="hq-address ellipsis" v-html="hq_address"/>
        <Skeleton v-else-if="loading" width="100%" height="2rem"/>
        <div v-else class="hq-address"/>
        <!-- INDUSTRY -->
        <div v-if="industry" class="industry">
          <Icon v-if="industry_icon" width="1.6rem" height="1.6rem" stroke="var(--gray-700)" :icon="industry_icon"/>
          <div class="text">{{ industry_title }}</div>
        </div>
        <Skeleton v-else-if="loading" width="100%" height="2rem"/>
        <div v-else class="industry"/>
      </div>

      <!-- ACTIONS -->
      <div class="actions">
        <div class="action-icon pointer" :disabled="!website_url" @click.stop="openWebsite()">
          <Icon icon="globe-02" stroke-width="1" stroke="var(--gray-900)" width="2rem" height="2rem"/>
        </div>
        <div class="action-icon pointer" :disabled="!linkedin_url" @click.stop="openLinkedin()">
          <Icon icon="social/linkedin-circle" width="2.2rem" height="2.2rem"/>
        </div>
        <div class="action-icon pointer" :disabled="loading" @click.stop="$emit('save-pdf')">
          <FileIcon font-size="0.6rem" width="1.9" height="2.2rem" ext="pdf"/>
        </div>
        <div class="action-icon pointer" :disabled="loading" @click.stop="$emit('save-rtf')">
          <FileIcon font-size="0.6rem" width="1.9" height="2.2rem" ext="rtf"/>
        </div>
        <Button v-if="can_follow" class="follow-btn" :primary="followed" :disabled="loading" @click.stop="on_follow">
          {{ followed ? 'Following' : 'Follow' }}
        </Button>
      </div>
    </div>
    <Divider m="0.8rem 0 0"/>

    <!-- DESCRIPTION -->
    <MdContent v-if="summary" :markdown="summary" class="bio"/>
    <Skeleton v-else class="bio" width="100%" height="2.4rem"/>
  </div>
</template>
<style lang="stylus" scoped>
.company-info {
  .show-more {
    position: absolute
    right: 1rem
    bottom: 1rem
    cursor: pointer
    color: var(--gray-600)
    font-size: 1.2rem
    line-height: 1.8rem
    font-weight: 600
    display: flex
    align-items: center
    gap: 0.6rem
  }
  .head {
    display: flex
    gap: 1.1rem
    .image {
      width: 4.8rem
      height: 4.8rem
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      flex-shrink: 0
      .placeholder {
        display: flex
        justify-content: center
        align-items: center
      }
    }
    .info {
      color: var(--gray-900)
      font-weight: 500
      display: grid
      flex-direction: column
      grid-template-rows: 3rem 2rem 2rem
      grid-template-areas: "name" "hq-address" "industry"
      gap: 0.4rem
      min-width: 18rem
      &.no-hq {
        grid-template-areas: "name" "industry" "hq-address"
      }
      .name {
        font-size: 2rem
        line-height: 3rem
        height: 100%
        grid-area: name
      }
      .hq-address {
        font-size: 1.4rem
        line-height: 2rem
        height: 100%
        grid-area: hq-address
      }
      .industry {
        font-size: 1.4rem
        line-height: 2rem
        height: 100%
        display: flex
        align-items: center
        gap: 0.4rem
        grid-area: industry
        .text {
          margin-top: 0.1rem
        }
      }
    }
    .actions {
      display: flex
      gap: 0.5rem
      align-items: center
      margin-left: auto
      flex-shrink: 0
      .action-icon {
        display: flex
        justify-content: center
        align-items: center
        padding: 1rem
        transition: background 0.3s ease
        border-radius: 0.8rem
        &:hover {
          background: var(--gray-100)
        }
      }
      .follow-btn {
        width: 9.4rem
      }
    }
  }

  .bio {
    margin-top: 1.2rem
    color: var(--gray-900)
    font-size: 1.6rem
    font-weight: 400
    line-height: 2.4rem
  }
}
</style>

<script>
import {INDUSTRIES_ICONS, INDUSTRIES_TITLES} from 'consts/industries'
import MdContent from 'components/md-content'
import $companies from 'stores/companies'

export default {
  components: {MdContent},
  props: {
    info: {type: Object, default: undefined},
    loading: {type: Boolean, default: false}
  },
  emits: ['save-pdf', 'save-rtf'],
  data() {
    return {
      followed: false,
      assigned_id: undefined,
      on_follow: $utils.timers.debounce(this.followCompany, 500)
    }
  },
  computed: {
    name() {
      return this.info?.name
    },
    logo_url() {
      return this.info?.logo_url
    },
    hq_address() {
      return this.info?.hq_address
    },
    industry() {
      return this.info?.industry
    },
    summary() {
      return this.info?.summary
    },
    linkedin_url() {
      return this.info?.linkedin_url
    },
    can_follow() {
      return this.info?.can_follow
    },
    user_company_id() {
      return this.info?.user_company_id || this.assigned_id
    },
    website_url() {
      return this.info?.website_url
    },
    industry_title(){
      return INDUSTRIES_TITLES[this.industry]
    },
    industry_icon(){
      return INDUSTRIES_ICONS[this.industry]
    }
  },
  methods: {
    openLinkedin() {
      window.open(this.linkedin_url, '_blank')
    },
    openWebsite() {
      window.open(this.website_url, '_blank')
    },
    async followCompany() {
      if (this.user_company_id) {
        const followed = !this.followed
        await $companies.follow(this.user_company_id, followed)
        this.followed = followed
        return
      }
      const regex = /linkedin\.com\/company\/([a-zA-Z0-9_%-]+)/i
      const match = this.linkedin_url.match(regex)
      if (!match) return
      const {id} = await $companies.newCompany(this.name, match[1])
      this.assigned_id = id
      this.followed = true
    }
  }
}
</script>