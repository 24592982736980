<template>
  <div class="chat-expanded">
    <div class="chat-absolute" :class="{fade}">
      <div class="container-block" :class="{focused, disabled, generating}" @click="focus">
        <div v-if="enableUploads && (uploads.length || files.length)" class="files-block">
          <Upload v-for="upload in uploads" :key="upload.id" 
                  :name="upload.file.name"
                  :size="upload.file.size"
                  :error="!!upload.error"
                  :processing="true"
                  @remove="files_store.deleteUpload(upload.id)"
                  @retry="retryUpload(upload.id)"
          />
          <Upload v-for="file in files" :key="file.id" 
                  :name="file.name"
                  :size="file.size"
                  :ext="file.ext"
                  :processing="file.processing"
                  :failed="file.failed"
                  :removing="file.removing"
                  @remove="files_store.delete(file.id)"
          />
        </div>
        <div class="input-block">
          <div v-if="enableUploads" class="input-button">
            <Icon class="pointer"
                  :class="{disabled: !can_upload}"
                  icon="attachment-02" 
                  width="2rem" 
                  height="2rem" 
                  stroke="var(--brand-500)"
                  @click.stop="$refs.file.click()"
            />
          </div>
          <textarea ref="input"
                    v-model="value"
                    :disabled="generating"
                    class="input"
                    :rows="rows"
                    :placeholder="placeholder"
                    maxlength="2048"
                    @focusin="focused = true"
                    @focusout="focused = false"
                    @keydown.enter.stop="onKeyEnter"
          />
          <Button v-if="generating" class="stop-button" primary @click.stop="onStop()">
            <Icon icon="stop" width="2rem" height="2rem"/>
          </Button>
          <Button v-else class="input-button" :disabled="!can_push" primary @click.stop="onPush()">
            <Icon icon="send-02" width="2rem" height="2rem" stroke="var(--base-white)"/>
          </Button>
        </div>
      </div>
      <Footnote :class="{disabled: disabled || generating}" class="footer" type="default">
        {{ description }}
      </Footnote>
      <input id="file"
             ref="file" 
             type="file"
             :accept="DOCUMENT.exts.join(',')"
             style="display: none"
             @click="() => {$refs.file.value = null}"
             @change="onUpload"
      />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.chat-expanded {
  position: relative
  height: 8rem
  .chat-absolute {
    background: transparent
    padding-top: 3rem
    position: absolute
    bottom: 0
    width: 100%
    z-index: 1
    &.fade {
      background:linear-gradient(transparent, var(--bg-primary) 3rem)
    }
    .container-block {
      display: flex
      flex-direction: column
      gap: 0.4rem
      cursor: text
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1)
      border-radius: 0.8rem
      background: #FFFDFB
      border: 0.1rem solid var(--gray-300)
      padding: 0.8rem 0.8rem 0.8rem 1.2rem
      width: 100%
      transition: box-shadow 0.1s ease, border-color 0.1s ease
      &:hover:not(.focused):not(.generating) {
        border-color: var(--input-hover-border-color)
      }
      &.focused:not(.generating) {
        border-color: var(--input-hover-border-color)
        box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        .input-block {
          .input {
            &::placeholder {
              opacity: 0
            }
          }
        }
      }
      &.generating {
        cursor: default
        &> *:not(.input-block)
        .input-block > *:not(.stop-button) {
          opacity: 0.3
          pointer-events: none
          cursor: default
        }
      }
      .files-block {
        display: grid
        gap: 0.8rem
        height: 5rem
        grid-template-columns: repeat(5, 1fr)
        grid-template-rows: 1fr
      }

      .input-block {
        display: flex
        gap: 0.8rem
        align-items: center
        .input-button
        .stop-button {
          display: flex
          justify-content: center
          align-items: center
          height: 4rem
          align-self: end
          padding: 0.9rem
        }

        .stop-button {
          transition: background 0.3s ease, border-color 0.3s ease
          svg {
            transition: fill 0.3s ease
            fill: var(--base-white)
          }
          &:active {
            background: var(--base-white)
            border-color: var(--gray-300)
            svg {
              fill: var(--brand-500)
            }
          }
          &:focus {
            box-shadow: none
          }
        }
        .input {
          flex-grow: 1
          border: none
          outline: none
          cursor: text
          font-size: 1.6rem
          font-style: normal
          font-weight: 400
          line-height: 2.4rem
          resize: none
          background: transparent
          &::placeholder {
            transition: opacity 0.3s
            opacity: 1
          }
        }
      }
    }
    .footer {
      display: flex
      justify-content: center
      color: var(--gray-600)
      font-size: 1.2rem
      font-weight: 400
      line-height: 1.8rem
    }
  }
}

</style>

<script>
import Upload from './upload.vue'
import {create_store} from 'stores/files'
import {DOCUMENT} from 'consts/ftypes.js'

const MAX_UPLOADS = 5

export default {
  components: {Upload},
  mixins: [ModelValue(String)],
  props: {
    placeholder: {type: String, default: ''},
    description: {type: String, default: ''},
    generating: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    enableUploads: {type: Boolean, default: true},
    fade: {type: Boolean, default: true},
  },
  emits: ['stop', 'regenerate'],
  data() {
    return {
      focused: false,
      files_store: create_store(DOCUMENT.type),
      rows: 1,
      DOCUMENT
    }
  },
  computed:{
    files() {
      return this.files_store.files.toReversed()
    },
    uploads() {
      return this.files_store.uploads
    },
    processing(){
      return this.files_store.files.some(x => x.processing)
    },
    can_upload() {
      return (this.files.length + this.uploads.length) < MAX_UPLOADS 
    },
    can_push() {
      return this.value?.length && !this.processing && this.uploads.length === 0
    }
  },
  watch: {
    async value() {
      this.rows = 1
      this.$nextTick(this.updateRows)
    }
  },
  methods: {
    focus() {
      if (!this.generating && !this.disabled) {
        this.$refs.input.focus()
      }
    },
    updateRows() {
      const input = this.$refs.input
      if (input.clientHeight < input.scrollHeight && this.rows < 3) {
        this.rows += 1
        this.$nextTick(this.updateRows)
      }
    },
    onUpload() {
      const file_input = this.$refs.file
      this.files_store.upload(file_input.files[0], {
        resolve: (file, upload) => {
          this.files_store.deleteUpload(upload.id)
        }
      })
    },
    retryUpload(id) {
      this.uploads.find(upload => upload.id === id).start()
    },
    onKeyEnter(ev){
      if (ev.shiftKey) return
      ev.preventDefault()
      this.onPush()
    },
    onPush(){
      if (!this.can_push) {
        return
      }  
      this.$refs.input.blur()
      this.$emit('regenerate', this.files.map(x => x.id))
      this.files_store = create_store(DOCUMENT.type)
    },
    onStop(){
      this.$emit('stop')
    }
  }
}
</script>
