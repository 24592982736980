.feed-item {
  padding: 2rem 2.6rem 0.8rem;
  line-height: 1.8rem;
}
.feed-item .event-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed-item .event-header .left {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
.feed-item .event-header .left .time {
  font-size: 1.2rem;
  display: flex;
  color: var(--gray-900);
}
.feed-item .event-header .right {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  opacity: 0;
  transition: opacity 0.3s;
}
.feed-item .event-header .right.show {
  opacity: 1;
}
.feed-item:hover .event-header .right {
  opacity: 1;
}
.feed-item .feed-card {
  margin-top: 0.8rem;
}
.feed-item .content {
  margin-top: 0.8rem;
}
.feed-item .content .title {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8rem;
}
.feed-item .content .title .indicator {
  position: absolute;
  background: #0ba5ec;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  top: 0.5rem;
  left: -1.3rem;
}
.feed-item .content .text {
  color: var(--gray-800);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 0.7rem;
}
.feed-item .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}
.feed-item .bottom .event-button {
  flex-basis: auto;
}
.feed-item .bottom .source {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  margin-top: 0.4rem;
}
.feed-item .bottom .source .name {
  color: var(--gray-700);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  white-space: nowrap;
}
.feed-item .bottom .source .favicon {
  flex-shrink: 0;
}
