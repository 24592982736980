.success-login {
  height: 100vh;
  width: 100vw;
  background: var(--base-white);
}
.success-login .content .logo-img {
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
  background: var(--base-white);
  width: 8rem;
  height: 8rem;
  padding: 1rem;
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.success-login .content .logo-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.success-login .text {
  margin-top: 3rem;
  text-align: center;
}
.success-login .btn {
  margin-top: 4rem;
  width: 11rem;
}
.success-login .btn .timer {
  width: 2rem;
  text-align: right;
}
