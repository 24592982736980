<template>
  <div class="clipboard-icon pointer" :title="title" relative @click="onCopy()">
    <Icon :style="{opacity: copy ? 1 : 0}"
          stroke-width="1"
          class="icon" 
          icon="clipboard-check"
          stroke="var(--success-600)" 
          :width="size" 
          :height="size"
    />
    <Icon :style="{opacity: copy ? 0 : 1}"
          stroke-width="1"
          class="icon" 
          icon="clipboard" 
          stroke="var(--gray-700)" 
          :width="size" 
          :height="size"
    />
  </div>
</template>
<style lang="stylus" scoped>
.clipboard-icon {
  width: v-bind(size)
  height: v-bind(size)
  .icon {
    position: absolute
    transition: opacity 0.2s
  }
}
</style>
<script>
export default {
  props: {
    size: { 
      type: String,
      default: '1.4rem'
    },
    title: {
      type: String,
      default: 'Copy to clipboard'
    }
  },
  emits: ['copy'],
  data() {
    return {
      copy: false,
    }
  },
  methods: {

    async onCopy(){
      if (this.copy) return
      this.copy = true
      this.$emit('copy')
      await $utils.timers.wait(2000)
      this.copy = false
    },
  }
}
</script>