<template>
  <div class="chat-error">
    <div :class="[mode]">
      <Icon class="icon" height="2rem" width="2rem" icon="alert-circle" :stroke="c_icon"/>
      <div class="text">{{ text }}</div>
      <el-tooltip :disabled="disabled"
                  content="Error copied to clipboard"
                  placement="top"
                  effect="light"
                  :visible="tooltip_visible"
      >
        <Icon style="outline: none;" 
              class="icon copy pointer" 
              :stroke="c_icon" 
              icon="clipboard" 
              width="2rem"
              height="2rem" 
              @click="onCopy"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.chat-error {
  .compact {
    display: flex
    justify-content: start
    padding: 1rem 1.4rem
    background: var(--error-600)
    border-radius: 8px 8px 8px 8px

    .text {
      font-size: 1.6rem
      font-weight: 400
      line-height: 2.4rem
      color: white
    }

    .icon {
      margin-right: 0.8rem
      margin-top: 0.1rem
    }
  }

  .expanded
  .onboarding {
    display: flex
    justify-content: start
    border: 0.1rem solid var(--error-600)
    border-radius: 0.8rem
    background: transparent
    padding: 1.6rem
    margin-bottom: 1.2rem
    
    .text {
      font-size: 1.6rem
      font-weight: 500
      line-height: 2.4rem
      color: var(--error-600)
    }

    .icon {
      margin-right: 1rem
    }
  }

  .icon {
    margin-top: 0.2rem
    min-width: 2rem
    
    &.copy {
      margin-right: 0
      margin-left: auto
    }
  }
}
</style>

<script>
export default {
  props: {
    chat_id: {type: String, required: true},
    data: {type: Object, required: true},
    mode: {type: String, required: true},
    disabled: {type: Boolean, default: false}
  },
  data(){
    return {
      tooltip_visible: false
    }
  },
  computed: {
    text() {
      return this.data.text
    },
    c_icon () {
      return this.mode === 'compact' ? 'white' : 'var(--error-600)'
    }
  },
  methods: {
    async onCopy(){
      await $utils.copyText($utils.format.json({
        host: window.location.host,
        chat_id: this.chat_id,
        ...this.data
      }))
      
      this.tooltip_visible = true
      await $utils.timers.wait(3000)
      this.tooltip_visible = false
    },
  }
}
</script>