@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
.collapsed .navbar-item .title,
.collapsed .navbar-item .count {
  display: none;
}
.navbar-item {
  transition: background 0.2s;
  display: flex;
  height: 4rem;
  border-radius: 0.8rem;
  padding: 0.8rem 1rem;
}
.navbar-item:hover {
  background: var(--gray-100);
}
.navbar-item:hover .count span {
  border-color: var(--gray-200);
  background: var(--gray-50);
}
.navbar-item.active {
  background: var(--gray-100);
}
.navbar-item.active .count span {
  border-color: var(--gray-200);
  background: var(--gray-50);
}
.navbar-item .link {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 1;
}
.navbar-item .link .title {
  animation: fade-in 0.9s;
  font-size: v-bind(font_size);
  font-weight: v-bind(font_weight);
  color: var(--gray-700);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.navbar-item .link .title.smaller {
  font-weight: 400;
  font-size: 1.4rem;
}
.navbar-item .count {
  display: flex;
}
.navbar-item .count .label {
  padding: 0.6rem;
  border: 1px solid #eaecf0;
  border-radius: 500rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #344054;
  min-width: 2.4rem;
  min-height: 2.4rem;
  text-align: center;
}
.navbar-item .count .arrow.opened {
  transform: rotate(180deg);
}
.navbar-item .count span {
  border-color: var(--gray-200);
  background: var(--gray-50);
}
