.ai-editor .segment {
  margin-top: 1rem;
  padding: 1.6rem 2.4rem;
}
.ai-editor .head {
  display: flex;
  justify-content: space-between;
  padding: 0 2.4rem;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
}
.ai-editor .head .title {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #000;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  user-select: none;
}
.ai-editor .head .title .icon {
  width: 1.6rem;
  height: 1.6rem;
}
.ai-editor .head .actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.ai-editor .head .actions [disabled]:not([disabled="false"]) {
  opacity: 0.5;
}
.ai-editor .head .actions .return {
  opacity: 0.4;
}
.ai-editor .head .actions .return.active {
  opacity: 1;
  cursor: pointer;
}
