.segment {
  display: flex;
  gap: 1.2rem;
  padding: 1.2rem;
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.segment .content {
  min-width: 0;
}
.segment .content .title {
  color: var(--gray-900);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}
.segment .content .text {
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.segment .image img {
  display: block;
  object-fit: contain;
  height: 100%;
  width: v-bind(img_width);
}
