.chats-dropdown-edit {
  --el-dropdown-menuItem-hover-color: var(--gray-700);
  padding: 0;
  background: #fffdfb;
  box-shadow: 0px 12px 16px -4px rgba(16,24,40,0.08), 0px 4px 6px -2px rgba(16,24,40,0.03);
}
.chats-dropdown-edit .title {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--gray-700);
  border-bottom: 0.1rem solid var(--gray-200);
  padding: 1.2rem 1.6rem;
}
.chats-dropdown-edit .item {
  color: var(--gray-700);
  gap: 1rem;
  padding: 1.2rem 1.6rem;
}
