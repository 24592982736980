<template>
  <div class="ip-whitelist">
    <SettingsIcon class="icon" :disabled="loading"/>
    <div class="info" :disabled="loading">
      <b>IP Whitelist:</b>
    </div>
    <div class="data" relative>
      <TextArea v-model.trim="data"
                :disabled="loading" :maxlength="100000" class="textarea"
                placeholder="118.226.82.231/32&#10;10.0.0.0/8&#10;172.16.0.0/16"
      />
      <Loader v-if="loading"/>
    </div>

    <div class="action" :disabled="loading">
      <Button primary :disabled="!can_send" @click="onSave">
        Apply Whitelist
      </Button>
      <Button :disabled="!data" plain @click="onClear">
        Remove Whitelist
      </Button>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.ip-whitelist {
  display: flex
  flex-direction: column
  align-items: center
  height: 100%
  overflow: overlay

  .icon {
    margin-top: 3rem
    margin-bottom: 2.5rem
  }

  .info {
    margin-top: 0.5rem
  }

  .action {
    display: flex
    justify-content: flex-end
    gap: 2rem
  }

  .data {
    flex-grow: 1
    padding: 1rem
    min-width: 65rem
    min-height: 30rem
    max-width: 75rem
    max-height: 60rem
    display: flex
    height: 0px
    overflow-y: overlay
    .textarea {
      flex-grow: 1
    }
  }
}
</style>
<script setup>
import SettingsIcon from './settings-icon.vue'
</script>

<script>
import {Rest} from 'utils'
import $settings from 'utils/settings'
import {SETTINGS_IP_WHITELIST} from 'consts/settings'

export default {
  data() {
    return {
      rest: new Rest(),
      loading: false,
      data: undefined,
      prev_data: undefined
    }
  },
  computed: {
    can_send() {
      return this.data !== this.prev_data
    }
  },
  async mounted() {
    const data = await $settings.get(SETTINGS_IP_WHITELIST)
    this.data = data || ''
    this.prev_data = this.data
    this.loading = false
  },
  methods: {
    async onSave() {
      this.loading = true
      try {
        if (!this.data) {
          await $settings.remove(SETTINGS_IP_WHITELIST)
        } else {
          await $settings.put(SETTINGS_IP_WHITELIST, this.data)
        }
        $utils.toasts.message({
          message: 'Whitelist successfully applied',
          showImage: 'assets/check-circle-green.svg',
          class: 'success',
        })
        this.prev_data = this.data
      } catch (e) {
        $utils.toasts.error(e)
      }
      this.loading = false
    },
    onClear() {
      this.data = ''
      if (this.prev_data) this.onSave()
    }
  },
}
</script>