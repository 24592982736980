<template>
  <div class="link-saver">
    <Input v-model="url" v-read:valid="valid" type="url" :disabled="limit"/>
    <div class="controls">
      <Button :disabled="!url" small @click="url = ''">Discard</Button>
      <Button :disabled="!can_add" small primary @click="addLink()">Add</Button>
    </div>
    <div class="added-links">
      <Input v-for="(_, i) in value" :key="i" v-model="value[i]" type="url" class="link">
        <template v-slot:custom_icon>
          <div class="input-controls">
            <Popconfirm width="max-content"
                        confirm-button-text="Yes"
                        cancel-button-text="No"
                        title="Are you sure you want to delete this source?"
                        :teleported="false"
                        @confirm="onDelete(i)"
            >
              <Icon class="pointer" icon="trash-04" stroke="var(--gray-500)"/>
            </Popconfirm>
          </div>
        </template>
      </Input>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.link-saver {
  .controls {
    margin-top: 1.2rem
    display: flex
    justify-content: flex-end
    gap: 0.8rem
  }
  .added-links {
    margin-top: 2.4rem
    display: flex
    flex-direction: column
    gap: 0.8rem
    .link {
      :deep() {
        .ui.left.label.url
        input {
          color: var(--gray-500)
        }
        input {
          pointer-events: none
          background: var(--gray-50)
        }
        .custom.right {
          right: 0.1rem
        }
      }
      .input-controls {
        border-bottom-right-radius: 0.8rem
        border-top-right-radius: 0.8rem
        border-left: 0.1rem solid var(--gray-300)
        padding: 0.9rem
        gap: 0.9rem
        display: flex
        background: var(--base-white)
      }
    }
  }
}
</style>
<script>
const DEF_LEN = 5
export default {
  mixins: [ModelValue(Array)],
  props: {
    maxLength: {
      type: Number, default: DEF_LEN
    }
  },
  emits: ['change'],
  data(){
    return {
      url: '',
      valid: true
    }
  },
  computed: {
    included(){
      return this.value.map(link => link.replace('https://', ''))
        .includes(this.url?.replace('https://', '') || '')
    },
    limit(){
      return this.value.length >= this.maxLength
    },
    can_add(){
      return !!this.url && this.valid && !this.included && !this.limit
    }
  },
  methods: {
    addLink(){
      this.value.push(this.url)
      this.url = ''
      this.$emit('change', this.value)
    },
    onDelete(i){
      this.value.splice(i, 1)
      this.$emit('change', this.value)
    }
  }
}
</script>
