<template>
  <div class="search-input" relative>
    <div v-if="!show" class="row-hvcenter pointer open-icon" :disabled="disabled">
      <Icon icon="search"
            stroke="var(--gray-700)" 
            width="2rem" 
            height="3.2rem" 
            @click="show = true"
      />
    </div> 
    <div ref="wrapper" class="search-wrapper" :class="{show}" @transitionend.self="onTransitionEnd">
      <Input ref="search"
             v-model="search"
             :minlength="minlength"
             :disabled="disabled"
             :style="{width}" 
             class="search" 
             icon="search" 
             type="text" 
             :spellcheck="false"
             :footer="false"
             :right-icon="search ? 'x-close' : ''"
             @right-icon="onClean()"
             @blur="do_blur()"
      />
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.search-input {
  min-width: 2rem
  min-height: 3.2rem
  .open-icon {
    position: absolute
    right: 0
  }
  .search-wrapper {
    width: v-bind(c_width)
    overflow: hidden
    transition: width 200ms
    position: absolute
    right: 0
    .search {
      :deep() {
        input {
          padding-top: 0.3rem
          padding-bottom: 0.3rem
        }
      }
    }
    &.show {
      margin: -0.4rem
      padding: 0.4rem
    }
  }
}
</style>
<script>

export default {
  props: {
    width: {
      type: String,
      default: '32rem'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minlength: {
      type: Number,
      default: 3
    }
  },
  emits: ['search'],
  data() {
    return {
      show: false,
      search: '',
      do_search: $utils.timers.debounce(this.makeSearch, 300),
      do_blur: $utils.timers.debounce(this.onBlur, 1000)
    }
  },
  computed: {
    tr_search() {
      return this.search?.trim() || ''
    },
    c_width() {
      return this.show ? `calc(${this.width} + 0.8rem)` : '0'
    }
  },
  watch: {
    tr_search() {
      this.do_search()
    },
  },
  methods: {
    async makeSearch(){
      if (this.tr_search.length > 0 && this.tr_search.length < this.minlength) return
      this.$emit('search', this.tr_search)
    },
    onBlur(){
      if (this.$refs.search.isFocused() || this.disabled || this.tr_search) return
      this.show = false
    },
    onTransitionEnd(ev){
      if (ev.propertyName === 'width' && this.show) 
        this.focus()
    },
    focus(){
      this.$refs.search.focus()
    },
    clearSearch(){
      this.search = ''
    },
    onClean(){
      this.clearSearch()
      this.focus()
    }
  }
}
</script>