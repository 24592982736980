.ai-cards {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.ai-cards .item {
  padding: 1.8rem 1.8rem 2.4rem;
}
.ai-cards :deep() .md-content {
  color: var(--gray-900);
  line-height: 2.3rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.ai-cards :deep() .md-content>* {
  line-height: inherit;
  margin-top: 0;
  display: block;
}
.ai-cards :deep() .md-content>*:not(:last-child) {
  margin-bottom: 0.4rem;
}
.ai-cards :deep() .md-content>*:last-child {
  margin-bottom: 0;
}
.ai-cards :deep() .md-content>h5 {
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--gray-700);
}
.ai-cards :deep() .md-content>h5:not(:first-child) {
  margin-top: 1.2rem;
}
.ai-cards :deep() .md-content ul,
.ai-cards :deep() .md-content ol {
  margin-left: 0;
  padding-left: 2rem;
}
.ai-cards :deep() .md-content ul li:not(:last-child),
.ai-cards :deep() .md-content ol li:not(:last-child) {
  margin-bottom: 0.4rem;
}
.ai-cards :deep() .md-content ul li:first-child,
.ai-cards :deep() .md-content ol li:first-child {
  margin-top: 0.4rem;
}
.ai-cards :deep() .md-content ul p,
.ai-cards :deep() .md-content ol p {
  margin: 0;
}
.ai-cards :deep() .md-content b {
  font-weight: 500;
}
.ai-cards :deep() .md-content table {
  width: 100%;
  border-radius: 0.4rem;
  border-collapse: separate;
  border-spacing: 0;
}
.ai-cards :deep() .md-content table tr th,
.ai-cards :deep() .md-content table tr td {
  border-right: 0.1rem solid var(--gray-200);
  border-bottom: 0.1rem solid var(--gray-200);
  padding: 1.2rem 1.4rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.ai-cards :deep() .md-content table tr th {
  background: #f7f5f3;
  border-top: 0.1rem solid var(--gray-200);
  font-weight: 600;
  color: var(--gray-900);
}
.ai-cards :deep() .md-content table tr td {
  color: var(--gray-900);
  font-weight: 400;
}
.ai-cards :deep() .md-content table tr th:first-child,
.ai-cards :deep() .md-content table tr td:first-child {
  border-left: 0.1rem solid var(--gray-200);
}
.ai-cards :deep() .md-content table tr:first-child th:first-child {
  border-top-left-radius: 0.4rem;
}
.ai-cards :deep() .md-content table tr:first-child th:last-child {
  border-top-right-radius: 0.4rem;
}
.ai-cards :deep() .md-content table tr:last-child td:first-child {
  border-bottom-left-radius: 0.4rem;
}
.ai-cards :deep() .md-content table tr:last-child td:last-child {
  border-bottom-right-radius: 0.4rem;
}
.ai-cards :deep() .md-content table {
  padding: 0.6rem 0;
}
.ai-cards :deep() .md-content table tr th,
.ai-cards :deep() .md-content table tr td {
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
  line-height: 1;
}
