<template>
  <div class="runner">
    <div class="caption">{{ caption }}</div>
    <div v-loading="loading" class="info">
      <div>
        Task ID: {{ task_id || 'N/A' }}
      </div>
      <div>
        <span :class="{err: task_status === 'failed'}">
          Status: {{ task_status }}
        </span>
        <el-tooltip v-if="task_error" 
                    content="Error copied to clipboard"
                    placement="top"
                    effect="light"
                    :visible="show_copy_ttip"
        >
          <Icon stroke="var(--gray-700)" 
                icon="clipboard"
                stroke-width="1"
                width="1.6rem"
                height="1.6rem"
                class="pointer copy"
                @click="copyError()"
          />
        </el-tooltip>
      </div>
      <div>
        Created at: {{ task_date }}
      </div>
    </div>
    <Button primary small :disabled="!can_run" @click="onRunTask">
      <Loader v-if="running" inline mini color="white"/>
      Run Task
    </Button>
  </div>
</template>

<style lang="stylus" scoped>
.runner {
  .caption {
    font-size: 1.5rem
    font-weight: 500
    margin-bottom: 0.5rem
  }

  .info {
    max-width: fit-content
    display: flex
    flex-direction: column
    gap: 0.3rem
    margin-bottom: 1rem

    & > div {
      font-size: 1.4rem
      display: flex
      flex-direction: row
      align-items: center

      .err {
        color: var(--error-600)
      }

      .copy {
        margin-left: 0.2rem
      }
    }
  }
}
</style>

<script>
import {settings, Tasks} from 'utils'

export default {
  props: {
    caption: {type: String, required: true},
    runnerId: {type: String, required: true},
    runner: {type: Function, required: true}
  },
  emits: ['run', 'end'],
  data() {
    return {
      tasks: new Tasks(),
      loading: true,
      running: false,
      task_setting: undefined,
      task_data: undefined,
      show_copy_tip: false
    }
  },
  computed: {
    setting_id() {
      return `${this.runnerId}_task`
    },
    task_id() {
      return this.task_setting ? this.task_setting.id : undefined
    },
    task_status() {
      return this.task_data ? this.task_data.status : 'N/A'
    },
    task_date() {
      return this.task_data ? $utils.format.datetime(this.task_data.created_at) : 'N/A'
    },
    task_error() {
      return this.task_data ? this.task_data.error : undefined
    },  
    can_run() {
      return !this.loading && !this.running
    },
  },
  async mounted() {
    this.loading = true
    this.task_setting = await settings.get(this.setting_id)
    this.loading = false

    this.running = true
    await this.waitTask()
    this.running = false
  },
  methods: {
    async waitTask() {
      if (this.task_setting) {
        try {
          this.task_data = await this.tasks.get(this.task_setting.id)
          this.task_data = await this.tasks.waitTask(this.task_setting.id)
        }
        catch(err) {
          if (err.http_code === 404) {
            // task might be missing if it was running long ago
            // that's ok, tasks are kept in db only for 1 month
            return
          }
          throw err
        }
      }
    },
    async onRunTask() {
      this.loading = true

      // runner can return no task
      let task_id = await this.runner(this.tasks)
      if (task_id) {
        this.task_setting = undefined
        this.task_data = undefined
        this.task_setting = {id: task_id}
        await settings.put(this.setting_id, this.task_setting)
      }
      this.loading = false

      if (task_id) {
        this.running = true
        await this.waitTask()
        this.running = false
      }
    },
    async copyError() {
      await $utils.copyText($utils.format.json(this.task_error))
      this.show_copy_tip = true
      await $utils.timers.wait(2000)
      this.show_copy_tip = false
    }
  }
}
</script>