<template>
  <div class="settings-frame">
    <div class="header">
      <div class="left">
        <div class="title">{{ title }}</div>
        <div class="description">
          <slot v-if="$slots.description" name="description"></slot>
          <span v-else>{{ description }}</span>
        </div>
      </div>
      <div v-if="$slots.right" class="right">
        <slot name="right"></slot>
      </div>
    </div>

    <div class="content">
      <slot/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.settings-frame {
  display: flex
  flex-direction: column
  min-height: 0
  overflow: overlay
  // TODO make shared
  margin: 0 -0.4rem
  padding: 0 0.4rem

  .header {
    display: flex
    flex-direction: row
    justify-content: space-between

    .title {
      color: var(--gray-900)
      font-size: 1.8rem
      font-weight: 500
      line-height: 2.8rem
    }
    .description {
      margin-top: 0.4rem
      color: var(--gray-600)
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
    }

    .right {
      display: flex
      justify-items: center
    }
  }

  .content {
    flex-grow: 1
    margin-top: 2.4rem
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''  
    }
  }   
}
</script>