.onboarding-modal {
  display: flex;
  gap: 1.6rem;
}
.onboarding-modal .icon .circle-bg {
  margin: -2rem -20rem -20rem -1.6rem;
  padding: 2rem 20rem 20rem 1.6rem;
  background: url("assets/bg-pattern-decorative.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -18.4rem -18rem;
  background-size: 170%;
}
.onboarding-modal .icon .circle-bg .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  background: var(--brand-50);
  border-radius: 50%;
}
.onboarding-modal .text .header {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.2rem;
}
.onboarding-modal .text .info {
  margin-top: 0.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
}
