<template>
  <Segment class="segment">
    <div ref="image" class="image">
      <img :src="card.image" @load="onImageLoaded"/>
    </div>
    <div class="content">
      <div v-if="card.title" class="title">{{ card.title }}</div>
      <div v-if="card.text" class="text">{{ card.text }}</div>
    </div>
  </Segment>
</template>
<style lang="stylus" scoped>
.segment {
  display: flex
  gap: 1.2rem
  padding: 1.2rem
  color: var(--gray-900)
  font-size: 1.4rem
  font-weight: 400
  line-height: 2rem
  .content {
    min-width: 0
    .title {
      color: var(--gray-900)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
    }
    .text {
      color: var(--gray-700)
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
    }
  }
  .image {
    img {
      display: block
      object-fit: contain
      height: 100%
      width: v-bind(img_width)
    }
  }
}
</style>
<script>
const MAX_WIDTH = 150
export default {
  props: {
    card: {type: Object, required: true}
  },
  data() {
    return {
      img_width: '0px'
    }
  },
  methods: {
    onImageLoaded() {
      const height = this.$refs.image.clientHeight
      this.img_width = `${height <= MAX_WIDTH ? height : MAX_WIDTH}px`
    }
  }
}
</script>