<template>
  <div v-if="!first" class="date-placeholder">
    Select dates
  </div>
  <div v-else class="date-input">
    {{ first }} {{ last }}
  </div>
  <Divider m="0 -1.6rem 0"/>
  <Calendar v-model="dates" inline placeholder="Select dates" selection-mode="range"/>
</template>

<style lang="stylus" scoped>
.date-input {
  font-size: 1.4rem
  font-weight: 400
  line-height: 2rem
  color: var(--gray-600)
  padding: 0.9rem 1rem
  cursor: default
}
.date-placeholder {
  @extend .date-input
  color: var(--gray-400)
}
</style>
<script>
import {ctx_mixin} from './filter-defs'

export default {
  mixins: [ctx_mixin],
  data() {
    return {
      // TODO: primevue 3 version has a bug with modelvalue, 
      // it works normally only with the initial parameter null. 
      // we will need to rewrite the code after the update.
      dates: null,
    }
  },
  computed: {
    first(){
      return this.dates && this.dates[0] && $utils.format.date(this.dates[0])
    },
    last(){
      return this.dates && this.dates[1] && `- ${$utils.format.date(this.dates[1])}`
    }
  },
  watch: {
    prev(){
      this.dates = this.prev.length ? this.prev : null
    },
    dates(){
      if (this.dates?.some(d => !d)) return
      this.next = this.dates || []
    }
  },
}
</script>