<template>
  <div v-click-outside="onClickOutside" class="popover_wrapper">
    <el-popover ref="pp" 
                placement="top-end" 
                popper-class="popover_container"
                width="max-content"
                :visible="visible" 
                :popper-options="options" 
    >
      <!-- TOGGLE BUTTON -->
      <template v-slot:reference>
        <div ref="toggle" class="popover_toggle pointer" :disabled="disabled" @click="show">
          <Icon external icon="stars-02-brand" width="2rem" height="2rem" stroke="var(--brand-600)" stroke-width="2"/>
        </div>
      </template>

      <!-- POPOVER -->
      <!-- HEADER -->
      <div class="popover_header">
        <div class="left">
          <Icon icon="stars-02" width="2rem" height="2rem" stroke="var(--brand-600)" stroke-width="2"/>
          <div class="title">AI content suggestion</div>
        </div>
        <div class="close pointer">
          <Icon icon="x" width="2rem" height="2rem" stroke="var(--gray-500)" @click="hide($event)"/>
        </div>
      </div>
      <!-- CONTENT -->
      <div class="popover_content" :style="{'margin-bottom': content_margin}">
        <div v-if="task_error" class="error" v-html="errmsg"/>
        <Loader v-else-if="loading" inline large/>
        <div v-else class="slot-content">
          <slot/>
        </div>
      </div>
      <!-- CONTROLS -->
      <div class="popover_controls" :disabled="loading">
        <slot name="controls"/>
      </div>
    </el-popover>
  </div>
</template>

<style lang="stylus">
.popover_container.el-popover {
  display: flex
  flex-direction: column
  --el-popover-bg-color: var(--base-white)
  --el-popover-font-size: var(--el-font-size-base)
  --el-popover-border-color: var(--gray-200)
  --el-popover-padding: 1.6rem
  --el-popover-padding-large: 1.6rem
  --el-popover-title-font-size: 16px
  --el-popover-border-radius: 0.8rem
  --el-box-shadow-light: 0 0.2rem 0.8rem 0 rgba(16, 24, 40, 0.1), 0 1.6rem 1.6rem 0 rgba(16, 24, 40, 0.07)
  .el-popper__arrow
  .el-popper__arrow:before {
    width: 1.5rem
    height: 1.5rem
  }
  &[data-popper-placement^=top]>.el-popper__arrow {
    bottom: -0.9rem
  }
  &[data-popper-placement^=bottom]>.el-popper__arrow {
    top: -0.9rem
  }
}
</style>

<style lang="stylus" scoped>  
.popover_toggle {
  border-left: 0.1rem solid var(--gray-300)
  padding-left: 0.5rem
}

.popover_container {
  .popover_header {
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin-bottom: 1.4rem
    width: 100%

    .left {
      display: flex
      flex-direction: row
      align-items: center
    }
    
    .title {
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 600
      white-space: nowrap !important
      padding: 0 1.6rem
    }
  }
  
  .popover_content {
    padding: 0 3.6rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: 6rem

    .error {
      text-align: center
      color: var(--error-500)
      font-size: 1.4rem
      font-weight: 500
      white-space: nowrap
    }
  }

  .popover_controls {
    padding: 0 3.6rem
  }
}
</style>
<script>
import {TaskError} from 'utils/errors'
import Tasks from 'utils/tasks'

export default {
  props: {
    parent: {type: Node, default: document.body},
    apiUrl: {type: String, required: true, default: undefined},
    apiArgs: {type: Function, required: true, default: undefined},
    expectedHeight: {type: Number, default: undefined, required: false},
    can_genrate: {type: Boolean, default: true, required: true},
    content_margin: {type: String, default: '1.4rem', required: false}
  },
  emits: ['generated'],
  data() {
    return {
      tasks: new Tasks(),
      visible: false,
      bottom: false,
      task_id: undefined,
      task_error: undefined,
      task_result: undefined,
      options: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [75, 15]
            }
          },
        ],
      }
    }
  },
  computed: {
    loading() {
      return this.task_error || this.task_result ? false : true
    },
    api_args() {
      return this.apiArgs ? this.apiArgs() : undefined
    },
    z_index() {
      return this.visible ? 12 : 0
    },
    errmsg() {
      if (this.task_error.code === 1000) {
        return 'Not enough information to generate AI content.<br>Fill fields above and try again.'
      }
      return 'Failed to generate AI content.<br>Please try again.'
    },
    disabled(){
      return !this.api_args || !this.can_genrate
    }
  },
  methods: {
    show(){
      if (this.visible) { 
        return
      }

      if (!this.task_id || this.task_error) {
        this.generate()
      }
           
      this.visible = true
    },
    onClickOutside(ev){
      if (ev && this.$refs.pp?.popperRef?.contentRef?.contains(ev.target)) {
        return
      }
      this.hide()
    },
    hide(){
      this.visible = false
    },
    async generate() {
      this.task_id = undefined
      this.task_error = undefined
      this.task_result = undefined
      try {
        this.task_id = await this.tasks.start(this.apiUrl, this.api_args)
        this.task_result = await this.tasks.waitResult(this.task_id)
        this.$emit('generated', this.task_result)
      }
      catch (err) {
        this.task_error = err instanceof TaskError ? err.task_error : err
      }
      finally {
        // it is necessary to redraw the popup (so that the arrow does not move when adding content).
        this.$nextTick(() => dispatchEvent(new CustomEvent('scroll')))
      }
    }
  }
}
</script>