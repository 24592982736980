.feed-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.feed-inner .empty {
  height: calc(100% - 0.8rem);
}
.feed-inner .head {
  top: 0;
  padding: 1rem 2rem 1rem 1.6rem;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  color: var(--gray-900);
  border-bottom: 0.1rem solid var(--gray-200);
}
.feed-inner .head .controls {
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 3.5rem;
  cursor: default;
}
.feed-inner .head .controls .title {
  flex-grow: 1;
}
.feed-inner .head .controls .active {
  color: var(--brand-500);
  fill: var(--brand-500);
  stroke: var(--brand-500);
}
.feed-inner .head .icon {
  margin-left: auto;
}
.feed-inner .refresh {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 7.1rem;
  right: 0;
  left: 0;
  width: max-content;
  margin: auto;
  border-radius: 999.9rem;
  background: var(--blue-500);
  border-color: transparent;
  padding: 0.8rem 2.4rem;
  box-shadow: 0px 4px 8px -2px rgba(16,24,40,0.2), 0px 2px 4px -2px rgba(16,24,40,0.1);
  animation: appear 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transform: translateY(0rem);
}
.feed-inner .refresh.loading-new {
  transform: translateY(-11.9rem);
}
@-moz-keyframes appear {
  0% {
    transform: translateY(-11.9rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@-webkit-keyframes appear {
  0% {
    transform: translateY(-11.9rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@-o-keyframes appear {
  0% {
    transform: translateY(-11.9rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@keyframes appear {
  0% {
    transform: translateY(-11.9rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
.feed-inner .feed-content {
  flex-grow: 1;
  transition: background 0.2s, width 0.2s;
  border-top: none;
  border-bottom: none;
  overflow-y: overlay;
}
.feed-inner .feed-content .bell {
  margin: 0 auto;
}
.feed-inner .feed-content .feed-items {
  display: flex;
  flex-direction: column;
}
.feed-inner .feed-content .feed-items .feed-item {
  transition: grid-template-rows 300ms, opacity 200ms;
  grid-template-rows: 1fr;
  opacity: 1;
}
.feed-inner .feed-content .feed-items .feed-item.list-enter-active,
.feed-inner .feed-content .feed-items .feed-item.list-leave-active {
  grid-template-rows: 1fr;
  opacity: 1;
}
.feed-inner .feed-content .feed-items .feed-item.list-enter-from,
.feed-inner .feed-content .feed-items .feed-item.list-leave-to {
  grid-template-rows: 0fr;
  opacity: 0;
}
.feed-inner .feed-content .feed-items >:last-child {
  margin-bottom: 1rem;
}
.feed-inner .feed-content .divider {
  padding: 0 1rem;
}
.feed-inner .feed-content .divider > div {
  margin: 1.2rem 0;
}
.feed-inner .feed-content .divider .text {
  color: var(--gray-500);
  font-weight: 400;
}
