.add-company[disabled] {
  opacity: 0.8;
}
.companies-table .follow,
.companies-table .unfollow {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}
.companies-table .follow {
  color: var(--brand-700);
}
.companies-table .unfollow {
  color: var(--gray-600);
}
.companies-table .company {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.companies-table .company .company-avatar {
  flex-shrink: 0;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}
.companies-table .company .company-name {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}
.companies-table .actions {
  display: flex;
  justify-content: flex-end;
  gap: 1.4rem;
}
