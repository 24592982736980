.collapse-wrapper {
  display: grid;
  overflow: hidden;
}
.collapse-wrapper.horizontal {
  grid-template-columns: 0fr;
  transition: grid-template-columns 200ms;
}
.collapse-wrapper.horizontal.open {
  grid-template-columns: 1fr;
}
.collapse-wrapper.horizontal .collapse-inner {
  min-width: 0;
  overflow: hidden;
}
.collapse-wrapper.vertical {
  grid-template-rows: 0fr;
  transition: grid-template-rows 200ms;
}
.collapse-wrapper.vertical.open {
  grid-template-rows: 1fr;
}
.collapse-wrapper.vertical .collapse-inner {
  min-height: 0;
  overflow: hidden;
}
