<template>
  <Skeleton class="skeleton" :unstyled="false"/>
</template>

<style lang="stylus" scoped>
.skeleton {
  background-color: #E9ECEF
  border-radius: 0.4rem
  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))
  }
}
</style>

<script setup>
import Skeleton from 'primevue/skeleton'
</script>