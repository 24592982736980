.empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.empty-wrapper.bordered {
  border-radius: 1.2rem;
  border: 0.1rem solid var(--blue-400);
  box-shadow: var(--box-shadow-indigo);
}
.empty-wrapper .circles {
  margin: -240px -240px -240px -240px;
  padding: 240px 240px 240px 240px;
  background: v-bind(c_bg);
  background-size: v-bind(bgSize);
}
.empty-wrapper .empty-icon {
  width: v-bind(circleSize);
  height: v-bind(circleSize);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}
.empty-wrapper .text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--text-primary);
  margin-top: 1.6rem;
  white-space: nowrap;
}
.empty-wrapper .sub-text {
  margin-top: 0.4rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--text-secondary);
}
.empty-wrapper .link {
  margin-top: 0.4rem;
  font-size: 1.4rem;
}
.empty-wrapper .actions {
  margin-top: 2.4rem;
  display: flex;
  gap: 1.2rem;
}
