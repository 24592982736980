.modal-dialog {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  background-color: transparent;
}
.modal-dialog .content {
  padding-top: 4px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.modal-dialog .controls {
  display: flex;
  justify-content: v-bind(ctrls_justify);
  gap: 0.5em;
}
.modal-dialog .controls > * {
  flex-basis: 0;
  flex-grow: 1;
  white-space: nowrap;
}
.modal-dialog .controls :slotted(button) {
  min-width: 8em !important;
}
