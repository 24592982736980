<template>
  <Segment class="news-item pointer" @click="onClick">
    <div class="image">
      <el-image class="el-image" fit="cover" :src="image_url">
        <template v-slot:error>
          <el-image class="placeholder" fit="cover" src="assets/news-placeholder.png"/>
        </template>
        <template v-slot:placeholder>
          <Skeleton width="100%" height="16.3rem" border-radius="0.8rem"/>
        </template>
      </el-image>
    </div>
    <div class="content">
      <div v-if="preview.title" class="title" v-html="preview.title"/>
      <div class="meta">
        <div v-if="date" class="date">
          {{ date }}
        </div>
        <Icon v-if="source && date" icon="dot" class="divider" width="0.7rem" height="0.8rem"/> 
        <div class="source">
          <span v-if="source.name" class="ellipsis">
            {{ source.name }}
          </span>
          <Favicon v-if="source.icon_url" :src="source.icon_url" size="1.8rem"/>
        </div>
      </div>
    </div>
  </Segment>
</template>
<style lang="stylus" scoped>
.news-item {
  display: flex
  gap: 1.2rem
  padding: 1.6rem
  line-height: 100%
  transition: border-color 0.3s, background 0.3s, box-shadow 0.3s
  &:hover {
    border-color: var(--gray-300)
    background: linear-gradient(0deg, #FFFDFB 0%, #FFFDFB 100%), var(--base-white)
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)
  }
  .image {
    height: 100%
    .el-image {
      width: 12rem
      height: 6.6rem
      border-radius: 0.8rem
      display: block
      .placeholder {
        height: 100%
        width: 100%
      }
    }
  }

  .content {
    display: flex
    flex-direction: column
    gap: 0.6rem
    .title {
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden
      text-overflow: ellipsis
    }
    .meta {
      display: flex
      gap: 0.8rem
      justify-content: flex-start
      align-items: center
      .date {
        font-size: 1.4rem
        font-weight: 400
        line-height: 2rem
        white-space: nowrap
      }
      .source {
        display: flex
        min-width: 0
        align-items: center
        gap: 0.6rem
        color: var(--gray-900)
        font-size: 1.4rem
        font-weight: 400
        line-height: 2rem
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    date(){
      if (this.data.relative_date) return this.data.relative_date
      if (this.data.date) return $utils.format.date(this.data.date)
      return undefined
    },
    source(){
      return this.data.source
    },
    preview(){
      return this.data.preview
    },
    image_url(){
      return this.preview?.image_url
    }
  },
  methods: {
    onClick() {
      window.open(this.source.url, '_blank')
    }
  }
}
</script>