<template>
  <div class="badge" :class="{brand, gray, indigo, success, error, blue, color, border, small, rounded}">
    <Icon v-if="!!icon" :icon="icon" class="icon"/>
    <slot/>
  </div>
</template>

<style lang="stylus" scoped>
.badge {
  font-size: 1.2rem
  line-height: 1.8rem

  font-weight: 500
  border-radius: 0.4rem
  padding: 0.3rem 1.1rem
  display: flex
  align-items: center
  gap: 0.4em
  white-space: nowrap
  border-color: var(--primary-200)
  background: var(--primary-50)
  color: var(--primary-700)
  width: max-content

  &.rounded {
    border-radius: 1.6rem
  }

  &.small {
    padding: 0 0.6rem
  }

  &.border {
    border-width: 0.1rem
    border-style: solid
  }

  &.color {
    color: v-bind(color) !important
  }
  
  &.brand {
    border-color: var(--brand-200)
    background: var(--brand-50)
    color: var(--brand-700)
  }

  &.indigo {
    border-color: var(--indigo-200)
    background: var(--indigo-100)
    color: var(--indigo-700)
  }

  &.gray {
    border-color: var(--gray-200)
    background: var(--gray-50)
    color: var(--gray-700)
  }

  &.error {
    border-color: var(--error-200)
    background: var(--error-50)
    color: var(--error-700)

    .icon:deep() * {
      stroke: var(--error-700) !important
    }
  }

  &.success {
    border-color: var(--success-200)
    background: var(--success-50)
    color: var(--success-700)

    .icon:deep() * {
      stroke: var(--success-700) !important
    }
  }

  &.blue {
    background-color: #B2DDFF
    color: var(--text-secondary)
  }

  .icon {
    width: 1.4rem
    height: 1.4rem

    &:deep() * {
      stroke: v-bind(iconColor) !important
    }
  }

  &:not(.border) {
    border-radius: 0.4rem !important
  }
}
</style>

<script>
export default {
  props: {
    color: {type: String, default: ''},
    icon: {type: String, default: ''},
    border: {type: Boolean, default: true},
    iconColor: {type: String, default: 'var(--primary-700)'},
    rounded: {type: Boolean, default: true},

    brand: {type: Boolean, default: false},
    gray: {type: Boolean, default: false},
    indigo: {type: Boolean, default: false},
    error: {type: Boolean, default: false},
    success: {type: Boolean, default: false},
    blue: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
  }
}
</script>