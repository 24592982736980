.ai-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.ai-chat .scroller {
  flex-grow: 1;
  min-height: 0;
  width: 100%;
}
.ai-chat.compact .entry {
  padding: 0 4.8rem;
  margin-top: 2.4rem;
}
.ai-chat.compact .entry:last-child {
  margin-bottom: 2.4rem;
}
.ai-chat.expanded .entry {
  max-width: 90rem;
  margin: 0 auto;
  padding-left: 1.4rem;
  padding-right: 2rem;
}
.ai-chat.expanded .entry:last-child:not(.chat-error) {
  padding-bottom: 2.4rem;
}
.ai-chat.onboarding :deep() .footnote {
  font-size: 1rem !important;
  margin: 0.4rem auto 0 auto !important;
  color: var(--gray-500);
}
.ai-chat.onboarding :deep() .chat-scroller-wrapper {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.ai-chat.onboarding :deep() .content {
  padding-top: 8.8rem;
}
.ai-chat.onboarding :deep() .bary {
  z-index: 100;
}
.ai-chat.onboarding .entry {
  max-width: 77.2rem;
  margin: 0 auto;
  padding-left: 1.4rem;
  padding-right: 2rem;
}
.ai-chat.onboarding .entry:last-child {
  padding-bottom: 11.8rem;
}
.loading {
  display: flex;
  justify-content: center;
}
