.md-content {
  color: var(--gray-900);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}
.md-content :deep() table {
  width: 100%;
  border-radius: 0.4rem;
  border-collapse: separate;
  border-spacing: 0;
}
.md-content :deep() table tr th,
.md-content :deep() table tr td {
  border-right: 0.1rem solid var(--gray-200);
  border-bottom: 0.1rem solid var(--gray-200);
  padding: 1.2rem 1.4rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.md-content :deep() table tr th {
  background: #f7f5f3;
  border-top: 0.1rem solid var(--gray-200);
  font-weight: 600;
  color: var(--gray-900);
}
.md-content :deep() table tr td {
  color: var(--gray-900);
  font-weight: 400;
}
.md-content :deep() table tr th:first-child,
.md-content :deep() table tr td:first-child {
  border-left: 0.1rem solid var(--gray-200);
}
.md-content :deep() table tr:first-child th:first-child {
  border-top-left-radius: 0.4rem;
}
.md-content :deep() table tr:first-child th:last-child {
  border-top-right-radius: 0.4rem;
}
.md-content :deep() table tr:last-child td:first-child {
  border-bottom-left-radius: 0.4rem;
}
.md-content :deep() table tr:last-child td:last-child {
  border-bottom-right-radius: 0.4rem;
}
.md-content :deep() table {
  margin: 1.4rem 0;
}
.md-content :deep() p {
  line-height: 2.4rem;
}
.md-content :deep() a sup {
  display: inline;
  text-align: center;
  color: var(--gray-900);
  transition: background 0.2s, color 0.2s;
  border-radius: 4rem;
  font-size: 1rem !important;
  padding: 0 0.3rem;
  margin-right: -0.2rem;
  margin-left: -0.1rem;
}
.md-content :deep() a:hover sup {
  color: var(--base-white);
  background: var(--brand-500);
}
.md-content :deep() li>p {
  margin-bottom: 0;
}
.md-content :deep() li>ul {
  margin-bottom: 1em;
}
