@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.collapsed .profile .info,
.collapsed .profile .logout {
  display: none;
}
.profile {
  gap: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile .info {
  line-height: 2rem;
  min-width: 0;
}
.profile .info .name {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--text-secondary);
}
.profile .info .email {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--text-tertiary);
}
.profile .logout {
  margin-left: auto;
  animation: fade-in 0.3s;
}
