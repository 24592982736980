.medium-editor-container * {
  outline: none;
  box-sizing: border-box;
}

.medium-editor-container {
  height: 100%;
  margin: 0 auto;
  position: relative;
  font-size: 1.2rem;
  padding-top: 1.6rem
}

.medium-editor-container p,
.medium-editor-container h1,
.medium-editor-container h2,
.medium-editor-container h3,
.medium-editor-container h4,
.medium-editor-container h5,
.medium-editor-container h6,
.medium-editor-container ul,
.medium-editor-container ol,
.medium-editor-container pre,
.medium-editor-container blockquote {
  max-width: 1000px;
  margin: 0 auto;
}

.medium-editor-container ul,
.medium-editor-container ol {
  margin-left: auto !important;
  padding-left: 30px;
}

.medium-editor-container .medium-editor-element,
.medium-editor-container.medium-editor-element {
  min-height: 40px;
}

.medium-editor-container .medium-editor-element:empty,
.medium-editor-container .medium-editor-placeholder {
  max-width: 1000px;
  margin: 0 auto;
}

.medium-editor-container .editor {
  max-width: 1000px;
  margin: 0 auto;
}

.medium-editor-container .medium-editor-placeholder::after {
  display: none
}

.medium-editor-container .editor.has-content {
  max-width: 100%;
  margin: 0 auto;
}

.medium-editor-container .editor.has-content.medium-editor-placeholder::after {
  display: none;
}


.medium-editor-container h1 {
  font-size: 2.5em;
  margin-bottom: 0.1em;
}

.medium-editor-container h2 {
  font-size: 2.2em;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
}

.medium-editor-container h3 {
  font-size: 1.9em;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
}

.medium-editor-container h4 {
  font-size: 1.6em;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
}

.medium-editor-container h5 {
  font-size: 1.3em;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
}

.medium-editor-container h6 {
  font-size: 1em;
  margin-bottom: 0.1em;
}

.medium-editor-container,
.medium-editor-container p {
  line-height: 2.4rem
}

.medium-editor-container p+p 
.medium-editor-container p+ul 
.medium-editor-container p+ol
.medium-editor-container br+ul
.medium-editor-container br+ol {
  margin-top: 1em;
}

.medium-editor-container ul {
  margin-left: 30px;
  list-style: disc;
}

.medium-editor-container ul li {
  margin-bottom: 0.5em
}

.medium-editor-container ol {
  margin-left: 30px;
}

.medium-editor-container ol li {
  margin-bottom: 0.5em
}

.medium-editor-container iframe {
  width: 1px;
  min-width: 100%;
}

iframe {
  border: 0px;
}

.medium-editor-container blockquote {
  border-left: 3px solid #3B3E3E;
  padding-left: 30px;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  font-style: italic;
}

.medium-toolbar-arrow-under:after {
  border-color: var(--base-white) transparent transparent transparent;
  top: 48px;
}

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent var(--base-white) transparent;
  top: -8px;
}

.medium-editor-toolbar {
  background-color: #222526;
  border: 1px solid #222526;
  border-radius: 4px;
  border: 0.1rem solid var(--gray-200);
}

[id^=medium-editor-toolbar-] {
  background: var(--base-white)
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)
  border-radius: 0.8rem
  &.medium-toolbar-arrow-over {
    box-shadow: 0px -12px 16px -4px rgba(16, 24, 40, 0.08), 0px -4px 6px -2px rgba(16, 24, 40, 0.03)
  }
  input {
    border-radius: inherit
  }
  li {
    button {
      border-right: none
      border-left: none
    }
  }
}

.medium-editor-toolbar li button {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: #FFF;
  height: 48px;
  min-width: 48px;
  -webkit-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medium-editor-toolbar li button:hover {
  background-color: transparent;
  color: #00BD6A;
}

.medium-editor-toolbar li .medium-editor-button-first {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.medium-editor-toolbar li .medium-editor-button-last {
  border-bottom-right-radius: 4px;
  border-right: none;
  border-top-right-radius: 4px;
}

.medium-editor-toolbar li .medium-editor-button-active {
  background-color: transparent;
  color: #00BD6A;
}

.medium-editor-toolbar-form {
  background: #222526;
  border-radius: 4px;
  color: #FFF;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input {
  background: #222526;
  color: #FFF;
  height: 48px;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input::-webkit-input-placeholder {
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-moz-placeholder {
  /* Firefox 18- */
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-ms-input-placeholder {
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
}

.medium-editor-toolbar-form a {
  color: #FFF;
}

.medium-editor-toolbar-anchor-preview {
  background: #222526;
  border-radius: 4px;
  color: #FFF;
}

.medium-editor-placeholder:after {
  color: #222526;
}

.medium-editor-container * {
  outline: none;
}

.medium-editor-container .insert-image-container {
  display: flex;
  position: fixed;
  left: 100px;
  top: 100px;
  transform: translate(-54px, -7px);
}

.medium-editor-container .insert-image-container .btn-toggle {
  border: 1px solid #DDD;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  color: #555;
  background-color: #FFF;
  padding: 5px;
}

.medium-editor-container .image-handler {
  display: flex;
  position: fixed;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -20px);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.medium-editor-container .image-handler .btn-toggle {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  color: #FFF;
  background-color: transparent;
  padding: 0px;
}

.medium-editor-container .image-handler .btn-toggle img {
  display: block;
  width: 100%;
  height: auto;
}

.medium-editor-container .image-handler .btn-toggle:hover {
  cursor: pointer;
  color: #00BD6A;
}

.medium-editor-container .insert-image-container .insert-image-menu {
  display: flex;
}

.medium-editor-container .insert-image-container .insert-image-menu .btn-toggle {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medium-editor-container .editor-image {
  display: block;
  max-width: 1000px;
  margin: 2rem auto;
}

.medium-editor-container .editor-image img {
  width: 100%;
  height: auto;
  display: block;
}

.medium-editor-container .editor-image-description {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: -1rem;
  font-size: 0.8rem;
  color: #999;
  position: relative;
}

.medium-editor-container .editor-image-description.is-empty::after {
  content: "Image Description";
  color: #BBB;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.medium-editor-container .editor-image+.editor-image-description.is-empty {
  display: none;
}

.medium-editor-container .editor-image.is-focus+.editor-image-description.is-empty {
  display: block;
}

.medium-editor-container .editor-image img:hover {
  cursor: pointer;
}

.medium-editor-container .editor-image.is-expand {
  max-width: 1200px;
}

.medium-editor-container .editor-image.is-full {
  max-width: 100%;
}

.medium-editor-container .editor-image input {
  margin: 0 auto;
  border: 0;
  display: block;
  font-size: 0.7rem;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  color: #888;
  margin-bottom: 2rem;
}

.medium-editor-container .editor-embed-container.is-inactive {
  display: none;
}

.medium-editor-container .editor-embed-input.is-inactive {
  display: none;
}

.medium-editor-container .editor-embed>a {
  display: none;
}

.medium-editor-container p
.medium-editor-container h1
.medium-editor-container h2
.medium-editor-container h3
.medium-editor-container h4
.medium-editor-container h5
.medium-editor-container h6
.medium-editor-container ul
.medium-editor-container ol
.medium-editor-container pre
.medium-editor-container blockquote {
  max-width: unset
}

.medium-editor-container {
  .ql-container.ql-snow {
    border: none
    .ql-editor {
      padding: 2rem 0
    }
    a {
      text-decoration: none
    }
  }
}