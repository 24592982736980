.tone-of-voice:deep()>.header,
.tone-of-voice:deep()>.content {
  max-width: 50%;
}
.tone-of-voice:deep()>.header .custom-textarea,
.tone-of-voice:deep()>.content .custom-textarea {
  flex-grow: 1;
}
.tone-of-voice:deep()>.header .custom-textarea.disabled,
.tone-of-voice:deep()>.content .custom-textarea.disabled {
  opacity: 0.8;
}
.tone-of-voice:deep()>.header .custom-textarea textarea,
.tone-of-voice:deep()>.content .custom-textarea textarea {
  min-height: 0rem;
  height: 100%;
}
.tone-of-voice:deep()>.header .uploader-segment,
.tone-of-voice:deep()>.content .uploader-segment {
  flex-grow: 1;
  max-height: 36.5rem;
}
.tone-of-voice:deep()>.header .uploader-segment .inline-text,
.tone-of-voice:deep()>.content .uploader-segment .inline-text {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tone-of-voice:deep()>.header .builder-inputs.col .field,
.tone-of-voice:deep()>.content .builder-inputs.col .field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.sample-input {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:deep().sample-input>.builder-inputs.col {
  flex-grow: 1;
}
:deep().sample-input>.builder-inputs.col .segment {
  background: transparent;
}
