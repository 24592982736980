<template>
  <div class="autosave">
    <template v-if="!valid">
      <span class="badge-text">Fix errors on the page to save</span>
      <Badge error class="badge" icon="save-01">Cannot save</Badge>
    </template>
    <Badge v-else-if="saving" success class="save" icon="refresh-cw-09">Saving</Badge>
    <template v-else-if="saved_at">
      <span class="badge-text">{{ saved_ago }}</span>
      <Badge success class="badge" icon="save-01">Saved</Badge>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.autosave {
  display: flex
  flex-direction: row
  height: min-content
  align-items: center
  gap: 0.8rem

  .badge {
    margin-right: 0.8rem
  }
    
  .badge-text {
    font-size: 1.2rem
    line-height: 1.8rem
    color: var(--gray-500)
  }
}
</style>

<script>

export default {
  props: {
    valid: {type: Boolean, default: true},
    saving: {type: Boolean, default: false},
    saved_at: {type: Number, default: undefined}
  },
  data() {
    return {
      saved_ago: undefined,
      ago_timer: undefined
    }
  },
  watch: {
    saved_at() {
      this.formatAgo()
    }
  },

  mounted() {
    this.ago_timer = setInterval(() => this.formatAgo(), 1000)
  },
  unmounted() { 
    clearInterval(this.ago_timer)
    this.ago_timer = undefined
  },
  methods: {
    formatAgo() {
      this.saved_ago = this.saved_at ? $utils.format.daysAgo(this.saved_at) : ''
    }
  }
}
</script>
