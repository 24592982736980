.p-select-overlay {
  --p-select-overlay-background: var(--base-white);
  --p-select-overlay-color: #4b5563;
  --p-select-overlay-border-color: var(--primary-300);
  --p-select-overlay-border-radius: 0 0 0.8rem 0.8rem;
  --p-select-overlay-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
  --p-select-option-color: var(--gray-900);
  --p-select-option-focus-color: var(--gray-900);
  border-top: none !important;
  -webkit-clip-path: inset(0rem -0.5rem -0.5rem -0.5rem);
  clip-path: inset(0rem -0.5rem -0.5rem -0.5rem);
  overflow: hidden;
  margin-top: -0.1rem;
}
.p-select-overlay .item {
  display: flex;
  gap: 0.8rem;
}
.p-select-overlay .p-select-list-container {
  max-height: 20rem !important;
}
.p-select-overlay .p-select-list-container .p-select-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.p-select-overlay .p-select-list-container .p-select-list .p-select-option {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #101828;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  border-top: 0.1rem solid #fafafa;
  cursor: pointer;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  white-space: normal !important;
}
.p-select-overlay .p-select-list-container .p-select-list .p-select-option.p-select-option-selected {
  background: var(--primary-100);
  color: rgba(0,0,0,0.95);
  font-weight: bold;
}
.p-select-overlay .p-select-list-container .p-select-list .p-select-option.p-select-option-selected.p-focus,
.p-select-overlay .p-select-list-container .p-select-list .p-select-option:not(.p-highlight):not(.p-disabled).p-focus {
  background: var(--primary-100);
  color: rgba(0,0,0,0.95);
}
.p-select-overlay .p-select-list-container .p-select-list .p-select-option:not(.p-select-option-selected):not(.p-disabled):hover {
  color: #101828;
  background: #edfaff;
}
.p-select-overlay .p-select-list-container .p-select-empty-message {
  padding: 0.75rem 1.25rem;
  color: #101828;
  background: transparent;
}
