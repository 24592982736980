.badge {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  border-radius: 0.4rem;
  padding: 0.3rem 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.4em;
  white-space: nowrap;
  border-color: var(--primary-200);
  background: var(--primary-50);
  color: var(--primary-700);
  width: max-content;
}
.badge.rounded {
  border-radius: 1.6rem;
}
.badge.small {
  padding: 0 0.6rem;
}
.badge.border {
  border-width: 0.1rem;
  border-style: solid;
}
.badge.color {
  color: v-bind(color) !important;
}
.badge.brand {
  border-color: var(--brand-200);
  background: var(--brand-50);
  color: var(--brand-700);
}
.badge.indigo {
  border-color: var(--indigo-200);
  background: var(--indigo-100);
  color: var(--indigo-700);
}
.badge.gray {
  border-color: var(--gray-200);
  background: var(--gray-50);
  color: var(--gray-700);
}
.badge.error {
  border-color: var(--error-200);
  background: var(--error-50);
  color: var(--error-700);
}
.badge.error .icon:deep() * {
  stroke: var(--error-700) !important;
}
.badge.success {
  border-color: var(--success-200);
  background: var(--success-50);
  color: var(--success-700);
}
.badge.success .icon:deep() * {
  stroke: var(--success-700) !important;
}
.badge.blue {
  background-color: #b2ddff;
  color: var(--text-secondary);
}
.badge .icon {
  width: 1.4rem;
  height: 1.4rem;
}
.badge .icon:deep() * {
  stroke: v-bind(iconColor) !important;
}
.badge:not(.border) {
  border-radius: 0.4rem !important;
}
