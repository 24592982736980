<template>
  <div class="filter">
    <div class="title">
      {{ title }}
    </div>
    <Divider m="1.2rem -1.6rem 0"/>
    <slot/>
    <Divider m="0 -1.6rem 0"/>
    <div class="actions">
      <div class="reset pointer" :disabled="!prev.length" @click="onReset()">
        Reset
      </div>
      <div class="apply pointer" :disabled="!can_apply" @click="onApply()">
        Apply
      </div>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.filter {
  padding: 1.2rem 1.6rem 0.7rem
  .title {
    font-size: 1.4rem
    font-weight: 600
    line-height: 2rem
    color: var(--gray-700)
  }
  .actions {
    display: flex
    align-items: center
    justify-content: center
    .reset
    .apply {
      padding: 0.8rem 0.5rem
      font-size: 1.2rem
      font-weight: 600
      line-height: 1.8rem
    }
    .reset {
      color: var(--gray-600)
    }
    .apply {
      color: var(--brand-700)
    }
  }
}
</style>
<script>
import {FILTER_CTX} from './filter-defs'

export default {
  mixins: [ModelValue(Array)],
  provide(){
    return {
      [FILTER_CTX]: {
        next: {
          get: () => this.next,
          set: v => this.next = v
        },
        prev: {
          get: () => this.prev,
          set: v => this.prev = v
        }
      }
    }
  },
  props: {
    title: {type: String, required: true}, 
  },
  emits: ['apply', 'on-apply'],
  data() {
    return {
      prev: [],
      next: [],
    }
  },
  computed: {
    can_apply() {
      return !$utils.compare.same(this.prev, this.next)
    }
  },
  watch: {
    value() {
      if (this.next !== this.value) this.next = this.value
      this.prev = this.value
    }
  },
  mounted() {
    if (this.value) {
      this.prev = this.value
      this.next = this.value
    }
  },
  methods: {
    onReset(){
      this.prev = []
      this.next = []
      this.onApply()
    },
    async onApply() {
      this.$emit('on-apply')
      this.value = this.next
    },
  }
}
</script>