<template>
  <span ref="animator" class="loader-text fira-code-400">
    {{ status }}
  </span>
</template>

<style lang="stylus" scoped>
.loader-text {
  color: var(--gray-600)
  text-align: left
  font-size: 1.6rem
  line-height: 2rem
  height: 2rem
  white-space: nowrap
  overflow: hidden
  width: 0%
  max-width: max-content

  &.typing {
    animation: typing 2s steps(40, end) infinite
    animation-iteration-count: infinite
    animation-duration: 4s

    @keyframes typing {
      0% {
        width: 0%
      }
      40% {
        width: 100%
      }
      100% {
        width: 100%
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    status: {type: String, required: true}
  },
  watch: {
    status: function () {
      this.animate()
    }
  },
  async mounted(){
    this.animate()
  },
  methods: {
    animate() {
      const animator = this.$refs.animator
      animator.className = 'loader-text fira-code-400'
      requestAnimationFrame(() => requestAnimationFrame(() => animator.className = 'loader-text fira-code-400 typing'))
    }
  }
}
</script>