<template>
  <div class="progressbar">
    <ProgressBar :pt="pt" :value="value"></ProgressBar>
    <div v-if="!error && label" class="progress-label">
      <span>{{ c_val }}{{ symb }}</span>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.progressbar {
  width: 100%
  display: flex
  min-height: 3.6rem
  gap: 1.2rem
  align-items: center
}

.root {
  width: 100%
  position: relative
  overflow: hidden
  border: 0 none
  height: 0.8rem
  background: v-bind(c_bg)
  border-radius: 0.4rem
}

.progress-label {
  display: inline-block
  color: var(--gray-700)
  font-size: 1.4rem
  font-weight: 500
  line-height: 2rem
  width: 3.7rem
  text-align: left
}

:deep() {
  .value {
    height: 100%
    width: 0%
    position: absolute
    display: none
    border: 0 none
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    transition: width 0.3s ease-in-out
    background: v-bind(c_color)
    border-radius: 0.4rem
  }
  .label {
    display: none
  }
}
</style>
<script setup>
import ProgressBar from 'primevue/progressbar'
import {computed} from 'vue'

const props = defineProps({
  value: {
    type: Number,
    default: 0
  },
  valueTitle: {
    type: Number,
    default: undefined
  },
  success: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  symb: {
    type: String,
    default: '%'
  },
  label: {
    type: Boolean,
    default: true
  }
})

const pt = {
  root: 'root',
  value: 'value',
  label: 'label'
}

const c_bg = computed(() => props.error ? 'var(--error-600)' : 'var(--gray-200)')
const c_val = computed(() =>  props.valueTitle === undefined ? props.value : props.valueTitle)
const c_color = computed(() => {
  if (props.success) return 'var(--success-500)'
  if (props.error) return'var(--error-600)'
  return 'var(--primary-600)'
})
</script>