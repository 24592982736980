<template>
  <Loader v-if="loading"/>
  <div v-else-if="empty" class="new-chat empty delay-in-1000">
    <div class="title">
      <img class="logo" src="assets/logo-icon.svg"/>
      <p>
        How can I help you today?
      </p>
    </div>
    <AIChat ref="chat" :description="'Genrate can make mistakes. Consider checking important information.'" mode="expanded" @regenerate="onChat"/>
  </div>
  <div v-else class="new-chat">
    <div class="title delay-in-1000">
      {{ greetings }}
    </div>
    <Divider m="0"/>
    <AIChat ref="chat" class="chat" :description="'Genrate can make mistakes. Consider checking important information.'" mode="expanded" @regenerate="onChat">
      <template v-slot:preview>
        <div class="new-chat delay-in-1000">
          <Feed class="feed" :store="feed" :autorefresh="false"/>
        </div>
      </template>
    </AIChat>
  </div>
</template>

<style lang="stylus" scoped>
.new-chat {
  display: flex
  flex-direction: column
  height: 100%
  background: var(--bg-primary)
  .title {
    text-align: center
    margin: 2.4rem
    font-size: 2.4rem
    font-weight: 400
    line-height: 3.2rem
  }
  .chat {
    flex-grow: 1
    .feed {
      width: 100%
    }
    :deep() {
      .head
      .feed-items
      .feed-items+.divider {
        width: 100%
        max-width: 86.6rem
        margin: 0 auto
      }
      .feed-content {
        padding-bottom: 2rem
      }
      .chat-input {
        border-top: none
        background: transparent
        padding-left: 0
        padding-right: 0
      }
      .footnote {
        font-size: 1rem
        margin: 0.4rem auto 0 auto
        color: var(--gray-500)
      }
    }
  }

  &.empty {
    .title {
      display: flex
      flex-direction: column
      gap: 2rem
      margin-top: 7.4rem
      .logo {
        display: block
        width: 4rem
        margin: 0 auto
      }
      p {
        color: var(--gray-900)
        font-size: 2.4rem !important
        font-weight: 400
        line-height: 3.2rem
      }
    }
  }
}
</style>

<script>
import $profile from 'stores/profile'
import AIChat from 'components/ai-chat'
import Feed from 'components/feed'
import $feed from 'stores/feed'
import $chats from 'stores/chats'
import $global from 'stores/global'
import {Later} from 'utils/timers'
import {Unauthorized} from 'utils/errors'

export default {
  components: {
    AIChat, 
    Feed
  },
  setup() {
    return {
      feed: $feed,
    }
  },
  data(){
    return {
      loading: true,
      load_new: new Later(30 * 1000),
    }
  },
  computed: {
    greetings(){
      const name = $profile.first_name || $profile.name
      const currentHour = new Date().getHours()
      let greetings = 'Welcome back'
      if (currentHour >= 6 && currentHour < 12) {
        greetings = 'Good morning'
      } else if (currentHour >= 12 && currentHour < 18) {
        greetings = 'Good afternoon'
      } else if (currentHour >= 18 && currentHour < 22) {
        greetings = 'Good evening'
      }
      
      return `${greetings}, ${name}! Let's catch you up.`
    },
    empty(){
      return $feed.items.length === 0
    }
  },
  async beforeUnmount() {
    this.load_new.abort()
  },
  async mounted() {
    await $feed.load()
    this.loading = false

    this.load_new.repeat(
      async () => {
        try {
          if ($feed.loading) return
          if (this.empty) await $feed.load()
          else await $feed.load({new: true})
        }
        catch(err) {
          // Feed refresh somwehy fails on amdocs deployment regulairly
          // Fast fix to avoid UI reload
          // TODO: investigate properly
          console.log('failed to refresh feed', err)
          if (err instanceof Unauthorized) {
            throw err
          } 
        }
      })
  },
  methods: {
    async onChat(user_query) {
      const chat = await $chats.newChat(user_query, 'chat')
      $global.goToChat(chat.id)
    },
  }
}
</script>