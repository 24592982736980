<template>
  <div class="ingest">
    <Loader v-if="loading">
      Loading<br>Please wait... 
    </Loader>
    <SettingsFrame v-else title="Ingest Bucket" description="Connect to ingest bucket and share files between all users.">
      <div v-if="have_certs" class="credentials">
        <div class="info">
          <span>Client Certificate:</span> <span>***************</span>
          <Copy size="1.6rem" @copy="$utils.copyText(ingest_cert)"/>
          <Icon class="icon" icon="download-02" width="1.6rem" height="1.6rem" @click="stringToFile(ingest_cert, 'client.pem')"/>
        </div>
        <div class="info">
          <span>Certificate Public Key:</span> <span>***************</span>
          <Copy size="1.6rem" @copy="$utils.copyText(ingest_pubkey)"/>
          <Icon class="icon" icon="download-02" width="1.6rem" height="1.6rem" @click="stringToFile(ingest_pubkey, 'client.pubkey.pem')"/>
        </div>
        <div class="info">
          <span>Certificate Private Key:</span> <span>***************</span>
          <Copy size="1.6rem" @copy="$utils.copyText(ingest_privkey)"/>
          <Icon class="icon" icon="download-02" width="1.6rem" height="1.6rem" @click="stringToFile(ingest_privkey, 'client.key')"/>
        </div>
        <div class="info">
          <span>Private Key Passphrase:</span> <span>***************</span>
          <Copy size="1.6rem" @copy="$utils.copyText(ingest_pass)"/>
        </div>
        <div class="info">
          <span>Bucket Name:</span> <span>{{ bucket_name }}</span>
          <Copy size="1.6rem" @copy="$utils.copyText(bucket_name)"/>
        </div>
        <div class="info">
          <span>Role ARN:</span> <span>{{ role_arn }}</span>
          <Copy size="1.6rem" @copy="$utils.copyText(role_arn)"/>
        </div>
        <div class="info">
          <span>Trust Anchor ARN:</span> <span>{{ ta_arn }}</span>
          <Copy size="1.6rem" @copy="$utils.copyText(ta_arn)"/>
        </div>
        <div class="info">
          <span>Profile ARN:</span> <span>{{ profile_arn }}</span>
          <Copy size="1.6rem" @copy="$utils.copyText(profile_arn)"/>
        </div>
        <div>
          <Popconfirm width="max-content"
                      confirm-button-text="Yes"
                      cancel-button-text="No"
                      title="Recreate Ingest Credentials?"
                      :disabled="loading"
                      @confirm="onRecreateIngestCreds"
          >
            <Button small :disabled="loading" @click="onCreate">Recreate Credentials</Button>
          </Popconfirm>
        </div>
      </div>
      <div v-else class="no-credentials">
        <div>No ingest bucket credentials available</div>
        <Button small @click="onCreate">Create Credentials</Button>
      </div>
    </SettingsFrame>
  </div>
</template>

<style lang="stylus" scoped>
.ingest {
  .credentials {
    display: flex
    flex-direction: column
    gap: 1rem

    .info {
      display: flex
      flex-direction: row
      gap: 0.8rem
      
      span {
        &:first-child {
          font-weight: 500
        }
        font-size: 1.5rem
      }

      .icon {
        cursor: pointer
      }
    }

    .issue {
      margin-top: 0.5rem
    }
  }

  .no-credentials {
    div {
      margin-bottom: 1rem
    }
  }
}
</style>

<script>
import SettingsFrame from '../settings-frame.vue'
import $settings from 'utils/settings'
import {Rest} from 'utils'
import {stringToFile} from 'utils/utils'

import {
  SETTINGS_INGEST_CERT,
  SETTINGS_INGEST_PRIVKEY,
  SETTINGS_INGEST_PUBKEY,
  SETTINGS_INGEST_PASS
} from 'consts/settings'

import {API_INGEST_INFO, API_INGEST_RECREATE} from 'consts/api'

export default {
  components: {
    SettingsFrame
  },
  data() {
    return {
      rest: new Rest(),
      loading: false,
      data: {}
    }
  },
  computed: {
    ingest_cert() {
      return this.data[SETTINGS_INGEST_CERT]
    },
    ingest_privkey() {
      return this.data[SETTINGS_INGEST_PRIVKEY]
    },
    ingest_pubkey() {
      return this.data[SETTINGS_INGEST_PUBKEY]
    },
    ingest_pass() {
      return this.data[SETTINGS_INGEST_PASS]
    },
    role_arn() {
      return this.data.info?.role_arn
    },
    ta_arn() {
      return this.data.info?.trust_anchor_arn
    },
    profile_arn() {
      return this.data.info?.profile_arn
    },
    bucket_name() {
      return this.data.info?.bucket_name
    },
    have_certs() {
      return this.ingest_cert && this.ingest_privkey && this.ingest_pass && this.ingest_pubkey && this.bucket_name
    }
  },
  async mounted() {
    this.loading = true
    const rest = new Rest()
    const [cert, privkey, pubkey, pass, info] = await Promise.all([
      $settings.get(SETTINGS_INGEST_CERT),
      $settings.get(SETTINGS_INGEST_PRIVKEY),
      $settings.get(SETTINGS_INGEST_PUBKEY),
      $settings.get(SETTINGS_INGEST_PASS),
      rest.get(API_INGEST_INFO)
    ])
    this.data = {
      [SETTINGS_INGEST_CERT]: cert,
      [SETTINGS_INGEST_PRIVKEY]: privkey,
      [SETTINGS_INGEST_PUBKEY]: pubkey,
      [SETTINGS_INGEST_PASS]: pass,
      info
    }
    this.prev_data = this.data
    this.loading = false
  },
  methods: {
    async onCreate() {
      this.loading = true
      const rest = new Rest()
      const {cert, privkey, pubkey, pass} = await rest.post(API_INGEST_RECREATE)
      this.data[SETTINGS_INGEST_CERT] = cert
      this.data[SETTINGS_INGEST_PRIVKEY] = privkey
      this.data[SETTINGS_INGEST_PUBKEY] = pubkey
      this.data[SETTINGS_INGEST_PASS] = pass
      this.loading = false
    },
    stringToFile(data, fname) {
      return stringToFile(data, fname)
    }
  }
}
</script>