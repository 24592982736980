.meeting-prep .actions {
  display: flex;
  gap: 1rem;
}
.meeting-prep .title {
  color: #000;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.meeting-prep .about {
  margin-top: 1.2rem;
  font-size: 1.4rem;
  color: var(--gray-900);
  font-weight: 400;
  line-height: 2rem;
}
.meeting-prep .collapse-menu {
  margin-top: 1.6rem;
}
