<template>
  <div class="profile">
    <Avatar :name="$profile.display_name" :image_id="$profile.avatar_id"/>
    <div class="info">
      <div class="name ellipsis">{{ $profile.display_name }}</div>
      <div class="email ellipsis">{{ $profile.email }}</div>
    </div>
    <div class="logout pointer">
      <Icon icon="log-out-01"
            width="2rem" height="2rem"
            stroke="var(--gray-500)"
            @click="onLogout"
      />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
@import 'styles/animations'
.ellipsis {
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
}
.collapsed {
  .profile {
    .info
    .logout {
      display: none
    }
  }
}
.profile {
  gap: 1.2rem
  display: flex
  flex-direction: row
  align-items: center

  .info {
    line-height: 2rem
    min-width: 0
    .name {
      font-size: 1.6rem
      font-weight: 500
      color: var(--text-secondary)
    }
    .email {
      font-size: 1.4rem
      font-weight: 400
      color: var(--text-tertiary)
    }
  }

  .logout {
    margin-left:auto
    animation: fade-in 0.3s
  }
}
</style>

<script setup>
import $profile from 'stores/profile'
import $global from 'stores/global'
</script>

<script>
export default {
  methods: {
    async onLogout() {
      await $global.logout()
    }
  }
}
</script>