.collapse-menu .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  user-select: none;
}
.collapse-menu .title.loading {
  color: var(--gray-500);
  font-weight: 400;
}
.collapse-menu .title .loading-icon {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.collapse-menu .title .icon {
  transition: transform 0.2s;
}
.collapse-menu .title .icon.open {
  transform: rotate(90deg);
}
.collapse-menu .wrapper {
  padding: v-bind(padding);
}
