<template>
  <Loader v-if="first_load">
    Loading<br>
    Please wait...
  </Loader>

  <Empty v-else-if="!total">
    <template v-slot:title>You don’t have any contacts uploaded yet...</template>
    <template v-slot:sub-text>
      Upload a CSV of your LinkedIn connections to build a comprehensive contact list in the app,<br>
      helping you stay connected and leverage your network more effectively.
    </template>
    <template v-slot:link>
      <a href="https://www.linkedin.com/help/linkedin/answer/a566336/export-connections-from-linkedin" target="_blank">
        Learn how to export your LinkedIn connections here.
      </a>
    </template>
    <template v-slot:actions>
      <UploadButton :loading="processing" primary stroke="white" icon="file-plus-02" 
                    :accept="LINKEDIN_CONTACTS.exts.join(',')"
                    @upload="files => $emit('upload', files)" 
      >
        Import CSV
      </UploadButton>
      <AddAutocomplete ref="add"
                       @submit="onNewCompanySubmit"
      >
        <template v-slot:reference>
          <Button class="add-company" primary :disabled="processing" @click="onNewCompany">
            <Icon icon="plus" width="2rem" height="2rem" stroke="var(--base-white)"/>
            Add Company
          </Button>
        </template>
      </AddAutocomplete>
    </template>
  </Empty>

  <DataTable v-else :items="items" 
             :total="count" 
             :loading="loading || processing" 
             :skip="load_params.skip"
             override-skip
             class="companies-table" 
             @page="onPage"
  >
    <!-- Empty -->
    <template v-slot:empty>
      <Empty bg="assets/bg-pattern-square.png">
        <template v-slot:image>
          <Icon width="4rem" height="4rem" icon="search" class="icon-svg"/>
        </template>
        <template v-slot:title>No companies were found</template>
        <template v-slot:sub-text>
          Your search “{{ highlight }}” did not match any companies. Please try again.
        </template>
      </Empty>
    </template>

    <!-- Header -->
    <template v-slot:header-right>
      <SearchInput ref="search"
                   style="margin-right: 1.6rem;"
                   :disabled="loading || processing"
                   @search="onSearch"
      />
      <AddAutocomplete ref="add"
                       title="Add Company"
                       label="Company Name"
                       placement="top-end"
                       placeholder="e.g. Genrate.ai"
                       @submit="onNewCompanySubmit"
      >
        <template v-slot:reference>
          <Icon class="pointer" :disabled="loading || processing" icon="plus" width="2rem" height="2rem" stroke="var(--gray-700)" @click="onNewCompany"/>
        </template>
      </AddAutocomplete>
    </template>

    <!-- Company -->
    <Column field="name" style="width: 70%">
      <template v-slot:header>
        <div class="company">
          Name
        </div>
      </template>
      <template v-slot:body="{data: {logo_url, name, deleting}}">
        <div class="ellipsis-cell" :disabled="deleting">
          <div class="company">
            <el-image class="company-avatar" fit="contain" :src="logo_url">
              <template v-slot:error>
                <el-image class="company-avatar" fit="contain" src="assets/company-placeholder.png"/>
              </template>
              <template v-slot:placeholder>
                <el-image class="company-avatar" fit="contain" src="assets/company-placeholder.png"/>
              </template>
            </el-image>
            <div class="company-name ellipsed">
              <Highlight :text="$utils.str.decodeHTMLEntities(name)" :highlight="highlight"/>
            </div>
          </div>
        </div>
      </template>
    </Column>

    <!-- Date -->
    <Column field="date" header="Date Added">
      <template v-slot:body="{data: {created_at, deleting}}">
        <span class="date" :disabled="deleting">{{ $utils.format.date(created_at) }}</span>
      </template>
    </Column>

    <!-- Actions -->
    <Column>
      <template v-slot:body="{data: {id, following, deleting}}">
        <div class="actions" :disabled="deleting">
          <Button v-if="following" primary @click="follow(id, false)">
            Following
          </Button>
          <Button v-else @click="follow(id, true)">
            Follow
          </Button>
          <Popconfirm width="max-content"
                      confirm-button-text="Yes"
                      cancel-button-text="No"
                      title="Are you sure you want to delete this company?"
                      @confirm="onDelete(id)"
          >
            <div class="row-hvcenter pointer">
              <Icon icon="trash-04" 
                    width="2rem" 
                    height="2rem" 
                    stroke="var(--gray-500)"
              />
            </div>
          </Popconfirm>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<style lang="stylus" scoped>
.add-company[disabled] {
  opacity: 0.8
}
.companies-table {
  .follow
  .unfollow {
    font-size: 1.4rem
    font-weight: 600
    line-height: 2rem
  }
  .follow {
    color: var(--brand-700)
  }
  .unfollow {
    color: var(--gray-600)
  }
  .company {
    display: flex
    align-items: center
    gap: 1rem
    .company-avatar {
      flex-shrink: 0
      border-radius: 50%
      width: 4rem
      height: 4rem
    }
    .company-name {
      color: var(--gray-900)
      font-size: 1.4rem
      font-weight: 500
      line-height: 2rem
    }
  }
  .actions {
    display: flex
    justify-content: flex-end
    gap: 1.4rem
  }
}
</style>

<script>
import Column from 'primevue/column'
import {DataTable, SearchInput} from 'components/data-table'
import {LINKEDIN_CONTACTS} from 'consts/ftypes.js'
import AddAutocomplete from './add-autocomplete.vue'
import $companies from 'stores/companies'

const DEF_LIMIT = 20

export default {
  components: {
    Column,
    DataTable,
    SearchInput,
    AddAutocomplete,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['upload', 'add'],
  setup(){
    return {
      LINKEDIN_CONTACTS,
    }
  },
  data() {
    return {
      highlight: '',
      first_load: true,
      load_params: {skip: 0, limit: DEF_LIMIT, filter: ''},
    }
  },
  computed: {
    items(){
      return $companies.companies
    },
    total(){
      return $companies.total
    },
    count(){
      return $companies.count
    },
    loading(){
      return $companies.loading
    },
  },
  watch: {
    async processing(val) {
      if (!val) {
        await $companies.load(this.load_params)
      }
    }
  },
  async mounted() {
    await $companies.load(this.load_params)
    this.first_load = false
  },
  methods: {
    async onSearch(filter){
      if (this.load_params.filter === filter) return

      this.load_params = {skip: 0, limit: DEF_LIMIT, filter}    
      await $companies.load(this.load_params)
      
      this.highlight = filter
      this.$refs.search.focus()
    },
    async onPage(ev){
      this.load_params = {...this.load_params, skip: ev.first, limit: ev.rows}
      await $companies.load(this.load_params)
    },
    async follow(id, follow) {
      await $companies.follow(id, follow)
    },    
    onNewCompany(){
      this.$refs.add.open()
    },
    async onNewCompanySubmit({name, linkedin_id}){
      await $companies.newCompany(name, linkedin_id)
      await $companies.load(this.load_params)
    },
    async onDelete(id){
      await $companies.delete(id)
      await $companies.load(this.load_params)
    }
  }
}
</script>
