<template>
  <div class="ai-message">
    <div class="chat" :class="{error}">
      <div class="left-panel" relative>
        <Icon class="icon" height="2rem" width="2rem" icon="stars-03"/>
        <slot name="additional-icon"/>
      </div>
      <div class="text" v-html="html"></div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.chat {
  display: flex
  justify-content: start
  padding: 1rem 1.4rem
  border-radius: 0px 8px 8px 8px
  background: var(--base-white)
  .text {
    color: var(--text-primary)
    font-size: 1.6rem
    line-height: 2rem
    font-weight: 400
    line-height: 2.4rem
  }
  .icon {
    margin-right: 0.8rem
    margin-top: 0.2rem
    min-width: 2rem
    stroke: var(--brand-500)
  }

  &.error {
    background: var(--gray-600)
    .icon {
      stroke: var(--base-white)
    }
    .text {
      color: var(--base-white)
    }
  }
}
</style>

<script>
export default {
  props: {
    text: {type: String, default: ''},
    error: {type: Boolean, default: false},
  },
  computed: {
    html() {
      return this.text
        .replaceAll('\n', '<br/>')
        .replace(/\*{2}(.*?)\*{2}/g, '<b>$1</b>')
        .replace(/(\[([^\]]+)])\(([^)]+)\)/g, '<a href="$3" target="_blank">$2</a>')
    }
  }
}
</script>