.ai-answer .answer-body {
  display: flex;
  gap: 1rem;
  padding: 1.6rem 0 1.4rem 0;
}
.ai-answer .answer-body .icon {
  flex-shrink: 0;
}
.ai-answer .answer-body .answer-content .text {
  font-size: 1.6rem;
  line-height: 3rem;
  word-break: break-word;
  color: var(--gray-900);
  font-weight: 400;
}
.ai-answer .answer-body .answer-content .ai-buttons {
  margin-top: 1.6rem;
}
.ai-answer .answer-body .icon {
  margin-top: -0.5rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 0.1rem solid var(--gray-300);
  background: var(--base-white);
}
.ai-answer .answer-body .chat-loader {
  line-height: 2.3rem;
  height: 2.3rem;
}
.ai-answer .start {
  margin-top: 1.6rem;
}
.ai-answer .start .button {
  width: 11.5rem;
  height: 4.2rem;
}
