<template>
  <div class="tg-data">
    <input ref="file_input" type="file" class="file" accept="application/json"/>
    <TgRunner runner-id="tg_entities" caption="Entities" :runner="(tasks) => runImport(tasks, 'entities')"/>
    <TgRunner runner-id="tg_data" caption="Data" :runner="(tasks) => runImport(tasks, 'data')"/>
    <TgRunner runner-id="tg_edges" caption="Edges" :runner="(tasks) => runImport(tasks, 'edges')"/>
  </div>
</template>

<style lang="stylus" scoped>
.tg-data {
  display: flex
  flex-direction: column
  gap: 2rem

  .file {
    display: none
  }
}
</style>

<script setup>
import TgRunner from './tg-runner.vue'
import {TASK_TGDATA_IMPORT} from 'consts/api'
import {waitForEvent} from 'utils/dom'
</script>

<script>
export default {
  methods: {
    async runImport(tasks, type) {
      let input = this.$refs.file_input
      
      input.value = null
      input.click()
      await waitForEvent(input, 'change')
      
      if (!input.value) {
        return undefined
      } 

      return await tasks.start(`${TASK_TGDATA_IMPORT}?type=${type}`, input.files[0], {fileData: true})
    },
  }
}
</script>