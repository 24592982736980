.person-card .actions {
  display: flex;
  gap: 1rem;
}
.person-card .links {
  margin-top: 2.4rem;
}
.person-card .collapse-menu {
  margin-top: 1.6rem;
}
.person-card .collapse-menu+.collapse-menu {
  margin-top: 2.7rem;
}
