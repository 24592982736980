.add-contact[disabled] {
  opacity: 0.8;
}
.people-table {
  height: 100%;
}
.people-table .contact {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.people-table .contact .contact-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  flex-shrink: 0;
}
.people-table .contact .contact-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.people-table .contact .contact-avatar .initials {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0.05rem solid var(--gray-300);
  border-radius: 50%;
  color: var(--gray-700);
  opacity: 0.8;
  background: var(--gray-100);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8rem;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  text-transform: uppercase;
}
.people-table .contact .contact-name {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}
.people-table .contact .contact-position {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--gray-600);
}
.people-table .company {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.people-table .company .company-img {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  flex-shrink: 0;
}
.people-table .company .company-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.people-table .action {
  display: flex;
  justify-content: flex-end;
  gap: 1.4rem;
}
