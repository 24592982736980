.root {
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 100%;
  width: 100%;
}
.root .content {
  position: relative;
  min-height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.root .feed {
  width: 54.6rem;
  border-left: 0.1rem solid var(--gray-200);
  background: var(--base-white);
}
.root .feed-notifier {
  position: absolute;
  right: 0;
  padding: 1.7rem 2rem 1.7rem 0rem;
  display: block;
  z-index: 1;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.root .feed-notifier.open {
  height: unset;
}
