.ai-option {
  padding: 0.7rem 1.5rem 0.7rem 1.1rem
  &:deep() {
    span {
      gap: 1rem
    }
  }
  &.accept {
    &.active {
      background-color: var(--success-50)
      border-color: var(--success-500)
    }
    &:active {
      --el-button-active-bg-color: var(--success-50)
      --el-button-active-border-color: var(--success-500)
    }
  }
  &.reject {
    &.active {
      background-color: var(--error-50)
      border-color: var(--error-600)
    }
    &:active {
      --el-button-active-bg-color: var(--error-50)
      --el-button-active-border-color: var(--error-600)
    }
  }
  &.regular {
    padding: 0.7rem 1.5rem
    &.active {
      background-color: var(--blue-50)
      border-color: var(--blue-500)
    }
    &:active {
      --el-button-active-bg-color: var(--blue-50)
      --el-button-active-border-color: var(--blue-500)
    }
  }
  &.secondary {
    padding: 0
    --el-button-bg-color: none
    --el-border: none
    ----el-button-text-color: var(--gray-300)
    &:hover
    &:focus {
      --el-button-hover-text-color: var(--gray-900)
      --el-button-hover-border-color: none
      --el-button-hover-bg-color: none
      box-shadow: none
    }
    &:active {
      --el-button-active-bg-color: none
    }
  }
  &:hover {
    --el-button-hover-border-color: var(--gray-300)
    --el-button-hover-bg-color: var(--gray-50)
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)
  }
}