<template>
  <div class="empty no-select">
    <div class="upload-wrapper">
      <div class="upload-icon">
        <img :draggable="false" class="img" src="assets/icons/file-type.svg" alt="file-type-icon"/>
      </div>
      <div class="text">
        You dont have any files uploaded yet...
      </div>
      <div class="sub-text">
        Here you will find all the files uploaded
      </div>
      <UploadButton primary stroke="white" @upload="(files) => $emit('upload', files)"/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.empty {
  display: flex
  justify-content: center
  align-items: center
  background: url('assets/bg-pattern-decorative.png') no-repeat center calc(50% - 6rem)
  height: 100%
  border-radius: 1.2rem
  border: 1px solid var(--shadow)
  box-shadow: var(--box-shadow)
  .upload-wrapper {
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .upload-icon {
      width: 10.4rem
      height: 10.4rem
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%)
    }
    .text {
      font-size: 1.6rem
      font-weight: 500
      line-height: 2.4rem
      color: var(--text-primary)
      margin-top: 1.6rem
      white-space: nowrap
    }
    .sub-text {
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
      color: var(--text-secondary)
      margin-bottom: 2.4rem
    }
  }
}
</style>

<script setup>
defineEmits(['upload'])
</script>
