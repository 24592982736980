.settings-frame {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: overlay;
  margin: 0 -0.4rem;
  padding: 0 0.4rem;
}
.settings-frame .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.settings-frame .header .title {
  color: var(--gray-900);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
}
.settings-frame .header .description {
  margin-top: 0.4rem;
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.settings-frame .header .right {
  display: flex;
  justify-items: center;
}
.settings-frame .content {
  flex-grow: 1;
  margin-top: 2.4rem;
}
