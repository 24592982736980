.company-info .show-more {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
  color: var(--gray-600);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.company-info .head {
  display: flex;
  gap: 1.1rem;
}
.company-info .head .image {
  width: 4.8rem;
  height: 4.8rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.company-info .head .image .placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-info .head .info {
  color: var(--gray-900);
  font-weight: 500;
  display: grid;
  flex-direction: column;
  grid-template-rows: 3rem 2rem 2rem;
  grid-template-areas: "name" "hq-address" "industry";
  gap: 0.4rem;
  min-width: 18rem;
}
.company-info .head .info.no-hq {
  grid-template-areas: "name" "industry" "hq-address";
}
.company-info .head .info .name {
  font-size: 2rem;
  line-height: 3rem;
  height: 100%;
  grid-area: name;
}
.company-info .head .info .hq-address {
  font-size: 1.4rem;
  line-height: 2rem;
  height: 100%;
  grid-area: hq-address;
}
.company-info .head .info .industry {
  font-size: 1.4rem;
  line-height: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  grid-area: industry;
}
.company-info .head .info .industry .text {
  margin-top: 0.1rem;
}
.company-info .head .actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.company-info .head .actions .action-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: background 0.3s ease;
  border-radius: 0.8rem;
}
.company-info .head .actions .action-icon:hover {
  background: var(--gray-100);
}
.company-info .head .actions .follow-btn {
  width: 9.4rem;
}
.company-info .bio {
  margin-top: 1.2rem;
  color: var(--gray-900);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}
