.sources .rest-items.open,
.sources .rest-items.open:deep() .collapse-inner {
  padding-bottom: 1rem;
  margin-bottom: -1rem;
}
.sources .rest-items {
  transition: all 200ms;
  margin-top: 0rem;
}
.sources .rest-items.open {
  margin-top: 1.2rem;
}
.sources .rest-items .rest {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  line-height: 1.7rem;
  min-height: 2.8rem;
  gap: 1.2rem;
}
.sources .rest-items .rest .source {
  min-width: 0;
}
.sources .rest-items .rest .less {
  justify-content: center;
}
