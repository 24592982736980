.progressbar {
  width: 100%;
  display: flex;
  min-height: 3.6rem;
  gap: 1.2rem;
  align-items: center;
}
.root {
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 0 none;
  height: 0.8rem;
  background: v-bind(c_bg);
  border-radius: 0.4rem;
}
.progress-label {
  display: inline-block;
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  width: 3.7rem;
  text-align: left;
}
:deep() .value {
  height: 100%;
  width: 0%;
  position: absolute;
  display: none;
  border: 0 none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  background: v-bind(c_color);
  border-radius: 0.4rem;
}
:deep() .label {
  display: none;
}
