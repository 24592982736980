.drop-file {
  padding: 0 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  border-radius: 1.2rem;
  border: 0.1rem solid var(--brand-200);
  background: rgba(255,241,243,0.7);
  box-shadow: var(--box-shadow);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
}
.drop-file * {
  pointer-events: none;
}
.drop-file.error {
  background: rgba(253,162,155,0.5);
  box-shadow: 0px 0px 0px 4px var(--error-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
  border-color: var(--error-500);
}
.drop-file .drop-block {
  min-height: 13.5rem;
  position: relative;
  border-radius: 1.2rem;
  border: 0.1rem solid var(--brand-500);
  background-color: var(--base-white);
  width: 100%;
  max-width: 51.2rem;
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  padding: 1.6rem 2.4rem;
}
.drop-file .drop-block .drop-img {
  position: absolute;
  right: 1.6rem;
  bottom: 1.2rem;
}
.drop-file .drop-block .drop-img img {
  display: block;
}
.drop-file .drop-block .description {
  margin-top: 1.2rem;
}
.drop-file .drop-block .description .click-to {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--text-primary);
}
.drop-file .drop-block .image {
  display: inline-block;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
  background: var(--base-white);
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);
}
.drop-file .drop-block .image .upload-icon {
  stroke: var(--gray-700);
}
