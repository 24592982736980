<template>
  <div class="file" relative>
    <div v-if="error" class="failed pointer" @click="$emit('retry')">
      <Icon icon="refresh-cw-01" stroke="var(--error-600)" width="1.6rem" height="1.6rem" class="icon"/>
    </div>

    <div v-else-if="processing || removing" class="loader">
      <Icon external icon="animated/logo-dots-90f" width="1.6rem" height="1.6rem" class="icon"/>
    </div>

    <FileIcon v-else class="icon" 
              :ext="ext" 
              :disabled="removing"
              :error="failed"
              font-size="0.8rem"
              height="3.2rem"
              width="2.6rem"
    />

    <div v-if="error" class="error-text">
      Oops! Something went wrong. Please try again
    </div>
    <div v-else class="info">
      <div class="name">{{ name }}</div>
      <div class="size">{{ $utils.format.bytes(size) }}</div>
    </div>
    <div :disabled="removing" class="close">
      <Icon stroke="var(--base-white)" 
            icon="x-close" width="1.6rem" height="1.6rem" 
            @click.stop="$emit('remove')"
      />
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.file {
  border-radius: 0.4rem
  border: 0.1rem solid var(--gray-200)
  padding: 0.4rem 0.8rem 0.4rem 0.4rem
  display: flex
  gap: 0.8rem
  align-items: center
  height: 100%
  max-width: 20rem
  min-width: 0

  .loader {
    display: flex
    align-items: center
    justify-content: center
    width: 3.2rem
    flex-shrink: 0
  }

  .failed {
    background: var(--error-100)
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
  }
  
  .error-text {
    color: var(--error-600)
    font-size: 1.2rem
    font-weight: 500
    line-height: 1.8rem
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
  }
  .info {
    min-width: 0
    .name {
      overflow: hidden
      color: var(--gray-700)
      text-overflow: ellipsis
      white-space: nowrap
      font-size: 1.2rem
      font-weight: 500
      line-height: 1.8rem
    }
    .size {
      overflow: hidden
      color: var(--gray-600)
      text-overflow: ellipsis
      font-size: 1.2rem
      font-weight: 400
      line-height: 1.8rem
    }
  }
  .close {
    border-radius: 50%
    background: var(--gray-600)
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15)
    cursor: pointer
    position: absolute
    right: -0.8rem
    top: -0.8rem
  }
}
</style>
<script>
export default {
  props: {
    name: {type: String, required: true},
    size: {type: Number, required: true},
    ext: {type: String, default: undefined},
    error: {type: Boolean, default: false},
    failed: {type: Boolean, default: false},
    processing: {type: Boolean, default: false},
    removing: {type: Boolean, default: false}
  },
  emits: ['remove', 'retry'],
}
</script>