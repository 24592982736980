<template>
  <div class="safari-plug">
    <img class="logo" src="/assets/logo.svg"/>
    <h2>Unsupported Browser</h2>
    <p>
      Browsers using the Safari engine (WebKit) are not supported. 
      <br>
      Please use a Chromium-based browser (e.g., Google Chrome, Edge) or Firefox.
    </p>
    <div class="buttons">
      <Button primary @click="open('https://www.google.com/chrome/')">Download Google Chrome</Button>
      <Button primary @click="open('https://www.mozilla.org/en-US/firefox/new/')">Download Mozilla Firefox</Button>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.safari-plug {
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: 100%
  .logo {
    width: 17.2rem
    margin: 0 auto
    display: block
  }
  p {
    text-align: center
  }
  .buttons {
    display: flex
    gap: 0.8rem
  }
}
</style>
<script>
export default {
  methods: {
    open(url) {
      window.open(url, '_blank')
    }
  }
}
</script>