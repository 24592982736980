.safari-plug {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.safari-plug .logo {
  width: 17.2rem;
  margin: 0 auto;
  display: block;
}
.safari-plug p {
  text-align: center;
}
.safari-plug .buttons {
  display: flex;
  gap: 0.8rem;
}
