<template>
  <div ref="editor" 
       class="medium-editor-container"
       @copy="onSelectionCopy"
  />
</template>
<style lang="stylus">
@import 'styles/medium-editor'
.medium-editor-divider {
  cursor: default !important
}
</style>
<style lang="stylus" scoped>
.medium-editor-container {
  font-size: 1.6rem
  font-weight: 400
  line-height: 2.4rem

  outline: none
  :deep() {
    a {
      cursor: pointer
      sup {
        display: inline
        text-align: center
        color: var(--gray-900)
        transition: background 0.2s, color 0.2s
        border-radius: 4rem
        font-size: 1rem !important
        padding: 0 0.3rem
        margin-right: -0.2rem
        margin-left: -0.1rem
        vertical-align: unset
        font-weight: normal
      }
      &:hover sup {
        color: var(--base-white)
        background: var(--brand-500)
      }
    }
  }
}
</style>
<script>
import 'medium-editor/dist/css/medium-editor.css'
import MediumEditor from 'medium-editor'
import {getSelectionHTML, getSelectionParentNode, createToolbarButton, createToolbarDivider} from './medium-editor-defs'
import {ICONS} from './medium-editor-icons'

const MAX_LEN = 10000

const CODE_Y = 89
const CODE_Z = 90
const CODE_BACKSPACE = 8


export default {
  mixins: [ModelValue(String)],
  emits: ['refrag', 'blur', 'focus', 'undo', 'redo', 'on-link', 'copy'],
  data() {
    return {
      editor: undefined,
      focused: false,
    }
  },
  watch: {
    value(){
      const html = this.getInnerHtml()
      if (this.value !== html) {
        this.setContent(this.value)
        if (this.focused) this.setCaretToEnd()
      }
    }
  },
  mounted() {
    this.initEditor()
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    initEditor(){
      if (this.editor) this.editor.destroy()
      this.editor = new MediumEditor(this.$refs.editor, {
        anchorPreview: false,
        placeholder: {
          text: ''
        },
        extensions: {
          'regenerate': createToolbarButton('regenerate', ICONS.regenerate, this.transformSelection),
          'brush': createToolbarButton('brush', ICONS.brush, this.transformSelection),
          'smile-circle': createToolbarButton('smile-circle', ICONS.smile_circle, this.transformSelection),
          'luggage': createToolbarButton('luggage', ICONS.luggage, this.transformSelection),
          'align-vertical': createToolbarButton('align-vertical', ICONS.align_vertical, this.transformSelection),
          'list': createToolbarButton('list', ICONS.list, this.transformSelection),
          'divider': createToolbarDivider(),
        },
        toolbar: {
          buttons: [
            'regenerate',
            // 'brush',
            // 'smile-circle',
            // 'luggage',
            // 'align-vertical',
            // 'list',
            // 'divider',
            {
              name: 'bold',
              contentDefault: ICONS.bold,
              aria: 'Bold'
            },
            {
              name: 'italic',
              contentDefault: ICONS.italic,
              aria: 'Italic'
            },
            {
              name: 'unorderedlist',
              contentDefault: ICONS.unorderedlist,
              aria: 'Unordered List'
            },
            {
              name: 'orderedlist',
              contentDefault: ICONS.orderedlist,
              aria: 'Ordered List'
            },
          ],
        }
      })

      this.setContent()

      this.editor.subscribe('editableInput', this.onChange)
      this.editor.subscribe('editableKeydown', this.onKeyDown)
      this.editor.subscribe('focus', (ev) => {
        this.focused = true
        this.$emit('focus', ev)
      })
      this.editor.subscribe('blur', (ev) => {
        this.focused = false
        this.$emit('blur', ev)
      })

      this.editor.subscribe('editableClick', this.onClick)
    },
    reset(){
      this.editor.destroy()
      this.initEditor()
    },
    onChange(ev) {
      const inner_text = this.getInnerText()
      if (inner_text.length > MAX_LEN) {
        const fake_div = document.createElement('div')
        fake_div.innerHTML = this.editor.getContent()
        const truncated = inner_text.substring(0, MAX_LEN)
        this.value = this.truncateNodes(fake_div, {text: truncated}).innerHTML
        this.reset()
        return
      }
  
      const html = this.getInnerHtml()
      if (this.value !== html) {
        this.value = this.getInnerHtml()
      }
    },
    onKeyDown(ev){
      // undo redo
      if (ev.ctrlKey || ev.metaKey) {
        const {keyCode} = ev
        if ([CODE_Z, CODE_Y].includes(keyCode)) {
          ev.preventDefault()
          this.$emit(keyCode === CODE_Y || keyCode === CODE_Z && ev.shiftKey ? 'redo' : 'undo', ev)
          return false
        }
      }

      if (ev.keyCode === CODE_BACKSPACE) {
        if (!this.value) {
          ev.preventDefault()
          return false
        }
      } 
    },
    onClick(ev){
      const link = ev.target.closest('a')
      if (link) this.$emit('on-link', link)
    },
    setContent(){
      this.editor.setContent(this.value, 0)
    },
    transformSelection(name){
      const toolbar = this.editor.getExtensionByName('toolbar')
      toolbar.hideToolbar()

      const element = this.editor.origElements
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)

      const beforeNode = document.createTextNode('<regenrate>')
      const afterNode = document.createTextNode('<regenrate>')

      range.insertNode(beforeNode)
      range.setStartAfter(beforeNode)
      range.collapse(false)
      range.insertNode(afterNode)

      const updated_html = element.innerHTML
      this.setContent()
  
      //TODO: emiters for ai-btns on toolbar
      switch (name) {
        case 'regenrate':
          return this.$emit('refrag', updated_html)
        default:
          return this.$emit('refrag', updated_html)
      }
    },
    getInput(){
      return this.$refs.editor
    },
    getInnerHtml() {
      const inner_text = this.getInnerText()
      let inner_html = this.editor.getContent()

      return inner_text ? inner_html : inner_text
    },
    getInnerText(){
      return this.getInput().innerText.replaceAll('\n','').trim()
    },
    async onSelectionCopy(){
      const parent = getSelectionParentNode()
      let selection = getSelectionHTML()
      switch (parent?.localName) {
        case 'ul': selection = `<ul>${selection}</ul>`; break
        case 'ol': selection = `<ol>${selection}</ol>`; break
        default:break
      }
      this.$emit('copy', selection)
    },
    setCaretToEnd() {
      const contentEditableElement = this.getInput()
      const range = document.createRange()
      range.selectNodeContents(contentEditableElement)
      range.collapse(false)
  
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    },
    truncateNodes(node, truncated) {
      if (node.nodeType === Node.TEXT_NODE) {
        const node_text = (node.textContent || node.innerText)
        if (truncated.text.length > 0) {
          const text_part = truncated.text.substring(0, node_text.length)
          node.textContent = text_part
          truncated.text = truncated.text.substring(text_part.length)
        }
        else {
          node.textContent = ''
        }

      } else if (node.nodeType === Node.ELEMENT_NODE) {
        for (let i = 0; i < node.childNodes.length; i++) {
          this.truncateNodes(node.childNodes[i], truncated)
        }
      }
      return node
    },
  }
}
</script>