<template>
  <AIChat ref="chat"
          class="chat-container" 
          :chat_id="chat.id"
          :history="chat.history" 
          :generating="generating"
          :regenerating="regenerating"
          mode="expanded"    
          description="Genrate can make mistakes. Consider checking important information."
          @regenerate="onChat"
          @stop="onStop"
          @ask-ai="onChat"
          @like="({idx, feedback}) => onLike(idx, feedback)"
          @dislike="({idx, feedback}) => onDislike(idx, feedback)"
          @save="({idx, text}) => onSave(idx, text)"
          @refrag="({idx, range}) => onRegenerateFragment(idx, range)"
  />
</template>

<style lang="stylus" scoped>
.chat-container {
  background: var(--bg-primary)
  font-size: 1.4rem

  .new-chat {
    height: 100%
  }
  
  :deep().footnote {
    font-size: 1rem !important
    margin: 0.4rem auto 0 auto !important
    color: var(--gray-500)
  }
}
</style>

<script setup>
import AIChat from 'components/ai-chat'
</script>

<script>
import {Chat} from 'stores/chats'

export default {
  props: {
    chat: {
      type: Chat,
      required: true
    }
  },
  data() {
    return {
      onLike: $utils.timers.debounce((idx, feedback) => this.chat.like(idx, feedback), 200),
      onDislike: $utils.timers.debounce((idx, feedback) => this.chat.dislike(idx, feedback), 200),
      onSave: $utils.timers.debounce((index, text) => this.chat.savePost(index, text), 400),
      regenerating: false
    }
  },
  computed: {
    generating() {
      return this.chat.generating
    }
  },
  mounted() {
    if (!this.generating) {
      this.$refs.chat.focus()
    }
  },
  methods: {
    async onChat(params) {
      await this.chat.doChat(params)
    },
    onStop() {
      this.chat.stopChat()
    },
    async onRegenerateFragment(history_idx, range) {
      this.regenerating = true
      await this.chat.regenratePostFragment(history_idx, range.start_idx, range.end_idx)
      this.regenerating = false
    }
  }
}
</script>
