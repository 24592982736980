<template>
  <div class="user-cancelled">
    <div :class="[mode]">
      <Icon class="icon" height="2rem" width="2rem" icon="alert-circle" :stroke="c_icon"/>
      <div class="text">{{ text }}</div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.user-cancelled {
  .compact {
    display: flex
    justify-content: start
    padding: 1rem 1.4rem
    background: var(--gray-600)
    border-radius: 8px 8px 8px 8px

    .text {
      font-size: 1.6rem
      font-weight: 400
      line-height: 2.4rem
      color: white
    }

    .icon {
      margin-top: 0.1rem
      margin-right: 0.8rem
    }
  }

  .expanded
  .onboarding {
    display: flex
    justify-content: start
    border: 0.1rem solid var(--gray-600)
    border-radius: 0.8rem
    background: transparent
    padding: 1.6rem
    margin-bottom: 1.2rem
    
    .text {
      font-size: 1.6rem
      font-weight: 500
      line-height: 2.4rem
      color: var(--gray-800)
    }

    .icon {
      margin-right: 1rem
      margin-top: 0.1rem
      min-width: 2rem
    }
  }
}
</style>

<script>
export default {
  props: {
    text: {type: String, required: true},
    mode: {type: String, required: true}
  },
  computed: {
    c_icon () {
      return this.mode === 'compact' ? 'white' : 'var(--gray-800)'
    }
  }
}
</script>