<template>
  <SettingsFrame title="Integrations and connected apps" description="Supercharge your workflow and connect your top sources">
    <div class="integrations">
      <Loader v-if="loading">
        Loading<br>
        Please wait...
      </Loader>
      <template v-else>
        <Segment v-for="item in integrations" :key="item.name" raised class="integration-item" :disabled="item.disabled" :red="item.store.enabled && !item.store.connected">
          <div class="content">
            <div class="head">
              <div class="logo-img">
                <img :src="item.logo" alt="logo">
              </div>
              <div class="name">{{ item.name }}</div>
              <Toggle v-model="item.store.enabled" class="toggle" :disabled="!item.store.connected" @change="() => onEnable(item.type, item.store.enabled)"/>
            </div>
            <div class="description">
              {{ item.description }}
            </div>
          </div>
          <Divider m="0" h="0.1rem"/>
          <div class="controls">
            <Button v-if="item.store.connected" primary text small @click="() => onDisconnect(item.store.type)">
              Disconnect
            </Button>
            <Button v-else primary small @click="() => onConnect(item.store.type)">
              {{ item.store.enabled ? 'Reconnect' : 'Connect' }}
            </Button>
          </div>
        </Segment>
      </template>
    </div>
  </SettingsFrame>
</template>

<style lang="stylus" scoped>  
.integrations {
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  gap: 2.4rem
  
  .integration-item {
    flex-basis: 38.5rem
    padding: 0
    display: flex
    flex-direction: column

    .content {
      padding: 2.4rem
      flex-grow: 1
      .head {
        display: flex
        gap: 1.2rem
        justify-content: space-between
        align-items: center
        .logo-img {
          border-radius: 0.8rem
          border: 0.1rem solid var(--gray-200)
          background: var(--base-white)
          width: 5.2rem
          height: 5.2rem
          display: flex
          align-items: center
          justify-content: center
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        }
        .name {
          font-size: 1.6rem
          font-weight: 500
          line-height: 2.4rem
          color: var(--gray-900)
          flex-grow: 1
        }
        .toggle {
          align-self: start
          display: block
          :deep() {
            label {
              padding-left: 3.5rem
            }
          }
        }
      }
      .description {
        margin-top: 2.6rem
      }
    }
    .controls {
      padding: 0.9rem 2.4rem
      display: flex
      justify-content: flex-end
    }
  }
}
</style>

<script>
import SettingsFrame from './settings-frame.vue'
import $store from 'stores/integrations'
import {getIntegrations} from 'consts/integrations.js'

export default {
  components: {
    SettingsFrame
  },
  data() {
    return {
    }
  },
  computed: {
    loading() {
      return $store.loading
    },
    integrations() {
      const result = []
      const integrations = getIntegrations()
      for(let item of integrations) {
        result.push({
          ...item,
          store: $store.getIntegration(item.type) || {enabled: false, connected: false, type: item.type}
        })
      }
      return result
    },
  },
  async mounted() {
    if (!$store.loading) await $store.load()
  },
  methods: {
    async onConnect(type) {
      await $store.connect(type)
    },
    async onDisconnect(type) {
      await $store.delete(type)
    },
    async onEnable(type, enabled) {
      $store.enable(type, enabled)
    }
  }
}
</script>
