<template>
  <div class="ai-answer delay-in-1000">
    <div class="answer-body">
      <div class="icon row-hvcenter">
        <AiLogo :animate="loading" stroke="var(--brand-500)"/>
      </div>
      <div v-if="text" class="answer-content">
        <Answer ref="answer" class="text" :text="text" :intermediate="item.intermediate" @text-added="onTextAdded"/>
        <AiInput v-if="input" class="ai-buttons" :disabled="!last" :input="input" @prompt="$emit('ask-ai', $event)"/>
      </div>
      <ChatLoader v-else class="chat-loader" :status="status" mode="onboarding"/>
    </div>
    <div v-if="last && completed && !loading" class="start row-hvcenter">
      <Button class="button" primary :disabled="starting" @click="onStart()">
        <template v-if="starting">
          <Loader inline small color="var(--base-white)"/>
        </template>

        <template v-else>
          <Icon height="1.4rem" width="1.4rem" stroke="var(--base-white)" icon="g-logo"/>
          Start Now
        </template>
      </Button>
    </div>
  </div>
</template>

<style lang="stylus" scoped>  
.ai-answer {
  .answer-body {
    display: flex
    gap: 1rem
    padding: 1.6rem 0 1.4rem 0
    .icon {
      flex-shrink: 0
    }
    .answer-content {
      .text {
        font-size: 1.6rem
        line-height: 3rem
        word-break: break-word
        color: var(--gray-900)
        font-weight: 400
      }
      .ai-buttons {
        margin-top: 1.6rem
      }
    }
    .icon {
      margin-top: -0.5rem
      width: 3.2rem
      height: 3.2rem
      border-radius: 50%
      border: 0.1rem solid var(--gray-300)
      background: var(--base-white)
    }
    .chat-loader {
      line-height: 2.3rem
      height: 2.3rem
    }
  }
  .start {
    margin-top: 1.6rem
    .button {
      width: 11.5rem
      height: 4.2rem
    }
  }
}
</style>

<script>
import Answer from './answer.vue'
import ChatLoader from '../chat-loader'
import AiInput from '../ai-input'
import AiLogo from '../ai-logo.vue'

export default {
  components: {
    Answer, ChatLoader, AiInput, AiLogo
  },
  props: {
    item: {type: Object, required: true},
    last: {type: Boolean, default: false},
    completed: {type: Boolean, default: false},
  },
  emits: ['text-added', 'start', 'ask-ai'],

  data() {
    return {
      typing: false,
      starting: false
    }
  },
  computed: {
    loading(){
      return this.item.intermediate || this.typing
    },
    status(){
      return this.item.data?.status || 'Thinking...'
    },
    text(){
      return this.item.data?.text
    },
    input() {
      return this.item.data?.input
    },
  },
  methods: {
    onTextAdded(full) {
      this.typing = !full
      this.$nextTick(() => this.$emit('text-added', full))
    },
    onStart(){
      this.starting = true
      this.$emit('start')
    },
  }
}
</script>