<template>
  <SettingsFrame title="All Agents" class="all-agents">
    <template v-slot:description>
      Enables the AI agents (Person Card, Company Card, Meeting Prep Card) to incorporate insights, news, and trends <br/> from the user’s favorite sources, ensuring that all cards stay current with trusted, relevant information
    </template>
    <template v-slot:right>
      <Autosave :saving="saving" :saved_at="saved_at"/>
    </template>
    <BuilderInputs :opts="schema" class="builder" @update="onUpdate"/>
  </SettingsFrame>
</template>

<style lang="stylus" scoped>
.all-agents {
  .builder {
    width: 100%
    max-width: 80rem
  }
}
</style>

<script>
import SettingsFrame from '../settings-frame.vue'
import Autosave from 'components/builder/autosave.vue'
import BuilderInputs from 'components/builder/builder-inputs.vue'
import {makeAgentsSchema} from '../settings-defs'

export default {
  components: {
    SettingsFrame,
    Autosave,
    BuilderInputs
  },
  provide() {
    return {
      store: this.all_agents,
    }
  },
  data() {
    return {
      all_agents: {
        external_sources: ['donik.com']
      },
      schema: makeAgentsSchema(),
      saved_at: undefined,
      saving: false,
      update: $utils.timers.debounce(this.onUpdate, 300)
    }
  },
  methods: {
    async onUpdate() {
      this.saving = true
      await $utils.timers.wait(1000)
      this.saved_at = Date.now()
      this.saving = false
    }
  }
}
</script>