<template>
  <span v-click-outside="onClickOutside">
    <el-popover ref="pp" :width="width"
                :popper-style="popperStyle"
                :popper-class="popover_class"
                :popper-options="popperOptions"
                :placement="placement"
                :visible="visible"
                :trigger="trigger"
                :show-arrow="showArrow"
                :disabled="disabled"
                :show-after="showAfter"
                :teleported="teleported"
                :offset="offset"
                aria-hidden="false"
                inert="true"
                @hide="$emit('hide')"
                @show="$emit('show')"
    >
      <template v-slot:reference>
        <slot name="reference"/>
      </template>
      <template v-slot>
        <slot/>
      </template>
    </el-popover>
  </span>
</template>

<style lang="stylus">
.popover {
  --el-text-color-regular: var(--text-secondary)
}
</style>

<script>
export default {
  mixins: [ModelValue(Boolean)],
  props: {
    width: {
      type: [Number, String],
      default: 'max-content',
    },
    popperStyle: {
      type: String,
      default: 'padding: 0; border-radius: 0.8rem;'
    },
    popperOptions:{
      type: Object,
      default: Object
    },
    popperClass: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    outsideExceptions: {
      type: Array,
      default: () => []
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    trigger: {
      type: String,
      default: 'click'
    },
    showAfter: {
      type: Number,
      default: 200
    },
    teleported: {
      type: Boolean,
      default: true
    },
    offset: {
      type: Number,
      default: undefined
    }
  },
  emits: ['hide', 'show'],
  computed: {
    popover_class() {
      return `popover ${this.popperClass}`.trim()
    },
    visible: {
      get() {
        if (this.trigger !== 'click') {
          return undefined
        }
        return this.value
      },
      set(val) {
        this.value = val
      }
    }
  },
  methods: {
    onClickOutside(ev){
      try {
        if (ev && this.$refs.pp?.popperRef?.contentRef?.contains(ev.target)) {
          return
        }
        if (this.outsideExceptions.some(x => ev.target.closest(x))) {
          return
        }
        this.value = false

      } catch (error) {
        // 
      }
    },
  }

}
</script>