.login-page {
  padding: 130px 0;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  display: flex;
  justify-content: center;
  min-height: 50rem;
  background: #fffdfb;
}
.login-page .form {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.login-page .header {
  width: 100%;
}
.login-page .header .logo {
  width: 17.2rem;
  margin: 0 auto;
  display: block;
}
.login-page .login-form-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  min-height: 212px;
}
.sign-in-header {
  padding-top: 2.4rem;
}
.sign-in-header .sign-in-title {
  text-align: center;
  font-size: 2.2rem;
  line-height: 1;
}
.sign-in-header .sign-in-subtitle {
  margin-top: 1.2rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  color: var(--gray-600);
}
.reset-pass-title {
  text-align: center;
  font-size: 2.2rem;
  line-height: 1;
}
@media (max-width: 1024px) {
  .login-page {
    padding: 83px 15px;
  }
  .login-page .header {
    margin: auto;
  }
  .login-page .header .logo {
    margin-top: 25px;
  }
}
