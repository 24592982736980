.autosave {
  display: flex;
  flex-direction: row;
  height: min-content;
  align-items: center;
  gap: 0.8rem;
}
.autosave .badge {
  margin-right: 0.8rem;
}
.autosave .badge-text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--gray-500);
}
