.segment {
  position: relative;
  background: var(--base-white);
  box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(16,24,40,0.05);
  margin: 0;
  padding: 1.6rem 1.6rem;
  border-radius: 1.2rem;
  border: 0.1rem solid var(--gray-200);
}
.segment.padded {
  padding: 2.3rem 2.9rem;
  border-radius: 1.6rem;
}
.segment.red:not(.inverted) {
  border-top: 0.2rem solid #db2828;
}
.segment.form {
  font-size: 1.6rem;
  position: relative;
}
.segment.raised {
  box-shadow: 0px 2px 4px -2px rgba(16,24,40,0.06), 0px 4px 8px -2px rgba(16,24,40,0.1);
  border-color: var(--gray-200);
}
.segment .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.segment .header .title {
  font-size: 1.6rem;
  color: var(--gray-500);
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
}
.segment .header .right {
  margin-left: auto;
}
.segment.expandable {
  cursor: pointer;
}
.segment.expandable .arrow {
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  border-radius: 1.1rem;
  border-bottom: 0.2rem solid;
  border-color: transparent;
  transition: border-color 0.3s ease;
}
.segment.expandable .arrow .icon {
  margin: 0 auto;
  stroke: var(--gray-300);
  transition: stroke 0.1s ease, transform 0.3s ease;
}
.segment.expandable:hover:not(.open) .arrow {
  border-color: var(--brand-200);
}
.segment.expandable:hover:not(.open) .arrow .icon {
  stroke: var(--brand-400);
}
.segment.expandable.open {
  cursor: default;
}
.segment.expandable.open .arrow .icon {
  transform: rotate(90deg);
}
.segment.expandable.open .arrow:hover {
  border-color: var(--brand-200);
}
.segment.expandable.open .arrow:hover .icon {
  stroke: var(--brand-400);
}
