.p-inputtext {
  color: #495057;
  background: var(--base-white);
  padding: 1rem 1.1rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  appearance: none;
  border-radius: 6px;
  box-shadow: var(--input-box-shadow);
}
.p-inputtext:enabled:hover {
  border-color: var(--primary-300);
}
.p-inputtext[aria-expanded="true"] {
  outline: none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16,24,40,0.05);
  border-color: var(--primary-300);
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}
.p-calendar {
  align-self: stretch;
  width: 100%;
}
.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #e24c4c;
}
.p-datepicker {
  background: var(--base-white);
  color: #495057;
  border-radius: 6px;
  width: 100%;
}
.p-datepicker .p-datepicker-panel .p-datepicker-header {
  padding: 0.5rem;
  color: #dee2e6;
  background: var(--base-white);
  font-weight: 600;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-prev-button,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-next-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s;
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-prev-button:hover,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-next-button:hover {
  color: #343a40;
  border-color: transparent;
  background: var(--gray-200);
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-prev-button:focus,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-next-button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-select-year,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-select-month,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-decade {
  color: #495057;
  transition: background-color 0.2s, color 0.2s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-select-year:enabled:hover,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-select-month:enabled:hover,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-decade:enabled:hover {
  color: var(--primary-500);
}
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-select-year .p-datepicker-select-month,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-select-month .p-datepicker-select-month,
.p-datepicker .p-datepicker-panel .p-datepicker-header .p-datepicker-title .p-datepicker-decade .p-datepicker-select-month {
  margin-right: 0.5rem;
}
.p-datepicker .p-datepicker-panel .p-datepicker-inline {
  background: var(--base-white);
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
  text-align: center;
}
.p-datepicker table th> span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td> span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid transparent;
  user-select: none;
  transition: background-color 0.2s, color 0.2s;
}
.p-datepicker table td> span.p-datepicker-day-selected,
.p-datepicker table td> span.p-datepicker-day-selected-range {
  color: var(--base-white);
  background: var(--primary-600);
}
.p-datepicker table td> span.p-datepicker-day-selected:hover,
.p-datepicker table td> span.p-datepicker-day-selected-range:hover {
  background: var(--primary-700);
}
.p-datepicker table td> span:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker table td.p-datepicker-today span {
  background: var(--gray-100);
  color: var(--gray-700);
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today span.p-datepicker-day-selected {
  color: var(--base-white);
  background: var(--primary-600);
}
.p-datepicker table td.p-datepicker-today:hover span {
  background: var(--gray-200) !important;
}
.p-datepicker table td> span:not(.p-datepicker-day-selected):not(.p-disabled):hover {
  background: var(--gray-200);
}
.p-datepicker table td> span:not(.p-datepicker-day-selected):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker table td.p-datepicker-other-month> span {
  color: var(--gray-300);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: var(--gray-200);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2rem;
}
.p-datepicker .p-timepicker button span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker button div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-datepicker-month-view {
  margin: 0.5rem 0;
}
.p-datepicker .p-datepicker-month-view .p-datepicker-month {
  padding: 0.5rem;
  border-radius: 6px;
}
.p-datepicker .p-datepicker-month-view .p-datepicker-month:not(.p-disabled):not(.p-datepicker-month-selected):hover {
  background: var(--gray-200);
}
.p-datepicker .p-datepicker-month-view .p-datepicker-month.p-datepicker-month-selected {
  color: var(--primary-700);
  background: var(--primary-50);
}
.p-datepicker .p-datepicker-year-view {
  margin: 0.5rem 0;
}
.p-datepicker .p-datepicker-year-view .p-datepicker-year {
  padding: 0.5rem;
  border-radius: 6px;
}
.p-datepicker .p-datepicker-year-view .p-datepicker-year.p-datepicker-year-selected {
  color: var(--primary-700);
  background: var(--primary-50);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled) .p-datepicker-month-view .p-datepicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker:not(.p-disabled) .p-datepicker-year-view .p-datepicker-year:not(.p-disabled):not(.p-datepicker-year-selected):hover {
  background: var(--gray-200);
}
.p-datepicker:not(.p-disabled) .p-datepicker-year-view .p-datepicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
