<template>
  <div v-if="loading">
    <Loader>
      Loading<br>
      Please wait...
    </Loader>
  </div>
  
  <div v-else class="connections">
    <div class="tabs">
      <Tabs v-model="active" 
            class="tabs" 
            :tabs="tabs" 
            bordered
            tab-position="right"
      />
      <Divider m="1.6rem 0 0"/>
      <UploadButton :loading="processing" 
                    style="width: 100%; margin-top: 2.4rem;" 
                    icon="file-plus-02" 
                    :accept="LINKEDIN_CONTACTS.exts.join(',')" 
                    @upload="onUpload"
      >
        Import CSV
      </UploadButton>
    </div>

    <div class="table">
      <Companies v-if="active === 'companies'" ref="companies" :processing="processing" @upload="onUpload"/>
      <Contacts v-if="active === 'contacts'" ref="contacts" :processing="processing" @upload="onUpload"/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.connections {
  display: grid
  grid-template-columns: max-content auto
  gap: 2.4rem
}
</style>

<script setup>
import {LINKEDIN_CONTACTS} from 'consts/ftypes.js'
</script>

<script>
import Contacts from './contacts.vue'
import Companies from './companies.vue'
import $contacts from 'stores/contacts'
import $companies from 'stores/companies'
import {get_store} from 'stores/files'
import {SETTING_CONNECTIONS_STORE_ID} from 'consts/settings'
import {settings as $settings} from 'utils'

export default {
  components: {Contacts, Companies},
  data() {
    return {
      active: 'companies',
      loading: true,
      fstore: undefined,
    }
  },
  computed: {
    companies() {
      return $companies
    },
    contacts() {
      return $contacts
    },
    tabs(){
      return [
        {value: 'companies', title: 'Companies', count: $companies.total},  
        {value: 'contacts', title: 'Contacts', count: $contacts.total}, 
      ]
    },
    processing() {
      if (!this.fstore) return false
      
      const [file] = this.fstore.files
      if (file) return file.processing
      
      const [upload] = this.fstore.uploads
      return upload !== undefined
    },
    failed () {
      if (!this.fstore) return false
      const [file] = this.fstore.files
      return file?.failed || false
    }
  },
  watch: {
    failed(val) {
      if (val) {
        $settings.remove(SETTING_CONNECTIONS_STORE_ID)
        this.fstore = undefined
      }
    },
    async processing(val) {
      if (!val && !this.failed) {
        await $companies.fetchTotal()
        await $contacts.fetchTotal()
      }
    }
  },
  async beforeMount() {
    this.loading = true
     
    const store_id = await $settings.get(SETTING_CONNECTIONS_STORE_ID)
    if (store_id) {
      const fstore = await get_store(LINKEDIN_CONTACTS.type, store_id)
      await fstore.load()
      this.fstore = fstore
    }

    await $companies.fetchTotal(),
    await $contacts.fetchTotal()

    this.loading = false
  },
  methods: {
    async onUpload(files) {
      const upload_id = crypto.randomUUID()
      $settings.put(SETTING_CONNECTIONS_STORE_ID, upload_id)
      this.fstore = get_store(LINKEDIN_CONTACTS.type, upload_id)
      this.fstore.upload(files[0])
    }
  }
}
</script>