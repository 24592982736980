.tags {
  display: flex;
  gap: 0.4rem;
}
.tags:deep().highlight {
  background: var(--gray-700);
}
.tags .popover .popover-tags {
  padding: 0.8rem 1.2rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: var(--gray-900);
}
