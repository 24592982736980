.chat-container {
  background: var(--bg-primary);
  font-size: 1.4rem;
}
.chat-container .new-chat {
  height: 100%;
}
.chat-container :deep().footnote {
  font-size: 1rem !important;
  margin: 0.4rem auto 0 auto !important;
  color: var(--gray-500);
}
