<template>
  <div v-if="int" class="success-login col-hvcenter ">
    <div class="col-hvcenter content">
      <div class="logo-img">
        <img :src="'/' + int.logo"/>
      </div>
      <div class="text">
        Failed to connect your <b>{{ int.name }}</b> account. Please try again later
      </div>
      <Button primary class="btn" @click="onClose">
        Close <div class="timer">({{ timer }})</div>
      </Button>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.success-login {
  height: 100vh
  width: 100vw
  background: var(--base-white)
  padding: 1rem
  .content {
    .logo-img {
      border-radius: 0.8rem
      border: 0.1rem solid var(--gray-200)
      background: var(--base-white)
      width: 8rem
      height: 8rem
      padding: 1rem
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
      img {
        display: block
        width: 100%
        height: 100%
        object-fit: contain
      }
    }
  }
  .text {
    margin-top: 3rem
    text-align: center
    background-color: var(--error-50)
    color: var(--error-600)
    box-shadow: 0 0 0 0.1rem var(--error-300) inset, 0 0 0 0 transparent
    padding: 1rem 1.5rem
    border-radius: 0.3rem
  }
  .btn {
    margin-top: 4rem
    width: 11rem
    .timer {
      width: 2rem
      text-align: right
    }
  }
}
</style>

<script>
import {INTEGRATIONS_META} from 'consts/integrations.js'
import {Later} from 'utils/timers'

export default {
  props: {
    type: {
      type: String, 
      required: true
    }
  },
  data(){
    return {
      int: undefined,
      timer: 10,
      later: new Later(1000)
    }
  },
  async mounted() {
    this.int = INTEGRATIONS_META[this.type] || {}
    await this.$nextTick()
    this.later.repeat(this.tick)
  },
  methods: {
    tick(){
      this.timer--
      if (this.timer === 0) this.onClose()
    },
    onClose(){
      this.later.abort()
      window.close()
    }
  }
}
</script>