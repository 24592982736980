.person .show-more {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
  color: var(--gray-600);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.person .head {
  display: flex;
  gap: 1.1rem;
}
.person .head .avatar {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.person .head .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.person .head .avatar .initials {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0.05rem solid var(--gray-300);
  border-radius: 50%;
  color: var(--gray-700);
  opacity: 0.8;
  background: var(--gray-100);
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8rem;
  width: 4.8rem;
  height: 4.8rem;
  overflow: hidden;
  text-transform: uppercase;
}
.person .head .info {
  color: var(--gray-900);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-width: 18rem;
}
.person .head .info .name {
  font-size: 2rem;
  line-height: 3rem;
}
.person .head .info .position {
  font-size: 1.4rem;
  height: 2rem;
}
.person .head .info .company {
  font-size: 1.4rem;
  height: 2rem;
}
.person .head .actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.person .head .actions .action-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: background 0.3s ease;
  border-radius: 0.8rem;
}
.person .head .actions .action-icon:hover {
  background: var(--gray-100);
}
.person .bio {
  margin-top: 1.2rem;
  color: var(--gray-900);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  width: 100%;
}
