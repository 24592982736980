.scrape {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}
.scrape .status {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.scrape .status .err {
  color: #ff7f7f;
}
.scrape .actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
