  .segment.uploader-segment {
    user-select: none
    padding: 1.6rem
    border-radius: 1.2rem
    display: flex
    transition: background 0.2s
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
    &.active {
      border-color: var(--input-focus-border-color)
      box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
    }

    &.error {
      border-color: var(--input-error-border-color)
    }

    .drop-block {
      width: 100%
      color: var(--gray-600)
      font-size: 1.4rem
      font-weight: 400
      line-height: 2rem
      display: flex
      place-items: center start
      gap: 1rem

      .description {
        flex-grow: 1
        text-align: center
        display: flex
        gap: 4px
        flex-direction: column

        .click-to {
          font-size: 1.4rem
          font-weight: 600
          line-height: 2rem
          color: var(--brand-600)
        }
      }
    
      .image {
        display: inline-block
        padding: 1rem
        border-radius: 0.8rem
        border: 0.1rem solid var(--gray-200)
        background: var(--base-white)
        box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05)
        .upload-icon {
          stroke: var(--gray-700)
        }

        &.error {
          border-color: var(--input-error-border-color)

          .upload-icon {
            stroke: var(--error-700)
          }
        }
      }
    }
  }

  .segment.list {
    margin-top: 1.6rem
    padding: 0
    overflow: hidden
    border-color: var(--border-secondary)

    .item:not(:last-child) {
      border-bottom: 0.1rem solid var(--border-secondary)
    }
  }