<template>
  <div class="logout">
    <div>
      You're logged in as <b>{{ session?.tokens?.idToken?.payload?.email }}</b>
    </div>
    <div>User id is {{ user?.userId }}</div>
    <div>Cognito pool is {{ cognito_pool }}</div>
    <div>Backend url is {{ backend_url }}</div>
    <Button primary @click="logout">Log Out</Button>  
  </div>
</template>

<style lang="stylus" scoped>
.logout {
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 0.5rem
  .button {
    margin-top: 1.5rem
  }
}
</style>

<script>
import {getCurrentUser, fetchAuthSession} from '@aws-amplify/auth'
import $global from 'stores/global'

export default {
  data() {
    return {
      cognito_pool: COGNITO_POOL,
      backend_url: BACKEND_URL,
      user: undefined,
      session: undefined
    }
  },
  async mounted() {
    this.user = await getCurrentUser()
    this.session = await fetchAuthSession()
  },
  methods: {
    async logout() {
      await $global.logout()
    }
  }
}
</script>
