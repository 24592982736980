.user-message.compact .inner {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.4rem;
  background: var(--brand-500);
  border-radius: 8px 0px 8px 8px;
}
.user-message.compact .inner .text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: var(--base-white);
}
.user-message.compact .inner .icon {
  margin-top: 0.2rem;
  min-width: 2rem;
}
.user-message.expanded {
  padding: 1.6rem 0 1.4rem 0;
  display: flex;
  gap: 1rem;
}
.user-message.expanded .text {
  color: var(--gray-900);
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-style: normal;
  font-weight: 400;
  word-break: break-word;
}
.user-message.expanded .icon {
  margin-top: 0.5rem;
}
.user-message.onboarding {
  padding: 1.6rem 0 1.4rem 0;
  display: flex;
  gap: 1rem;
}
.user-message.onboarding .text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 3rem;
  word-break: break-word;
}
.user-message.onboarding .icon {
  margin-top: -0.1rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 0.1rem solid var(--gray-300);
  background: var(--base-white);
}
