@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.1;
  }
}
.initials {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0.05rem solid var(--gray-300);
  border-radius: 50%;
  color: var(--gray-700);
  opacity: 0.8;
  background: var(--gray-100);
  font-size: v-bind(fontSize);
  font-style: normal;
  font-weight: 500;
  line-height: 1.8rem;
  width: v-bind(width);
  height: v-bind(height);
  overflow: hidden;
  text-transform: uppercase;
}
.initials img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.initials.pulse {
  animation: pulse 2s infinite;
}
.initials.error {
  background: var(--error-100);
  border-color: var(--error-300);
  color: var(--error-700);
}
.info {
  margin-left: 1.4rem;
  min-width: 0;
  font-size: 1.4rem;
}
.info .skeleton {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.info .skeleton div {
  background: var(--gray-100);
  animation: pulse 2s infinite;
  height: 1.9rem;
}
.info .text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.info .text .error {
  color: var(--error-600);
}
.info .text .size {
  color: var(--gray-600);
  font-weight: 400;
  line-height: 20px;
}
.info .text .name {
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
