<template>
  <div class="segment" :class="{form, raised, padded, red, expandable: $slots.extra, open: expanded}" @click="onSegment">
    <div v-if="$slots['header-left'] || title || $slots['header-right']" class="header">
      <slot v-if="$slots['header-left']" name="header-left"></slot>
      <div v-else-if="title" class="title">{{ title }}</div>
      <div v-if="$slots['header-right']" class="right">
        <slot name="header-right"/>
      </div>
    </div>
    <slot class="content"/>
    <template v-if="$slots.extra">
      <Collapse v-model="expanded">
        <slot name="extra"/>
      </Collapse>
      <div class="arrow pointer" @click.stop="toggleExpand">
        <Icon class="icon" icon="chevron-down" width="2.4rem" height="2.4rem"/>
      </div>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.segment {
  position: relative
  background: var(--base-white)
  box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(16, 24, 40, 0.05)
  margin: 0
  padding: 1.6rem 1.6rem
  border-radius: 1.2rem
  border: 0.1rem solid var(--gray-200)
  &.padded {
    padding: 2.3rem 2.9rem
    border-radius: 1.6rem
  }
  &.red:not(.inverted) {
    border-top: 0.2rem solid #DB2828
  }
  &.form {
    font-size: 1.6rem
    position: relative
  }
  &.raised {
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)
    border-color: var(--gray-200)
  }
  .header {
    display: flex
    justify-content: space-between
    align-items: center
    gap: 1rem
    
    .title {
      font-size: 1.6rem
      color: var(--gray-500)
      font-style: normal
      font-weight: 600
      line-height: 2.4rem
      margin-bottom: 1.6rem
    }
    .right {
      margin-left: auto
    }
  }
  &.expandable {
    cursor: pointer
    .arrow {
      position: absolute
      bottom: -0.1rem
      left: 0
      width: 100%
      border-radius: 1.1rem
      border-bottom: 0.2rem solid
      border-color: transparent
      transition: border-color 0.3s ease
      .icon {
        margin: 0 auto
        stroke: var(--gray-300)
        transition: stroke 0.1s ease, transform 0.3s ease
      }
    }
    &:hover:not(.open) {
      .arrow {
        border-color: var(--brand-200)
        .icon {
          stroke: var(--brand-400)
        }
      }
    }
    &.open {
      cursor: default
      .arrow {
        .icon {
          transform: rotate(90deg)
        }
        &:hover {
          border-color: var(--brand-200)
          .icon {
            stroke: var(--brand-400)
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'segment',
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    form: {
      type: Boolean,
      default: false,
      required: false
    },
    raised: {
      type: Boolean,
      default: false,
      required: false
    },
    padded: {
      type: Boolean,
      default: false,
      required: false
    },
    red: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    onSegment() {
      if (this.$slots.extra && !this.expanded) {
        this.toggleExpand()
      }
    },
    toggleExpand(){
      this.expanded = !this.expanded
    }
  }
}
</script>