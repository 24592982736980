<template>
  <div class="chat-input" :class="[mode]">
    <template v-if="mode === 'expanded'">
      <ChatInputExp ref="input"
                    v-model="value"
                    :placeholder="placeholder"
                    :description="description"
                    :disabled="disabled"
                    :generating="generating"
                    :enable-uploads="enable_uploads"
                    @regenerate="onRegenerateExpanded"
                    @stop="onStopExpanded"
      />
    </template>
    <template v-else-if="mode === 'onboarding'">
      <ChatInputExp ref="input"
                    v-model="value"
                    :enable-uploads="false"
                    :fade="false"
                    :placeholder="placeholder"
                    :description="description"
                    :disabled="disabled"
                    :generating="generating"
                    @regenerate="onRegenerateExpanded"
                    @stop="onStopExpanded"
      />
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.chat-input {
  width: 100%
  max-width: 90rem
  margin: 0 auto
  border-top: none
  background: transparent
  padding-top: 0
  padding-left: 1.4rem
  padding-right: 2rem
  padding-bottom: 2rem
  .input {
    flex-grow: 1
  }
}
</style>

<script>
import ChatInputExp from './chat-input-exp.vue'
import $global from 'stores/global'

export default {
  components: {ChatInputExp},
  props: {
    generating: {type: Boolean, default: false},
    disabled: {type:Boolean, default: false},
    loading: {type: Boolean, default: false},
    placeholder: {type: String, default: ''},
    description: {type: String, default: ''},
    mode: {type: String, default: 'compact'},
  },
  emits: ['regenerate', 'stop'],
  data () {
    return {
      value: ''
    }
  },
  computed: {
    enable_uploads() {
      return $global.fileUploadEnabled
    }
  },
  methods: {
    focus() {
      const input = this.$refs.input
      input.focus()
    },
    preparePrompt(prompt) {
      // capitalize first word of each sentence
      return prompt.replace(/(?<=(?:[.?!]\s|^)\W*)[a-z]/g, i => i.toUpperCase())
    },
    onRegenerateCompact() {
      this.$emit('regenerate', this.preparePrompt(this.value))
      this.value = ''
    },
    onRegenerateExpanded(files) {
      const params = {
        text: this.preparePrompt(this.value)
      }
      if (files.length) {
        params['files'] = files
      }
      this.$emit('regenerate', params)
      this.value = ''
    },
    onStopExpanded() {
      this.$emit('stop')
    }
  }
}
</script>