.header {
  user-select: none;
}
.header span {
  color: var(--gray-600);
  font-size: 1.4rem;
  line-height: 2rem;
}
.header .icon:deep() * {
  stroke: var(--gray-400) !important;
}
.segments {
  overflow-y: overlay;
  margin-top: 0.8rem;
  margin: 1rem 0;
  border: 1px solid #d0d5dd;
  box-shadow: none;
  border-radius: 0.8rem;
}
.segments :deep() .segment {
  top: 0;
  bottom: 0;
  border-radius: 0;
  margin: 0;
  width: auto;
  box-shadow: none;
  border: none;
  border-top: 0.1rem solid var(--gray-300);
  background: transparent;
}
.segments :deep() .segment:first-child {
  border-top: none;
}
.segments :deep() .segment.active {
  background-color: var(--primary-25);
}
