<template>
  <SourcePopover ref="popover" 
                 v-model="show_tip" 
                 :summary="summary" 
                 :img="logo" 
                 :name="name" 
                 :type="type"
                 :url="url"
                 :disabled="disabled"
                 placement="bottom"
                 @hide="show_tip = false"
                 @show="show_tip = true"
  >
    <sup :class="{pointer: pointer, focused: show_tip}" @click.stop="pointer && onRefClick()">
      {{ num }}
    </sup>
  </SourcePopover>
</template>
<style lang="stylus" scoped>
sup {
  display: inline
  text-align: center
  color: var(--gray-900)
  transition: background 0.2s, color 0.2s
  border-radius: 4rem
  font-size: 1rem !important
  padding: 0 0.3rem
  margin-right: -0.2rem
  margin-left: -0.1rem
  &.focused
  &:hover {
    color: var(--base-white)
    background: var(--brand-500)
  }
}
</style>
<script>
import SourcePopover from './source-popover.vue'
export default {
  components: {SourcePopover},
  inject: ['di_sources'],
  props: {
    num: {
      type: Number, required: true
    },
    disabled: {
      type: Boolean, default: false
    }
  },
  data(){
    return {
      show_tip: false
    }
  },
  computed: {
    source(){
      return this.di_sources[this.num - 1]
    },
    type(){
      return this.source?.type
    },
    name(){
      return this.source?.name
    },
    logo(){
      return this.source?.logo
    },
    url(){
      return this.source?.url
    },
    file_id(){
      return this.source?.file_id
    },
    summary(){
      if (['api', 'file', 'genrate'].includes(this.type)) {
        return this.source?.summary
      }
      return this.source?.summary || this.url
    },
    pointer(){
      return !this.show_tip && !this.disabled
    },
  },
  methods: {
    onSrcClick(){
      this.$refs.popover.onSrcClick()
    },
    onRefClick() {
      if (this.summary) this.show_tip = true
      else this.onSrcClick()
    },
  },

}
</script>