<template>
  <div class="login-page" relative>
    <authenticator ref="auth" :hide-sign-up="true" :login-mechanisms="['email']" :form-fields="formFields">
      <template v-slot:header>
        <div class="header">
          <img class="logo" src="/assets/logo.svg"/>
        </div>
      </template>
      <template v-slot:sign-in-header>
        <div class="sign-in-header">
          <div class="sign-in-title amplify-heading amplify-heading--3">
            Log into your account
          </div>
          <div class="sign-in-subtitle">
            Welcome back! Please enter your details.
          </div>
        </div>
      </template>
      <template v-slot:forgot-password-header>
        <div class="reset-pass-title amplify-heading amplify-heading--3">
          Reset Password
        </div>
      </template>
      <template v-slot:sign-in-form="{onInput, onSignInSubmit, onForgotPasswordClicked}">
        <form ref="eform" method="post" :disabled="auth.isPending || loading" data-amplify-form @input="onInput" @submit.prevent="onSignInSubmit">
          <div class="amplify-flex amplify-authenticator__column">
            <fieldset class="amplify-flex amplify-authenticator__column">
              <div class="amplify-flex amplify-field amplify-textfield">
                <template v-if="stage=='email'">
                  <label class="amplify-label" for="username">Email</label>
                  <input ref="email" class="amplify-input amplify-field-group__control" placeholder="Enter your email" type="email" name="username" required="true"/>
                </template>
                <template v-if="stage=='password'">
                  <label class="amplify-label" for="password">Password</label>
                  <input ref="password" class="amplify-input amplify-field-group__control" type="password" name="password" required="true"/>
                </template>
              </div>
              <div v-if="auth.error" class="amplify-alert amplify-alert--error">
                {{ auth.error }}
              </div>
            </fieldset>
            <template v-if="stage=='email'">
              <amplify-button variation="primary" :fullwidth="true" @click.prevent="onEmailContinue" @submit.prevent="() => {}">
                Continue
              </amplify-button>
            </template>
            <template v-if="stage=='password'">
              <amplify-button variation="primary" :fullwidth="true" type="submit">
                Sign In
              </amplify-button>
            </template>
            <amplify-button :style="{visibility: stage == 'password' ? 'visible' : 'hidden'}" variation="link"
                            size="small" 
                            @click="onForgotPassword(onForgotPasswordClicked)"
            >
              Forgot your password?
            </amplify-button>
          </div>
        </form>
      </template>
      <template v-slot:sign-in-footer>
        <!-- There should be something to hide default 'Forgot password' -->
        <div/>
      </template>
      <!-- <template v-slot="{ user, signOut }">
        <strong>Welcome!</strong>
        <button @click="signOut">Sign Out</button>
        <p>{{ JSON.stringify(user) }}</p>
        <pre>{{ JSON.stringify(auth, '<br>', 2) }}</pre>
      </template> -->
    </authenticator>
  </div>
</template>

<style lang="stylus" scoped>
.login-page {
  padding: 130px 0
  height: 100%
  overflow-y: auto
  background: #FFF
  display: flex
  justify-content: center
  min-height: 50rem
  background: #FFFDFB

  .form {
    width: 20rem
    display: flex
    flex-direction: column
    gap: 0.8rem
  }

  .header {
    width: 100%
    .logo {
      width: 17.2rem
      margin: 0 auto
      display: block
    }
  }

  .login-form-container {
    height: 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 16px
    min-height: 212px
  }
}

.sign-in-header {
  padding-top: 2.4rem
  .sign-in-title {
    text-align: center
    font-size: 2.2rem
    line-height: 1
  }

  .sign-in-subtitle {
    margin-top: 1.2rem
    font-size: 1.2rem
    font-weight: 400
    text-align: center
    line-height: 1
    color: var(--gray-600)
  }
}


.reset-pass-title {
  text-align: center
  font-size: 2.2rem
  line-height: 1
}

@media (max-width 1024px) {
  .login-page {
    padding: 83px 15px
    .header {
      margin: auto
      .logo {
        margin-top: 25px
      }
    }
  }
}
</style>

<!-- Amplify styles should be not scoped -->
<style lang="stylus">
@import '@aws-amplify/ui-vue/styles.css'

:root {
  --amplify-colors-primary-10: var(--brand-50)
  --amplify-colors-primary-20: var(--brand-100)
  --amplify-colors-primary-80: var(--brand-600)
  --amplify-colors-primary-90: var(--brand-700)
  --amplify-colors-primary-100: var(--brand-800)
  --amplify-components-tabs-item-font-size: 1.2rem
  --amplify-components-field-font-size: 1.4rem
  --amplify-font-sizes-small: 1.2rem
  --amplify-font-sizes-medium: 2rem
  --amplify-space-medium: 1.7rem
  --amplify-components-tabs-panel-padding-block: 0
  --amplify-components-fieldcontrol-focus-box-shadow: none
  --amplify-components-authenticator-router-border-color: transparent
  --amplify-components-authenticator-router-box-shadow: none
  --amplify-components-button-border-radius: 0.8rem
  --amplify-components-fieldcontrol-border-radius: 0.8rem
  --amplify-components-button-font-weight: 700

  --amplify-components-fieldcontrol-color: var(--gray-700)
  --amplify-components-textfield-font-size: 1.4rem
  --amplify-components-text-error-color: var(--error-600)
  --amplify-components-fieldcontrol-border-color: var(--gray-300)
  --amplify-components-button-hover-border-color: none

  --amplify-components-fieldcontrol-focus-border-color: var(--input-hover-border-color)
  --amplify-components-button-focus-border-color: var(--input-hover-border-color)
  --amplify-components-button-hover-color: var(--input-hover-border-color)
  --amplify-components-button-link-color: var(--gray-600)
  --amplify-components-button-link-hover-color: var(--amplify-components-button-link-color)
  --amplify-components-button-link-focus-color: var(--amplify-components-button-link-color)
  --amplify-components-button-link-active-color: var(--amplify-components-button-link-color)
  --amplify-components-button-link-hover-background-color: var(--gray-100)
  --amplify-components-button-link-focus-background-color: var(--gray-100)
  --amplify-components-button-link-active-background-color: var(--gray-200)
  --amplify-colors-background-primary: #FFFDFB
}
.amplify-label {
  font-weight: 500
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 0.8rem
  border-end-start-radius: 0.8rem
}
[data-amplify-heading] {
  text-align: center
}
[data-variation="error"]:not(.amplify-flex.amplify-alert.amplify-alert--error.amplify-authenticator__base) {
  font-size: 1.2rem
  font-weight: 400
}
[data-variation="error"].amplify-flex.amplify-alert.amplify-alert--error.amplify-authenticator__base
.amplify-alert.amplify-alert--error {
  font-size: 1.2rem
  font-weight: 400
  line-height: 1
}

[aria-label="Dismiss alert"] {
  padding: 0
  &:hover {
    background: none
  }
}

.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small.amplify-field-group__control.amplify-authenticator__font {
  font-weight: 700 !important
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus):hover {
  border-inline-start-color: var(--input-hover-border-color)
}

.amplify-field__show-password {
  background: transparent
  color: var(--brand-600)
  &:hover
  &:focus {
    color: var(--brand-700)
    border-color: var(--input-hover-border-color)
    border-inline-start-color: var(--input-hover-border-color)
  }
  &:focus {
    box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
  }
}

.amplify-input {
  &:hover
  &:focus {
    border-color: var(--input-hover-border-color)
  }
  &:focus {
    box-shadow: 0px 0px 0px 4px var(--primary-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
  }
}

[data-amplify-authenticator-confirmresetpassword] p.amplify-text--error+p.amplify-text--error {
  display: none
}

[data-amplify-authenticator] {
}
</style>

<script>
import {useAuthenticator} from '@aws-amplify/ui-vue'
import {signInWithRedirect} from 'aws-amplify/auth'

export default {
  data() {
    const auth = useAuthenticator()
    return {
      auth, 
      loading: false,
      stage: 'email',
      formFields: {
        forgotPassword: {
          username: {
            label: 'Email',
            placeholder: 'Enter your email',
          },
        },
      }
      
    }
  },
  watch: {
    'auth.isPending': function (val) {
      if (val === false) {
        this.stage = 'email'
      }
    },
    stage(val) {
      if (val === 'email') {
        this.$nextTick(() => {
          if (this.$refs.email) {
            this.$refs.email.value = ''
          }
        })
      }
    }
  },
  async mounted() {
    // Enable if SAML IDP-initated login support is requested
    // TODO: Should be changed when amply support for this login type is added.
    //       Refer https://github.com/aws-amplify/amplify-js/issues/13343
    // Example Azure RelayState URL for dev: 
    //       identity_provider=Genrate.AI&client_id=7p3oknno1r60i8t07op2mc4hli&response_type=code&scope=openid+email+profile&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Flogin%3Fidp_initiated%3Dtrue%26idp%3DGenrate.AI
    if (this.$route.query.idp_initiated?.toLowerCase() === 'true') {
      if (!this.$route.query.idp) throw Error('no idp in idp-initiated sign in')
      await signInWithRedirect({provider: {custom: this.$route.query.idp}})
    }
  },
  methods: {
    async onEmailContinue() {
      if (!this.$refs.email.checkValidity()) {
        this.$refs.email.reportValidity()
        return
      }

      this.loading = true
      try {
        const domain = this.$refs.email.value.split('@')[1].toLowerCase()
        const realmProviders = JSON.parse(PROVIDERS)
        let provider = realmProviders.domain_providers[domain]
        if (!provider) {
          provider = 'COGNITO'
        }

        if (provider == 'COGNITO') this.stage = 'password'
        else await signInWithRedirect({provider: {custom: provider}})
      }
      finally {
        this.loading = false
      }
    },
    onForgotPassword(amplifyForgot) {
      this.stage = 'email'
      amplifyForgot()
    }
  }
}
</script>
