<template>
  <div class="settings">
    <div class="header">Settings</div>
    <Tabs v-model="active" class="tabs" :tabs="tabs" bordered/>
    <div class="content">
      <Profile v-if="active === 'profile'"/>
      <ToneOfVoice v-if="active === 'tone-of-voice'"/>
      <Integrations v-if="active === 'integrations'"/>
      <Feed v-if="active === 'feed'"/>
      <Superuser v-if="is_superuser && active === 'superuser'"/>
      <Admin v-if="is_admin && active === 'admin'"/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.settings {
  height: 100%
  width: 100%
  background: var(--bg-primary)
  padding: 1.6rem 3.6rem
  display: flex
  flex-direction: column

  .header {
    font-size: 2.3rem
    font-weight: 500
    line-height: 3.8rem
  }

  .tabs {
    margin-top: 2rem
    margin-bottom: 1.2rem
  }

  .content {
    flex-grow: 1
    min-height: 0
    &>* {
      height: 100%
    }
  }

  :deep() {
    .builder-inputs {
      .field {
        margin: 0 0 1em
        &>label:not(.checkbox) {
          display: block
          color: var(--gray-700)
          margin: 0 0 0.6rem 0
          font-size: 1.4rem
          font-weight: 500
          text-transform: none
        }
      }
    }
  }
}
</style>

<script setup>
import Profile from './profile'
import ToneOfVoice from './tone-of-voice.vue'
import Integrations from './integrations.vue'
import Feed from './feed'
import Superuser from './superuser'
import Admin from './admin'
</script>

<script>
import $profile from 'stores/profile'
import $global from 'stores/global'

export default {
  computed: {
    is_superuser() {
      return $profile.is_superuser
    },
    is_admin() {
      return $profile.is_admin
    },
    tabs() {
      const result = [
        {value: 'profile', title: 'Profile'},
        {value: 'tone-of-voice', title: 'Tone of Voice'},
        {value: 'integrations', title: 'Integrations'},
        {value: 'feed', title: 'Feed'}
      ]

      if (this.is_superuser) {
        result.push({value: 'superuser', title: 'Superuser'})
      }
      if (this.is_admin) {
        result.push({value: 'admin', title: 'Admin'})
      }

      return result
    },
    active: {
      get(){
        return this.$route.query.tab
      },
      set(val){
        $global.goToSettings(val)
      }
    },
  },
  beforeMount(){
    const {tab} = this.$route.query
    if (tab && this.tabs.some(x => x.value === tab)) return
    $global.goToSettings('profile', {replace: true})
  }
}
</script>
