<template>
  <div class="navbar-item pointer" :class="{active}">
    <div class="link">
      <div v-if="icon">
        <Icon stroke="var(--gray-500)" 
              :icon="icon" 
              :width="icon_size" 
              :height="icon_size"
        />
      </div>
      <span class="title" :class="{smaller: level === 2}">{{ title }}</span>
    </div>
    <div class="count">
      <span v-if="store" class="label">
        {{ store.total }}
      </span>
      <div :class="{opened}" class="arrow">
        <Icon v-if="children"
              stroke="var(--gray-500)"
              icon="chevron-down"
              :width="icon_size" 
              :height="icon_size"
              @click.stop.prevent="$emit('arrow')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped> 
@import 'styles/animations'
.collapsed {
  .navbar-item {
    .title
    .count {
      display: none
    }
  }
}

.navbar-item {
  transition: background 0.2s
  display: flex
  height: 4rem
  border-radius: 0.8rem
  padding: 0.8rem 1rem
  &:hover {
    background: var(--gray-100)
    .count {
      span {
        border-color: var(--gray-200)
        background: var(--gray-50)
      }
    }
  }

  &.active {
    background: var(--gray-100)
    .count {
      span {
        border-color: var(--gray-200)
        background: var(--gray-50)
      }
    }
  }

  .link {
    display: flex
    align-items: center
    gap: 1.2rem
    overflow: hidden
    flex-shrink: 0
    flex-grow: 1

    .title {
      animation: fade-in 0.9s
      font-size: v-bind(font_size)
      font-weight: v-bind(font_weight)
      color: var(--gray-700)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      &.smaller {
        font-weight: 400
        font-size: 1.4rem
      }
    }
  }

  .count {
    display: flex

    .label {
      padding: 0.6rem
      border: 1px solid rgb(234, 236, 240)
      border-radius: 500rem
      font-size: 1.2rem
      line-height: 1.2rem
      color: rgb(52, 64, 84)
      min-width: 2.4rem
      min-height: 2.4rem
      text-align: center
    }

    .arrow {
      &.opened {
        transform: rotate(180deg)
      }
    }

    span {
      border-color: var(--gray-200)
      background: var(--gray-50)
    }
  }
}
</style>

<script>
export default {
  props: {
    icon: {type: [String], default: undefined},
    children: {type: Number, default: 0},
    level: {type: Number, default: 0},
    title: {type: String, requied: true, default: undefined},
    active: {type: Boolean, default: false},
    opened: {type: Boolean, default: false},
    store: {type: [Object, undefined], default: undefined},
  },
  emits: ['arrow'],
  computed: {
    is_nested() {
      return this.level > 0
    },
    icon_size(){
      return this.is_nested ? '2rem' : '2.4rem'
    },
    font_size(){
      return this.is_nested ? '1.4rem' : '1.6rem'
    },
    font_weight(){
      return this.is_nested ? '400' : '500'
    }
  }
}
</script>
