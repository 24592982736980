<template>
  <div class="feed-inner" relative>
    <div v-if="collapsible" class="head">
      <Icon v-if="collapsible" stroke="var(--gray-500)"
            class="pointer"
            style="transform: rotate(135deg)" 
            icon="download-02" 
            width="1.6rem" 
            height="1.6rem"
            @click="$emit('collapse')"
      />
      <div class="controls">
        <span class="title">Feed</span>
        <!-- TODO: Uncomment when features will be done -->
  
        <div class="all" :class="{active: !filters.favorite, pointer: filters.favorite}" @click="() => filters.favorite && onFilter({favorite: false})">All</div>
        <!-- <div class="events pointer" :class="{active: filters.news}" @click="() => !filters.news && onFilter({news: !filters.news})">Events</div> -->
        <Icon icon="star-01" :class="{active: filters.favorite, pointer: !filters.favorite}" width="1.6rem" height="1.6rem" stroke-width="1" 
              @click="() => !filters.favorite && onFilter({favorite: true})"
        />
        <!-- <Popover v-model="show_filter" :show-arrow="false" width="21.1rem">
            <template v-slot:reference>
              <Icon v-if="filters_active" icon="filter-lines" width="1.6rem" height="1.6rem" 
                    stroke="var(--brand-500)" 
                    class="pointer" 
                    @click="show_filter = true"
              />
              <Icon v-else icon="filter-lines" width="1.6rem" height="1.6rem" 
                    stroke="var(--gray-500)" 
                    class="pointer" 
                    @click="show_filter = true"
              />
            </template>
            <Filter :filters="filters" @apply="onFilter"/>
          </Popover> -->
        <div class="row-hvcenter pointer" @click="goToSettings()">
          <Icon v-if="unseen > 0" 
                stroke="var(--gray-900)" 
                width="1.6rem" 
                height="1.6rem" 
                icon="filter-lines-notification"
          />
          <Icon v-else
                stroke="var(--gray-900)" 
                width="1.6rem" 
                height="1.6rem" 
                icon="filter-lines"
          />
        </div>
      </div>
    </div>
    <div ref="feed" v-infinite-scroll="onLoadMore" :infinite-scroll-distance="30" class="feed-content" @wheel="onWheel">
      <Loader v-if="loading && empty" huge>
        Loading feed...
      </Loader>
        
      <Empty v-else-if="empty" 
             :bordered="false" 
             class="empty" bg-size="36rem" circle-size="7.6rem"
      >
        <template v-slot:sub-text>
          You're all set!<br>
          Move mouse wheel to refresh
        </template>
        <template v-slot:image>
          <Icon stroke="var(--brand-500)"
                width="3rem" 
                height="3rem" 
                icon="bell-03"
          />
        </template>
      </Empty>
      <template v-else>
        <TransitionGroup name="list" class="feed-items" tag="div" duration="300">
          <Collapse v-for="(item, ind) in items" :key="item.id" class="feed-item">
            <Divider v-if="ind > 0" m="0"/>
            <FeedItem :item="item"
                      @action="onChat"
                      @read="onRead"
                      @dislike="onDislike(item.id, $event)"
                      @like="onLike(item.id, $event)"
                      @favorite="onFavorite(item.id)"
            />
          </Collapse>
        </TransitionGroup>
        <div v-if="feedAtend" class="divider delay-in-1000">
          <el-divider content-position="center" class="divider">
            <span class="text">
              No more items
            </span>
          </el-divider>
        </div>

        <div v-else class="row-hvcenter" style="margin-bottom:1.8rem">
          <Loader inline medium/>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="stylus" scoped>
.feed-inner {
  display: flex
  flex-direction: column
  height: 100%
  .empty {
    height: calc(100% - 0.8rem)
  }
  .head {
    top: 0
    padding: 1rem 2rem 1rem 1.6rem
    display: flex
    gap: 0.6rem
    align-items: center
    color: var(--gray-900)
    border-bottom: 0.1rem solid var(--gray-200)
    .controls {
      display: flex
      width: 100%
      gap: 2rem
      align-items: center
      font-size: 1.4rem
      font-weight: 400
      line-height: 3.5rem
      cursor: default
      .title {
        flex-grow: 1
      }
      .active {
        color: var(--brand-500)
        fill: var(--brand-500)
        stroke: var(--brand-500)
      }
    }
    .icon {
      margin-left: auto
    }
  }
  .refresh {
    flex-grow: 1
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    z-index: 1
    top: 7.1rem
    right: 0
    left: 0
    width: max-content
    margin: auto
    border-radius: 999.9rem
    background: var(--blue-500)
    border-color: transparent
    padding: 0.8rem 2.4rem
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.2), 0px 2px 4px -2px rgba(16, 24, 40, 0.1)
    animation: appear 0.2s ease-in
    transition: transform 0.2s ease-in
    transform: translateY(0rem)

    @keyframes appear {
      0% {
        transform: translateY(-11.9rem)
      }
      100% {
        transform: translateY(0rem)
      }
    }
    &.loading-new {
      transform: translateY(-11.9rem)
    }
  }

  .feed-content {
    flex-grow: 1
    transition: background 0.2s, width 0.2s
    border-top: none
    border-bottom: none
    overflow-y: overlay

    .bell {
      margin: 0 auto
    }

    .feed-items {
      display: flex
      flex-direction: column
      .feed-item {
        transition: grid-template-rows 300ms, opacity 200ms
        grid-template-rows: 1fr
        opacity: 1
        &.list-enter-active
        &.list-leave-active {
          grid-template-rows: 1fr
          opacity: 1
        }
        &.list-enter-from
        &.list-leave-to {
          grid-template-rows: 0fr
          opacity: 0
        }
      }
      & >:last-child {
        margin-bottom: 1rem
      }
    }
    .divider {
      padding: 0 1rem
      & > div {
        margin: 1.2rem 0
      }
      .text {
        color: var(--gray-500)
        font-weight: 400
      }
    }
  }
}
</style>

<script>
import FeedItem from './feed-item.vue'
// import Filter from './filter.vue'
import {Feed} from 'stores/feed'
import $global from 'stores/global'
import $chats from 'stores/chats'
import {Later} from 'utils/timers'
import {AGENTS} from 'consts/agents'
import {Unauthorized} from 'utils/errors'

export default {
  components: {FeedItem},
  props: {
    store: {type: Feed, required: true},
    collapsible: {type: Boolean, default: false},
    autorefresh: {type: Boolean, default: true}
  },
  emits: ['collapse'],
  data() {
    return {
      show_filter: false,
      load_new: new Later(30 * 1000),
      filters: {
        news: false,
        favorite: false,
        ...AGENTS.reduce((prev, agent) => {
          prev[agent.type] = false 
          return prev
        }, {})
      },
      onLike: $utils.timers.debounce((id, feedback) => this.store.like(id, feedback), 200),
      onDislike: $utils.timers.debounce((id, feedback) => this.store.dislike(id, feedback), 200),
      onFavorite: $utils.timers.debounce(id => this.onFavToggle(id), 200),
    }
  },
  computed: {
    items() {
      return this.store.items
    },
    loading() {
      return this.store.loading
    },
    unseen(){
      return this.store.unseen
    },
    empty() {
      return this.items.length === 0
    },
    feedAtend() {
      return this.items.length === this.store.total
    },
    filters_active(){
      return AGENTS.some(x => this.filters[x.type])
    },
  },
  async mounted() {
    if (this.autorefresh) {
      await this.store.load()
      this.load_new.repeat(async () => {
        try {
          await this.onLoadNew()
        }
        catch(err) {
          // Feed refresh somwehy fails on amdocs deployment regulairly
          // Fast fix to avoid UI reload
          // TODO: investigate properly
          console.log('failed to refresh feed', err)
          if (err instanceof Unauthorized) {
            throw err
          } 
        }
      })
    }
  },
  async beforeUnmount() {
    this.load_new.abort()
  },
  methods: {
    loadItems(params = {}) {
      let {news, ...filters} = this.filters
      const type = news ? 'news' : undefined

      filters = Object.entries(this.filters || {}).filter(x => x[1]).map(x => x[0])
      if (!filters.length) filters = undefined
      
      return this.store.load({filters, type, ...params})
    },
    async onWheel(ev) {
      if (this.loading) return
      if (!this.empty) return
      await this.loadItems()
    },
    async onLoadMore() {
      if (this.loading) return
      if (this.empty) return
      if (this.feedAtend) return
      await this.loadItems({more:true})
    },
    async onLoadNew() {
      if (this.loading) return
      if (this.empty) await this.loadItems()
      else await this.loadItems({new: true})
    },
    async onChat(user_query) {
      const chat = await $chats.newChat(user_query, 'chat')
      $global.goToChat(chat.id)
    },
    async onRead(id) {
      await this.store.markSeen(id)
    },
    async onFilter(filters) {
      this.filters = {...this.filters, ...filters}
      this.show_filter = false
      await this.loadItems()
    },
    async onFavToggle(id) {
      await this.store.favToggle(id, this.filters.favorite)
      if (this.filters.favorite && this.empty) {
        this.filters.favorite = false
        this.loadItems()
      }
    },
    async goToSettings() {
      $global.goToSettings('feed')
    }
  }
}
</script>
