.action {
  display: inline-flex;
  gap: 0.4rem;
  flex-direction: row;
  font-size: 1.2rem;
  color: var(--text-secondary);
  border-radius: 0.6rem;
  line-height: 1.8rem;
  padding: 0.4rem 0.8rem 0.4rem 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.action.feed .collapse {
  margin-top: -0.4rem;
}
.action.feed .collapse .strange-button {
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  border: 0.1rem solid;
  border-color: transparent;
  margin: 0.1rem 0.4rem 0.3rem 0.1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}
.action.feed .collapse .strange-button:hover {
  border-color: var(--gray-300);
  background: var(--base-white);
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(16,24,40,0.1), 0rem 0.1rem 0.2rem 0rem rgba(16,24,40,0.06);
}
.action.feed .icons {
  display: flex;
  align-self: flex-start;
  width: 1.6rem;
  height: 1.6rem;
  min-width: max-content;
}
.action.feed .icons .icon {
  position: absolute;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.action.feed .icons .active {
  opacity: 0;
}
.action:hover {
  color: var(--brand-700);
}
.action:hover .icons .icon {
  opacity: 0;
}
.action:hover .icons .active {
  opacity: 1;
}
