.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("assets/bg-pattern-decorative.png") no-repeat center calc(50% - 6rem);
  height: 100%;
  border-radius: 1.2rem;
  border: 1px solid var(--shadow);
  box-shadow: var(--box-shadow);
}
.empty .upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty .upload-wrapper .upload-icon {
  width: 10.4rem;
  height: 10.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
}
.empty .upload-wrapper .text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--text-primary);
  margin-top: 1.6rem;
  white-space: nowrap;
}
.empty .upload-wrapper .sub-text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--text-secondary);
  margin-bottom: 2.4rem;
}
