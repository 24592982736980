<template>
  <div class="admin">
    <Tabs v-model="active"
          class="tabs"
          :tabs="tabs" 
          bordered
          tab-position="right"
    />
    <Scraping v-if="active === 'scraping'"/>
    <UserTable v-if="active === 'user-table'"/>
    <TgData v-if="active === 'tg-data'"/>
  </div>
</template>
<style lang="stylus" scoped>
.admin {
  display: grid
  grid-template-columns: max-content auto
  gap: 3.2rem
  .tabs {
    height: max-content
    padding-right: 1.6rem
    border-right: 0.1rem solid var(--gray-200)
  }
}
</style>

<script setup>
import Scraping from './scraping.vue'
import UserTable from './user-table.vue'
import TgData from './tg-data.vue'
</script>

<script>
export default {
  data() {
    return {
      active: 'scraping',
      tabs: [
        {value: 'scraping', title: 'Scraping'},
        {value: 'user-table', title: 'User Table'},
        {value: 'tg-data', title: 'TG Data'}
      ]
    }
  }
}
</script>