<template>
  <div class="chats">
    <div class="chat-container">
      <NewChat v-if="mode_new"/>
      <Loader v-else-if="chat_loading">
        Loading chat data<br>
        Please wait...
      </Loader>
      <Chat v-else class="chat" :chat="chat"/>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.chats {
  background: var(--bg-primary)
  display: flex
  height: 100%

  .chat-container {
    flex-grow: 1
    padding: 0 0 0 0

    .chat {
      margin: 0 auto
      height: 100%
      display: flex
      flex-direction: column
    }
  }

  .feed {
    flex-basis: 41rem
  }
}
</style>

<script setup>
import Chat from './chat.vue'
import NewChat from './new-chat.vue'
</script>

<script>
import $chats from 'stores/chats'

export default {
  data() {
    return {
      chat: undefined
    }
  },
  computed: {
    mode_new () {
      return this.$route.query.id == undefined
    },
    chat_loading () {
      return this.chat === undefined || this.chat?.loading
    }
  },
  async mounted() {
    const chat_id = this.$route.query.id
    this.chat = $chats.getChat(chat_id)

    if (this.chat) {
      await this.chat.load()
    }
  },
  unmounted() {
    if (this.chat) {
      this.chat.abortGenerating()
      this.chat = undefined
    }
  }
}
</script>