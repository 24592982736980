<template>
  <CollapseMenu :loading="loading" 
                class="collapse-menu" 
                :title="title"
  >
    <div class="finances">
      <div v-if="stats" class="stats">
        <Segment v-for="stat in stats" :key="stat.title" class="stat-segment">
          <div class="stat">
            <div class="title ellipsis">
              {{ stat.title }}
            </div>
            <div class="value ellipsis">
              {{ stat.value }}
            </div>
            <div class="note ellipsis">
              {{ stat.note }}
            </div>
          </div>
        </Segment>
      </div>
      <Segment v-if="info" class="info">
        <MdContent :markdown="info"/>
      </Segment>
    </div>
  </CollapseMenu>
</template>
<style lang="stylus" scoped>
.finances {
  margin: 0 -1.6rem
  .stats {
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 1.2rem
    .stat-segment {
      padding: 1.6rem 1.2rem
      .stat {
        display: grid
        grid-template-areas: "title title" "value note"
        grid-template-columns: auto 1fr
        gap: 1.2rem 0.4rem
        .title {
          font-size: 1.4rem
          font-weight: 500
          line-height: 2rem
          grid-area: title
        }
        .value {
          font-size: 2.4rem
          font-weight: 600
          line-height: 2.1rem
          grid-area: value
        }
        .note {
          align-self: flex-end
          font-size: 1.6rem
          font-weight: 500
          line-height: 1
          grid-area: note
        }
      }
    }
  }
  .info {
    margin-top: 2.2rem
  }
}
</style>

<script setup>
import MdContent from 'components/md-content'
</script>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    title(){
      return this.data.title
    },
    stats () {
      return this.data.stats
    },
    info(){
      return this.data.info
    },
    loading(){
      return !this.stats
    }
  }
}
</script>