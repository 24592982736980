.toggle-wrapper {
  color: var(--gray-700);
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.toggle-wrapper .label {
  font-weight: 500;
  font-size: 1.4rem;
}
.toggle-wrapper .description {
  font-weight: 400;
  font-size: 1.2rem;
}
