.error.download-progress {
  background: var(--error-50);
}
.error .text {
  color: var(--error-600);
  white-space: nowrap;
}
.download-progress {
  display: grid;
  grid-template-areas: 'Info Progress Delete';
  grid-template-columns: 26rem 1fr auto;
  grid-template-rows: 1fr;
  background: var(--base-white);
  align-items: center;
}
.download-progress .file-info {
  grid-area: Info;
  display: flex;
  padding: 1.6rem 2rem 1.6rem 2.4rem;
  line-height: 2rem;
  gap: 1.2rem;
}
.download-progress .file-info .text {
  min-width: 0;
  font-size: 1.4rem;
}
.download-progress .file-info .text .size {
  color: var(--gray-600);
  font-weight: 400;
}
.download-progress .file-info .text .name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.download-progress .delete {
  grid-area: Delete;
  padding: 1.6rem 2.4rem 1.6rem 2rem;
  display: flex;
}
.download-progress .progress {
  grid-area: Progress;
  padding: 1.6rem 0;
}
.download-progress.inline {
  grid-template-areas: 'Info Info Delete' '. Progress Progress';
  grid-template-columns: 6.7rem 1fr auto;
  grid-template-rows: 1fr;
  padding: 0;
}
.download-progress.inline .progress {
  padding: 0 1.6rem 1.6rem 0;
}
.download-progress.inline .file-info {
  padding: 1.6rem 2rem 0 1.6rem;
}
.download-progress.inline .delete {
  padding: 0 1.6rem 0 0;
}
