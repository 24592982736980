<template>
  <Modal ref="modal" max_width="50%" min_width="30em">
    <template v-slot:content>
      <div class="text">Set file storage limit for {{ user.name }} ({{ user.email }})</div>
      <div class="input-row">
        <Input v-model="limit" class="input" type="number" min="0"/>
        <label class="bytes">bytes</label>
      </div>
    </template>
    <template v-slot:controls>
      <Button @click="submit">
        Set limit
      </Button>
      <Button @click="close">
        Cancel
      </Button>
    </template>
  </Modal>
</template>

<style lang="stylus" scoped>
.bytes {
  padding-left: 4px
  padding-top: 6px
}

.input-row {
  display: flex
  flex-direction: row
  .input {
    max-width: 46px
    min-width: 25rem
    margin-right: 1rem
    margin-left: -26px
    width: 100%
  }
}

input::-webkit-outer-spin-button
input::-webkit-inner-spin-button {
  -webkit-appearance: none
  margin: 0
}
.ui.basic.label {
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 0 !important
  .divider {
    height: 1px
    width: 100%
    background: rgba(34,36,38, 0.15)
  }
  i {
    margin: 0
    text-align: center
    padding: 0 10px
    box-sizing: border-box
    color: rgba(34,36,38, 0.6)
  }
}
</style>

<script>
export default {
  emits: ['update'],
  data () {
    return {
      user: {},
      limit: 0
    }
  },
  methods: {
    open(user) {
      this.user = user
      this.limit = String(user.storage_limit)
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    },
    async submit() {
      const data = {storage_limit: +this.limit}
      this.$emit('update', this.user.id, data)
      this.close()
    }
  }
}
</script>